import { FRONTEND_DATA } from "../../../globals";
import { 
	retrieveSequentualNodesEffectivelyContainedByRange,
	getNodesInRangeFilteredBySelector,
} from "../helpers";
import { helpers } from "@cargo/common";

import _ from 'lodash';

export default {

	requiresTextInRange: true,
	requiresUncollapsedRange: true,
	requiresActiveRange: true,
	ignoreButtonInUseState: true,
	suppressWindowAfterExecution: true,

	defaults: {
		speed: -10,
		direction: 'horizontal',
		behavior: 'scroll',
		'pointer-interaction': true,
		height: '70vh'
	},

	execute: function(){


		var range = FRONTEND_DATA.contentWindow.CargoEditor.getActiveRange();

		if(range == undefined) {
			return null;
		}

		if(this.getState(range).isApplied){
			return
		}

		FRONTEND_DATA.contentWindow.CargoEditor.mutationManager.execute(()=>{

			// If the range includes nodes with uses="fit-text", remove fit text from the uses attribute
			const usesNodes = getNodesInRangeFilteredBySelector('[uses]', range);
			usesNodes.forEach((node)=>{
				node.removeAttribute('uses');
				if (node.tagName === 'SPAN' && node.hasAttributes() === false) {
					const firstChild = node.firstChild;
					const lastChild = node.lastChild;
					FRONTEND_DATA.contentWindow.CargoEditor.helpers.unwrap(node, true);
					const range = FRONTEND_DATA.contentWindow.CargoEditor.rangy.createRange();
					range.setStartBefore(firstChild);
					range.setEndAfter(lastChild);
					FRONTEND_DATA.contentWindow.CargoEditor.helpers.setActiveRange(range);
				}
			});

		    const sessionState = window.store.getState().adminState.session;

		    let marqueeSettings = sessionState['last-marquee-settings'] ?? {...this.defaults};
			let marqueeSet = FRONTEND_DATA.contentWindow.document.createElement('marquee-set');

			marqueeSet._newMarqueeSet = true;

			Object.entries(marqueeSettings).forEach(([name, value])=>{
				marqueeSet.setAttribute(name, value)
			});
		
			range = FRONTEND_DATA.contentWindow.CargoEditor.helpers.growRangeToWidestBoundaries(range);

			let frag = FRONTEND_DATA.contentWindow.CargoEditor.helpers.advancedSplitRange(range).extractContents();
			
			while(frag.childNodes.length > 0) {
				marqueeSet.appendChild(frag.childNodes[0]);
			}
			
			range.insertNode(marqueeSet);	

			if (range.commonAncestorContainer.nodeType === Node.ELEMENT_NODE && 
				range.commonAncestorContainer.hasAttribute('uses')
			) {
				range.commonAncestorContainer.removeAttribute('uses');
				// Remove attributes associated with each uses default
				const usesDefaultAttrs = ['rotation', 'animate', 'blink-speed', 'blink-duration'];
				usesDefaultAttrs.forEach((attr)=>{
					range.commonAncestorContainer.removeAttribute(attr);
				});

				if (range.commonAncestorContainer.tagName === 'SPAN' && range.commonAncestorContainer.hasAttributes() === false) {
					const firstChild = range.commonAncestorContainer.firstChild;
					const lastChild = range.commonAncestorContainer.lastChild;
					FRONTEND_DATA.contentWindow.CargoEditor.helpers.unwrap(range.commonAncestorContainer, true);
					const newRange = FRONTEND_DATA.contentWindow.CargoEditor.rangy.createRange();
					newRange.setStartBefore(firstChild);
					newRange.setEndAfter(lastChild);
					FRONTEND_DATA.contentWindow.CargoEditor.helpers.setActiveRange(newRange);
				}
			}

		});

	},

	getState: function(range) {
		const marqueeNodes = retrieveSequentualNodesEffectivelyContainedByRange('marquee-set', range);

		const galleryNode = helpers.closest(range.commonAncestorContainer, (parentNode)=>{
			return parentNode.nodeName.startsWith('GALLERY-');
		});

		return {
			isApplied: marqueeNodes.length > 0,
			isAllowed: !range.collapsed && !galleryNode,
		};
	},		
	priority: 1
 
}