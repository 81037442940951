import React, { Component } from 'react';
import _ from 'lodash';
import { HotKey } from "./";

// Serves as an importable hotkey component that can be fed into a global component 
// that couldn't otherwise import the local hotkey instance.

export const HotKeyProxy = ( shortcut, boundTo, callbackFunction ) => { 

	return (
		<HotKey
	 		shortcut={ shortcut }
	 		boundTo={ boundTo }
	 		callback={ callbackFunction }
	 	/> 
	)

}

