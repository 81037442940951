import React, { Component, useEffect, useState, useRef, useLayoutEffect } from 'react';
import _ from 'lodash';
import FourSidedIcon from "../../svg-icons/four-sided.svg";
import * as actions from "../../actions";

export const RangeExtension = ({open, sessionStorage, children, text, icon, noGap, checkbox, disabled, className, firstField, noMargin, ...props}) => {

  const menuStates = {
      isOpen       : 'isOpen',
      measureOpen  : 'measureOpen',
      isOpening    : 'isOpening',
      measureClose : 'measureClose',
      isClosing    : 'isClosing',
      isClosed     : 'isClosed'
  }

  const [menuState, setMenuState]   = useState('isClosed');
  const [menuHeight, setMenuHeight] = useState(null);
  // Ref used to measure content height
  const contentRef = useRef();

    useEffect(() => {

        if (sessionStorage && getSessionStorage() !== null) {
            setMenuState(getSessionStorage() ? menuStates.isOpen : menuStates.isClosed);
        }

        return () => { 
        // cleanup function / similar to componentWillUnmount
        }

    }, []);

    useEffect(() => {
        if( menuState === 'measureClose' ){
            setMenuState(menuStates.isClosing)
        }
    }, [menuHeight]);

    const getSessionStorage = () => {
        const sessionState = window.store.getState().adminState.session;
        return sessionState[sessionStorage] ?? null;
    }

    const setSessionStorage = value => {
        if (sessionStorage) {
            window.store.dispatch({
                type: actions.actionTypes.UPDATE_ADMIN_STATE, 
                    payload: {
                        session: {
                            [sessionStorage]: value
                        }
                }
            });
        }
    };

  const handleToggle = (e) => {

      if( e ){
          e.preventDefault()
          if( e.target.closest('.range') ) return
          if( e.target.closest('.scrubber' ) ) return
          if( e.type == 'toggle' ) return
          if( e.target.nodeName !== 'DIV' ) return
      }

      if( menuState === 'measureOpen' || menuState === 'measureClose' || menuState === 'isOpening' || menuState === 'isClosing' ) return
      // Blur to prevent spacebar from toggling open/closed
      // contentRef?.current.blur();

      if( menuState === 'isClosed' ){
          setMenuState( menuStates.measureOpen );
          setSessionStorage(true);
      } else {
          setMenuState( menuStates.measureClose );
          setSessionStorage(false);
      }

  }

  const getMenuHeight = () => {

      let styles          = getComputedStyle( contentRef.current ),
          verticalPadding = parseInt(styles.paddingTop) + parseInt(styles.paddingBottom),
          height          = ( contentRef.current.scrollHeight + verticalPadding ) - 1;

      setMenuHeight(height);

      if( menuState === 'measureClose' && menuHeight !== null ){
          // if the height doesn't change, we won't run the menuHeight useEffect
          // So we need to hit this here...
          setMenuState(menuStates.isClosing)
      }

  }

  useLayoutEffect(() => { 
      if( menuState === 'measureOpen' ){
          setMenuHeight(0);
      }

      if( menuState === 'measureClose' ){
          getMenuHeight();
      }

      if( menuState === 'isClosing' ){
          setMenuHeight(0);
      }

  },[menuState])

  useLayoutEffect(()=>{
      if( menuState === 'measureOpen' && menuHeight === 0 ){
          requestAnimationFrame( () => {
              getMenuHeight();
          })
      } else if ( menuState === 'measureOpen' && menuHeight !== 0 ){
              requestAnimationFrame( () => {
          setMenuState(menuStates.isOpening);
        })
      }

  },[menuHeight])

  const updateAfterTransition = () => {

      if( menuState === 'isClosing' ){
          // Clean up inline styles after closing transition complete
          // and complete closing operations
          setMenuState( menuStates.isClosed );
          setMenuHeight( null );

      }

      if( menuState === 'isOpening' ){
          setMenuState( menuStates.isOpen );

      }

  }

  let styles = {
      height: menuHeight,
      minHeight: 0,
      overflow: "hidden",
      transition: "height 100ms ease-in-out, padding 100ms ease-in-out"
  }

  // className="range-extension-svg"
  // label={<FourSidedIcon/>} 

  return (
    <details 
        open={ menuState !== 'isClosed' }
        className={`range-extension${className ? ' '+className : ''}${ menuState === 'isClosing' ? ' closing' : ''}${menuState !== 'isClosed' ? ' bars' : ''}`}
        onClick={(e) => { 
            e.preventDefault();
            e.stopPropagation();
        }}
        disabled={ disabled ? true : null }
    >
        <summary
            onMouseDown={(e)=> {
                handleToggle(e);
            }}
        >
            { firstField }
            <div className="summary-row">
                <div className="range-extension-toggle">
                  <FourSidedIcon/>
                </div>
            </div>
    
        </summary>
        <div 
            ref={ contentRef }
            onTransitionEnd={(e)=> { updateAfterTransition(e); }}
            className={`content ${noGap ? "" : "gap"}`}
            style={ ( menuState !== 'isOpen' && menuState !== 'isClosed' ) ?
                {
                    height: menuHeight,
                    minHeight: 0,
                    overflow: "hidden",
                    transition: "height 100ms ease-in-out, padding 100ms ease-in-out"
                } : ( null ) }
        >
            { menuState !== 'isClosed' && children && <>
                {children }
                {!noMargin && <div className="uiWindow-spacer"></div>}
            </>}
        </div>
    </details>
  )

}
