import React, {Component, Fragment, useState} from 'react';


export const Input = ({field, className, autocomplete, innerRef, form, label, placeholder, type, icon, iconClass, noBars, barLeft, barRight, focusOnInit, ...props }) => {

		const [focused, setFocused] = useState(false);

		const inputEl = (
			<div className={`text-input${className && !label ? ' '+className : ''}${noBars ? '' : ' bars'}${barLeft ? ' bar-left' : ''}${barRight ? ' bar-right' : ''}${focused ? ' focused' : ''}`} >
				<input 
					type={`${type ? type : 'text'}`}
					value={field.value} 
					ref={innerRef ? innerRef: null}
					autoComplete={ !autocomplete ? "off" : null }
					onFocus={() => { setFocused(true) }}
					onBlur={() => { setFocused(false) }}
					onChange={e => { 

						if ( form ) {
							form.setFieldValue(
								field.name, 
								e.currentTarget.value, 
								false
							)
						}

					}} 
					placeholder={placeholder}
					autoFocus={focusOnInit ? true : false }
				/>
			</div>
		)

		if( label ){
			return(
				<div className={`label-input-group${className ? ' '+className : ''}`}>
					<div 
						className={`input-label ${noBars ? '' : 'bars'}`}
						onClick={(e)=> {
							let inputEl = e.currentTarget.nextElementSibling.querySelector('input');
							let len = inputEl?.value.length;

						    if( len && len !== undefined ){
						        inputEl.focus();
						        inputEl.setSelectionRange(len, len+1)
						    } else if ( len === 0 ){
						    	inputEl.focus();
						    }
						}}
					>
						{ label }
					</div>
					{ inputEl }
				</div>
			)
		}

		if( icon ){
			return(
				<div className="input-icon-group">
					{ inputEl }
					<div className={`input-icon ${iconClass ? iconClass : ''}`} >
						{ icon }
					</div>
				</div>
			)
		}

		return inputEl

}

