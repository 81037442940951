import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { actions } from "../../actions";

import { Formik, Field } from 'formik';
import FormikChangeListener from "./formik-change-listener";

import { RadioButton } from "./";
import { AspectRatioButtons } from "./aspect-ratio-buttons";

import { FRONTEND_DATA } from "../../globals";
import _ from 'lodash';

class RatioMenuComponent extends Component {

		constructor(props) {
		    super(props);

		    this.state = { activeRatio: null } 

		    this.ratioButtons = AspectRatioButtons;
		}

		RatioOption = (index) => {

			let data = this.ratioButtons[index];
			// Render a normal button...
			return (
				<Field component={RadioButton} key={data.name} name="ratio" value={data.name} label={data.label} />
			)

		}

		render() {

			let initialValue = this.ratioButtons.find(obj => {
				// return obj.ratio === this.props.adminState.mobileRatio;
				return obj.ratio === 0.4615;
			})

			initialValue = _.get(initialValue, 'name');

			return (
				<div 
					id="ratio-menu"
				>
					<div className="ratio-menu-content">
						<div className="">
							<Formik
								// this is important for websocket updating
								enableReinitialize
								// defaults are set here
								initialValues={{ 
									ratio : initialValue,
								}}
							>
								<>
									<FormikChangeListener onChange={this.onChange}/>

									<div className="radio-button-group">
										{this.ratioButtons.map((button, index) => {
											return this.RatioOption(index)
										})}
									</div>

								</>
							</Formik>
						</div>
					</div>
				</div>
			);
		}

		onChange = (changes) => {
			let newAspectRatio = _.get(changes, 'ratio');
			if( newAspectRatio ){
				let newValue = this.ratioButtons.find(obj => {
					  return obj.name === newAspectRatio;
					})
				newValue = newValue.ratio;
				this.props.updateAdminState({mobileRatio : newValue})
			}
		}

}

function mapReduxStateToProps(state, ownProps) {

	return {
		adminState: state.adminState,
	};

}

function mapDispatchToProps(dispatch) {

	return bindActionCreators({
		updateAdminState: actions.updateAdminState,
	}, dispatch);

}

export const RatioMenu = connect(
	mapReduxStateToProps, 
	mapDispatchToProps
)(RatioMenuComponent);
