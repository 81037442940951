import { FRONTEND_DATA } from "../../../globals";

import _ from 'lodash';


export default {

	execute: function(){

		 
	},

	getState: (range)=> {

		let tagNames = ['IMG', 'VIDEO', 'IFRAME'];
		let mediaItemNodes = [];
		if( range){

			let mediaNodes = range.getNodes([Node.ELEMENT_NODE], function(node) {
			    return tagNames.includes(node.tagName) || (node.hasAttribute('width') && node.hasAttribute('height'))
			});	

			if( mediaNodes.length > 0){
				mediaItemNodes = _.compact(mediaNodes.map((node)=>node.closest('MEDIA-ITEM')));
			}

		}

		return {
			isApplied: mediaItemNodes.length > 0,
			isAllowed: mediaItemNodes.length > 0,
		};
	},	
	priority: 1
 
}