import { FRONTEND_DATA } from "../../../globals";
import dropshadow from "./dropshadow";
import uses from "./uses";
import border from "./border";

import _ from 'lodash';

export default {

	requiresTextInRange: false,
	requiresUncollapsedRange: true,
	requiresActiveRange: true,


	execute: function(){
		
		return

	},
	
	getState: (range) => {

		const dropshadowState = dropshadow.getState();
		const borderState = border.getState();
		const usesState = uses.getState();

		const states = {
			dropshadow: dropshadow.getState(),
			border: border.getState(),
			uses: uses.getState()
		}

		const skip = ['fittext', 'node'];

		return {
			isAllowed: Object.keys(states).some((key) => states[key].isAllowed === true),
			isApplied: Object.keys(states).some((key) => {
				if (key === 'uses' && states[key].isApplied === true) {
					const matches = Object.keys(states[key].matches).filter((matchKey) => {
						return states[key].matches[matchKey] ? !skip.includes(matchKey) : false;
					});
					if (matches.length === 0){
						return false;
					}
				}
				if (states[key].isApplied === true) {
					return true;
				}
				return false;
			}),
		}

	},
	priority: 1
}