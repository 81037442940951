import React, { useState } from 'react';
import { connect } from 'react-redux';
import { actions } from "../../actions";
import * as sessionActions from "../../actions";
import { bindActionCreators } from 'redux';
import { RadioButton, ColorPicker } from "./";
import ColorPickerModal from "./color-picker-modal";
import { Formik, Field, Form } from 'formik';

import { setColorPickerPosition } from "./helpers";

import _ from 'lodash';
import tinycolor from 'tinycolor2'

import FormikChangeListener from "./formik-change-listener";


const TabList = (tabs, mode, setMode) => tabs.map((tab, index) => {

    const sanitizedName = String(tab.name).replace(/,/g, '-').replace(/\./g, '');
    const generated_id = "radio" + '/' + sanitizedName;

    return (

        <div 
            className={`radio-button${ _.isEqual(parseInt(index), parseInt(mode)) ? ' checked' : ''}`}
            key={String(tab.name)}
            onMouseDown={(e)=> {
                setMode( index );
            }}
        >
            <input
                type="radio"
                id={generated_id}
                value={ _.isEqual(parseInt(index), parseInt(mode)) } 
                checked={ _.isEqual(parseInt(index), parseInt(mode)) }
                onChange={(e)=>{ }}
                // onBlur={field.onBlur}
            />
            <label htmlFor={generated_id} >
                {tab.label}
            </label>
        </div>
        
    )

})

class ColorPickerTabs extends React.Component {

    constructor(props) {
        super(props);

        let initialTab   = 0;
        let firstTabVal  = _.get(this.props.form.values, this.props.tabs[0].name);
        let secondTabVal = _.get(this.props.form.values, this.props.tabs[1].name);

        if( this.props.ignoreTabPriority === true && !firstTabVal ){

            this.state = {
                mode: String(initialTab),
                tabs: this.props.tabs,
            }

            return
        }

        if( ( tinycolor( firstTabVal ).getAlpha() === 0 || !firstTabVal )
            && tinycolor( secondTabVal ).getAlpha() !== 0
            && secondTabVal
        ){
            // If first color tab is transparent and 2nd color tab is not
            // open to secondary tab by default.
            initialTab = 1;
        }

        // if this menu uses session storage, set the open state
        if ( this.getSessionStorage() !== null ) {
            initialTab = this.getSessionStorage();
        }

        this.state = {
            mode: String(initialTab),
            tabs: this.props.tabs,
        }

    }

    colorPickerInstance = (index) => {
        
        let tab = this.props.tabs[index];

        const tabValue = _.get(this.props.form.values, tab.name);
        let colorString = tabValue || this.props.deriveColorString( name );

        if( !tabValue && !colorString ){
            colorString = 'rgba(0,0,0,0)';
        }

        return (
            <ColorPickerModal 
                key             = { String(tab.name) }

                form            = { this.props.form }
                name            = { tab.name }

                colorString     = { colorString } 

                tabbed          = { true }
                tabs            = { this.props.tabs }

                updateFieldOnChange = { this.props.updateFieldOnChange }
                removeRule          = {(name) => { this.props.removeRule(name) }}
             />
        )
    }

    render(){

        return (
            <div className = {`picker-tab-shell`}>
                <div className={`inner-shell`}>

                    <div className="picker-toggle">
                       { TabList( this.props.tabs, this.state.mode, this.setMode ) }
                    </div>

                    {/* Color picker UI */}
                    {this.state.tabs.map((tab, index) => {
                        if( this.state.mode == index ){
                            return this.colorPickerInstance(index)
                        }
                    })}
                </div>
            </div>
        )

    }

    setMode = (value) => {
        // Update tab props before swap...
        this.props.updateTabProps();
        // Swap tabs
        this.setState({ mode : value })
    }

    componentDidMount = () => {

        let existingPicker = _.find(this.props.uiWindows.byId, (uiWindow) => { return uiWindow.id === 'color-palette' || uiWindow.id === 'formatting/textcolor' });
        // If we have an existing tabbed color picker, and it's not our current tabbed picker
        if( existingPicker && !existingPicker.id.includes( this.props.windowName ) ){
            // remove the existing color picker
            this.props.removeUIWindow( existingPicker.id );
            // If it's not the textcolor picker
            if( !existingPicker.id.includes('textcolor') ){
                // Find the original color picker's invoke window ( should be disabled )
                let windowToEnable = _.find(this.props.uiWindows.byId, (uiWindow) => {
                    return uiWindow.id === existingPicker.props.invokeWindow
                });
                //Re-enable pointer events on invoke window....
                if( windowToEnable ){
                    windowToEnable?.props?.reference?.current?.removeAttribute('prevent-events');
                }
            }
        }

    }

    getSessionStorage = () => {
        const sessionState = window.store.getState().adminState.session;
        return sessionState[this.props.sessionStorage] ?? null;
    }

    setSessionStorage = value => {
        if (this.props.sessionStorage) {
            window.store.dispatch({
                type: sessionActions.actionTypes.UPDATE_ADMIN_STATE, 
                payload: {
                    session: {
                        [this.props.sessionStorage]: value
                    }
                }
            });
        }
        
    };

    componentWillUnmount = () => {
        this.setSessionStorage( this.state.mode );
    }

    componentDidUpdate = (prevProps, prevState) => {

        // textcolor modal draws source of truth from DOM rather than form values.
        // Look here if color picker is unexpectedly recieving wrong tab color on update.
        if( this.props.windowName !== 'textcolor' &&
            prevProps.form.values !== this.props.form.values
        ){
            // We are dragging, don't update.
            if( this.props.dragging !== prevProps.dragging ){
                return
            }
        }
       
    }


}


function mapReduxStateToProps(state, ownProps) {

    return {
        uiWindows : state.uiWindows,
        adminState: state.adminState,
    };

}

function mapDispatchToProps(dispatch) {
    
    return bindActionCreators({
        removeUIWindow: actions.removeUIWindow
    }, dispatch);

}

export default connect(
    mapReduxStateToProps, 
    mapDispatchToProps
)(ColorPickerTabs);
