import React, { useEffect, useRef, useState, useCallback} from "react";
import _ from 'lodash';

export const TextArea = ({
	form,
	field,
	name,
	className,
	bars = true,
	placeholder,
	maxLength,
	rows,
	staticHeight,
	focusOnInit,
	onKeyPress,
	onBlur,
	onPaste,
	spellcheck,
	disabled,
	...props
}) => {

		const textareaRef = useRef(null);
		const [currentValue, setCurrentValue ] = useState("");

		useEffect(() => {
		    if( !staticHeight ){
		    	textareaRef.current.style.minHeight = "";
		    	textareaRef.current.style.height = "";
		    	const scrollHeight = textareaRef.current.scrollHeight;
		    	textareaRef.current.style.minHeight = scrollHeight + "px";
		    	textareaRef.current.style.height = scrollHeight + "px";
		    }
		}, [currentValue, field.value]);

		const focusInputEnd = () => { 
		    let inputLength = textareaRef?.current?.value.length;
		    if( textareaRef?.current && inputLength !== undefined ){
		        textareaRef?.current.focus();
		        textareaRef?.current.setSelectionRange(inputLength, inputLength+1)
		    }
		}

		useEffect(() => {
			if( focusOnInit ){
				setTimeout(()=>{
					focusInputEnd();	
				}, 5)
				
			}

		},[])

		return(
			<div
				className={`text-area${className ? ' '+className : ''}${bars === true ? ' bars' : ''} `}
				style={disabled === true ? {pointerEvents: 'none', opacity: 0.5} : null}
				onMouseDown={ focusInputEnd }
			> 
				<textarea 
					spellCheck={spellcheck !== undefined ? spellcheck : null}
					onKeyPress={onKeyPress}
					onBlur={onBlur}
					onPaste={onPaste}
					name={name}
					maxLength={maxLength ? maxLength : '600'}
					type="text"
					placeholder={placeholder ? placeholder : ''}
					rows={rows ? rows : '2'}
					ref={textareaRef}
					value={field.value}
					id={props?.id}
					autoFocus={props.autoFocus}
					onChange={e => { 

						setCurrentValue(e.target.value);

						if ( form ) {
							form.setFieldValue(
								field.name, 
								e.currentTarget.value, 
								false
							)
						}

					}} 
				>
				</textarea>	
			</div>
		)
		
}

