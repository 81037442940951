import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { TooltipContext } from "@cargo/common/tooltip";

import { FRONTEND_DATA } from "../../globals";

// Identical to the Button component in packages/ui-kit/button/button.js
// except for the import statement at the top of the file
// and the the ComponentDidUpdate function. Since Button is one of the
// most widely used components, we did not want to bog app performance with
// this extra function, but it was necessary to get the desired behavior.

export class CommerceRightMenuBarButton extends Component {

	constructor(props) {
		super(props);
		this.buttonRef = React.createRef();
	}

	componentDidMount() {
		const globalDragEventController = FRONTEND_DATA?.globals?.ADMIN_FRAME?.globalDragEventController;
		if ( globalDragEventController && this.props.onDragOver) {
			globalDragEventController.on('dragover', this.props.onDragOver);
		}
		if ( globalDragEventController && this.props.onDragEnter) {
			globalDragEventController.on('dragenter', this.props.onDragEnter);
		}
		if ( globalDragEventController && this.props.onDragLeave) {
			globalDragEventController.on('dragleave', this.props.onDragLeave);
		}
		if ( globalDragEventController && this.props.onDrop) {
			globalDragEventController.on('drop', this.props.onDrop);
		}
	}

	componentWillUnmount() {
		const globalDragEventController = FRONTEND_DATA?.globals?.ADMIN_FRAME?.globalDragEventController;
		if ( globalDragEventController && this.props.onDragOver) {
			globalDragEventController.off('dragover', this.props.onDragOver);
		}
		if ( globalDragEventController && this.props.onDragEnter) {
			globalDragEventController.off('dragenter', this.props.onDragEnter);
		}
		if ( globalDragEventController && this.props.onDragLeave) {
			globalDragEventController.off('dragleave', this.props.onDragLeave);
		}
		if ( globalDragEventController && this.props.onDrop) {
			globalDragEventController.off('drop', this.props.onDrop);
		}
	}

    componentDidUpdate = (prevProps) => {
        // This is why this component exists. If this is not being utalized
        // please use the Button component from packages/ui-kit/button/button.js
        if( prevProps?.className.includes('blocked') 
            && !this.props?.className.includes('blocked') 
            && !this.props.productFetchRequired 
            && this.props.updateAdminState 
        ){
            this.props.updateAdminState({
                productFetchRequired: true
            })
        }
    }

	render() {

		const {
			label,
			labelHtml, 
			icon, 
			title, 
			innerPadding, 
			className, 
			toolTipIcon, 
			innerRef, 
			children, 
			iconLabel, 
			uiWindowIsOpen, 
			onDrop,
			onDragOver,
			onDragEnter,
			onDragLeave,
			onMouseDown, 
			onPointerEnter, 
			onPointerLeave,
			tooltipOffsetX,
			tooltipOffsetY,
            updateAdminState,
            productFetchRequired,
			...otherProps
		} = this.props;

		return (
			<TooltipContext.Consumer>
			    {(Tooltip) => 
					<button
						{...otherProps}
						className={`${ this.props.className ? this.props.className : ''}${iconLabel ? ' icon-first' : ''}`}
						title={title}
						ref={this.buttonRef}
						onPointerEnter={(e) => {

							if (onPointerEnter) {
								onPointerEnter(e)
							}

							if( !this.props.tooltip ){ return }
							if( uiWindowIsOpen ){ return }

							let buttonPos = this.buttonRef.current.getBoundingClientRect();

							Tooltip.openTip?.({
							    innerText: this.props.tooltip,
							    shortcutText: null,
							    icon: toolTipIcon,
							    buttonPos: {
							    	x: buttonPos.x,
							    	y: buttonPos.y,
							    	width: buttonPos.width,
							    	height: buttonPos.height,
							    	customOffsetX: this.props.tooltipOffsetX,
							    	customOffsetY: this.props.tooltipOffsetY
							    },
							    event: e,
							})
							
						}}
						onPointerLeave={(e) => { 

							if (onPointerLeave) {
								onPointerLeave(e)
							}

							if( !this.props.tooltip ){ return } 
							Tooltip.closeTip?.(e) 

		
						}}
						onMouseDown={(e) => { 

							if (this.props.tooltip) {
								Tooltip.closeTip?.(e)
							}

							if (this.props.type === 'link') {
								// open URL in new tab
								if (e.metaKey === true || e.ctrlKey === true ) {
									window.open(this.props.href, '_blank');
								// open URL in same window
								} else {
									window.location.href = this.props.href
								}
							}

							// run the button's original pointerDown method
							if (onMouseDown) {
								onMouseDown(e)
							}

						}}
						type={ this.props.type ? ( this.props.type ) : ( "button" )}
					>
						<>
							{iconLabel ? ( 
								<>
									{icon}
									{label ?? (labelHtml && <span dangerouslySetInnerHTML={{__html: labelHtml}}></span>)}
								</>
							) : ( 
								<>
									{label ?? (labelHtml && <span dangerouslySetInnerHTML={{__html: labelHtml}}></span>)}
									{icon}
								</>
							)}
							{children}
						</>
					</button>
				}
			</TooltipContext.Consumer>
		);

	}

};