import { FRONTEND_DATA } from "../../../globals";

import { 
	rangeCanBeModifiedByTextCommands,
} from "../helpers";

import _ from 'lodash';

import bold from './bold';
import italic from './italic';
import smallcaps from "./smallcaps";
import superscript from "./superscript";
import subscript from "./subscript";
import underline from "./underline";
import strikethrough from "./strikethrough";
import nowrap from './nowrap';
import fontsize from './fontsize';
import textcolor from './textcolor';
import backcolor from './backcolor';
import lineheight from './lineheight';
import uses from './uses';

export default {

	requiresTextInRange: true,
	requiresUncollapsedRange: true,
	requiresActiveRange: true,

	getState: function(range) {

		if( range && range.commonAncestorContainer && range.commonAncestorContainer.parentElement.closest('[thumbnail-index]') || range.commonAncestorContainer.hasAttribute?.('thumbnail-index')){
			return {
				isApplied: false,
				isAllowed: false,
			}
		}

		const isApplied = rangeCanBeModifiedByTextCommands(range) &&
			(
				bold.getState().isApplied
				||	italic.getState().isApplied
				||	smallcaps.getState().isApplied
				||	superscript.getState().isApplied
				||	subscript.getState().isApplied
				||	underline.getState().isApplied
				||	strikethrough.getState().isApplied
				||	nowrap.getState().isApplied
				||  fontsize.getState().isApplied
				||	textcolor.getState().isApplied
				||	backcolor.getState().isApplied
				||	lineheight.getState().isApplied
				||  uses.getState().matches?.fittext
				|| range?.commonAncestorContainer?.parentElement?.closest('[uses*="fit-text"]')
			)

		return {
			isApplied: isApplied,
			isAllowed: rangeCanBeModifiedByTextCommands(range),
		};
	},
	priority: 1
 
}