import { FRONTEND_DATA } from "../../../globals";

import _ from 'lodash';

let selectedProductWidgetElement = null;

export default {

	requiresTextInRange: false,
	requiresUncollapsedRange: false,
	requiresActiveRange: true,


	execute: function(productData){

		var range = FRONTEND_DATA.contentWindow.CargoEditor.getActiveRange();

		if(range == undefined){
			return
		}

		// if( !selectedProductWidgetElement ){
			
			FRONTEND_DATA.contentWindow.CargoEditor.mutationManager.execute(()=>{

				let shopProduct = FRONTEND_DATA.contentWindow.document.createElement('SHOP-PRODUCT');

				shopProduct.setAttribute('product', productData.product)
				shopProduct.setAttribute('variant', productData.variant)

				range.insertNode(shopProduct);

				range.setStartAfter(shopProduct);
				range.setEndAfter(shopProduct);

				// Set new active range
				FRONTEND_DATA.contentWindow.CargoEditor.helpers.setActiveRange(range);

				const rect = shopProduct.getBoundingClientRect();
				const svgPosition = {
					x: rect.x + -10,
					y: rect.y,
					left: rect.right + -10,
					right: rect.right + -10,
					top: rect.top + 0,
					bottom: rect.top + 27,
					width: rect.width,
					height: 10
				}

				window.UIWindowOpener.openUIWindow({
					windowName: 'commerce-ineditor',
					windowAlias: 'commerce',
					positionRect: svgPosition,
					closeButton: false,
					closeOnAllClickout: true,
					supportsMobile: true,
					props: {
						shopProductNode: shopProduct
					}
				});		

				this.getState(range);
			});

		// }

	},

	getState: function(range) {

		let products = window.store.getState()?.commerce?.products;
		let hasProducts = products ? Object.keys(products).length > 0 : false;

		return {
			isApplied: false,
			isAllowed: hasProducts,
			selectedProductWidgetElement
		};
	},
	priority: 1
 
}