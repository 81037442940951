import React, {Component, Fragment, useRef, useEffect} from 'react';
import ReactDOM from 'react-dom';

import _ from 'lodash';

let previouslyShownTooltipCount = 0;

export class ToolTip extends Component {

	constructor(props) {
		super(props);

		this.state = {
			position: {
				x: 0,
				y: 0
			},
			visible: 'none',
			arrowSide: 'right',
			arrowJustify: 'center'
		}

		this.toolTipRef = React.createRef(null);
	}

	componentDidMount() {

		const toolTipEl = this.toolTipRef.current;
		const { innerWidth: windowWidth, innerHeight: windowHeight } = window;

		if (this.props.buttonPos) {
			this.setToolTipPosition(toolTipEl, window, this.props.buttonPos )
		}

	}

	componentWillUnmount(){
		// Clear interval
		if(this.toolTipTimeout){
			clearTimeout(this.toolTipTimeout)
		}

		if(this.state.visible === 'visible') {
			previouslyShownTooltipCount++;
			setTimeout(() => {
				previouslyShownTooltipCount--;
			}, 300);
		}

	}

	setToolTipPosition(toolTipEl, window, buttonPos ) {

		this.setState({ 

			visible: 'measure',
			x: 0,
			y: 0

		 }, ()=>{

	 		let toolTipWidth  = toolTipEl.offsetWidth,
	 		    toolTipHeight = toolTipEl.offsetHeight,
	 	        viewWidth     = window.document.documentElement.clientWidth,
	 	        viewHeight    = window.document.documentElement.clientHeight,
	 	        buttonX       = buttonPos.x,
	 	        buttonY       = buttonPos.y,
	 	        x             = null,
	 	        y             = null,
	 	        rowHeight     = Math.min(buttonPos.height, buttonPos.width),
	 	        arrowBuffer   = 9;

	 			if( viewWidth - buttonX <= rowHeight ){
	 				
	 				let toolTipCenter = toolTipHeight / 2;
	 				let buttonCenter = buttonY + ( rowHeight / 2 )
	 				// open left of button
	 				x = ( toolTipWidth + arrowBuffer ) * -1;
	 				y = ( buttonCenter - toolTipCenter ) - rowHeight;

	 				this.setState({
	 					arrowSide: 'right',
	 					arrowJustify: 'center'
	 				})

	 				if( buttonX + rowHeight == viewWidth && buttonY == 0 ){
	 					y = ( rowHeight + arrowBuffer ); 
	 					x = viewWidth - ( rowHeight + arrowBuffer + toolTipWidth );

	 					this.setState({
	 						arrowSide: 'right',
	 						arrowJustify: 'corner'
	 					})
	 				}

	 			} else if( buttonY < rowHeight ){
	 	
	 				let toolTipCenter = toolTipWidth / 2;
	 				let buttonCenter = buttonX + ( buttonPos.width / 2 )
	 				// Open below the button
	 				x = buttonCenter - toolTipCenter;
	 				y = ( rowHeight + arrowBuffer ); 

	 				if( x <= 0 ){

	 					x = arrowBuffer;

	 					this.setState({
	 						arrowSide: '',
	 						arrowJustify: 'left'
	 					})

	 				} else if ( ( toolTipWidth + buttonX > viewWidth - rowHeight ) && buttonY == 0 ) { 

	 					x = viewWidth - ( rowHeight + arrowBuffer + toolTipWidth );
	 					// top right corner
	 					this.setState({
	 						arrowSide: 'top',
	 						arrowJustify: 'right-side'
	 					})	

	 				} else {

	 					// if( !this.props.isFirstChild ){
	 						this.setState({
	 							arrowSide: 'top',
	 							arrowJustify: 'center'
	 						})	
	 					
	 				}

	 			} else if( buttonY > rowHeight && ( buttonY + rowHeight !== viewWidth ) ){
	 				// This should only run for flyout menus originating from the top bar.
	 				let toolTipCenter = toolTipHeight / 2;
	 				let roundedButtonY = ( Math.floor( buttonY / 10 ) * 10 )
	 				let buttonCenter = roundedButtonY + ( rowHeight / 2 )
	 				// open left 
	 				x = buttonX - ( toolTipWidth + arrowBuffer )
	 				y = buttonCenter - toolTipCenter

	 				this.setState({
	 					arrowSide: 'right',
	 					arrowJustify: 'left'
	 				})
	 			}

	 			if( this.props.buttonPos.onButton ){
	 				x = rowHeight
	 				y = rowHeight
	 			}


	 			let timeout = 1000
	 			// No delay if we just showed a tooltip.
	 			if(previouslyShownTooltipCount > 0) {
	 				timeout = 0;
	 			}

	 			this.toolTipTimeout = setTimeout(() => {
	 				if( !this.props.text ){
	 					return 
	 				}
	 				this.setState({
	 					position: {x: x, y: y},
	 					visible: 'visible',
	 				});

	 			}, timeout)

		 });

	}

	render() {

		let styles = { top: this.state.position.y, left: this.state.position.x }
		
		return (
			<div 
				className={`tool-tip${this.props.className ? this.props.className : ""} ${this.state.visible} ${this.state.arrowSide} ${this.state.arrowJustify}`}
				style={styles}
				ref={this.toolTipRef}
			>
				<span>{ this.props.text }</span>
				<div className="shortcut">{ this.props.shortcut }</div>
			</div>
		)
		
	}
}
