import { FRONTEND_DATA } from "../../../globals";
import { rangeCanBeModifiedByTextCommands } from "../helpers";

export default {

	requiresTextInRange: false,
	requiresUncollapsedRange: false,
	requiresActiveRange: true,

	execute: function(){

		FRONTEND_DATA.contentWindow.CargoEditor.mutationManager.execute(function(){

			// clean lineated lists
			const range = FRONTEND_DATA.contentWindow.CargoEditor.getActiveRange();

			// clear lineated class from the list
			const listsInRange = range.getNodes([Node.ELEMENT_NODE], (el) => el.nodeName=="OL" || el.nodeName=="UL" );
			const commonAncestorEl = range.commonAncestorContainer.nodeType === Node.TEXT_NODE ? range.commonAncestorContainer.parentNode : range.commonAncestorContainer;

			if(commonAncestorEl.closest('ol, ul')) {
				listsInRange.push(commonAncestorEl.closest('ol, ul'));
			}

			listsInRange.forEach(list => {
				list.classList.remove('lineated');

				if(list.classList.length === 0) {
					list.removeAttribute('class');
				}
			});

			const lineatedDivs = range.getNodes([Node.ELEMENT_NODE], (el) => {
				return el.nodeName === "DIV" && el.classList.contains('lineated');
			});

			if(commonAncestorEl.closest('div.lineated')) {
				lineatedDivs.push(commonAncestorEl.closest('div.lineated'));
			}

			lineatedDivs.forEach(div => {
				FRONTEND_DATA.contentWindow.CargoEditor.helpers.removePreservingDescendants(div, range);
			});

			// insert list
			FRONTEND_DATA.contentWindow.CargoEditor.execCommand('insertorderedlist');

		});

	},
	getState: function(range) {

		let isApplied = FRONTEND_DATA.contentWindow.CargoEditor.queryCommandState('insertorderedlist');

		// check if one of the parents is a ordered list
		if(range && !isApplied) {
			const commonAncestorEl = range.commonAncestorContainer.nodeType === Node.TEXT_NODE ? range.commonAncestorContainer.parentNode : range.commonAncestorContainer;

			if(commonAncestorEl.closest('ol')) {
				isApplied = true;
			}
		}

		return {
			isApplied,
			isAllowed: rangeCanBeModifiedByTextCommands(range),
		};
	},		
	priority: -1

}