import React, { Component } from 'react';
import { Button } from "@cargo/ui-kit";
import MoreIcon from "../../svg-icons/more.svg";

export const MoreButton = (props) => {

	const {children, className, ...otherProps} = props;

	return (
		<Button
			label={<MoreIcon />}
			className={`more-button${className ? ' '+className : ''}`}
			{...otherProps}
			// onClick={ e => console.log(e) }
		>
			{children}
		</Button>
	);

};