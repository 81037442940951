import React, { Component } from 'react';
import * as actions from "../../actions";
import _ from 'lodash';

export class DetailsMenu extends Component {

    constructor(props) {
        super(props);

        this.state = {
            hasStyles: false,
            isOpen: this.props.open ? true : false,
            isClosing: false,
            height: 0,
            minHeight: 0,
            overflow: "hidden",
            transition: "height 100ms ease-in-out, padding 100ms ease-in-out",
            menuRef: null,
        }; 

    }

    componentDidMount = () => {

        if( this.props.forceOpen === true ){
            this.forceOpen();
            return;
        }

        // if this menu uses session storage, set the open state
        if (this.props.sessionStorage && this.getSessionStorage() !== null) {
            this.setState({ isOpen: this.getSessionStorage() });
        }
    }

    forceOpen = () => {
        this.setState({ isOpen: true });

        if( this.props.sessionStorage ){
             this.setSessionStorage(true)
        }
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {

        if( prevState.isOpen      == true && 
            this.state.isOpen     == true &&
            this.state.isClosing  == true &&
            prevState.isClosing   == false &&
            this.state.hasStyles  == false ){
      
                // Sometimes the animation gets stuck in transitioning closed.
                // This resets state and makes things continue to operate smoothly.
                this.setState({
                    isClosing: false,
                    isOpen : false,
                    hasStyles: false
                })

                this.setSessionStorage(false);

        }

        if( !prevState.isOpen && this.props.forceOpen === true ){
            this.forceOpen();
            return;
        }

    }


    getSessionStorage = () => {
        const sessionState = window.store.getState().adminState.session;
        return sessionState[this.props.sessionStorage] ?? null;
    }

    setSessionStorage = value => {
        if (this.props.sessionStorage) {
            window.store.dispatch({
                type: actions.actionTypes.UPDATE_ADMIN_STATE, 
                payload: {
                    session: {
                        [this.props.sessionStorage]: value
                    }
                }
            });
        }
        
    };

    setInnerRef = (ref) => this.setState({ menuRef: ref });

    handleToggle = (e) => {

        if( e ){
            e.preventDefault()
            if( e.type == 'toggle' ) return
            if( e.target.nodeName !== 'DIV' ) return
        }

        if( this.state.isClosing == true ) return
        // Blur to prevent spacebar from toggling open/closed
        this.state.menuRef.blur()
        if( !this.state.isOpen ){
            // add styles and begin opening
            this.setState({
                hasStyles: true,
                isOpen: true,
            }, () => {

                this.setSessionStorage(true);

                let styles = getComputedStyle(this.state.menuRef),
                verticalPadding = parseInt(styles.paddingTop) + parseInt(styles.paddingBottom),
                height = ( this.state.menuRef.scrollHeight + verticalPadding ) - 1;
                requestAnimationFrame( () => {
                    requestAnimationFrame( () => {
                        // Apply height to start the transition
                        this.setState({
                            height: height
                        });
                    })
                })


                // 
            })

        } else {

            let styles = getComputedStyle(this.state.menuRef),
            verticalPadding = parseInt(styles.paddingTop) + parseInt(styles.paddingBottom),
            height = ( this.state.menuRef.scrollHeight + verticalPadding ) - 1;
   
            // add styles to begin closing
            this.setState({
                hasStyles: true,
                height: height
            }, () => {
                // Double request animation frame for smoother transition
                requestAnimationFrame( () => {
                    requestAnimationFrame( () => {
                        this.setState({
                            isClosing: true,
                            height: 0
                        })
                    })
                })
                // 
            })

        }

    }

    updateAfterTransition = () => {
        if( this.state.isOpen    == true &&
            this.state.isClosing == true ){
            // Clean up inline styles after closing transition complete
            // and complete closing operations
            this.setState({
                isOpen: false,
                isClosing: false,
                hasStyles: false
            });

            this.setSessionStorage(false);

        } else {

            this.setState({
                hasStyles: false
            })

            if (this.props.openMenuCallback) {
                this.props.openMenuCallback();
            }

        }

    }

    render(){

        const {children, summaryChildren, text, icon, noGap, noMargin, checkbox, sessionStorage, onMouseDown, ...props} = this.props;

        this.styles = {
            height: this.state.height,
            minHeight: this.state.minHeight,
            overflow: this.state.overflow,
            transition: this.state.transition
        }

        return (
            <details 
                open={ this.state.isOpen }
                className={`${this.props.className ? this.props.className : ''}${ this.state.isClosing ? ' closing' : ''}`}
                onToggle={ (e)=> this.handleToggle(e) }
                disabled={ this.props.disabled ? true : null }
                style={this.props.style}
            >
                <summary
                    // onClick={(e) => this.handleToggle(e) }
                    onClick={(e) => { 
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                    onMouseDown={(e) => {
                        if( this.props.disabled ){ return }
                        this.handleToggle(e) 
                        if (onMouseDown) {
                            onMouseDown();
                        }
                    }}
                >
                    <div className="summary-row">
                        <div className="summary-arrow">
                            <div className="arrow"></div>
                        </div>
                        <div className="summary-title">
                            <div className="title">{text}</div>
                            <div className="icon-text">{icon}</div>
                        </div>
                        {summaryChildren}
                    </div>
                    { checkbox }
                </summary>
                <div 
                    ref={this.setInnerRef}
                    onTransitionEnd={() => this.updateAfterTransition()}
                    style={ this.state.hasStyles ? this.styles : null }
                    className={`content ${noGap ? "" : "gap"}`
                }>
                    { children }
                    {!noMargin && <div className="uiWindow-spacer"></div> }
                </div>
            </details>
        )

    }

}
