import { DetailsMenu } from "./details-menu";
import ImagePicker from "./image-picker";
import Thumbnail from "./thumbnail";

const ImagePickerDetails = ({
    showThumbnail = true,
    thumbnailMedia,
    label,
    images,
    selected,
    onSelectedChange,
    pageId,
    onDrop,
    excluded,
    onExcludedChange,
    order,
    onOrderChange,
    onRemove,
    removeOnHotKey,
    emptyMessage,
    children,
    checkbox,
    allowDuplicates,
    sessionStorage,
    clickoutLayer = false,
    sortable,
    forceOpen,
    inUse = false,
    className = '',

    ...rest
}) => {
    return (
        <>
            <div className={`ui-group ${className}`}>
                <DetailsMenu
                    text={label}
                    className={`thumbnail-details bars${inUse ? ' in-use' : ''}`}
                    checkbox={checkbox}
                    noMargin={true}
                    forceOpen={forceOpen}
                    sessionStorage={sessionStorage}
                    summaryChildren={
                        showThumbnail === true ? 
                            thumbnailMedia ? (
                                <Thumbnail 
                                    media={thumbnailMedia} 
                                    isSquare={true} 
                                    width="80"
                                />
                            ) : (<div
                                    className="thumbnail-placeholder"
                                >
                                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M39 38.2929L39 1L1.70711 0.999998L39 38.2929ZM1 1.70711L0.999998 39L38.2929 39L1 1.70711ZM40 0L40 1L40 39L40 40L39 40L0.999998 40L-1.74846e-06 40L-1.70474e-06 39L-4.37114e-08 0.999998L0 -1.74846e-06L1 -1.70474e-06L39 -4.37114e-08L40 0Z"/>
                                    </svg>
                                </div>
                            )
                        : null
                    }
                >
                    <div className="ui-group">
                        <ImagePicker
                            addPadding={true}
                            images={images}
                            selected={selected}
                            onSelectedChange={onSelectedChange ? (val) => onSelectedChange(val) : null}
                            columns={rest.columns}
                            componentWidth={286}
                            pageId={pageId}
                            onDrop={(val) => onDrop(val)}
                            excluded={excluded}
                            onExcludedChange={onExcludedChange ? (val) => onExcludedChange(val) : null}
                            order={order}
                            onOrderChange={onOrderChange ? (val) => onOrderChange(val) : null}
                            onRemove={onRemove ? (val) => onRemove(val) : null}
				            removeOnHotKey={removeOnHotKey}
				            emptyMessage={emptyMessage}
                            allowDuplicates={allowDuplicates}
                            clickoutLayer={clickoutLayer}
                            sortable={sortable}
                        />
                    </div>
                    {children}
                </DetailsMenu>	
            </div>
        </>
    )
}

export default ImagePickerDetails;