import React from 'react';
import CustomFontPreview from './custom-font-preview';
import CustomFontEditorButton from './custom-font-editor-button';

export const FontVariant = ({forwardedRef, font, family, group, location, url, bgSize, isSingle, title, selectFont, selectedFont, activeIsStaffPick, customIframesLoadingState, onTransitionEnd, isCustomSprite, onPointerLeave, onPointerEnter, linkedHover, onWillUnmount, renderCustomFontIframes, onIframeRenderComplete, colorScheme}) => {

	const isCustomFont = font.source === 'custom' || font.provider === 'custom';

	const backgroundStyle = {
		backgroundPositionY: ( location * -36 ),
	    backgroundImage: "url("+url+")",
	    backgroundSize: bgSize
	}

	const customBGStyle = {
		backgroundPositionY: '-1px',
		backgroundImage: "url("+url+")",
		backgroundSize: '400px 36px',
		marginLeft: font.family === 'Chaumont Script' ? '1px' : '13px',
	}

	const containerStyles = font.containerStyles;

	let styles = null

	if( containerStyles !== null && isSingle ){
		styles = {
			...containerStyles,
			...font.reactStyle
		}
	} else {
		styles = font.reactStyle
	}

	const embeddedFontReference = font.embeddedFontReference;
	const fontFamily = font;
	if( isSingle ){
		const family = font;
		font = font.variants[0];
		font.embedCode = family.embedCode;
		font.embeddedFontReference = family.embeddedFontReference;
	}

	let isStaffPick = title === 'staffpicks';
	let isActive    = null;
	let isVariable  = font.variable === true;
	if( selectedFont == font.name ) {
		if( activeIsStaffPick && isStaffPick ) {
			isActive = true;
		}
		if( !activeIsStaffPick && !isStaffPick ) {
			isActive = true;
		} 
	}

	return (
		<li 
			// onClick         = { () => selectFont() }
			onMouseDown   = { () => selectFont() }
			data-source     = { font.provider ?? font.source } 
			data-family     = { font.family }
			data-font-group = { group }
			style           = { styles }
			className       = {`variant${isActive ? ' active' : ''}${isSingle ? ' family-head single-font disable-drag' : ''}${isStaffPick ? ' staffpickfont' : ''}${isVariable ? ' variable' : ''} ${linkedHover ? 'linked-hover': ''}${isCustomFont && customIframesLoadingState ? ' loading-custom-fonts' : ''}`}
			data-name       = { font.name } 
			data-selector   = { font.selectorName }
            onPointerEnter = { onPointerEnter }
            onPointerLeave = { onPointerLeave }			
			// onTransitionEnd = { isSingle ? () => onTransitionEnd('section', title, font.family) : undefined }
			ref             = { forwardedRef ? forwardedRef : null }
		>
			{
				isCustomFont === false ? <span className="pngpreview" style={ isCustomSprite ? customBGStyle : backgroundStyle }></span> :
				<>{ renderCustomFontIframes ? <CustomFontPreview colorScheme={colorScheme} font={font} name={font.name} onIframeRenderComplete={onIframeRenderComplete} customIframesLoadingState={customIframesLoadingState} /> : null }</>
			}
			{isCustomFont ? <CustomFontEditorButton font={font} single={true} onWillUnmount={onWillUnmount} /> : null}
		</li>
	);
}

// <li 
// 	data-source="google" 
// 	data-family="Archivo Narrow" 
// 	data-font-group="Archivo Narrow" 
// 	class="no-select variant" 
// 	style="font-family: Archivo Narrow; font-style: normal; font-weight: 400;" 
// 	data-name="Archivo Narrow Regular" 
// 	data-font-selector="Archivo Narrow n4"
// >