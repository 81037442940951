import { Field } from 'formik';
import { RangeExtension } from './range-extension';
import { Scrubber } from './scrubber';
import { useState, useEffect } from 'react';

export const CompositeScrubber = (props) => {

    const [mainValue, setMainValue] = useState(props.field.value)
    const [verticalValue, setVerticalValue] = useState(props.field.value.split(' ').length === 2 ? props.field.value.split(' ')[1] : props.field.value);
    const [horizontalValue, setHorizontalValue] = useState(props.field.value.split(' ').length === 2 ? props.field.value.split(' ')[0] : props.field.value);

    const getStateFromValue = (value) => {
        if ( mainValue === value ) {
            return;
        }
        setMainValue(value);
        setVerticalValue(value.split(' ').length === 2 ? value.split(' ')[1] : value);
        setHorizontalValue(value.split(' ').length === 2 ? value.split(' ')[0] : value);
    }

    useEffect(() => {
        getStateFromValue(props.field.value);
    }, [props.field.value])

    useEffect(() => {
        props.form.setFieldValue(
            props.field.name, 
            mainValue,
            false
        );
    }, [mainValue])

    useEffect(() => {
        let newCompositeValues = [];
        if (horizontalValue !== verticalValue) {
            newCompositeValues[1] = verticalValue;
            newCompositeValues[0] = horizontalValue;
        } else {
            newCompositeValues[0] = horizontalValue;
        }
        setMainValue(newCompositeValues.join(' '));
    },[horizontalValue, verticalValue])

    const handleMainChange = (val) => {
        setHorizontalValue(val);
        setVerticalValue(val);
    }

    const handleHorizontalChange = (val) => {
        setHorizontalValue(val);	
    }

    const handleVerticalChange = (val) => {
        setVerticalValue(val);	
    }


    const mainScrubberIsMultiple = mainValue.split(' ').length > 1 ? true : false;

    const mainProps = {
        ...props,
        hideScrubberValue: mainScrubberIsMultiple,
        field: {
            ...props.field,
            name: `${props.field.name}`,
            value: horizontalValue
        },
        onChange: handleMainChange
    }

    const isOverriding = (axis) => {
        const desktopValue = props.form.values[props.field.name.replace('mobile-', '')];
        const mobileValue = props.field.value;

        if (desktopValue === mobileValue) {
            return false;
        }

        const desktopParts = desktopValue.split(' ');
        const mobileParts = mobileValue.split(' ');

        if (axis === 'horizontal') {
            if (desktopParts[0] !== mobileParts[0]) {
                return true;
            }
        }

        if (axis === 'vertical') {
            if (
                desktopParts?.[1] !== mobileParts?.[1] || 
                (desktopParts.length === 1 && mobileParts.length === 2 && desktopParts[0] === mobileParts[1]) ||
                (desktopParts.length === 2 && mobileParts.length === 1 && desktopParts[1] === mobileParts[0])
            ) {
                return true;
            }
        }

        return false;
    }

    isOverriding('horizontal');

    const horizontalProps = {
        ...props,
        label: `Horizontal ${props.label}`,
        isOverriding: false, //isOverriding('horizontal'),
        field: {
            ...props.field,
            name: `${props.field.name}-h`,
            value: horizontalValue
        },
        onChange: handleHorizontalChange
    }

    const verticalProps = {
        ...props,
        label: `Vertical ${props.label}`,
        isOverriding: false, //isOverriding('vertical'),
        field: {
            ...props.field,
            name: `${props.field.name}-v`,
            value: verticalValue
        },
        onChange: handleVerticalChange
    }

    return (
        <RangeExtension
            firstField= { 
                <Scrubber
                    {...mainProps}
                />
            }
        > 
            <Scrubber {...horizontalProps} />
            <Scrubber {...verticalProps} />
        </RangeExtension>
    )
}