import { FRONTEND_DATA } from "../../../globals";
import { 
	rangeCanBeModifiedByTextCommands,
	getAllParents,
	wrapRangeWith,
	getNodesInRangeFilteredBySelector
} from "../helpers";

import _ from 'lodash';

export default {

	requiresTextInRange: true,
	requiresUncollapsedRange: true,
	requiresActiveRange: true,

	execute: function(newSize){

		wrapRangeWith('span', {
			before: () => {

				// first remove all old text-styles from our newly wrapped range
				getNodesInRangeFilteredBySelector('SPAN, H1, H2, A').forEach(node => {

					if(node.style.lineHeight !== '') {
						node.style.removeProperty('line-height');
					}

					if(node.style.length === 0) {
						node.removeAttribute('style');
					}

				});

			},
			after: (nodes) => {

				nodes.forEach(node => {
					// set font style
					node.style.lineHeight = newSize;
				});
				
			}
		});

	},

	getState: (range) => {

		let matches = {};

		range = range || FRONTEND_DATA.contentWindow.CargoEditor.getActiveRange();

		// loop over all spans contained by this range
		getNodesInRangeFilteredBySelector('SPAN, H1, H2, A', {
			parentsOnly: true
		}).forEach(node => {
			if(node.style.lineHeight !== '') {
				(matches[node.style.lineHeight] = matches[node.style.lineHeight] || []).push(node);
			}
		});

		return {
			isAllowed: rangeCanBeModifiedByTextCommands(range),
			isApplied: Object.keys(matches).length > 0,
			matches
		}

	},
	priority: 1
 
}
