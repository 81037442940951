import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from "@cargo/ui-kit";
import { actions } from '../../actions';

const LibraryButton = ({
    addUIWindow,
    removeUIWindow,
    onSelectedChange = null,
    clickoutLayer = false,
}) => {

    return (
        <Button 
            className="library-button" 
            label={`Library`}
            onMouseDown={e => {
                addUIWindow({
                    group: 'right-menu-bar',
                    component: import('../right-menu-bar/library-window'),
                    id: `library-window`,
                    props: {
                        type: 'popover',
                        uiWindowType: 'popover',
                        borderRadius: 'radius-all',
                        clickoutLayer: clickoutLayer,
                        windowName: 'library',
                        closeOnSingleClickout: true,
                        invokeTarget: e.currentTarget,
                        invokeWindow: 'page-settings-window',
                        closeButton: false,
                        positionType: 'center',
                        waitForHeightBeforeRender: true,
                        minimumRenderHeight: 50,
                        avoidTransform: true,
                        testProp: 'test',
                        maxHeight: true,
                        mediaType: 'images',
                        onSelectedChange: (selected) => {
                            if ( onSelectedChange !== null ) {
                                onSelectedChange(selected);
                                removeUIWindow('library-window');
                            }
                        }
                    }
                },{
                    removeGroup: false
                });
            }}
        />
    )
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        addUIWindow: actions.addUIWindow,
        updateUIWindow: actions.updateUIWindow,
        removeUIWindow: actions.removeUIWindow,
    }, dispatch);

}

function mapReduxStateToProps(state, ownProps) {

    const pageId = ownProps.pageID ? ownProps.pageID : state.frontendState.PIDBeingEdited;

	return {
		pageId: state.frontendState.PIDBeingEdited
	};
}
    
export default connect(
    mapReduxStateToProps,
    mapDispatchToProps
)(LibraryButton);