import { FRONTEND_DATA } from "../../../globals";

export default {

	requiresTextInSelection: false,
	requiresUncollapsedRange: false,
	requiresActiveRange: false,

	execute: function(){
		
		var range = FRONTEND_DATA.contentWindow.CargoEditor.getActiveRange();


		FRONTEND_DATA.contentWindow.CargoEditor.events.once('mutation-before-success', (editor)=>{

			var summaries = editor.generateUnreportedMutationSummaries();

			var addedClock = summaries.flatMap(summary=>summary.added).find(added=>added.tagName==='DIGITAL-CLOCK');

			if( addedClock){
				addedClock._newClock = true;				
			}

		});


		FRONTEND_DATA.contentWindow.CargoEditor.execCommand('insertHTML', false, `<digital-clock value="{hour}:{minute}:{second}" twentyfour-hour="true" pad-hour="true"></digital-clock>`);
		FRONTEND_DATA.contentWindow.CargoEditor.events.trigger('cursor-activity', FRONTEND_DATA.contentWindow.CargoEditor.getActiveEditor());		

		return false

	},
	getState: (range)=> {
		if( !range){
			range = FRONTEND_DATA.contentWindow.CargoEditor.getActiveRange();
		}

		if(!range){
			return {
				isApplied: false,
				isAllowed: false,
			};
		}

		let commonAncestor = range.commonAncestorContainer.nodeType == Node.TEXT_NODE ? range.commonAncestorContainer.parentElement : range.commonAncestorContainer;

		const isAllowed = !commonAncestor.tagName?.startsWith('GALLERY-')

		return {
			isApplied: false,
			isAllowed: isAllowed,
		};
	},

}