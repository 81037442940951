import { FRONTEND_DATA } from "../../../globals";

export default {

	requiresTextInSelection: false,
	requiresUncollapsedRange: false,
	requiresActiveRange: true,

	execute: function(){
		
		var range = FRONTEND_DATA.contentWindow.CargoEditor.getActiveRange();

		return false

	},
	getState: (range)=> {

// 		const imageNodes = FRONTEND_DATA.contentWindow.CargoEditor.helpers.getAllEffectivelyContainedNodes(
// 			range, 
// 			node => node.nodeName === "IMG" && node.classList.value.includes('icon')
// 		);
// 
// 		const regex_emoji = /[\p{Extended_Pictographic}\u{1F3FB}-\u{1F3FF}\u{1F9B0}-\u{1F9B3}]/u;
// 		const emojiTest = regex_emoji.test( range );

		return {
			isApplied: false,
			isAllowed: true,
		};
	},

}