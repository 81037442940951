import React from 'react';

import ColumnsIcon from "../../svg-icons/formatting-columns.svg";
import ImageGalleryIcon from "../../svg-icons/formatting-image-gallery.svg";
import ImageIcon from "../../svg-icons/formatting-image-options.svg";


export const AspectRatioButtons = [
			{
				name    : 'iPhone XS',
				ratio   : .4615, //19.5:9
				label   : 1
			},
			{
				name    : 'iPhone/Mobile Standard',
				ratio   : .5625, //16:9
				label   : 2	
			},
			{
				name    : 'Android Standard',
				ratio   : 0.75, //4:3
				label   : 3,
			},
			{
				name    : 'Galaxy A50',
				ratio   : 0.9473, //9.5 : 9
				label   : 4
			},
			{
				name    : 'Tablet',
				ratio   : 1.333, //3:4
				label   : 5
			},
]
