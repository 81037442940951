import React, {Component, Fragment} from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import selectors from "../../selectors";

import { helpers } from "@cargo/common";
import { Button } from "@cargo/ui-kit";
import { FRONTEND_DATA } from "../../globals";
import { globalUndoManager } from "../../lib/undo-redo";
import { DetailsMenu } from "./";

const defaultWallpaperModel = {
	hash: 'F1332270929626196865795426877515',
    id: 1234,
	name: 'scape.jpg',
    width: 4000,
    height: 2250,
    file_type: "jpg",
    mime_type: "image/jpg",
    is_image: true,
    is_video: false,
}

class FocalPoint extends Component {
	constructor(props){
		super(props);
		this.state = {

			dragging: false,
			focusedImage: this.props.activeImage,
			draggingFocalPoint:{
				x: 50,
				y: 50,
			}
		}

		this.imageSizingRef = React.createRef();
		this.imageRef = React.createRef();
	}

	render(){
		let {
			field,
			id="focalpoint/"+this.props.field.name,
			images,
			interfaceAreaHeight,
		} = this.props;

		let {
			dragging,
			focusedImage
		} = this.state;

		let imageModel = images.find(model=>model.hash === focusedImage)

		if( images.length === 0  ){
			imageModel = defaultWallpaperModel
		}



		let imageW = 640;
		let imageH = 480;
		let imgSrc = '';

		let alignment = {
			x: 50,
			y: 50
		};

		if( imageModel){
			imageW = imageModel.width;
			imageH = imageModel.height;
			if( imageModel.is_video && imageModel.poster){
				imgSrc = `https://freight.cargo.site/w/600/q/75/i/${imageModel.poster.hash}/${imageModel.poster.name}`;				
			} else {
				imgSrc = `https://freight.cargo.site/w/600/q/75/i/${imageModel.hash}/${imageModel.name}`;				
			}


			if( Object.keys(this.props.alignments).includes(imageModel.hash) ){
				alignment = this.props.alignments[imageModel.hash];
			}
		}

		if( !imageModel ){
			return null;
		}


		return (
			<DetailsMenu
				text="Set Focal Point"
			>
				<div className="focal-point">
					<div className="ui-group focal-point-buttons">
						<Button onMouseDown={this.reset}>Reset</Button>
						<Button onMouseDown={this.resetAll}>Reset All</Button>
						<Button
							className={images.length < 2 ? 'disabled': ''}
							onMouseDown={(e)=>{ this.changeImage(e, -1)} }
						>←</Button>
						<Button
							className={images.length < 2 ? 'disabled': ''}
							onMouseDown={(e)=>{ this.changeImage(e, 1)} }>
						→</Button>
					</div>
					<div className="image-sizing ui-group"
						onMouseDown={this.onMouseDown}					
					>
						<div
							className="image-sizing-reference" ref={this.imageSizingRef}
							style={{
								paddingBottom: (imageH/imageW)*100 + '%',
							}}
						>
							<img
								crossOrigin=""
								src={imgSrc}
								ref={this.imageRef}
								onLoad={this.onImageLoad}
							/>
							<div className="focal-point-cursor-wrapper"
								style={{
									transform: (
										this.state.dragging ? 
										`translate(${this.state.draggingFocalPoint.x}%, ${this.state.draggingFocalPoint.y}%)`:
										`translate(${alignment.x}%, ${alignment.y}%)` 
									),
								}}					
							>
								<div className="focal-point-cursor"
								></div>
							</div>
						</div>
					</div>
				</div>
			</DetailsMenu>
		)
	}

	pauseSlideshow = ()=>{
		this.slideshow?.pause();
		clearTimeout(this.slideshowUnpauseTimeout);
		this.slideshowUnpauseTimeout = setTimeout(()=>{
			this.slideshow?.resume();
		}, 1500);		
	}

	onMouseDown=(e)=>{

		this.setState({
			dragging: true,
		})

		this.props.form.setFieldValue(
			'activeImage',
			this.state.focusedImage,
			false
		)

		globalUndoManager.pause();
		this.pauseSlideshow();
		clearTimeout(this.slideshowUnpauseTimeout)
		window.addEventListener('pointermove', this.setFocalValue)
		window.addEventListener('pointerup', this.onPointerUp)
		window.addEventListener('pointerleave', this.onPointerUp)
		this.setFocalValue(e);
	}

	setFocalValue=(e)=>{
		e.preventDefault();

		const {
			field, form
		}= this.props;

		if(!this.imageSizingRef.current) {
			return;
		}

		const rect = this.imageSizingRef.current.getBoundingClientRect();
		let x = (e.clientX-rect.left)/rect.width;
		let y = (e.clientY-rect.top)/rect.height;
		
		const focalPointList = {...field.value} || {}
		focalPointList[this.state.focusedImage] = {
			x: Math.max(0, Math.min(100, x*100)),
			y: Math.max(0, Math.min(100, y*100))			
		}

		this.setState({
			draggingFocalPoint: focalPointList[this.state.focusedImage]
		})

		form.setFieldValue(
			field.name,
			focalPointList,
			false
		)		
	}

	onPointerUp = (e)=>{
		this.setFocalValue(e)
		this.pauseSlideshow();		
		this.setState({
			dragging: false
		})
		globalUndoManager.resume();	
		window.removeEventListener('pointerleave', this.onPointerUp)
		window.removeEventListener('pointermove', this.setFocalValue)
		window.removeEventListener('pointerup', this.onPointerUp)		
	}

	reset = (e)=>{
		this.pauseSlideshow();
		const {
			form, field
		} = this.props;

		const {
			focusedImage
		}= this.state;

		e.preventDefault();
		form.setFieldValue(
			field.name,
			{...field.value,
				[focusedImage]: {x: 50, y: 50}
			},
			false
		)		

	}

	resetAll = (e)=>{
		this.pauseSlideshow();
		const {
			form, field
		} = this.props;

		e.preventDefault();
		form.setFieldValue(
			field.name,
			null,
			false
		)		
	}

	changeImage=(e, delta)=>{
		this.pauseSlideshow();
		e.preventDefault();

		const activeIndex = this.props.images.findIndex(model=>model.hash === this.state.focusedImage);
		const focusedImage = this.props.images[(activeIndex+delta+this.props.images.length)%this.props.images.length]?.hash || null;
		this.props.form.setFieldValue(
			'activeImage',
			focusedImage,
			false
		)
	}

	onAfterSlideChange = (e)=>{
		const {
			index,
			mediaItem,
		} = e.detail;

		// gather up slideshow after its load/slidechange event is triggered
		this.slideshow = this.activeBackdrop?.shadowRoot?.querySelector('gallery-slideshow');
		this.getActiveSlideFromSlideshow();
	}

	// clean output on mount by removing nonexistent keys from obj
	componentDidMount(){
		const {
			field, form
		}= this.props;

		const focalPointList = {...field.value}
		Object.keys(focalPointList).forEach(hash=>{
			if(!this.props.images.some(model=>model.hash ===hash)){
				delete focalPointList[hash]
			}
		});

		form.setFieldValue(
			field.name,
			focalPointList,
			false
		)

		this.activeBackdrop = FRONTEND_DATA.contentWindow.document.querySelector('[id="'+this.props.PIDBeingEdited+'"] .backdrop .wallpaper');
		if( this.activeBackdrop ){

			this.activeBackdrop.addEventListener('afterSlideChange', this.onAfterSlideChange)
			this.slideshow = this.activeBackdrop.shadowRoot?.querySelector('gallery-slideshow');
			this.getActiveSlideFromSlideshow();
			
		}
	}

	getActiveSlideFromSlideshow = ()=>{

		if( this.slideshow ){

			const activeItem = this.slideshow.getActiveSlide()[1];
			const hash = activeItem?.getAttribute('hash') || '';

			if(hash !==''){
				this.setState({
					focusedImage: hash
				})			
			}
		}		
	}

	componentWillUnmount(){
		clearTimeout(this.slideshowUnpauseTimeout)
		this.slideshow?.resume();
		this.activeBackdrop?.removeEventListener('afterSlideChange', this.onAfterSlideChange)
	}


	componentDidUpdate(prevProps){

		// if the focused image isn't in the array, assign a new one from the array
		if( !this.props.images.some(model=>model.hash ==this.state.focusedImage ) && this.props.images.length > 0 ){

			if( !this.props.images[0].loading ){
				this.setState({
					focusedImage: this.props.images[0].hash
				})
			}


		} else if(
			(
				prevProps.activeImage !== this.props.activeImage &&
				this.props.activeImage !== this.state.focusedImage
			) || this.props.form.values['cycle-images'] !== prevProps.form.values['cycle-images']

		){
			this.setState({
				focusedImage: this.props.activeImage
			})
		}
	}

}


function mapReduxStateToProps(state, ownProps) {
	
	const PIDBeingEdited = state.frontendState.PIDBeingEdited;
	let images = selectors.getMediaByParent(state)[PIDBeingEdited]?.filter((item)=>{
		return item.is_image || item.is_video
	})

    if( ownProps.order){
	    images.sort(function(a,b){              
	        return ownProps.order.indexOf(a.hash) < ownProps.order.indexOf(b.hash) ? -1 : 1
	    });    	
    }

	if( ownProps.excluded ){
		images = images.filter(model=>ownProps.excluded.indexOf(model.hash) == -1);		
	}

	return {
		hasLoadableImage: images.length > 0 && !images[0]?.loading,
		PIDBeingEdited,
		images
	};
}

const ConnectedFocalPoint = connect(
	mapReduxStateToProps,
	null
)(FocalPoint);

export  {ConnectedFocalPoint as FocalPoint};
