import { FRONTEND_DATA } from "../../../globals";

import { 
	retrieveSequentualNodesEffectivelyContainedByRange 
} from "../helpers";
import { helpers } from "@cargo/common";

import _ from 'lodash';

export default {

	requiresTextInRange: false,
	requiresUncollapsedRange: true,
	requiresActiveRange: true,
	requiresImageInRange: true,
	ignoreButtonInUseState: true,


	// gallery tag name can happen when this command is triggered via context menu
	// otherwise, grab the last-created one
	execute: function(galleryTagName){

		let range = FRONTEND_DATA.contentWindow.CargoEditor.getActiveRange();
		const state = this.getState(range);
		if(!state.isAllowed || state.isApplied) {
			return;
		}

		FRONTEND_DATA.contentWindow.CargoEditor.mutationManager.execute(()=> {

			const activeEditor = FRONTEND_DATA.contentWindow.CargoEditor.getActiveEditor()

			let galleryElement = FRONTEND_DATA.contentWindow.document.createElement('gallery-grid');

			galleryElement.setAttribute('thumbnail-index', 'all');
			
			if(range){				
				range.insertNode(galleryElement);
			} else {
				activeEditor.getElement().appendChild(galleryElement);
			}

		});

		range.collapse(true);
		FRONTEND_DATA.contentWindow.CargoEditor.helpers.setActiveRange(range);

	},

	getState: (range)=> {

		if( !range){
			range = FRONTEND_DATA.contentWindow.CargoEditor.getActiveRange();
		}

		if(!range){
			return {
				isApplied: false,
				isAllowed: false,
			};
		}

		let galleryTags = [];
		for (let name in FRONTEND_DATA.galleryData){
			galleryTags.push(FRONTEND_DATA.galleryData[name].tagName);
		}
		
		let galleryNodes = range.getNodes([Node.ELEMENT_NODE], function(node) {
			return galleryTags.indexOf(node.tagName) >-1;
		});
		let galleryNode = helpers.closest(range.commonAncestorContainer, (node)=>{
			return galleryTags.indexOf(node.tagName) >-1;
		})
		if( galleryNode){
			galleryNodes.push(galleryNode)
		}

		return {
			isApplied: galleryNodes.length > 0,
			// isAllowed: mediaItemNodes.length > 0 || galleryNodes.length > 0,
			isAllowed: true
		};
	},	
	priority: 1

 
}
