import React, {Component, Fragment, useState, useEffect, useRef} from 'react';


export const PageListInput = ({
	field,
	className,
	autocomplete,
	innerRef,
	form,
	label,
	placeholder,
	type,
	icon,
	iconClass,
	noBars,
	barLeft,
	barRight,
	focusOnInit,
	selectOnInit,
	forceFocus,
	onBlur,
	inputType,
	inputClass,
	forceRenderSelection, 
	isEditingTitle,
	...props 
}) => {

	const textInput = useRef(null);

	const [inputVal, setInputVal] = useState('');
	const [anyInteraction, setAnyInteraction] = useState(false)

	useEffect(() => {
		if (!selectOnInit) return;  // prevent running on start
		textInput?.current.setSelectionRange(0, field.value.length);
	}, [])

	useEffect(() => {

		let localRef = null;
		if (textInput.current) localRef = textInput.current;

		if( forceFocus ){  
			textInput?.current.focus();
			textInput?.current.setSelectionRange(0, field.value.length);
		}

		return ()=> {
			if( isEditingTitle ){
				onBlur(field.name, localRef.value, inputType)
			}
		}

	}, [])

	useEffect(()=>{ 
		// ignore re-renders that might drop the ball.
		// If we are editing the title and haven't clicked in yet, we force selection.
		// This is required for page duplication via option + drag, where a mouse up clears the range.
		if( !anyInteraction && isEditingTitle ){
			textInput?.current.setSelectionRange(0, field.value.length);
		}
	})

	const inputEl = (
		<div className={`text-input${className && !label ? ' '+className : ''}${noBars ? '' : ' bars'}${barLeft ? ' bar-left' : ''}${barRight ? ' bar-right' : ''}`} >
			<input 
				type={`${type ? type : 'text'}`}
				value={field.value} 
				ref={textInput}
				onClick={()=> { setAnyInteraction(true) }}
				onMouseDown={()=> { setAnyInteraction(true) }}
				onPointerDown={()=> { setAnyInteraction(true) }}
				autoComplete={ !autocomplete ? "off" : null }
				className={inputClass ? inputClass : null}
				data-no-dnd="true"
				onChange={e => { 

					setAnyInteraction(true);

					if ( form ) {
						form.setFieldValue(
							field.name, 
							e.currentTarget.value, 
							false
						)
						setInputVal( e.currentTarget.value );
					}

				}} 
				onPointerUp={(e)=>{
					if( forceRenderSelection ){ //fixes on pointer up safari bug after input initially renders
						e.preventDefault();
						textInput?.current.setSelectionRange(0, field?.value?.length);
					}
				}}
				placeholder={placeholder}
				autoFocus={focusOnInit ? true : false }
				onBlur={() => {
					if( forceFocus ){
						textInput?.current?.focus();
						textInput?.current?.setSelectionRange(0, field?.value?.length);
					}
					if( forceRenderSelection ){ //annoying safari bug on pointer up
						textInput?.current.setSelectionRange(0, field?.value?.length);
					}
					if( onBlur && textInput.current){
						onBlur(field.name, textInput.current.value, inputType)
					}
				}}
				onFocus={()=> {
					if( forceRenderSelection ){
						textInput?.current?.setSelectionRange(0, field?.value?.length);
					}
				}}
				onKeyDown={(e) => {
					setAnyInteraction(true)
					if( e.keyCode === 13 && onBlur && textInput.current ){
						onBlur(field.name, textInput.current.value, inputType)
					} 
				}}
			/>
		</div>
	)

	if( label ){
		return(
			<div className={`label-input-group${className ? ' '+className : ''}`}>
				<div className={`input-label ${noBars ? '' : 'bars'}`} >
					{ label }
				</div>
				{ inputEl }
			</div>
		)
	}

	if( icon ){
		return(
			<div className="input-icon-group">
				{ inputEl }
				<div className={`input-icon ${iconClass ? iconClass : ''}`} >
					{ icon }
				</div>
			</div>
		)
	}

	return inputEl

}

