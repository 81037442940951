import { FRONTEND_DATA } from "../../../globals";
import { rangeCanBeModifiedByTextCommands } from "../helpers";

export default {

	requiresTextInRange: true,
	requiresUncollapsedRange: true,
	requiresActiveRange: true,

	execute: function(){
		FRONTEND_DATA.contentWindow.CargoEditor.execCommand('small');
	},
	getState: (range) => {

		return {
			isAllowed: rangeCanBeModifiedByTextCommands(range),
	 		isApplied: FRONTEND_DATA.contentWindow.CargoEditor.queryCommandState('small')
	 	}
	},
	priority: -1

}