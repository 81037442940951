import { FRONTEND_DATA } from "../../../globals";

import { 
	retrieveSequentualNodesEffectivelyContainedByRange,
	rangeCanBeModifiedByTextCommands,
	wrapRangeWith,
	getNodesInRangeFilteredBySelector

} from "../helpers";

import _ from 'lodash';

const maybeConvertRGBToHex = (value) => {
	if (!value || !value.includes('rgb')) {
		return value;
	}
	if (value.includes('rgba')) {
		return value;
	}
	const rgbValues = value.replace('rgb(', '').replace(')', '').split(',');
	const hexValues = rgbValues.map((value) => {
		const hex = parseInt(value).toString(16);
		return hex.length === 1 ? `0${hex}` : hex;
	});
	return `#${hexValues.join('')}`;
}

export default {

	requiresTextInRange: true,
	requiresUncollapsedRange: true,
	requiresActiveRange: true,

	execute: function(hexColor){

		if(hexColor === 'rgba(0,0,0,0)' || hexColor === 'rgba(0, 0, 0, 0)'){

			// remove all color nodes
			wrapRangeWith('span', {
				before: () => {

					// first remove all old text-styles from our newly wrapped range
					getNodesInRangeFilteredBySelector('SPAN, H1, H2, A').forEach(node => {

						if(node.style.color !== '') {
							node.style.removeProperty('color');
						}

						if(node.style.length === 0) {
							node.removeAttribute('style');
						}

					});

				},
				after: (nodes) => {

					nodes.forEach(node => {
						if(node.nodeName === "SPAN" && node.attributes.length === 0) {
							FRONTEND_DATA.contentWindow.CargoEditor.helpers.unwrap(node, true);
						}
					});
					
				}
			});

		} else {

			// generate color nodes
			wrapRangeWith('span', {
				before: () => {

					// first remove all old text-styles from our newly wrapped range
					getNodesInRangeFilteredBySelector('SPAN, H1, H2, A').forEach(node => {

						if(node.style.color !== '') {
							node.style.removeProperty('color');
						}

						if(node.style.length === 0) {
							node.removeAttribute('style');
						}

					});

				},
				after: (nodes) => {

					nodes.forEach(node => {
						// set color
						node.style.color = hexColor;
					});

				}
			});

		}

	},

	getCurrentColor: () => {

		const nodes = getNodesInRangeFilteredBySelector('SPAN, H1, H2, A', {
			parentsOnly: true
		});

		let currentColor = null;
		let index = 0;
		while(!currentColor && nodes[index]){
			if(getComputedStyle(nodes[index]).color !== '') {
				currentColor = maybeConvertRGBToHex(getComputedStyle(nodes[index]).color);
			}
			index++;
		}

		if (!currentColor && nodes.length === 0) {
			// get bodycopy color
			currentColor = maybeConvertRGBToHex(getComputedStyle(FRONTEND_DATA.contentWindow.document.querySelector('bodycopy')).color);
		}
		
		return currentColor;

	},

	getState: (range) => {
		
		range = range || FRONTEND_DATA.contentWindow.CargoEditor.getActiveRange();

		const nodes = getNodesInRangeFilteredBySelector('SPAN, H1, H2, A', {
			parentsOnly: true
		});

		let foundMatch = false;
		let index = 0;
		while(!foundMatch && nodes[index]){
			let node = nodes[index]
			if(node.style.color !== '') {
				foundMatch = true;
			}
			index++;
		}

		const iconNodes = FRONTEND_DATA.contentWindow.CargoEditor.helpers.getAllEffectivelyContainedNodes(
			range, 
			node => node.nodeName === "IMG" && node.classList.value.includes('icon')
		);

		const isAllowed = rangeCanBeModifiedByTextCommands(range) || iconNodes.length > 0;

		return {
			isAllowed: isAllowed,
	 		isApplied: foundMatch
	 	}
	},	
	priority: 1
 
}