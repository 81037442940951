import { Field } from 'formik';
import { RangeExtension } from './range-extension';
import { Scrubber } from './scrubber';
import { TextButton } from './text-button';
import { useState, useEffect } from 'react';
import { DetailsMenu } from './details-menu';
import { dropshadowSliderDefaults, blurSliderDefaults } from '../../defaults/scrubber-defaults';
import ColorPickerField from './color-picker-field'
import { MoreActions } from '@cargo/ui-kit';
import { CheckBox } from "../ui-kit";
import { processCSSValue } from './helpers';
import { has } from 'lodash';

export const Filter = ({allowedProperties = ['drop-shadow'], ...props}) => {

    const [ dropshadowValues, setDropshadowValues ] = useState(null);
    const [ filterValues, setFilterValues ] = useState(null);

    const findClosingBracket = (str, pos) => {
		const rExp = /\(|\)/g;
		rExp.lastIndex = pos + 1;
		var deep = 1;
		while ((pos = rExp.exec(str))) {
		  if (!(deep += str[pos.index] === "(" ? 1 : -1 )) { return pos.index }
		}
	}

	const getDropshadowValues = () => {
		let propWatcherDropshadowParts = [null,null,null,null];
		let dropshadowString = null;
		if (props.field.value) {
			const start = props.field.value.indexOf('drop-shadow(') + 12;
    		const end = findClosingBracket(props.field.value, start);
			dropshadowString = props.field.value.substring(start, end);
			propWatcherDropshadowParts = dropshadowString.split(' ');
		}
        const background = props.form.values[Object.keys(props.form.values).find((key) => {
            if (props.form.values[key] && props.form.values[key]['background']) {
                return true;
            }
            return false;
        })]?.['background'] ?? null;
		
        const obj = {
			'dropshadow-offset-x': propWatcherDropshadowParts[0] ?? '0rem',
			'dropshadow-offset-y': propWatcherDropshadowParts[1] ?? '0rem',
			'dropshadow-blur-radius': propWatcherDropshadowParts[2] ?? '0rem',
			'dropshadow-color': dropshadowString ? dropshadowString.substring(dropshadowString.indexOf(propWatcherDropshadowParts[3])) : 'rgba(0, 0, 0, 0.25)',
            'dropshadow-background' : background
		}
		return obj;
	}

    useEffect(() => {
        setFilterValues(getFilterValues());
        setDropshadowValues(getDropshadowValues());
    }, [props.field.value])


    const handleChange = (name, value) => {
        if (name.startsWith('dropshadow')) {
            const prevDropshadowObj = getDropshadowValues();
            const newDropshadowObj = {
                ...prevDropshadowObj,
                [name]: value
            }
            if (name === 'dropshadow-offset') {
                let dropshadowOffsetXValue = processCSSValue( newDropshadowObj['dropshadow-offset-x'] );
                let dropshadowOffsetValue = processCSSValue( value );
                if (dropshadowOffsetXValue[0] !== dropshadowOffsetValue[0]) {
                    newDropshadowObj['dropshadow-offset-x'] = value;
                    newDropshadowObj['dropshadow-offset-y'] = value;
                }
            }
            if (newDropshadowObj['dropshadow-color'] === null) {
                newDropshadowObj['dropshadow-color'] = 'rgba(0,0,0,0)';
            }
            const prevObj = getFilterValues();
            const newObj = {
                ...prevObj,
                'drop-shadow': `${newDropshadowObj['dropshadow-offset-x']} ${newDropshadowObj['dropshadow-offset-y']} ${newDropshadowObj['dropshadow-blur-radius']} ${newDropshadowObj['dropshadow-color']}`
            }
            const newFilter = Object.keys(newObj).map((key) => {
                return `${key}(${newObj[key]})`;
            }).join(' ').trim();  
            props.form.setFieldValue(
                props.field.name, 
                newFilter,
                false
            );
        } else {
            const prevObj = getFilterValues();
            console.log('prevObj', prevObj)
            const newObj = {
                ...prevObj,
                [name]: value
            }
            const newFilter = Object.keys(newObj).map((key) => {
                return `${key}(${newObj[key]})`;
            }).join(' ').trim();
            props.form.setFieldValue(
                props.field.name, 
                newFilter,
                false
            );
        }
    }

    const getFilterValues = () => {
        const filterValues = {};
		if (props.field.value) {
			// Parse the backdrop-filter string to get the properties and their values
            const filterParts = props.field.value.split(' ').reduce((acc, part) => {
                if (part.includes('(') && part.includes(')')) {
                    acc.push(part);
                }
                return acc;
            }, []);
            filterParts.forEach((part) => {
                let [key, value] = part.split('(');
                // Remove the last character from the value, which is the closing bracket
                value = value.substring(0, value.length - 1);
                filterValues[key] = value;
            });
            if (hasDropshadowStyles()) {
                const dropshadowValues = getDropshadowValues();
                filterValues['drop-shadow'] = `${dropshadowValues['dropshadow-offset-x']} ${dropshadowValues['dropshadow-offset-y']} ${dropshadowValues['dropshadow-blur-radius']} ${dropshadowValues['dropshadow-color']}`;
            }
		}
        return filterValues;
	}

    const removeProperty = (property) => {
        const start = props.field.value.indexOf(`${property}(`) + property.length + 1;
        const end = findClosingBracket(props.field.value, start);
        const pattern = props.field.value.substring(start - 12, end + 1);
        let newFilter = props.field.value.replace(pattern, '');
        // If new filter is empty, set to null
        if (newFilter === '') {
            newFilter = null;
        }
        props.form.setFieldValue(
            props.field.name,
            newFilter,
            false
        );
    }

    const hasDropshadowStyles = () => {
        let hasDropshadowStyles = false;
        if (props.field.value && props.field.value.includes('drop-shadow')) {
            hasDropshadowStyles = true;
        }
        return hasDropshadowStyles;
    };

    const hasVisibleDropshadowStyles = () => {
        let hasVisibleDropshadowStyles = false;
        if (props.field.value && props.field.value.includes('drop-shadow')) {
            const values = getDropshadowValues();
            const check = ['dropshadow-offset-x', 'dropshadow-offset-y', 'dropshadow-blur-radius', 'dropshadow-color'];
            check.forEach((key) => {
                if (key !== 'dropshadow-color' && processCSSValue(values[key])[0] !== 0) {
                    hasVisibleDropshadowStyles = true;
                }
                if (key === 'dropshadow-color' && 
							(
								values[key] === 'rgba(0,0,0,0)' ||
								values[key] === 'rgba(0, 0, 0, 0)' ||
								values[key] === 'transparent' ||
								values[key] === 'none'
							)
						) {
						hasVisibleDropshadowStyles = false;
					}
            });
        }
        return hasVisibleDropshadowStyles;
    };

    const hasPropertyStyles = (property) => {
        let hasPropertyStyles = false;
        if (props.field.value && props.field.value.includes(property)) {
            hasPropertyStyles = true;
        }
        return hasPropertyStyles;
    };

    const hideDropshadowOffsetScrubber = false;

    return (
        <>
        {allowedProperties.includes('drop-shadow') && (
        <DetailsMenu 
            text="Drop Shadow"
            className={`bars${hasVisibleDropshadowStyles() ? ' in-use ' : ''}${props.className ? ' ' + props.className : ''}`}
            sessionStorage={`ui-menu-dropshadow-`+props.sessionStorage}
            // open={hasDropshadowStyles()}
            noMargin={props.noMargin ? props.noMargin : null}
        >
            <div className="ui-group">
                <RangeExtension
                    firstField= {
                        <Scrubber
                            label="Offset"
                            hideScrubberValue={ dropshadowValues && (dropshadowValues['dropshadow-offset-x'] !== dropshadowValues['dropshadow-offset-y']) ? true : false }
                            field={{
                                name: "dropshadow-offset",
                                value: dropshadowValues ? dropshadowValues['dropshadow-offset-x'] : null,
                            }}
                            form={{
                                setFieldValue: (name, value) => handleChange(name, value),
                                setFieldTouched: () => {}
                            }}
                            {...dropshadowSliderDefaults} 
                        />
                    }
                >
                    <Scrubber
                        label="Horizontal"
                        hideScrubberValue={ hideDropshadowOffsetScrubber }
                        field={{
                            name: "dropshadow-offset-x",
                            value: dropshadowValues ? dropshadowValues['dropshadow-offset-x'] : null,
                        }}
                        form={{
                            setFieldValue: (name, value) => handleChange(name, value)
                        }}
                        {...dropshadowSliderDefaults} 
                    />
                    <Scrubber
                        label="Vertical"
                        hideScrubberValue={ hideDropshadowOffsetScrubber }
                        field={{
                            name: "dropshadow-offset-y",
                            value: dropshadowValues ? dropshadowValues['dropshadow-offset-y'] : null,
                        }}
                        form={{
                            setFieldValue: (name, value) => handleChange(name, value)
                        }}
                        {...dropshadowSliderDefaults} 
                    />
                </RangeExtension>

                <Scrubber
                    label="Blur"
                    field={{
                        name: "dropshadow-blur-radius",
                        value: dropshadowValues ? dropshadowValues['dropshadow-blur-radius'] : null,
                    }}
                    form={{
                        setFieldValue: (name, value) => handleChange(name, value)
                    }}
                    {...blurSliderDefaults}
                    min={0}
                />
                <Field 
                    component={ColorPickerField}
                    synced={false}                        
                    tabs = {[
                        {
                            name      : 'dropshadow-color',
                            label     : 'Shadow',
                        }, 
                        {
                            name      : 'dropshadow-background',
                            label     : 'Background',
                        }
                    ]}
                    form={{
                        setFieldValue: (name, value) => handleChange(name, value),
                        values: dropshadowValues
                    }}
                    label="Color"
                    showRandom={true}
                />

                <MoreActions className="left">
                    {hasDropshadowStyles()
                    ? <TextButton
                        className="code"
                        onMouseDown={(e) => {
                            removeProperty('drop-shadow');
                        }}
                        label={<><em>×</em> Remove</>}
                    />
                    : null}
                </MoreActions>
            </div>
        </DetailsMenu>
        )}
        {allowedProperties.includes('blur') && (
        <Scrubber
            className={props.className}
            label={props.prefix ? `${props.prefix} Blur` : 'Blur'}
            field={{
                name: "blur",
                value: filterValues ? filterValues['blur'] : null,
            }}
            form={{
                setFieldValue: (name, value) => handleChange(name, value),
                setFieldTouched: () => {}
            }}
            {...blurSliderDefaults} 
        />
        )}
        {allowedProperties.includes('invert') && (
        <CheckBox
            label={props.prefix ? `${props.prefix} Invert` : 'Invert'}
            field={{
                name: "invert",
                value: filterValues && filterValues['invert'] === '100' ? true : false,
            }}
            form={{
                setFieldValue: (name, value) => {
                    if (value === true) {
                        handleChange(name, 100)
                    } else {
                        handleChange(name, 0)
                    }
                },
                setFieldTouched: () => {}
            }}
        />
        )}
    </>
    )
}