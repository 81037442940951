import React, { Component } from 'react';
import { connect } from 'react-redux';
import { actions } from "../../actions";
import { bindActionCreators } from 'redux';
import { Formik, Field } from 'formik';
import FormikChangeListener from "./formik-change-listener";

import _ from 'lodash';
import { createSelector } from 'reselect';
import { commands } from "../../lib/inline-editor";
import { MoreButton } from "./";
import { Select } from "@cargo/ui-kit";
import { ContextMenuButton, ContextSubMenu, ContextMenuCheckbox, MenuContext } from "@cargo/common/context-menu";
import { deriveVariantFromCSS, createFVSPropertyMap, deriveFontDisplayName } from "@cargo/common/font-helpers.js";

import { withPropWatchers } from "../../lib/shared-css";
import TypographyMenuContextUI from "../right-menu-bar/typography-menu-context-ui";
class TypographySelectComponent extends Component {

	constructor(props) { 
		super(props);

		this.state = {};
		
	};

	componentDidMount(){

	}

	getPreviewName = (selectorValues) => {
		if (this.props.fontCollectionLoaded && this.props.fontCollection) {

			return deriveFontDisplayName(this.props.fontCollection, this.getFontSettings(selectorValues), 'family')
		}
		return null
	}

	getFontSettings = (selectorValues) => {

		let collection = this.props.fontCollection?.collection;
		let familyInCSS = selectorValues['font-family'];
		let family     = _.first(selectorValues['font-family']?.split(','))?.trim()?.replace(/^"|"$/g, '');;
		let style      = selectorValues['font-style'];
		let weight     = selectorValues['font-weight']
		let fvs        = selectorValues['font-variation-settings']

		if( fvs ){
			const fvsMap = createFVSPropertyMap( fvs );
			if( fvsMap['wght'] ){
				weight = fvsMap['wght'];
			}
		}

		if( !family ){
			console.log("Could not find family in prop watcher values", this.props.selector, this.props.propWatcherValues);
		};
		
		let variant = deriveVariantFromCSS( this.props.fontCollection?.collection, family, style, weight, fvs, familyInCSS );

		return {
			family,
			variant,
			style,
			weight,
			fvs,
			familyInCSS
		}

	}

	render() {

		// let previewSuffix = getPreviewName( props.values['current_text_style_menu_tag'] )
		// let previewName = props.values['current_text_style_menu_tag'] + " — " + previewSuffix;
		let currentTextStyle = this.props.currentTextStyle;
		let showCreateNewFlow = this.props.showCreateNewFlow;

		// if no propWatcher is found we can't render
		if(!this.props.propWatcherValues[this.props.currentTextStyle.tag]) {
			return null;
		}

		let previewSuffix = this.getPreviewName(this.props.propWatcherValues[this.props.currentTextStyle.tag])
		// let previewName = this.props.isMobile ? currentTextStyle.name + " — " + previewSuffix : currentTextStyle.name;
		let textTagName = { current_text_style_menu_tag: currentTextStyle.tag }
		return (
			<div className="grid-columns-auto-square typography-select select">
				<div>
					<Formik
						// this is important for websocket updating
						enableReinitialize
						// defaults are set here
						initialValues={{
							currentTextStyle,
							showCreateNewFlow,
							'current_text_style_menu_tag': currentTextStyle.tag,
						}}
					>
						{props => {

							return (
								<>
								<FormikChangeListener onChange={this.props.onChange}/>
								
								<Field 
									component={Select} 
									selectedIndex={this.props.selectedIndex}
									buttonLabel={ currentTextStyle.name }
									buttonSelect={true}
									noBars={true}
									name="current_text_style_menu_tag"
									// {...props} 
								>
									{[
										this.props.typographySelectors.map((typeStyle, index) => {
											return <option 
												key={typeStyle.tag+index}
												value={typeStyle.tag}
											>
												{typeStyle.name}
											</option>
										})
									]}
									<hr></hr>
									{!this.props.isMobile && <option 
										key={`add_new`}
										value={`add_new`}
									>
										New Style...
									</option>}
								</Field>

								</>
							)
						}
						}
					</Formik>
				</div>

				{!this.props.isMobile && <div className="more circle">
					<MenuContext.Consumer>
						{(Menu) => 
							<MoreButton
								// id={site.direct_link}
								onMouseDown = { e => {
										e.preventDefault();
										Menu.openMenu({
											innerUI: <TypographyMenuContextUI
												currentTextStyle={this.props.currentTextStyle}
												handleNew={() => {
													this.props.setShowCreateNewFlow(true);
												}}
												handleDuplicate={() => {
													this.props.showCopySelectorFlow(this.props.selector);
												}}
												handleDelete={() => {
													this.props.deleteStyleFromSelector(this.props.selector);
													this.props.refreshTypographyMenus();
												}}
												renameCurrentTextStyle={(newName) => {
													this.props.renameCurrentTextStyle(newName);
												}}
											/>,
											type: 'mouse',
											event: e
										}) 
									}
								}
							/>
						}
					</MenuContext.Consumer>
				</div>}
			
			</div>
		)

	};

};

const memoizedWatchedProperties = createSelector(
	(ownProps) => ownProps.typographySelectors,
	(
		selectors
	) => {

		const config = {};

		selectors.forEach(selector => {

			config[selector.tag] = [
				'font-size',
				'font-variant-name',
				'font-family',
				'font-weight',
				'font-style',
				'font-variation-settings',
				'font-feature-settings'
			]
		})


		return config;

	}
);

const mapReduxStateToProps = (state, ownProps) => {

	const isMobile = ownProps.isMobileData === true;

	return {
		isMobile,
		fontCollection: state.fontCollection,
		fontCollectionLoaded: _.get(state.fontCollection, 'hasFontCollection'),
	};

}

export const TypographySelect = connect(
	mapReduxStateToProps
	)(withPropWatchers(TypographySelectComponent,  {
		parser: 'global',
		watchedProperties: memoizedWatchedProperties
	})
);


