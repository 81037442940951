import { FRONTEND_DATA } from "../../../globals";

import { LOREM_TEMPLATES } from "../../../content-templates"
import { helpers } from "@cargo/common";

import _ from 'lodash';

let lastLoremArrNum = 0;

export default {

	requiresTextInRange: false,
	requiresUncollapsedRange: false,
	requiresActiveRange: true,

	execute: function(){

		let range = FRONTEND_DATA.contentWindow.CargoEditor.getActiveRange();

		const state = this.getState(range);

		if(!state.isAllowed || state.isApplied) {
			return;
		}

		let markup = ``;

		let arrIndex = this.getRandomLoremIndex();

		markup = LOREM_TEMPLATES[arrIndex];

		if( !markup ){
			console.warn('Lorem Ipsum template not found.')
			return 
		}

		FRONTEND_DATA.contentWindow.CargoEditor.mutationManager.execute(()=> {

			const activeEditor = FRONTEND_DATA.contentWindow.CargoEditor.getActiveEditor()

			let insertEl = FRONTEND_DATA.contentWindow.document.createElement('div');
			
			insertEl.innerHTML = markup;

			range = FRONTEND_DATA.contentWindow.CargoEditor.getActiveRange();

			range.insertNode( insertEl )

			setTimeout(()=>{
				let len = insertEl.childNodes.length - 1;
				range.setStart(insertEl.childNodes[len], insertEl.childNodes[0].childNodes.length);
				range.setEnd(insertEl.childNodes[len], insertEl.childNodes[0].childNodes.length);
				range.collapse();

				FRONTEND_DATA.contentWindow.CargoEditor.helpers.setActiveRange(range);	

				insertEl.replaceWith(...insertEl.childNodes);

			}, 100)

			
						
			console.log("insertEl.childNodes", insertEl.childNodes )
			// FRONTEND_DATA.contentWindow.CargoEditor.rangy.getSelection().removeAllRanges();
			// FRONTEND_DATA.contentWindow.CargoEditor.forcedRange = null;

		});
	},

	getRandomLoremIndex: function(){
	    let currentNum = Math.floor(Math.random() * 10);
	    while (lastLoremArrNum === currentNum) {
	        currentNum = Math.floor(Math.random() * 10);
	    }
	    lastLoremArrNum = currentNum;
	    return currentNum;
	},

	getState: (range)=> {

		if(!range){
			range = FRONTEND_DATA.contentWindow.CargoEditor.getActiveRange();
		}


		return {
			isApplied: false,
			isAllowed: range !== null && range !== undefined
		};
	},	
	priority: 1

 
}