import { Field } from 'formik';
import { RangeExtension } from './range-extension';
import { Scrubber } from './scrubber';
import { TextButton } from './text-button';
import { useState, useEffect } from 'react';
import { DetailsMenu } from './details-menu';
import { dropshadowSliderDefaults, blurSliderDefaults } from '../../defaults/scrubber-defaults';
import ColorPickerField from './color-picker-field'
import { MoreActions } from '@cargo/ui-kit';
import { CheckBox } from "../ui-kit";
import { processCSSValue } from './helpers';

export const BoxShadow = (props) => {

    const [ boxshadowValues, setBoxshadowValues ] = useState(null);

    const splitBoxShadowValues = (boxShadowString) => {
        // Check for 'inset' and remove it
        let hasInset = false;
        if (boxShadowString.includes('inset')) {
          boxShadowString = boxShadowString.replace('inset', '');
          hasInset = true;
        }
      
        // Regular expression for matching box-shadow values
        const boxShadowRegex = /(-?\d*\.?\d+)([a-zA-Z%]*)?\s*(-?\d*\.?\d+)?([a-zA-Z%]*)?\s*(-?\d*\.?\d+)?([a-zA-Z%]*)?\s*(-?\d*\.?\d+)?([a-zA-Z%]*)?\s*((rgba?\([^)]*\)|#(?:[a-fA-F0-9]{6}|[a-fA-F0-9]{3})|(?:[a-zA-Z]+)))(?:\s*\/\s*\d{1,3}%)?/;

        // Match box-shadow values using the regex
        let matches = boxShadowString.match(boxShadowRegex);
      
        // Extract matched values and return an array
        let result = [
          `${matches[1] || '0'}${matches[2] || ''}`,
          `${matches[3] || '0'}${matches[4] || ''}`,
          `${matches[5] || '0'}${matches[6] || ''}`,
          `${matches[7] || '0'}${matches[8] || ''}`,
          matches[9] || 'rgba(0, 0, 0, 0.25)'
        ];
      
        // Add 'inset' back to the array if it was present
        if (hasInset) {
          result.push('inset');
        }
      
        return result;
    };

	const getBoxshadowValues = () => {
        let propWatcherBoxshadowParts = [null,null,null,null,null,null];

        if (props.field.value) {
            let shadowString = props.field.value;
            propWatcherBoxshadowParts = splitBoxShadowValues(shadowString);
        }
        
        const obj = {
			'boxshadow-offset-x': propWatcherBoxshadowParts[0] ?? '0rem',
			'boxshadow-offset-y': propWatcherBoxshadowParts[1] ?? '0rem',
			'boxshadow-blur-radius': propWatcherBoxshadowParts[2] ?? '0rem',
			'boxshadow-spread-radius': propWatcherBoxshadowParts[3] ?? '0rem',
			'boxshadow-color': propWatcherBoxshadowParts[4] ?? 'rgba(0,0,0,0)',
			'boxshadow-inset': propWatcherBoxshadowParts[5] ?? '',
		}
		return obj;
	}

    useEffect(() => {
        setBoxshadowValues(getBoxshadowValues());
    }, [props.field.value])


    const handleChange = (name, value) => {
        const prevObj = getBoxshadowValues();
        const newObj = {
            ...prevObj,
            [name]: value
        }

        if (name === 'boxshadow-offset') {
            let boxshadowOffsetXValue = processCSSValue( newObj['boxshadow-offset-x'] );
            let boxshadowOffsetValue = processCSSValue( value );
            if (boxshadowOffsetXValue[0] !== boxshadowOffsetValue[0]) {
                newObj['boxshadow-offset-x'] = value;
                newObj['boxshadow-offset-y'] = value;
            }
        }

        if (name === 'boxshadow-inset') {
            if (prevObj['boxshadow-inset'] === '' || !prevObj['boxshadow-inset']) {
                newObj['boxshadow-inset'] = true;
            } else {
                newObj['boxshadow-inset'] = false;
            }    
        }

        let isNoColor = ( newObj['boxshadow-color'] == 'rgba(0,0,0,0.0)' ||
        newObj['boxshadow-color'] == 'rgba(0,0,0,0)' ||
        newObj['boxshadow-color'] == 'rgba(0, 0, 0, 0.0)' ||
        newObj['boxshadow-color'] == 'rgba(0, 0, 0, 0)' ||
        newObj['boxshadow-color'] == 'transparent' ||
        newObj['boxshadow-color'] == 'none')

        // check length values before
        let processedValue = [
            processCSSValue( newObj['boxshadow-offset-x'] ),
            processCSSValue( newObj['boxshadow-offset-y'] ),
            processCSSValue( newObj['boxshadow-blur-radius'] ),
            processCSSValue( newObj['boxshadow-spread-radius'] )
        ]

        let isXZero = parseFloat( processedValue[0] ) === 0;
        let isYZero = parseFloat( processedValue[1] ) === 0;
        let isBlurZero = parseFloat( processedValue[2] ) === 0;
        let isSpreadZero = parseFloat( processedValue[3] ) === 0;

        let isLengthZero = isXZero && isYZero && isBlurZero && isSpreadZero;

        let newBoxshadow = null;

        // default color
        if (isNoColor && name !== 'boxshadow-color') {
            newObj['boxshadow-color'] = 'rgba(0, 0, 0, 0.25)'
            isNoColor = false;
        }

        // default length
        if (isLengthZero && ( name == 'boxshadow-color' || name == 'boxshadow-inset' ) ) {
            newObj['boxshadow-offset-x'] = '0.4rem'
            newObj['boxshadow-offset-y'] = '0.4rem'
            newObj['boxshadow-blur-radius'] = '1rem'
        }

        // check length values after
        let reprocessedValue = [
            processCSSValue( newObj['boxshadow-offset-x'] ),
            processCSSValue( newObj['boxshadow-offset-y'] ),
            processCSSValue( newObj['boxshadow-blur-radius'] ),
            processCSSValue( newObj['boxshadow-spread-radius'] )
        ]

        let isXZeroAgain = parseFloat( reprocessedValue[0] ) === 0;
        let isYZeroAgain = parseFloat( reprocessedValue[1] ) === 0;
        let isBlurZeroAgain = parseFloat( reprocessedValue[2] ) === 0;
        let isSpreadZeroAgain = parseFloat( reprocessedValue[3] ) === 0;

        let isLengthStillZero = isXZeroAgain && isYZeroAgain && isBlurZeroAgain && isSpreadZeroAgain;


        // if we're default page settings, and there's no color, and the lengths are zero
        if ( !props.localShadow && isNoColor && isLengthStillZero ) {
            // clear color
            newObj['boxshadow-color'] = 'rgba(0,0,0,0.0)'
        } else {
            // write new box shadow
            newBoxshadow = `${newObj['boxshadow-offset-x']} ${newObj['boxshadow-offset-y']} ${newObj['boxshadow-blur-radius']} ${newObj['boxshadow-spread-radius']} ${newObj['boxshadow-color']}${(newObj['boxshadow-inset']) ? ' inset' : ''}`;
        }

        props.form.setFieldValue(
            props.field.name, 
            newBoxshadow,
            false
        );
    }

    const removeBoxshadow = () => {
        let newBoxshadow = null;

        props.form.setFieldValue(
            props.field.name,
            newBoxshadow,
            false
        );
    }

    const hasBoxshadowStyles = () => {
        let hasBoxshadowStyles = false;
        if (props.field.value) {
            hasBoxshadowStyles = true;
        }
        return hasBoxshadowStyles;
    };

    const hasVisibleBoxshadowStyles = () => {
        let hasVisibleBoxshadowStyles = false;
        if (props.field.value) {
            const values = getBoxshadowValues();
            const check = ['boxshadow-offset-x', 'boxshadow-offset-y', 'boxshadow-blur-radius', 'boxshadow-spread-radius', 'boxshadow-color', 'boxshadow-inset'];
            check.forEach((key) => {
                if (key !== 'boxshadow-color') {
                    hasVisibleBoxshadowStyles = true;
                }
                if (key === 'boxshadow-color' && props.localShadow && 
							(
                            values[key] === 'rgba(0,0,0,0)' ||
                            values[key] === 'rgba(0, 0, 0, 0)' ||
                            values[key] === 'transparent' ||
                            values[key] === 'none'
                        )
                    ) {
                    hasVisibleBoxshadowStyles = false;
                }
            });
        }
        return hasVisibleBoxshadowStyles;
    };

    const hideBoxshadowOffsetScrubber = false;

    // render
    return (
        <DetailsMenu 
            text="Page Shadow"
            className={`bars${hasVisibleBoxshadowStyles() ? ' in-use' : ''}${props.className ? props.className : ''}`}
            sessionStorage={`ui-menu-boxshadow-`+props.sessionStorage}
            // open={hasBoxshadowStyles()}
            noMargin={props.noMargin || false}
        >
            <div className="ui-group">
                <RangeExtension
                    firstField= {
                        <Scrubber
                            label="Offset"
                            hideScrubberValue={ boxshadowValues && (boxshadowValues['boxshadow-offset-x'] !== boxshadowValues['boxshadow-offset-y']) ? true : false }
                            field={{
                                name: "boxshadow-offset",
                                value: boxshadowValues ? boxshadowValues['boxshadow-offset-x'] : null,
                            }}
                            form={{
                                setFieldValue: (name, value) => handleChange(name, value),
                                setFieldTouched: () => {}
                            }}
                            isOverriding={ props.showReset ?? false } 
                            overrideReset={()=> {
                                removeBoxshadow();
                            }} 
                            {...dropshadowSliderDefaults} 
                        />
                    }
                >
                    <Scrubber
                        label="Horizontal"
                        hideScrubberValue={ hideBoxshadowOffsetScrubber }
                        field={{
                            name: "boxshadow-offset-x",
                            value: boxshadowValues ? boxshadowValues['boxshadow-offset-x'] : null,
                        }}
                        form={{
                            setFieldValue: (name, value) => handleChange(name, value)
                        }}
                        {...dropshadowSliderDefaults} 
                    />
                    <Scrubber
                        label="Vertical"
                        hideScrubberValue={ hideBoxshadowOffsetScrubber }
                        field={{
                            name: "boxshadow-offset-y",
                            value: boxshadowValues ? boxshadowValues['boxshadow-offset-y'] : null,
                        }}
                        form={{
                            setFieldValue: (name, value) => handleChange(name, value)
                        }}
                        {...dropshadowSliderDefaults} 
                    />
                </RangeExtension>
                <Scrubber
                    label="Blur"
                    field={{
                        name: "boxshadow-blur-radius",
                        value: boxshadowValues ? boxshadowValues['boxshadow-blur-radius'] : null,
                    }}
                    form={{
                        setFieldValue: (name, value) => handleChange(name, value)
                    }}
                    {...blurSliderDefaults}
                    min={0}
                />
                <Scrubber
                    label="Spread"
                    field={{
                        name: "boxshadow-spread-radius",
                        value: boxshadowValues ? boxshadowValues['boxshadow-spread-radius'] : null,
                    }}
                    form={{
                        setFieldValue: (name, value) => handleChange(name, value)
                    }}
                    {...dropshadowSliderDefaults}
                />
                <Field 
                    component={ColorPickerField}
                    synced={false}                        
                    name='boxshadow-color'
                    form={{
                        setFieldValue: (name, value) => handleChange(name, value),
                        value: boxshadowValues ? boxshadowValues['boxshadow-color'] : null,
                    }}
                    field={{
                        name: "boxshadow-color",
                        value: boxshadowValues ? boxshadowValues['boxshadow-color'] : null,
                    }}
                    label="Color"
                    showRandom={true}
                    modalResetColor={ 'rgba(0,0,0,0)' }
                />
                <CheckBox
                    label="Inset"
                    field={{
                        name: "boxshadow-inset",
                        value: boxshadowValues ? boxshadowValues['boxshadow-inset'] ? true : false : false,
                    }}
                    form={{
                        setFieldValue: (name, value) => handleChange(name, value),
                    }}
                />

                <MoreActions className="left">
                    { hasBoxshadowStyles() && props.showRemove ? 
                        ( <TextButton
                            className="code"
                            onMouseDown={(e) => {
                                removeBoxshadow();
                            }}
                            label={<><em>×</em> Remove</>}
                        />
                    ) : null}
                </MoreActions>
            </div>
        </DetailsMenu>
    )
}