export const
	 LOREM_TEMPLATES = [
	`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut nisl metus, accumsan id eros id, vehicula tincidunt nisl. Nulla sit amet urna ut mi tempor euismod. Fusce elementum sapien eget elit sodales fringilla. Pellentesque et nulla vel velit malesuada fermentum vitae vitae justo. Nulla dignissim volutpat interdum. Pellentesque at molestie tortor. Proin magna ante, fermentum ac elementum at, lacinia et arcu.`
	,
	`Ut volutpat pharetra orci, ac bibendum nunc vulputate in. Praesent porttitor venenatis lacus sed mollis. Nulla varius leo nec porta tempus. Nunc orci enim, bibendum in gravida eu, pretium eu purus. Sed vestibulum quam sed eros malesuada hendrerit. Praesent pellentesque ipsum non commodo congue. Nam et metus malesuada leo aliquet facilisis in in enim. In consequat orci eleifend, feugiat eros eu, lobortis purus. Curabitur facilisis gravida ipsum a tempus. Vestibulum pharetra, magna eu eleifend ultricies, sem tellus ullamcorper elit, sed porttitor nisi libero non dui. Aenean porttitor arcu sem. Sed id ipsum felis. Vivamus ut placerat ex, sit amet eleifend ligula.`
	,
	`Curabitur vitae odio pretium, volutpat augue efficitur, ullamcorper ante. Nunc ultrices eget urna in aliquet. Nulla at ullamcorper leo. Aliquam egestas non lacus sed tempor. Ut bibendum mauris sit amet augue mollis, et tincidunt nisi gravida. Proin dictum dapibus libero vitae luctus. Morbi gravida dolor sit amet erat sagittis, suscipit blandit nibh ullamcorper.`
	,
	`Integer et placerat est, eu imperdiet neque. Proin sit amet purus metus. In nec efficitur justo, sed maximus est. Ut ornare arcu eros, at condimentum enim imperdiet vitae. Morbi lorem augue, commodo vel ipsum ac, sollicitudin placerat urna. Praesent sit amet mauris tortor. Duis ac risus sem. Fusce at elementum justo, ac mollis turpis. Pellentesque accumsan purus ut tristique bibendum. Nam feugiat sit amet enim in vehicula.`
	,
	`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam fringilla massa massa, sit amet molestie orci suscipit sed. Ut tincidunt eleifend felis sit amet suscipit. Nulla accumsan diam enim. Integer scelerisque, velit vitae accumsan tempor, quam ex vestibulum elit, eget volutpat elit ipsum id ante. Morbi condimentum quis elit in finibus. Donec accumsan feugiat varius. Etiam ac libero at lectus blandit vulputate.<br><br>Aenean lorem urna, tempus ac rhoncus vel, lacinia eu velit. In non quam eu odio molestie consectetur ac quis nisi. Curabitur ultrices metus iaculis nisi auctor, vitae ornare lorem interdum. Nullam faucibus dui et quam iaculis mattis. Proin quis ultricies est, ut posuere tortor. Proin tempus mauris lacus, sollicitudin varius ex dapibus eu. Nam feugiat mollis convallis. Nam convallis neque ut urna pretium aliquam. Duis malesuada quam fermentum, mollis ligula et, lobortis mi. Vestibulum et enim ligula. Nam sit amet faucibus justo. Aliquam erat volutpat. Duis at pretium massa.`
	,
	`Praesent bibendum a elit non efficitur. Etiam et velit vitae quam aliquet fermentum. Quisque sit amet nulla dictum, cursus erat a, tempus est. Sed laoreet, magna eget mattis tincidunt, felis tellus vestibulum augue, in lobortis mauris risus eu nunc. Vivamus sed condimentum leo. Nunc efficitur, magna condimentum gravida egestas, ligula mauris posuere neque, sit amet sagittis dui velit vitae neque. Donec in dapibus arcu. Aliquam efficitur suscipit magna, vel pretium mi. Aenean nisl nulla, porttitor interdum enim nec, sagittis suscipit mauris. Quisque malesuada ullamcorper lacus, nec posuere odio egestas vel. Suspendisse potenti.`
	,
	`Sed a suscipit tortor. Pellentesque volutpat metus a odio sollicitudin pulvinar. In finibus metus at eros faucibus consequat. Ut posuere interdum leo id pharetra. Vivamus pellentesque, lectus elementum hendrerit convallis, sapien massa porta mauris, ac malesuada quam felis in felis. Fusce id vehicula ipsum. Duis lacinia porttitor justo, ac interdum leo sollicitudin quis.`
	,
	`Vestibulum et dignissim magna. Nullam at ultrices felis. Suspendisse facilisis sagittis sem sit amet tempor. Nam sit amet nibh et enim laoreet porta sit amet vel purus. Maecenas augue velit, feugiat in maximus id, auctor vel ex. Integer placerat massa nec tellus convallis aliquet. Nam lobortis lectus mi, vel molestie neque dictum at. Vivamus sed sapien fermentum, fermentum metus ut, efficitur nunc. Pellentesque sit amet ex sed est dapibus vestibulum. Donec ornare urna metus, ut mollis risus dignissim in. Phasellus tristique sed leo et dignissim. Nam lobortis vitae leo at finibus. Aliquam eget efficitur lacus. Quisque nunc nisi, dictum at sodales sed, ornare vel dolor. Sed rhoncus placerat ante rutrum condimentum. Fusce nunc sapien, sodales et consectetur at, sodales egestas lectus.`
	,
	`Mauris leo erat, placerat quis semper eu, pulvinar in est. Nam eget justo quis ligula faucibus pretium ac nec mi. Aliquam lacus est, auctor sed arcu at, luctus condimentum lectus. Quisque vitae eleifend quam, vel suscipit nisl. Duis efficitur est nunc, eget efficitur felis porttitor in. Donec facilisis ultrices mattis. Quisque blandit ac enim non accumsan. Donec euismod magna turpis, ut blandit purus tempor sit amet. Suspendisse et hendrerit mi. Integer gravida, sapien quis ullamcorper auctor, metus eros aliquet ante, ut dictum ipsum tortor eget elit. Maecenas eu rutrum dui, ut faucibus lectus. Vivamus porttitor bibendum tortor.`
	,
	`Curabitur congue accumsan ante, consectetur eleifend purus laoreet in. Ut facilisis tincidunt neque in pharetra. In ut libero ut ex commodo venenatis ac vel ligula. Suspendisse tortor lacus, ultrices at magna in, ullamcorper faucibus sem. Nullam urna urna, dictum at quam vel, aliquet pulvinar risus. Vestibulum nulla felis, venenatis vel ipsum id, ullamcorper efficitur elit. Proin sollicitudin lectus nec eleifend mattis. Integer non sapien elit. Morbi ullamcorper erat ipsum, sed sodales enim elementum in.`
	]






