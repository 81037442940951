import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Alert, AlertContext, Button } from "@cargo/ui-kit";
import Thumbnail from './thumbnail';
import UploadProgressIndicator from './upload-progress-indicator';
import { actions } from "../../actions";
import { HotKey, HotKeyProxy } from "./";
import { helpers } from "@cargo/common";

class MediaComponent extends Component {

	constructor(props) {
		super(props);

	}

	render() {

		const mediaJSX = _.map(this.props.media, (item, index) => {

			// in-progress loader
			if ( this.props.listItemComponent ){

				const ListItem = this.props.listItemComponent;
				return <ListItem key={item.hash +'__'+index} item={item} />

			} else if(item.loading){

				return <UploadProgressIndicator key={item.hash} hash={item.hash} />

			} else {
				return <li key={item.hash+'__'+index}>{item.name}</li>
			}

		});

		return (

			this.props.media?.length > 0 ?
				<>
					<div 
						className="media-component"
						media-type={this.props.mediaType}
					>
	 					{mediaJSX}
					</div>

					<div 
						ref={(el) => { this.scrollBottom = el; }} 
						style={{width: '0px', height: '0px', flexGrow: 1, maxWidth: '0%', maxHeight: '0%' }}>
					</div>
				</>

			:
				<div className="empty-media-component">
					<div className="icon">
						{this.props.mediaType && this.props.mediaType === 'images' ?
							<svg width="59" height="74" viewBox="0 0 59 74" fill="none">
								<path fillRule="evenodd" clipRule="evenodd" d="M58 73H1V1H43.5V15.5H58V73ZM59 14.5V15.5V74H0V0H43.5H44.5L59 14.5ZM44.5 1.41406L57.5859 14.5H44.5V1.41406ZM13 49L22.3711 36.0058L25.5273 40.696L34.708 28.0705L48 49H13ZM25.4951 42.4401L34.6514 29.8478L46.1807 48H14.9541L22.3428 37.7548L25.4951 42.4401ZM22.375 31.75C24.2393 31.75 25.75 30.239 25.75 28.375C25.75 26.511 24.2393 25 22.375 25C20.5107 25 19 26.511 19 28.375C19 30.239 20.5107 31.75 22.375 31.75ZM22.375 32.75C24.791 32.75 26.75 30.7913 26.75 28.375C26.75 25.9587 24.791 24 22.375 24C19.959 24 18 25.9587 18 28.375C18 30.7913 19.959 32.75 22.375 32.75Z"/>
							</svg>
						: 
							<svg width="59" height="74" viewBox="0 0 59 74" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fillRule="evenodd" clipRule="evenodd" d="M43.5 15.5V1H1V73H58V15.5H43.5ZM59 14.5V74H0V0H44.5L59 14.5ZM44.5 1.41421L57.5858 14.5H44.5V1.41421Z" fill="#CECECE"/>
							</svg> 
						}
						


					</div>
					<div className="message">
						<span>{this.props.emptyMessage}</span>
					</div>
				</div>
			

		);

	}


	componentDidUpdate(prevProps, prevState){

		if( this.props.media?.length > prevProps.media?.length ){
			this.scrollToBottom();
		}

	}

	scrollToBottom = () => {
	  this.scrollBottom.scrollIntoView({ behavior: "instant" });
	}

}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		updateUIWindow: actions.updateUIWindow
	}, dispatch);

}

export default connect(
	null,
	mapDispatchToProps
)(MediaComponent);

MediaComponent.defaultProps = {
	media: [],
}

export {MediaComponent}