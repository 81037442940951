import React, { Component } from 'react';
import uniqueId from 'lodash/uniqueId';

import { HotKeyProxy } from "../ui-kit";

import { ProcessingAnimation } from '@cargo/ui-kit';

export const AlertConfirmCheckBox = (props) => {
	const {form, field, groupName, label, labelIcon, labelIconOff } = props;
	// generate a quasi-unique ID to link label and input.
	const id = props.id ? props.id : "checkbox" + '/' + field.name;
	const isChecked = field.value && (field.value === true || field.value[0] === "on" || field.value === "true");
	const disabled = props.disabled ? ' disabled' : '';
	const classNames = props.className ? ' '+props.className : '';
	const processing = props.processing ? true : false;

	return (
		<label 
			className={`checkbox${disabled}${classNames}`} 
			htmlFor={id}
			checked-status={isChecked ? 'true' : 'false'}
			onMouseDown={()=>  { 

				if( !isChecked && props.runConfirmFunction ){

					props.alert.openModal({
						header: props.message,
						type: 'confirm',
						HotKeyProxy: HotKeyProxy,
						onConfirm: () => {

							if( props.confirmFunction ){
								props.confirmFunction();
							}

							form.setFieldValue(field.name, !isChecked);

						},
					});

				} else {

					form.setFieldValue(field.name, !isChecked);

				}

			}}
		>

			{ processing ? (
				<ProcessingAnimation className={`dark`} />
			) : ( null )}
			
			{label ? label : ''}
			<input
				name={field.name}
				checked={isChecked}
				id={id}
				type="checkbox"
				onChange={(e)=>{ 
				
				}}
				onBlur={field.onBlur}
			/>

			<div className="status-icon">

				{isChecked ? 
					<>
					{labelIcon ? labelIcon : 
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path className="circle" d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10Z" fill="var(--baseColor-accent)"/>
							<path fillRule="evenodd" clipRule="evenodd" d="M16.0829 4.95417L9.04259 15.7855L4.88968 11.0393L6.01854 10.0516L8.86564 13.3054L14.8253 4.13669L16.0829 4.95417Z" fill="var(--baseColor-accent-reverse)"/>
						</svg>
					}
					</>
				: 
					<>
					{labelIconOff ? labelIconOff :
						<svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
							<rect width='22' height='22' fill='transparent'/><g opacity='0.12'><path fillRule='evenodd' clipRule='evenodd' d='M20 11C20 15.9706 15.9706 20 11 20C6.02944 20 2 15.9706 2 11C2 6.02944 6.02944 2 11 2C15.9706 2 20 6.02944 20 11ZM21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11ZM10.0428 16.7855L17.0832 5.95417L15.8255 5.13669L9.86585 14.3054L7.01876 11.0516L5.88989 12.0393L10.0428 16.7855Z' fill='black'/></g>
						</svg>
					}
					</>
				}
			</div>
		</label>
	)
	
};