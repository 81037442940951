import { FRONTEND_DATA } from "../../globals";
import { useEffect, useState, useRef } from "react";
import _, { set } from 'lodash';
const CustomFontPreview = ({font, name, onIframeRenderComplete, customIframesLoadingState, colorScheme}) => {

    const [ previewFont, setPreviewFont ] = useState(null);

    useEffect(() => {
        const normalVariants = font.variants ? font.variants.filter((variant) => {
            return variant.style === 'normal';
        }) : [];
    
        const regularNormalVariant = normalVariants.find((variant) => {
            return variant.weight === 400;
        });

        let preferred = regularNormalVariant ? regularNormalVariant : (normalVariants[0] ? normalVariants[0] : font.variants?.[0]);
        if (preferred) {
            setPreviewFont(preferred);
        } else {
            setPreviewFont(font);
        }
    }, [font])

    const iframeRef = useRef(null);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const iframe = iframeRef.current;
        if (!previewFont) {
            return;
        }
        if (!iframe || loaded === true) {

            if( iframe && loaded ){
                if (iframe.contentWindow.document.body.firstElementChild) {
                    iframe.contentWindow.document.body.firstElementChild.style.opacity = 1;
                }

                if(  onIframeRenderComplete ){
                    onIframeRenderComplete( font, name )
                }
                
            }
            return;
        }
        const iframeWindow = iframe.contentWindow;
        //if we have an embed code readily available, use it instead of replicating the entire stylesheet
        if( previewFont?.embed ){
            const style = iframeWindow.document.createElement("style");
            if (previewFont.embed.location.includes('https://')) {
                // Pull the url from the embed location
                const url = previewFont.embed.location.split('[')[1].split(']')[0];
                style.innerHTML = `@import url(${url});`;
            } else {
                style.innerHTML = previewFont.embed.rule;
            }
            iframeWindow.document.head.appendChild(style);
        }

        let textColor = '#000'; //colorScheme === 'dark' ? 'rgba(255,255,255,.95)' : '#000';
        let bgColor = '#fff'; //colorScheme === 'dark' ? '#000' : '#fff';

        iframeWindow.document.body.innerHTML = `
            <div style="text-transform: capitalize; display: flex; align-items: center; white-space: nowrap; width: 100%; height: 100%; background: ${bgColor}; color: ${textColor}; position: absolute; top: 0; left: 0; font-size: 24px; padding-left: 14px; ${(previewFont.css_font_style) ? ' font-style:' + previewFont.css_font_style + ';' : ''}${(previewFont.css_font_weight) ? ' font-weight:' + previewFont.css_font_weight + ';' : ''}${(previewFont.css_font_fvs ? ' font-variation-settings:' + previewFont.css_font_fvs + ';' : '')} font-family: ${previewFont.cssName.replace('\"', '')};">
                ${name}
            </div>
        `;
        
        iframeWindow.document.fonts.ready.then(() => {
            setTimeout(() => {
                setLoaded(true);
            }, 250);
        });
    }, [iframeRef, loaded, previewFont, font, name]);

    return (
        <iframe 
            className="custompreview"
            scrolling="no"
            style={{ opacity: loaded && !customIframesLoadingState ? '100%' : '0%', maxHeight: '44px', overflow: 'hidden', width: '100%', pointerEvents: 'none'}}
            ref={iframeRef}
        />
    );
}

export default CustomFontPreview