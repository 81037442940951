import React from 'react';

import { RadioButton } from "./";

export const RadioCellGroup = (props) => { 

	const { children, label, name, className, ...otherProps } = props;

    return (
        <div className={`radio-cell-group${className ? ' '+className : ''}`}>
	    	<div className="cell-label">{label}</div>
	    	<div className="radio-cells square">
	    		{ children }
	    	</div>
	    </div>
    )
}