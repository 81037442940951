import { FRONTEND_DATA } from "../../../globals";

import { 
	retrieveSequentualNodesEffectivelyContainedByRange,
	rangeCanBeModifiedByTextCommands
} from "../helpers";


export default {

	requiresTextInRange: false,
	requiresUncollapsedRange: false,
	requiresActiveRange: true,

	execute: function(){

		const state = this.getState();

		if(
			state
			// we have no regular unordered list
			&& !state.isApplied
			// but we might have a lineated unordered list
			&& state.hasUnorderedList
		) {
			
			// just remove lineated class
			FRONTEND_DATA.contentWindow.CargoEditor.mutationManager.execute(function(){
				retrieveSequentualNodesEffectivelyContainedByRange('UL').forEach(list => {
					
					list.classList.remove('lineated');

					if(list.classList.length === 0) {
						list.removeAttribute('class');
					}

				})
			});

		} else {

			FRONTEND_DATA.contentWindow.CargoEditor.mutationManager.execute(function(){

				// clean lineated lists
				const range = FRONTEND_DATA.contentWindow.CargoEditor.getActiveRange();

				// clear lineated class from the list
				const listsInRange = range.getNodes([Node.ELEMENT_NODE], (el) => el.nodeName=="OL" || el.nodeName=="UL" );
				const commonAncestorEl = range.commonAncestorContainer.nodeType === Node.TEXT_NODE ? range.commonAncestorContainer.parentNode : range.commonAncestorContainer;

				if(commonAncestorEl.closest('ol, ul')) {
					listsInRange.push(commonAncestorEl.closest('ol, ul'));
				}

				listsInRange.forEach(list => {
					list.classList.remove('lineated');

					if(list.classList.length === 0) {
						list.removeAttribute('class');
					}
				});

				const lineatedDivs = range.getNodes([Node.ELEMENT_NODE], (el) => {
					return el.nodeName === "DIV" && el.classList.contains('lineated');
				});

				if(commonAncestorEl.closest('div.lineated')) {
					lineatedDivs.push(commonAncestorEl.closest('div.lineated'));
				}

				lineatedDivs.forEach(div => {
					FRONTEND_DATA.contentWindow.CargoEditor.helpers.removePreservingDescendants(div, range);
				});

				// insert list
				FRONTEND_DATA.contentWindow.CargoEditor.execCommand('insertunorderedlist');

			});
			

		}

	},

	getState: function(range) {

		let commandState = FRONTEND_DATA.contentWindow.CargoEditor.queryCommandState('insertunorderedlist');

		// check if one of the parents is a ordered list
		if(range && !commandState) {
			const commonAncestorEl = range.commonAncestorContainer.nodeType === Node.TEXT_NODE ? range.commonAncestorContainer.parentNode : range.commonAncestorContainer;

			if(commonAncestorEl.closest('ol')) {
				commandState = true;
			}
		}

		let foundMatch = false;
		let index = 0;

		if( commandState ){
			const nodes = retrieveSequentualNodesEffectivelyContainedByRange('UL');
			while(!foundMatch && nodes[index]){
				let node = nodes[index]
				if(node.nodeType === Node.ELEMENT_NODE && node.nodeName === 'UL') {
					if( !node.classList.contains("lineated") ) {
						foundMatch = true;
					}
				}
				index++;
			}
		}

		return {
			isApplied:  foundMatch,
			hasUnorderedList: commandState,
			isAllowed: rangeCanBeModifiedByTextCommands(range),
		};
	},	
	priority: -1

}