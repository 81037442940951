import { FRONTEND_DATA } from "../../globals";
import { TextStyleDefaults } from "../../defaults/text-style-defaults";
import { subscriptions as textStyleSubscriptions } from "../../lib/shared-css/global-css-subscriptions";

export const getAllTextStyles = () => {
	return [
		...TextStyleDefaults,
		...textStyleSubscriptions.get('text-style-selectors')
	]
}

export const getTextStyleClassNames = () => {
	return getAllTextStyles()
		.filter(style => style.hasOwnProperty('className'))
		.map(style => style.className);
}

export const cleanupNestedSpansOrLinksOrHeadersCreatedDuringMutation = (editor, options = {}) => {

	const range = options.range || CargoEditor.getActiveRange();

	if(!range) {
		return;
	}

	editor.generateUnreportedMutationSummaries().forEach(summary => {

		summary.added.forEach(node => {

			if(node.nodeName === "SPAN" || node.nodeName === "A") {

				// node that is redundant and will be removed
				let nodeToKill = null;
				// the node that'll replace the removed node and inherit it's attributes
				let nodeToRetain = null;

				const parentsContainingSameContent = [];
				const childrenContainingSameContent = [];
				const nodeText = node.textContent;

				let parent = node.parentNode;
				while(parent && !CargoEditor.helpers.isEditingHost(parent)) {
					if(parent.nodeType === Node.ELEMENT_NODE && parent.textContent === nodeText) {
						parentsContainingSameContent.push(parent);
					}
					parent = parent.parentNode;
				}

				let child = node.childElementCount === 1 && node.children[0];
				while(child) {
					if(child.nodeType === Node.ELEMENT_NODE && child.textContent === nodeText) {
						childrenContainingSameContent.push(child);
					}
					child = child.childElementCount === 1 && child.children[0];
				}

				for(const childContainingSameContent of childrenContainingSameContent) {
					if(
						// child is a span or header or both node and child are links
						(node.nodeName === "A" && childContainingSameContent.nodeName === "A")
						|| (
							childContainingSameContent.nodeName === "SPAN" 
							|| (
								options.retainSpansInHeadings !== true 
								&& /^H[1-6]$/.test(childContainingSameContent.nodeName)
							)
						)
					) {

						nodeToKill = node;
						nodeToRetain = childContainingSameContent;

						// Swap nodes when trying to delete a link. We'll absorb the child span
						if(node.nodeName === "A") {
							nodeToKill = childContainingSameContent;
							nodeToRetain = node;
						}

						// only handle first result
						break;

					}
				}

				// if we have not found a suitable match, try looking for a parent node that needs to be replaced.
				if(!nodeToKill || !nodeToRetain) {
					for(const parentContainingSameContent of parentsContainingSameContent) {

						// <a> tags can inherit from <span>. <span> cannot inherit from <a> (because we can't make a span a link)
						// nested <span> tags can inherit from each other
						// <h1> and <h2> can inherit from <span>. <span> cannot inherit from <h1> or
						if(
							// span was created inside of another span or header or both nodes are links
							(node.nodeName === "A" && parentContainingSameContent.nodeName === "A")
							|| (
								parentContainingSameContent.nodeName === "SPAN" 
								|| (
									options.retainSpansInHeadings !== true 
									&& /^H[1-6]$/.test(parentContainingSameContent.nodeName)
								)
							)
						) {

							nodeToKill = node;
							nodeToRetain = parentContainingSameContent;

							// Swap nodes when trying to delete a link. We'll absorb the parent span
							if(node.nodeName === "A") {
								nodeToKill = parentContainingSameContent;
								nodeToRetain = node;
							}

							// only handle first result
							break;

						}
					}
				}

				if(nodeToKill && nodeToRetain) {

					if(/^H[1-6]$/.test(nodeToKill.nodeName)) {
						return;
					}

					// Copy over all attributes except class
					Array.from(nodeToKill.attributes).forEach(function(attr) {

						if(nodeToRetain.nodeName === 'A' && ['href', 'rel'].includes(attr.nodeName)) {
							return;
						}

						if(nodeToRetain.nodeName !== 'A' && ['class'].includes(attr.nodeName)) {
							return;
						}

						nodeToRetain.setAttribute(attr.nodeName, attr.nodeValue);

					});

					// the node that needs removal is a child of the
					// node we're keeping. Just unwrap it
					if(nodeToRetain.contains(nodeToKill)) {

						CargoEditor.helpers.unwrap(
							nodeToKill, 
							true,
							range
						);

					} else {

						// move the nested node to retain after the node to be removed
						CargoEditor.helpers.movePreservingRanges(nodeToRetain, nodeToKill.parentNode, CargoEditor.helpers.getIndexInParent(nodeToKill), range);

						// mark as reparented to prevent link reordering behavior
						// in the editor's implementationFixes plugin
						nodeToRetain.__reparented__ = true;

						// move over content
						while(nodeToKill.childNodes.length > 0) {
							CargoEditor.helpers.movePreservingRanges(nodeToKill.childNodes[0], nodeToRetain, -1, range);
						}

						// finally, kill the node we want to remove
						nodeToKill.parentNode.removeChild(nodeToKill);

					}

				}

			}

		});

	});

	if(!options.range) {
		CargoEditor.helpers.setActiveRange(range);
	}

}

export const wrapRangeWith = (tag, options = {}) => {

	const spans = [];
	const CargoEditor = FRONTEND_DATA.contentWindow.CargoEditor;

	if(!CargoEditor.getActiveRange()) {
		return;
	}

	// check if the first eligible parent is a link. If so, apply the style directly to this link
	if(tag && tag.toLowerCase() === 'span') {

		const rangeToCheck = CargoEditor.helpers.shrinkRangeToNarrowestBoundaries(CargoEditor.getActiveRange().cloneRange());
		const rangeTextContent = rangeToCheck.toString();

		const firstEligibleParent = getNodesInRangeFilteredBySelector('A, SPAN, H1, H2', {
			range: rangeToCheck,
			parentsOnly: true
		})[0];

		// check if the first parent fully wrapping the selection is a link
		if(
			firstEligibleParent?.nodeName === "A"
			&& firstEligibleParent.textContent === rangeTextContent
		) {
			// if so, wrap the range in `<a>` rather than `<span>`
			tag = 'a';
		}

	}

	// shrink range to exclude breaks at the start and end
	const trimmedRange = CargoEditor.helpers.trimBreaksAndWhitespaceFromEdgesOfRange(CargoEditor.getActiveRange());

	if(trimmedRange && !trimmedRange.equals(CargoEditor.getActiveRange())) {
		CargoEditor.helpers.setActiveRange(trimmedRange);
	}

	// for each style host, grab all children and record the text styles we've applied to them
	CargoEditor.mutationManager.execute(function(){

		const editor = CargoEditor.getActiveEditor();

		if(!editor) {
			return;
		}

		// wrap the range in the supplied tag
		CargoEditor.helpers.wrapRangeWith(tag, options);

		// trim range again
		CargoEditor.helpers.setActiveRange(
			CargoEditor.helpers.trimBreaksAndWhitespaceFromEdgesOfRange(CargoEditor.getActiveRange())
		);

		// Kill any new spans that are fully wrapping a header's contents, as we'd just use the
		// header itself instead
		cleanupNestedSpansOrLinksOrHeadersCreatedDuringMutation(editor, options);

		options.before?.();

		const range = CargoEditor.getActiveRange();

		if(!range){
			return
		}

		// grow range to make sure we capture any spans that might be created above the common ancestor of the current range.
		CargoEditor.helpers.growRangeToWidestBoundaries(range, true);

		const nodesToCheck = [];
		const commonAncestorElement = range.commonAncestorContainer.nodeType === Node.TEXT_NODE 
				? range.commonAncestorContainer.parentNode : range.commonAncestorContainer;
		let previousNode;

		// for each summary, gather the modified nodes
		editor.generateUnreportedMutationSummaries().forEach(summary => {
			nodesToCheck.push(...summary.added);
			nodesToCheck.push(...(summary.attributeChanged.style || []));
		});
			
		// grab any spans inside of the range not affected by the command
		CargoEditor.helpers.getAllEffectivelyContainedNodes(range, function(node){
			if(
				// grab spans & existing headings
				(node.nodeName === "SPAN" || node.nodeName === "A" || /^H[1-6]$/.test(node.nodeName))
				&& !nodesToCheck.includes(node)
			) {
				nodesToCheck.push(node)
			}
		});

		// if the common ancestor is a span, add it to the list of nodes to check
		if (nodesToCheck.length === 0 && (commonAncestorElement.nodeName === 'SPAN' || commonAncestorElement.nodeName === 'A' || /^H[1-6]$/.test(commonAncestorElement.nodeName))) {
			nodesToCheck.push(commonAncestorElement);
		}

		// sort nodes by their position in the DOM. Top level first, then deeper nodes. This
		// allows us to skip nested nodes
		nodesToCheck.sort(function(a,b) {
			if( a === b) return 0;
			if( b.compareDocumentPosition(a) & Node.DOCUMENT_POSITION_FOLLOWING) {
				return 1;
			}
			return -1;
		});

		nodesToCheck.forEach((node) => {

			node.setSaveable(true);

			// only handle spans, links and headings
			if(node.nodeName !== "SPAN" && node.nodeName !== "A" && /^H[1-6]$/.test(node.nodeName) === false) return;

			if(!range.containsNode(node, true)) return;

			// that are contained by the range (excluding the range commonAncestorContainer itself)
			if(!commonAncestorElement.contains(node) && node !== commonAncestorElement) return;

			// if this span or link is a child of a previously handled span or heading
			if(
				( node.nodeName === "SPAN" || node.nodeName === "A" )
				&& previousNode 
				&& previousNode.contains(node)
				&& !(options.retainSpansInHeadings && /^H[1-6]$/.test(previousNode.nodeName))
			) {

				// Delete empty nested spans
				if(node.nodeName === "SPAN" && node.attributes.length === 0) {
					CargoEditor.helpers.removePreservingDescendants(node, range);
				}

			} else {

				spans.push(node);
				previousNode = node;

			}

		});

	}, {
		runImplementationFixesPlugin: true
	});

	CargoEditor.mutationManager.execute(function(){
		options.after?.(spans);
	});

	// we can run a cleanup pass here to look at all spans and combine them if they are adjecent and are equal
	// I.E: <span class="xxx">a></span><span class="xxx">b</span> should be merged.

}

export const shouldBeWrappedInSpans = (targets, mediaItems) => {
	const allowedTags = [
		'SPAN',
		'A',
		'MEDIA-ITEM',
		'H1',
		'H2',
	]

	let shouldBeWrappedInSpans = true;

	if (targets.length === 1 && allowedTags.includes(targets[0].tagName)) {
		shouldBeWrappedInSpans = false;
	}

	if (mediaItems) {
		shouldBeWrappedInSpans = false;
	}

	return shouldBeWrappedInSpans;
}

export const getEffectsTargetsContainedByRange = (range) => {

	range = range || FRONTEND_DATA.contentWindow.CargoEditor.getActiveRange();

	if (!range) {
		return [];
	}

	const allowedTags = [
		'SPAN',
		'A',
		'H1',
		'H2',
		'MEDIA-ITEM',
		'AUDIO-PLAYER',
		'SHOP-PRODUCT',
		'TEXT-ICON',
		'DIGITAL-CLOCK'
	]

	// get a list of all spans completely contained by this range
	let targets = FRONTEND_DATA.contentWindow.CargoEditor.helpers.getAllEffectivelyContainedNodes(range);

	targets = targets.filter((node, index) => {
		if (node.nodeType === Node.ELEMENT_NODE && allowedTags.includes(node.nodeName) === false) {
			return false;
		}
		return true;
	});
	targets = targets.reduce((acc, curr, index) => {
		if (CargoEditor.helpers.isWhitespaceNode(curr)) {
			return acc;
		}
		const hasParent = targets.some((node) => node !== curr && node.contains(curr));
		if (hasParent === false) {
			acc.push(curr);
		}
		return acc;
	}, []);

	// if the range is completely contained by a single span, then we only want to apply the effect to that span
	let targetParent = false;

	let parentLastChild = targets.length ? targets[targets.length - 1].parentNode.lastChild : null;
	if (parentLastChild && parentLastChild.tagName === 'BR') {
		parentLastChild = parentLastChild.previousSibling;
	}

	const parentLastChildContent = parentLastChild && parentLastChild.wholeText ? parentLastChild.wholeText.replaceAll(`\n`, '') : '';
	const endContainerContent = range.endContainer?.wholeText ? range.endContainer?.wholeText?.replaceAll(`\n`, '') : '';
	
	// if (
	// 	targets.length > 1 && 
	// 	targets[0].parentNode === targets[targets.length - 1].parentNode &&
	// 	targets[0].parentNode.firstChild === targets[0] &&
	// 	parentLastChildContent === endContainerContent &&
	// 	range.startOffset === 0 &&
	// 	range.endContainer.nodeType === Node.TEXT_NODE &&
	// 	(range.endOffset === range.endContainer.data.length)
	// ) {
	// 	targetParent = true;
	// }

	if (
		range.startContainer === range.endContainer &&
		range.startContainer !== range.commonAncestorContainer &&
		range.startContainer.nodeType === Node.TEXT_NODE &&
		range.startOffset === 0 &&
		range.endOffset === range.endContainer?.wholeText?.length
	) {
		targetParent = true;
	}

	if (
		range.startOffset === 0 &&
		range.endContainer.nodeType === Node.ELEMENT_NODE &&
		range.endOffset === range.endContainer.childNodes.length
	) {
		targetParent = true;
	}

	if (
		range.commonAncestorContainer.nodeType === Node.ELEMENT_NODE &&
		range.commonAncestorContainer.childNodes.length > 1 &&
		range.startContainer.nodeType === Node.TEXT_NODE &&
		range.endContainer.nodeType === Node.TEXT_NODE &&
		range.startOffset === 0 &&
		range.endOffset === range.endContainer?.data?.length
	) {
		targetParent = true;
	}

	if (
		targets.every((node) => node.nodeType === Node.TEXT_NODE) &&
		targets[0]?.parentNode?.textContent?.trim() === targets.map((node) => node?.textContent).join('').trim() &&
		range.endContainer.nodeType === Node.TEXT_NODE &&
		range.endOffset > endContainerContent.length
	) {
		targetParent = true;
	}

	if (
		targets.length === 1 &&
		targets[0].nodeType == Node.TEXT_NODE &&
		targets[0].parentNode?.childNodes.length === 1 &&
		range.startOffset === 0 &&
		range.endOffset === range.endContainer?.wholeText?.length
	) {
		targetParent = true;
	}

	if (
		range.commonAncestorContainer.nodeType === Node.ELEMENT_NODE &&
		range.commonAncestorContainer.childNodes.length > 1 &&
		range.startOffset === 0 &&
		(
			range.endOffset === range.endContainer?.wholeText?.length || 
			(range.endOffset == range.endContainer?.wholeText?.length - 1 && range.endContainer?.wholeText?.endsWith(' ')) ||
			range.endOffset === range.endContainer?.wholeText?.trim()?.length
		)
	) {
		targetParent = true;
	}

	if ( targets.length === 1 && targets[0].tagName === "MEDIA-ITEM" ) {
		targetParent = false;
	}

	if (
		targets.length === 1 && targets[0].parentNode.childNodes.length === 1 &&
		targets.length === 1 && targets[0].tagName === 'SHOP-PRODUCT' ||		
		targets.length === 1 && targets[0].tagName === 'AUDIO-PLAYER' ||
		targets.length === 1 && targets[0].tagName === 'TEXT-ICON' ||
		targets.length === 1 && targets[0].tagName === 'DIGITAL-CLOCK'
	) {
		targetParent = true;
	}

	let parent = targets.length && targets[0].parentNode === targets[targets.length-1].parentNode ? targets[0].parentNode : range.commonAncestorContainer;

	if (
		range.commonAncestorContainer === range.startContainer &&
		range.commonAncestorContainer === range.endContainer &&
		range.startOffset === 0 &&
		range.commonAncestorContainer.nodeType === Node.TEXT_NODE &&
		range.commonAncestorContainer?.wholeText?.trim()?.length === range.endOffset
	) {
		targetParent = true;
	}

	while (parent) {
		
		if (targetParent && allowedTags.includes(parent.tagName)) {
			targets = [parent];
			if(
				parent.hasAttribute('uses') || 
				parent.getAttribute('style')?.includes('border') ||
				parent.getAttribute('style')?.includes('background') ||
				parent.getAttribute('style')?.includes('drop-shadow')
			) {
				// don't look further if it has a uses attribute
				break;
			}
		}
		
		parent = parent.parentNode;

	}

	const disallowedParentTags = [
		'COLUMN-UNIT',
		'BODYCOPY',
	];

	if (targets.length === 1 && disallowedParentTags.includes(targets[0].tagName)) {
		targets = Array.from(targets[0].childNodes);
	}

	if (targets && targets.length) {
		if (
			CargoEditor.helpers.isWhitespaceNode(targets[targets.length - 1]) &&
			targets[targets.length - 1].tagName !== "MEDIA-ITEM" &&
			targets[targets.length - 1].tagName !== "AUDIO-PLAYER" &&
			targets[targets.length - 1].tagName !== "SHOP-PRODUCT" &&
			targets[targets.length - 1].tagName !== "TEXT-ICON" &&
			targets[targets.length - 1].tagName !== "DIGITAL-CLOCK" &&
			targets[targets.length - 1].tagName !== "SPAN"
		) {
			targets.pop();
		}
		if (targets[targets.length - 1]?.tagName === 'BR') {
			targets.pop();
		}
	}

	return targets;
}

export const getNodesInRangeFilteredBySelector = (selector, options = {}) => {

	let range = options.range || FRONTEND_DATA.contentWindow.CargoEditor.getActiveRange();

	if(!range) {
		return [];
	}

	// clone the range so we don't mutate a live range
	range = range.cloneRange();

	let matches = [];

	if(options.parentsOnly !== true) { 

		if(options.includePartiallyContainedNodes) {

			// grow range to make sure we capture any spans that might be created above the common ancestor of the current range.
			FRONTEND_DATA.contentWindow.CargoEditor.helpers.growRangeToWidestBoundaries(range, true);			

			// we probably want something in here to filter nodes that are contained by the range, but have no text selected
			// for example the last node in the range that has a startoffset of 0, or the first node that has a start offset of it's own length
			
			matches = range.getNodes(false, function(node){
				return node.matches?.(selector);
			});

		} else {
			// get a list of all spans completely contained by this range
			matches = FRONTEND_DATA.contentWindow.CargoEditor.helpers.getAllEffectivelyContainedNodes(range, function(node){
				return node.matches?.(selector);
			});
		}

	} else {

		// only get parents
		options.includeParents = true;

		// Shrink range so we don't trip over breaks at the start/end of the selection
		FRONTEND_DATA.contentWindow.CargoEditor.helpers.trimBreaksAndWhitespaceFromEdgesOfRange(range);

		// shrink range to the smallest size so we can capture all parents, even if we've slightly over-selected it like:
		// |<span>text</span>| -> <span>|text|</span> so we capture the span as a parent
		FRONTEND_DATA.contentWindow.CargoEditor.helpers.shrinkRangeToNarrowestBoundaries(range);


	}

	let parent = range.commonAncestorContainer;

	// get a list of all spans wrapping the current range
	// let parent = range.commonAncestorContainer;
	while(options.includeParents && parent) {
		if(parent.matches?.(selector)) {
			matches.push(parent)
		}
		parent = parent.parentNode;
	}

	return matches;

}


export const removeParentsWithGivenNodeName = (node, nodeName, range) => {

	const parents = getAllParents(node);

	if(typeof nodeName === "string"){

		parents.forEach(node => {
			if(node.nodeName === nodeName) {
				FRONTEND_DATA.contentWindow.CargoEditor.helpers.removePreservingDescendants(node, range);
			}
		});

	} else if(typeof nodeName === "function"){

		const filter = nodeName;

		parents.forEach(node => {
			if(filter(node)) {
				FRONTEND_DATA.contentWindow.CargoEditor.helpers.removePreservingDescendants(node, range);
			}
		});

	}
	

}

export const getAllParents = (node) => {
	
	const res = [];

	// loop up till we reach the end of the doc or end of the current editing container
	while(node && node.contentEditable !== "true" ) {

		res.push(node);

		node = node.parentNode;
	}

	return res;
}


// test for h1, h2, etc
export const rangeCanBeModifiedByTextCommands =(range)=>{

	if(range === undefined) {
		range = FRONTEND_DATA.contentWindow.CargoEditor.getActiveRange();

		if(range == undefined) {
			return false;
		}
	}

	const iconsAndClocks = range.getNodes([Node.ELEMENT_NODE], (node)=>node.tagName=== 'TEXT-ICON' || node.tagName === 'DIGITAL-CLOCK' );
	
	// range is not collapsed and contains text
	return !range.collapsed && ( range.toString() != '' || iconsAndClocks.length > 0)

}

export const rangeCanBeAligned =(range)=>{

	if(range === undefined) {
		range = FRONTEND_DATA.contentWindow.CargoEditor.getActiveRange();

		if(range == undefined) {
			return false;
		}
	}

	// range is not collapsed
	return !range.collapsed

}

export const retrieveSequentualNodesEffectivelyContainedByRange = (nodeName, range) => {

	if(range === undefined) {
		range = FRONTEND_DATA.contentWindow.CargoEditor.getActiveRange();

		if(range == undefined) {
			return [];
		}
	}

	if(nodeName === undefined) {
		return [];
	}

	if(range !== undefined) {

		var container = range.commonAncestorContainer;

		while(container !== null && container.contentEditable !== "true") {

			if(container.nodeType === Node.ELEMENT_NODE && container.matches(nodeName)) {

				var res = [container];

				// find children too
				range.getNodes([Node.ELEMENT_NODE], function(node) {
					if(node.matches(nodeName)) {
						res.push(node);
					}
				});

				return res;
			}

			container = container.parentNode;
		}

		if(range.collapsed) {
			return [];
		}

		var parent,
			nodesMatchingNodesName = [],
			hasNodeNameInRange = false,
			textNodesOutsideNodeName = range.getNodes([Node.TEXT_NODE], function(node) {

				if( FRONTEND_DATA.contentWindow.CargoEditor.helpers.isEditable(node) || FRONTEND_DATA.contentWindow.CargoEditor.helpers.isWhitespaceNode(node) ) {
					if( node.parentNode.nodeName !== 'SHOP' ){
						return false;
					}
				}

				parent = node.parentNode;

				while(parent !== null && parent !== range.commonAncestorContainer){

					if(parent.matches(nodeName)) {
						if(nodesMatchingNodesName.indexOf(parent) === -1) {
							nodesMatchingNodesName.push(parent);
						}
						hasNodeNameInRange = true;
						return false;
					}

					parent = parent.parentNode;
				}

				return true;
			});

		if(textNodesOutsideNodeName.length === 0 && hasNodeNameInRange) {
			return nodesMatchingNodesName;
		}

	}

	return [];
}
