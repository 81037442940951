import React, { Component, useEffect, useState } from 'react';

import { MessageContext } from "@cargo/ui-kit";

export const RemoteMessage = (props) => {

	const [ popNotice, setPopNotice ] = useState(false);
	const [ messageText, setMessageText ] = useState(null);
	const [ duration, setDuration ] = useState(null);

	useEffect(() => {

		function showNotice(e){
			setMessageText( e.detail.message );
			if( e.detail.duration ){
				setDuration( e.detail.duration )
			}
		}

		document.addEventListener('open-remote-message', showNotice)
	  return () => {
	  	// cleanup
	  	document.removeEventListener('open-remote-message', showNotice);
	  };
	}, []);


	useEffect(() => {
		// If we've set a message string 
		if( messageText !== null ){
			// Trigger modal to be visible by rendering the "open modal" function.
			setPopNotice( true );
			return
		}
		// Else reset the modal by returning null. This lets it be called and recalled as needed.
		setPopNotice( false )
	}, [messageText])


	return popNotice ? (
		<MessageContext.Consumer>
			{(Message) => {

				setTimeout(() => {
					Message.showMessage({
						messageText: messageText,
						preventClickout: true
					});
				});

				{ duration ? (
					setTimeout(() => {
						Message.hideMessage();
						setPopNotice( false );
						setDuration( null );
						setMessageText( null );
					}, duration)
				) : null }

			}}
		</MessageContext.Consumer>
	) : ( null )
	
};