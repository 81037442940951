import React, { Component } from 'react';

import axios from 'axios';
import _, { has } from 'lodash';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from "../../actions";

import { FontSection, FontVariant, HotKey } from "./";
import { Button, LoadingAnimation } from '@cargo/ui-kit';
import RandomIcon from "@cargo/common/icons/randomize.svg";
import { FRONTEND_DATA } from "../../globals";
import { removeDiacritics, deriveFontModelFromCSS, deriveVariantFromCSS, deriveVariantFromModel, createFVSPropertyMap, generateFontChangeObj } from "@cargo/common/font-helpers.js";
import { getCustomFonts, getFontSpriteSrc } from "./helpers";
import loadFonts from '@cargo/font-loader';
import { globalUndoManager } from "../../lib/undo-redo";
import fontCollection from '../../reducers/font-collection';
import {preloadImage} from './typography-menu-ui';

let fontAnimationStartEvent = new Event('font-animation-start');

const loadAndCacheSprite = () => {
	return new Promise(resolve => {

		// Manually set flag when testing new font sprite.	
		let src = getFontSpriteSrc()

		if( preloadImage.src != src ){
			preloadImage.src = src;
		}

		preloadImage.decode().then(result=>{
			resolve( preloadImage.naturalHeight / 2 );
		}).catch(e => {
			console.error(e);
		});

	});
	
}

class FontPickerComponent extends Component {
	
	constructor(props) {
		super(props);

		this.state = {

			customGroupHoverMap	: new Map(),
			activeCustomFonts	: [],

			pickerRef           : null,

			offsetY             : null,
			transitions         : [],
			titlesToAnimate     : [],

			collection          : null,  // font data after initial processing
			collectionForRender : null,  // font data ready for render

			activeFamily        : null,
			activeVariant       : null,
			activeWeight        : null,
			activeStyle         : null,
			activeIsStaffPick   : null,
			isSetup      	 	: true,
			isLoading     	    : true,

			renderCustomFontIframes    : false,
			customIframesLoadingState  : true, 
		}

		// 372px 22140px
		// this.backgroundSize = '372px '+this.props.fontSpriteHeight+'px';
		this.backgroundSize = '390px '+this.props.fontSpriteHeight+'px';

		this.sprite_url = getFontSpriteSrc();

		// Lets us know how much to offset the PNG when rendering.
		this.pngCount = -1;

		// Ref variables
		this.container = null; // font container 
		this.refMap    = null; // map of all font headers

		// transition variables
		this.completedTransitions = [];
		this.isAnimating = false;

		// Font search variables
		this.flattenedList = null;
		this.letterArray   = [];
		this.modKey = null;

		this.customFontsToRender = [];

		// For scrolling height diff
		this.lastClosedVariantLength = 0;

		this.artificialFamily = {
			// 'Bureau Grot Ultra' 	: 'Bureau Grot',
			// 'Fort Extrabold'		: 'Fort',
			'Infini GF Ligatures'	: 'Infini GF'
		}

		this.uniquePNG = {
			'Infini GF Ligatures' : 'infini-ligatures',
			'Monument Grotesk Ultra': 'monument-grotesk-ultra',
			'Chaumont Script': 'chaumont-script'
		}

		this.initialSetupRun = false;

		this.initialFontData = {
			family     : this.props.family,
			variant    : this.props.variant,
			dataVariant: this.props.dataVariant
		}

	}

	componentDidMount = () => {

		if( this.props.fontSpriteHeight ){
			this.setupPicker();
		} else {

			loadAndCacheSprite().then((height) => {

				this.backgroundSize = '372px '+height+'px';
				this.setupPicker();

			});

		}
		
		this.container.focus();

		window.addEventListener("keydown", this.handleKeyDown);
		FRONTEND_DATA.contentWindow.addEventListener("keydown", this.handleKeyDown);

		globalUndoManager.on('yjs-stackitem-start', this.onYJSUndoStackItemStarted);
		globalUndoManager.on('yjs-stackitem-finalized', this.onYJSUndoStackItemFinalized);
		globalUndoManager.on('yjs-stackitem-applied', this.onYJSUndoStackItemApplied);

	}

	preloadChunks = () => {

		const chunks = [
			import('../right-menu-bar/custom-font-wizard')
		]

	}

	componentDidUpdate(prevProps, prevState){
		
		if( this.props.hasFontCollection && prevProps.hasFontCollection !== this.props.hasFontCollection ){
			const {
				family,
				variant,
			} = this.props.getCurrentFontSettings();

			this.initialFontData = {
				dataVariant      : variant ?? null,
				family           : family ?? null,
				variant          : variant?.style ?? null,
			}

			this.setupPicker( true /* determine scroll inside setup */ );
		}

		// after font collection updates, setup the picker to update the list with the new font
	    if( this.props.hasFontCollection && this.state.collection && prevProps.fontCollection !== this.props.fontCollection ){

			if( _.isEmpty( this.props.fontCollection.collection ) ){ return }
			
			// Find custom fonts that were added to the collection
			const prevFonts = prevProps.fontCollection.collection;
			const newFonts = this.props.fontCollection.collection;

			const addedFonts = newFonts.filter(newFont => !prevFonts.some(prevFont => prevFont.family === newFont.family));
			
			this.setupPicker( true /* dont scroll */ );

			if( addedFonts.length > 0 ){
				const select = addedFonts[0];
				// this.toggleFamilyNoAnimation(select.family, 'custom', 'open');
				// this.selectFont( select.name, select.family, null, 'custom' );

				setTimeout(() => { 

					this.props.removeUIWindow(uiWindow => {
						return uiWindow.id === "custom-font-wizard";
					});

				}, 800);

			}
	    }

	    // then, after the collection is updated, pick the new font that got added
	    if( this.props.hasFontCollection && this.state.collection && this.state.collection !== prevState.collection && this.state.waitingToPick ){

			const fontInsideCollection = this.state.collection.find(fontFamily=>fontFamily.cssName === this.state.waitingToPick.fontFamily.family_name);

			if( fontInsideCollection ){

				const variant = fontInsideCollection.variants.find(variant=> this.state.waitingToPick.variant.css_font_weight === variant.css_font_weight );
				this.toggleFamilyNoAnimation(fontInsideCollection.family, fontInsideCollection.provider, 'open');
				this.selectFont( variant.name, variant.family, null, fontInsideCollection.provider, fontInsideCollection );

			}

			this.setState({
				waitingToPick: null
			})
	    }

		if( !this.state.isSetup && prevState.isSetup !== this.state.isSetup ){
			requestAnimationFrame(() => {
				this.setState({
					renderCustomFontIframes: true
				})
			})
		}

	}

	componentWillUnmount() {

		window.removeEventListener("keydown", this.handleKeyDown);
		FRONTEND_DATA.contentWindow.removeEventListener("keydown", this.handleKeyDown);

		globalUndoManager.off('yjs-stackitem-start', this.onYJSUndoStackItemStarted);
		globalUndoManager.off('yjs-stackitem-finalized', this.onYJSUndoStackItemFinalized);
		globalUndoManager.off('yjs-stackitem-applied', this.onYJSUndoStackItemApplied);


	}

	onYJSUndoStackItemStarted = (stackItem) => {

		if(!stackItem.meta.has(this)) {
			stackItem.meta.set(this, {})
		}

		if (stackItem.meta.get(this)['undo-font'] === undefined) {
			stackItem.meta.get(this)['undo-font'] = _.pick(this.state, ['activeVariant', 'activeWeight', 'activeFamily', 'activeVariation', 'activeIsStaffPick']);
		}

	}

	onYJSUndoStackItemFinalized = (stackItem) => {
		
		if(!stackItem.meta.has(this)) {
			stackItem.meta.set(this, {})
		}

		if (stackItem.meta.get(this)['redo-font'] === undefined) {
			stackItem.meta.get(this)['redo-font'] = _.pick(this.state, ['activeVariant', 'activeWeight', 'activeFamily', 'activeVariation', 'activeIsStaffPick']);
		}

	}

	onYJSUndoStackItemApplied = (stackItem, actionType) => {

		const oldFont = actionType === 'undo' ? stackItem.meta.get(this)['undo-font'] : stackItem.meta.get(this)['redo-font'];

		if(oldFont) {
			this.setState({
				...oldFont
			}, () => {

				let fontModel = deriveFontModelFromCSS(this.state.collection, oldFont?.activeFamily);
				let variantArray = _.get( fontModel, 'variants' );
				let index = this.getVariantIndex(oldFont?.activeVariant, variantArray, oldFont?.activeWeight);
				index = index ? index : 0;

				this.scrollToFont(null, oldFont.activeFamily, index, fontModel);
			});
		}

	}

	setupPicker = ( notInitialSetup ) => {

		let collection = [...this.props.fontCollection.collection];

		if( this.state.collectionForRender ){
			let lastCollection = { ...this.state.collectionForRender };
		}
		let collectionForRender = { ...this.props.fontCollection.collectionForRender };
		let retainCustomFontLoader = false;
		let dontScroll = notInitialSetup ? true : false;

		// This object reconcillation step preserves the open/close state of the font sections.
		// between custom font prompted render cycles.
		if( this.state.collectionForRender && notInitialSetup ){

			let lastCollection = { ...this.state.collectionForRender };

			for (const key in lastCollection) {
				if (lastCollection.hasOwnProperty(key) && collectionForRender.hasOwnProperty(key)) {
					collectionForRender[key] = collectionForRender[key].map(newFamilyData => {
						const matchingFamily = lastCollection[key].find(oldFamilyData => oldFamilyData.family === newFamilyData.family);
						if (matchingFamily) {
							return {
								...newFamilyData,
								isOpen: matchingFamily.isOpen
							};
						}
						return newFamilyData;
					});
				}
			}
		}

	
		if( !this.initialSetupRun && !this.props.hasFontCollection ){
			return
		} else if( !this.initialSetupRun && this.props.hasFontCollection ) {
			this.initialSetupRun = true;
			dontScroll = false;
		}
		
		// Reset custom fonts array.
		this.customFontsToRender = [];

		let customFontToSelect = null;

		this.setState({
			collection, // font data after initial processing
			collectionForRender // font data ready for render
		}, () => {


			this.refMap = {}
			let refName = null;
			// Create a an array of refs -- bind to a promise, this can take a moment.
			let refSetup = new Promise((resolve, reject) => {
				collection.forEach((font, index, arr)=> {
					if( font.is_active ){

						refName = removeDiacritics( font.family );
						
						// Ref for regular families in list.
						this.refMap[refName] = React.createRef();
						// Create refs for staff picks
						let isStaffPick = false;
						// Check if ANY variant is marked
						_.each( font.variants, (variant) => {
							if( variant.staff_pick == 1 ){
								isStaffPick = true;
							}
						})

						// If it is, add a ref to assign later.
						if( isStaffPick ){
							let staffPickName = font.family+'_staffpick'
							this.refMap[staffPickName] = React.createRef();
						}

						if( font.provider === 'custom' ){
							this.customFontsToRender.push( font.family )
						}

					}

					if (index === arr.length -1) resolve();
				})
			});

			// Create list of all font variants for type to search.
			this.flattenedList = this.flattenFonts( collectionForRender );

			// Check if we have any custom fonts, and if the loading state is already in place.
			retainCustomFontLoader = this.flattenedList.find((family)=> family.provider === 'custom') ? true : false;
			if( !this.state.customIframesLoadingState ){
				retainCustomFontLoader = false
			}
			
			// When we're done with ref settup...
			refSetup.then(() => {

				let family  = this.initialFontData.family;
				let variant = this.initialFontData.variant;
				let name    = this.initialFontData.dataVariant?.['name'];

				if( customFontToSelect){
					family  = customFontToSelect.family;
					variant = customFontToSelect.variants[0];
					name    = variant.name;
				}

				let normalizedFamilyName = removeDiacritics(family)
				let familyData = deriveFontModelFromCSS(collection, normalizedFamilyName);
				// let familyData = _.find(this.props.fontCollection.collection, {'family': family} )
				let title = _.get(familyData, 'serif') ?? 'custom';

				title = this.getSectionFromSerif( title );
				// find and scroll to our currently active font
				// if we don't have one, nothing should happen here.
				

				this.setState({ 
					isSetup: false,
					customIframesLoadingState: retainCustomFontLoader,
				 }, () => {

					if( !dontScroll ){
						this.selectFont( name, family, null, title, familyData, true, true );
					}

				})

			});

		})

		// preload 1 second after the picker has been setup 
		setTimeout(() => {
			this.preloadChunks();
		}, 1000);

	}

	selectFont = ( name, family, weight, section, model, scrollTo, silent) => {
		
		if( family == '-apple-system' ){
			family = 'Sans-Serif System Default'
		}
		const fontFamily  = removeDiacritics( family );
		const fontName    = name ?? family;
		const fontSection = section;

		let fontModel   = model;
		let isStaffPick = section === 'staffpicks';
		let fonts       = [ ...this.state.collection ];

		if( !fontModel ){
			fontModel = deriveFontModelFromCSS(fonts, fontFamily);
		}

		if( !fontModel ){
			console.log("No font model data found", name, fonts, fontFamily )
			if( this.state.isLoading ){ this.setState({ isLoading : false }) }
			return
		}
		// Check if we're trying to select a different font / variant OR if we're trying to select the same font variant
		// but it's a staff pick.
		if( fontName !== this.state.activeVariant || 
			  ( fontName === this.state.activeVariant &&
				  ( !isStaffPick && this.state.activeIsStaffPick || isStaffPick && !this.state.activeIsStaffPick )
			  )
		){

			let variantArray = _.get( fontModel, 'variants' );
			let index = this.getVariantIndex(fontName, variantArray, weight);

			let activeVariant   = variantArray[index] ? variantArray[index].name   : null;
			let activeWeight    = variantArray[index] ? variantArray[index].weight : null;
			let activeVariation = variantArray[index] ? variantArray[index]?.writeable_fvs : null;

			this.setState({
				// activeVariant     : fontName,
				activeVariant     : activeVariant,
				activeWeight	  : activeWeight,
				activeFamily	  : fontFamily,
				activeVariation   : activeVariation ? activeVariation : null,
				activeIsStaffPick : isStaffPick
			}, () => {
				if( scrollTo == true ){

					// Account for combo families
					_.each( Object.keys(this.artificialFamily), ( name ) => {
						if( name === family ){
							family = this.artificialFamily[family];
						}
					})

					this.toggleFamilyNoAnimation(family, fontSection, 'open');

					setTimeout(() => {
						// this.scrollToFont(null, fontModel.family, index);
						this.scrollToFont(null, family, index, fontModel);
					},1)
				}
			});

			if(silent === true) {
				// we don't want to generate any changes, just scroll to the active font.
				// Whe running initial setup we don't want to modify the CRDT when opening
				// the picker for the first time.
				return;
			}
			
			if( !fontModel ){
				console.log("No font model data found", name, fontFamily, fonts)
				return false
			}

			let fontModelVariants = _.get(fontModel, 'variants');
			let provider          = _.get(fontModel, 'provider');

			if( provider !== 'system' ){
				let loadedFontFamilies = [];

				FRONTEND_DATA.contentWindow.document.fonts.forEach((font) => { 
					if( loadedFontFamilies.indexOf( font.family ) === -1 ){
						loadedFontFamilies.push( font.family )
					}
				 });

				let loaded = loadedFontFamilies.indexOf( fontFamily ) !== -1;

				if( loaded ){
					this.generateAndSetNewFontFamilyString(variantArray, index);
				} else {
					// don't add new CSS string to the CSS until the font has loaded on the frontend
					this.loadFont(fontFamily, fontModelVariants, provider, ()=>{
						this.generateAndSetNewFontFamilyString(variantArray, index);
					})
				}

			} else {
				// system font are on the machine, don't need to wait for them to load
				this.generateAndSetNewFontFamilyString(variantArray, index);
			}

		}

	}

	generateAndSetNewFontFamilyString = (variantArray, index) => {
		
		let changeObj = generateFontChangeObj( variantArray, index, this.props.selector);
		if(typeof this.props.onFontSelect === "function") {
			this.props.onFontSelect( changeObj )
		}

	}

	loadFont = (family, fontModelVariants, provider, callback) => {

		let fontModelJoinedVariantString = provider !== 'cargo' ? family+':' : family;
		let preloaded = null;
		let difference = 0;

		_.each(fontModelVariants, (variant, index)=>{
			var comma = index === 0 ? '' : ',';

			if( provider === 'google' ){
				let fvdString = _.get(variant, 'google_variant') + "," +  _.get(variant, 'google_variant')+'italic'
				fontModelJoinedVariantString += comma + fvdString;
				// Alegreya:400,400italic,700,700italic
			}
			
		});

		// Check to see if we've already loaded this font.
		if ( !this.props.fontsLoaded.includes( family ) ) {

			this.props.updateFrontendState({
				fontsLoaded: [...this.props.fontsLoaded, family]
			});

			//load the font on the frontend
			loadFonts({
				context: FRONTEND_DATA.contentWindow,
				active: callback,
				families: [{
					provider,
					family: fontModelJoinedVariantString
				}]
			});
		} else {
			callback();
		}

	}

	toggleFamilyNoAnimation = ( family, section, action, scrollTo ) => {

		// Clone collection, we never modify state directly.
		let collection = _.cloneDeep( this.state.collectionForRender );
		let scrollToLocation = null;
		let scrollToEl       = null;
		let offset           = null;

		if( !collection?.[section] ){ return }
		// Iterate through each font in the section...
		_.each(collection[section], (font, index) => {

			if( action == 'close' ){
				this.lastClosedVariantLength = collection[section][index].variants.length;
			}

			if( font.family == family || family.trim() == font.match || family.replace('-', ' ') === font.family || family === font.cssName || font.variants.some((variant) => variant.cssName === family)) {
			
				if( collection[section][index].variants.length > 1 ) {

					if( !action ){
						collection[section][index].isOpen = !font.isOpen
					}
					if( action == 'open' ){
						collection[section][index].isOpen = true;
					}
					if( action == 'close' ){
						collection[section][index].isOpen = false;
					}

					this.setState({
						collectionForRender : collection,
					}, () => {
						// This is all for arrow navigation to function properly ( keep your "place" in the list )
						// Must happen after we re-render the newly opened family.
						if ( scrollTo == 'scrollTo' ){
							scrollToEl       = this.container.querySelector('.active');
							// 132;
							scrollToLocation = scrollToEl.parentElement.parentElement?.offsetTop + scrollToEl.offsetTop - 152;
							this.container.parentElement.scrollTop = scrollToLocation;
						} 
					})
				}
			}


		})

	}

	toggleFamily = (event, family, section) => {
		// Clone collection, we never modify state directly.
		let collection = _.cloneDeep( this.state.collectionForRender );

		// Iterate through each font in the section...
		_.each(collection[section], (font, index) => {

			if( font.family == family && collection[section][index].variants.length > 1 ){
				
				this.toggleMenuAnimation(event, collection, font, section, index);

			}
		})
	}

	handleKeyDown = (e) => {

		// if the focus is in a textarea or input somewhere, let it proceed naturally
		if( e.target.closest('textarea, input')){
			return
		}

		if( e.metaKey ){
			return
		}

		let charCode = e.keyCode;
		let isLetter = this.lettersOnly( charCode );
		let letter = null;
		let searchableFonts = this.flattenedList;

		if(charCode == 32){
			this.letterArray.push(" ");
		}

		// set flag for cmd
		if( e.metaKey || e.ctrlKey ){
			this.modKey = true;
		}
		// Set / close on esc
		// if( charCode == 27 || charCode == 13 ){
		// 	e.preventDefault()
		// 	this.props.removeUIWindow(uiWindow => {
		// 		return uiWindow.id === 'font-picker';
		// 	});
		// }

		if( charCode == 38 ){
			e.preventDefault();
			this.jumpToNextFont('up');
		}

		if( charCode == 40 ){
			e.preventDefault();
			this.jumpToNextFont('down');
		}

		if(isLetter){
			// Start array managment of typed letters
			this.manageTimeout();
			// Only accept letters
			letter = String.fromCharCode(charCode);
			// Push letters into array
			this.letterArray.push(letter);
			// Match most like font and scroll
			this.firstFont(searchableFonts, this.letterArray);
		}
	}

	jumpToNextFont = _.throttle(( direction ) => {
		// Arrow key navigation...
		let activeRef = this.refMap[ this.state.activeFamily ]?.current;
		let inStaffPick = this.container.querySelector('.active')?.classList.contains('staffpickfont');
		let activePos = null;
		let familyObj = null;
		let isComboFont = false;

		if( !activeRef ){
			let tempFamily = this.state.activeFamily?.replace(/^'|'$/g, '');
			activeRef      = this.refMap[ tempFamily ]?.current;
		}

		if( !activeRef ){
			// Check if we're dealing with a combo font
			_.each( Object.keys(this.artificialFamily), ( name ) => {
				if( name === this.state.activeFamily ){
					// Reassign the active ref
					activeRef = this.refMap[ this.artificialFamily[name] ]?.current;
				}
			})
		}

		// If we don't have an active family or an active ref, start from the top.
		if( !this.state.activeFamily || !activeRef  ){
		
			let first = this.props.fontCollection.collectionForRender['staffpicks'][0];
			let variant = first.variants[0];

			this.toggleFamilyNoAnimation(variant.family, 'staffpicks', 'open');
			this.selectFont( variant.name, variant.family, null, 'staffpicks', first );

			this.container.parentElement.scrollTop = 0;

			return
		}
		
		let currentSection = null;

		// Get font data from collection
		let normalizedFamilyName = removeDiacritics( this.state.activeFamily )
		familyObj = deriveFontModelFromCSS(this.props.fontCollection.collection, normalizedFamilyName);

		// Determine font section
		if( inStaffPick ){

			currentSection = 'staffpicks'
			activeRef = this.refMap[ this.state.activeFamily+'_staffpick' ]?.current;


			// Once again check for combo fonts...
			if( !activeRef ){
				// Check if we're dealing with a combo font
				_.each( Object.keys(this.artificialFamily), ( name ) => {
					if( name === this.state.activeFamily ){
						// Reassign the active ref
						activeRef = this.refMap[ this.artificialFamily[name]+'_staffpick' ]?.current;
					}
				})
			}
			
		} else {

			currentSection = familyObj.serif;

		}

		let activeLi      = null,
			family        = null,
			variant       = null,
			nextIsVariant = null,
			nextIsSingle  = null,
			nextFamily    = null,
			nextName      = null,
			nextTitle     = null,
			nextLi        = null,
			prevFamily    = this.state.activeFamily,
			prevTitle     = currentSection == 'staffpicks' ? currentSection : this.getSectionFromSerif( familyObj.serif ),
			isSingle      = activeRef?.classList.contains('single-font'),
			isVariant     = activeRef?.classList.contains('variant') && !activeRef.classList.contains('single-font');

		if( !activeRef ){
			return 
		}

		if( !isSingle ){

			activeLi = activeRef?.querySelector('.active');

			if( direction == 'up' ){
				nextLi = activeLi.previousElementSibling;
			}

			if( direction == 'down' ){
				nextLi = activeLi.nextElementSibling;
			}

			if ( nextLi == null ){

				if( direction == 'up' ){
					nextLi = activeRef.previousElementSibling;
				}

				if( direction == 'down' ){
					nextLi = activeRef.nextElementSibling;
				}
			
			}

		} else {

			activeLi = activeRef;

			if( direction == 'up' ){
				nextLi = activeLi.previousElementSibling;
			}

			if( direction == 'down' ){
				nextLi = activeLi.nextElementSibling;
			}

		}

		// If we hit a category title ( Serif, Sans Serif, Monospace ) that needs to be traversed.
		if( nextLi.classList.contains('category-title') ){

			nextTitle = nextLi.getAttribute('section');

			if( nextTitle == 'staffpicks' && direction == 'up' ){
				// We should loop to the bottom or do nothing when hitting the top of the list.
				return 
			}

			if( direction == 'up' && nextTitle !== null ){
				let sectionsArray = ['staffpicks', 'sansserifs', 'serifs', 'monospaced', 'custom', 'end']; //sections in "visual" order
				let tempTitle = this.getSectionFromSerif( nextTitle );
				let index = sectionsArray.indexOf( tempTitle );

				nextTitle = sectionsArray[ index - 1 ];
			}

			if( nextTitle == 'end' && direction == 'down' ){
				// We should loop to the top or do nothing when hitting the bottom of the list.
				return 
			}

			if( direction == 'up' && nextTitle !== null ){
				nextLi = nextLi?.previousElementSibling;
			}

			if( direction == 'down' && nextTitle !== null ){
				nextLi = nextLi?.nextElementSibling;
			}

		}

		// If nextLi is a font section...
		if( nextLi.classList.contains('font-section') ){
			if( direction == 'up' ){
				nextLi = nextLi.querySelector('.variant-container').lastElementChild;
			}

			if( direction == 'down' ){
				nextLi = nextLi.querySelector('.variant-container').firstElementChild
			}
		}

		nextTitle     = nextTitle === null ? prevTitle : nextTitle;
		nextTitle     = this.getSectionFromSerif( nextTitle ); //get name in collection from data
		nextIsVariant = nextLi.classList.contains('variant');
		nextIsSingle  = nextLi.classList.contains('single-font');

		nextName   = nextLi.dataset.name;
		nextFamily = nextLi.dataset.family;

		let nextWeight = parseInt( nextLi.style.fontWeight );

		if( prevFamily !== nextFamily ){
			this.toggleFamilyNoAnimation(prevFamily, prevTitle, 'close', 'scrollTo');
		}

		// Passing variant name, family, weight, section title (sans serif / serif / staff ), no model, no scrolling behavior
		this.selectFont( nextName, nextFamily, nextWeight, nextTitle, null, false );

		if( isSingle ){
			// selected element + last closed variant container * 44
			// is subtracted from the overall position in the list with a buffer of 132px left at the top.
			this.container.parentElement.scrollTop = ( nextLi?.offsetTop + this.lastClosedVariantLength * 44 ) - this.container.parentElement.offsetTop - 132;
		}

		// Determine name before we toggle and scroll, but AFTER we select the font. 
		_.each( Object.keys(this.artificialFamily), ( name ) => {
			if( name === nextFamily ){
				nextFamily = this.artificialFamily[name];
			}
		})
		// Opens closed family, and scrolls to correct position. If family is already open, this just handles scrolling.
		this.toggleFamilyNoAnimation(nextFamily, nextTitle, 'open', 'scrollTo');

	}, 250)

	//Manages array of letters used when typing to search for a font.
	manageTimeout = () => {

		if (this.letterArray.length >= 8 ){
			this.letterArray = [];
			clearTimeout( this.letterTimer );
			this.inProgress = false;
		}
		
		if (this.inProgress){
			//If there is a timer in progress, clear it, and set a new one.
			clearTimeout( this.letterTimer );
			this.letterTimer = setTimeout(_.bind(() => { this.letterArray = []; this.inProgress = false; },this), 600);
			this.inProgress = true;
		} else {
			//No timer? Create a timer.
			this.letterTimer = setTimeout(_.bind(() => { this.letterArray = []; this.inProgress = false; },this), 600);
			this.inProgress = true;
		}

	}

	//Activates scroll behavior on successful type to search match
	firstFont = (fontGroup, letters) => {

		let length    = letters.length;  
		let userInput = letters.join('');

		//Returns first font found in list...
		let font = _.find(fontGroup, function(font){

			let str = font.family;

			if( str.includes('Adobe ') ){
				str = str.replace('Adobe ', '');
			}

			let matchThis = str.substring(0, length);
			
			var matchFirstLetter = matchThis.toUpperCase();

			if ( matchFirstLetter == userInput ){
				return font 
			}

			if ( matchThis.toUpperCase() == userInput ){
				return font 
			}
		});

		if( font ){
			let family = _.get( font, 'family' );
			let section = this.getSectionFromSerif( _.get( font, 'serif' ) );

			//Scrolling to the font...
			this.scrollToFont('search', family, 1);
		}

	}

	// Used in filtering for only letters when typing to search.
	lettersOnly = (charCode) => {
		if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || charCode == 8){
			return true;
		} else {
			return false;
		}
	}

	scrollToFont = ( action, family, index, fontModel ) => {

		let scrollToEl  = null;
		let parentEl    = null;
		let offset      = null;
		let indexOffset = 44 * index;// adjust by depth of variant in list 
		// scroll to active font
		if( action == 'active' ){
			scrollToEl = this.refMap[ this.state.activeFamily ];
		} else if( family ){
			scrollToEl = this.refMap[ family ];

			if( !scrollToEl ){
				// Remove single quotes from name before we search in ref map...
				family = family?.replace(/^'|'$/g, '');
				scrollToEl = this.refMap[ family ];
			}

			if( !scrollToEl ){
				// Replace dashes with spaces before we search in ref map...
				family = family?.replace(/-/g, ' ');
				scrollToEl = this.refMap[ family ];
			}

		}

		if( !scrollToEl && fontModel ){
			scrollToEl = this.refMap[ fontModel.family ];
		}

		if( action == 'search' && scrollToEl ){
			this.psudoActive( scrollToEl.current );
		} 

		if( scrollToEl && scrollToEl.current ){
			offset = scrollToEl.current.offsetTop;
			// offset = offset - 152;
			offset = offset - 90;
			offset = offset + indexOffset;
			this.container.parentElement.scrollTop = offset;
		}

		// setTimeout(()=>{
			if( this.state.isLoading ){ this.setState({ isLoading : false }) }
		// }, 900)
		
	}

	psudoActive = (el) => {

		if( !el.classList.contains('active') || !el.classList.contains('psudoActive') ){

			let currentActive = this.container.querySelector('.psudoActive');

			if( currentActive ) {
				currentActive.classList.remove('psudoActive');
			}

			if(  el.querySelector('.transition-cover') ){
				el = el.querySelector('.transition-cover');
			}

			el.classList.add('psudoActive');
			// setTimeout(function(){
				// el.removeClass('psudoActive');
			// }, 1800);
		}
	}

	scrollToTitle = ( location ) => {

		if( !this.state.collectionForRender || this.state.isSetup ){
			return
		}

		let header = this.container.querySelector('[section="'+location+'"]');
		let offset = header.offsetTop;

		offset = location !== 'staffpicks' ? offset : 0;

		this.container.parentElement.scrollTop = offset;
	}

	scrollToRandom = () => {

		if( !this.state.collectionForRender || this.state.isSetup ){
			return
		}

		let searchableFonts = this.state.collection; // use unorganized collection.

		let searchableVariants = [];
		for (const font of searchableFonts) {
			for (const variant of font.variants) {
				if (variant.css_font_style !== 'italic' && variant.css_font_style !== 'oblique') {
					searchableVariants.push(variant);
				}
			}
		}

		let min = 0;
		let max = searchableVariants.length - 1;

		let randomIndex = Math.floor(Math.random() * (max - min + 1)) + min;
		let selectedVariant = searchableVariants[randomIndex];

		let name    = _.get( selectedVariant, 'name' )
		let family  = _.get( selectedVariant, 'family' )
		let section = this.getSectionFromSerif( _.get( selectedVariant, 'serif' ) );

		if (selectedVariant.source === 'custom') {
			family = selectedVariant.cssName;
			section = 'custom';
		}

		if( section !== 'custom' && !this.refMap[family]?.current ){
			// If the ref map for this family fails, try again.
			this.scrollToRandom()
			return
		}
		// Select and scroll to family -->
		// name, family, section, scrollTo
		this.selectFont( name, family, null, section, null, true );

	}

	// Flat list of fonts and variants for type to search.
	flattenFonts = (sections) => {

		let sectionsArray = sections 
		let flatArray = []

		_.each(sectionsArray, function(family){
			_.each(family, function(variant){
				// TODO: Either do this to all fonts, or check for special characters if
				// we add more than one "special" case.
				if( variant.family == "Sélavy" ){
					var variant = variant
					let normalizedName = removeDiacritics( variant.family );
					variant.family = normalizedName
				}
				flatArray.push( variant )
			})

		})

		return flatArray
	}

	addCustom = (e)=>{

		e.preventDefault();
		const buttonPos = this.container.closest('.uiWindow').getBoundingClientRect();

		this.props.addUIWindow({
			group: 'right-menu-bar',
			component: import('../right-menu-bar/custom-font-wizard'),
			id: `custom-font-wizard`,
			props: {
				type: 'popover', 
				positionType: 'from-button', 
				// borderRadius: options.borderRadius, 
				// autoHeight: options.autoHeight, 
	            buttonPos: {
	                top: buttonPos.y+20,
	                y: buttonPos.y+20,
	                x: buttonPos.x-135,
	                left: buttonPos.left-135,
	                height: 0,
	                width: 0,
	                bottom: buttonPos.y+20,
	            }, 
	            mediaType: 'files',
				// tabbed: options.tabbed,
				windowName: 'custom-font-wizard',
				clickoutLayer: true,
				//preventClickout: true,
				onWillUnmount: this.resetCustomFonts,
				closeOnAllClickout: false,
				flow: 'new',

				// preventEsc: options.preventEsc,
				acceptDrops: false,
				draggingUploadedImage: false,
				selectFont: this.selectFont,
			}
		},{
			removeGroup: false
		});		

	}

	showIntercomArticle = (e) =>{
		e.preventDefault();
		window.Intercom('showArticle', 1)
	}

	// Below is RENDER logic --> 
	parseSectionTitle = (section, collection) => {
		switch( section ){
			case 'staffpicks':
				return 'Staff Picks'
			case 'sansserifs':
				return 'Sans'
			case 'serifs':
				return 'Serif'
			case 'monospaced':
				return 'Mono'
			case 'custom':
				return <>Custom  <span className="custom-add" onMouseDown={this.addCustom}>Add Fonts<span className="custom-add-button">+</span></span>
							{ !this.state.customIframesLoadingState && collection && collection?.length == 0 ? ( <div className="no-fonts-message">Upload or embed fonts from other foundries or font services. <a href="#" onClick={(e)=> { e.preventDefault(); Intercom('showArticle', 8486845); }} className="intercom-link">Learn more</a><span> ↗</span></div> ) : null}
					</>
			default:
				return null
		}
	}

	getSectionFromSerif = ( serif ) => {
		switch (serif) {
			case 'serif':
				return 'serifs'
			case 'sans-serif':
				return 'sansserifs'
			case 'sansserif' :
				return 'sansserifs'
			case 'monospace':  
				return 'monospaced'
			case 'custom':
				return 'custom'
			default: 
				return serif
			}
	}
	
	getVariantIndex = (variantName, variantArray, weight) => {
		
		let variantIndex = null;
		let tempMatch    = null;
		let trimName     = null;

		if( !variantName || !variantArray  ){ 
			console.log('No variant name. Check CSS for errors', variantName, variantArray);
			return null
		}

		// Normalize name for strange or uncommon characters not allowed in the DB
		let normalizedName = removeDiacritics( variantName );

		for (var i=0; i < variantArray.length; i++) {
			// If current iteration variant name === passed variant name
			// and we're not looking at a FVS font where all the weights could match.
			if (variantArray[i].name === variantName && !variantArray[0].css_font_fvs ) {
				if( weight ){
					if( variantArray[i].weight == weight ){
						variantIndex = i;
					}
				} else {
					variantIndex = i;
				}
			}
		}

		// Cycle through variants and check if names match after we do some string sanatization
		if( !variantIndex && variantIndex !== 0 ){

			for (var i=0; i < variantArray.length; i++) {
				let tempMatch = removeDiacritics(  variantArray[i].name );

				if ( tempMatch === normalizedName ) {
					variantIndex = i;
				}
			}
		}

		// Account for system fonts where name is mismatched from what is written in CSS
		// Sans Serif System Default == -apple-system
		if( !variantIndex && variantIndex !== 0 ){
			for (var i=0; i < variantArray.length; i++) {
				// Split for name written in CSS. E.g. -apple-system 
				tempMatch = _.first( variantArray[i].cssName.split(',') )?.trim();
				trimName = normalizedName.trim()

				if ( tempMatch === trimName ) {
					variantIndex = i;
				}
			}
		}

		if( variantIndex === null ){
			console.log("Could not find", variantName, "with weight",  weight, "in family model.", variantArray)
		}

		return variantIndex
	}

	// List wrapper, renders Sections ( serif / sans serif / monospace ) containing
	// single list item or font container with all their variants
	FontList = (collection, renderCustomFontIframes) => Object.keys(collection).map((section, index) => {

		if( this.state.offsetY !== null && this.state.titlesToAnimate.indexOf( section ) !== -1  ){

			let titleStyles = {
				transform: `translateY(${this.state.offsetY}px)`,
				transition : 'transform 180ms cubic-bezier(.85,0,.19,1)',
				zIndex: 3
			}

			return (
				<React.Fragment key={section}>
					<div section={section} style={titleStyles} className="category-title">
						{ this.parseSectionTitle( section, collection[section] ) }
					</div>
					{ this.FontSection( collection[section], section, renderCustomFontIframes ) }
				</React.Fragment>
			)

		} else {
			let customFontClasses = ''
			if( section === 'custom' && this.state.customIframesLoadingState ){
				customFontClasses = ' loading-custom-fonts'
			}
			if( section === 'custom' && !this.state.customIframesLoadingState && collection['custom'].length == 0 ){
				customFontClasses = ' custom-fonts-empty-message'
			}
			// Render main list
			return (
				<React.Fragment key={section}>
					<div section={section} className={`category-title${customFontClasses}`}>
						{ this.parseSectionTitle( section, collection[section] ) }
					</div>
					{ this.FontSection( collection[section], section, renderCustomFontIframes ) }
				</React.Fragment>
			)
		}

	})

	// Renders Font Header & Container with variants OR single font.
	FontSection = ( section, title, renderCustomFontIframes ) => section.map((font, index) => {
		let variantCount = font.variants.length;
		let lastFamily = false;

		// Grab correct ref from map
		let staffPickRef = null;
		if( title == 'staffpicks' ){
			let staffPickName = font.family+'_staffpick';
			staffPickRef = this.refMap[ staffPickName ]
		}

		if( font.comboFamily !== undefined ){
			_.each(font.comboFamily, (family) => {
				let comboVariants = family.variants.length
				variantCount += comboVariants
			})
		}

		let group = font.family;

		this.pngCount = this.pngCount + 1

		if( index === section.length -1 ){
			lastFamily = true;
		}

		let isLinkedHover = false;
		if(
			font.embeddedFontReference &&
			this.state.customGroupHoverMap &&
			this.state.customGroupHoverMap.has(font.embeddedFontReference) &&
			this.state.customGroupHoverMap.get(font.embeddedFontReference).length > 0

		){
			isLinkedHover = true;
		}


		let onPointerEnter, onPointerLeave;
		if( font.embed){
			onPointerEnter = (e)=>{this.onCustomHover(font.cssName, true, font.embed)}
			onPointerLeave = (e)=>{this.onCustomHover(font.cssName, false, font.embed)}
		}

		if( variantCount > 1 ) {
			return (
				<FontSection 
					font         = { font }
					index        = { index }
					title        = { title }
					count        = { variantCount }
					key          = { font.family }
					location     = { this.pngCount }
					url          = { this.sprite_url }
					toggleFamily = { (e) => this.toggleFamily( e, font.family, title ) }
					bgSize		 = { this.backgroundSize }
					forwardedRef = { title == 'staffpicks' ? staffPickRef : this.refMap[font.family] }
					lastFamily   = { lastFamily }
					pseudoActive = { false }

					renderCustomFontIframes={renderCustomFontIframes}
					onIframeRenderComplete={this.handleIframeRenderComplete}
					customIframesLoadingState={this.state.customIframesLoadingState}
					colorScheme = {this.props.colorScheme}
					
					onPointerEnter= {onPointerEnter}
					onPointerLeave= {onPointerLeave}
					linkedHover={isLinkedHover}
					onWillUnmount = {this.resetCustomFonts}
				>
					{ this.Variants( font.variants, font, group, false, title, renderCustomFontIframes ) }
					{ font.comboFamily !== undefined ?
						( this.Variants( font.comboFamily[0].variants, font.comboFamily, group, true, title ) )
						: ( null )
					}
				</FontSection>
			)
		} else {

			let isCustomSprite = false;
			let url = this.sprite_url;
			_.each( Object.keys(this.uniquePNG), (name) => {
				if( font.family == name ){ 
					url = PUBLIC_URL + '/images/'+this.uniquePNG[name]+'.png';
					isCustomSprite = true;
				}
			})
			// Render variant
			return (
				<FontVariant 
					selectFont        = { () => this.selectFont( font.variants[0].name, font.family, null, title ) }
					selectedFont      = { this.state.activeVariant }
					activeIsStaffPick = { this.state.activeIsStaffPick }
					font              = { font } 
					index             = { index } 
					title             = { title } 
					count             = { variantCount } 
					key               = { font.family } 
					location          = { this.pngCount } 
					url               = { url }
					bgSize            = { this.backgroundSize }
					forwardedRef      = { title == 'staffpicks' ? staffPickRef : this.refMap[font.family] }
					pseudoActive      = { false }
					isCustomSprite    = { isCustomSprite }
					isSingle          = { true }

					renderCustomFontIframes={renderCustomFontIframes}
					onIframeRenderComplete={this.handleIframeRenderComplete}
					customIframesLoadingState={this.state.customIframesLoadingState}

					onPointerEnter= {onPointerEnter}
					onPointerLeave= {onPointerLeave}
					linkedHover       = {isLinkedHover}
					onWillUnmount = {this.resetCustomFonts}
					colorScheme = {this.props.colorScheme}
				/>
			)
		}

	})

	resetCustomFonts = ()=>{
		this.setState({
			activeCustomFonts: []
		});
	}

	// a single embed code can contain multiple fonts
	// editing a single embed code can modify many groups of fonts
	// indicate this through a shared hover state??
	onCustomHover = (fontName, hoverIn, customHTMLFontGroup)=>{

		return;


		this.setState((prevState)=>{

			const customGroupHoverMap = prevState.customGroupHoverMap;

			if( hoverIn){

				if( customGroupHoverMap.has(customHTMLFontGroup) ){

					const hoveredOverFonts = customGroupHoverMap.get(customHTMLFontGroup);
					if( hoveredOverFonts.indexOf(fontName) == -1 ){
						hoveredOverFonts.push(fontName)
					}
					customGroupHoverMap.set(customHTMLFontGroup, hoveredOverFonts);	
				} else {
					customGroupHoverMap.set(customHTMLFontGroup, [fontName]);	
				}
				
			} else {

				if( customGroupHoverMap.has(customHTMLFontGroup) ){
					const hoveredOverFonts = customGroupHoverMap.get(customHTMLFontGroup);
					hoveredOverFonts.splice( hoveredOverFonts.indexOf(fontName), 1 );

					customGroupHoverMap.set(customHTMLFontGroup, hoveredOverFonts);	
				}

				
			}

			return {
				customGroupHoverMap: customGroupHoverMap
			}
		});
	}

	// Returns each LI within a font container
	Variants = ( variants, parentFont, group, isCombo, title, renderCustomFontIframes ) => variants.map((variant, index) => {
		// TODO: Revise combo family behavior to accomidate more than the first family in array.
			// This is skips the Family title for combo families.
			// i.e. for Fort Extrabold Regular we want to skip over the Fort Extrabold title on the PNG 
		if( isCombo && variants.length > 1 && index == 0 ){
			this.pngCount = this.pngCount + 1
		}

		let selectFamily = parentFont.family;

		if( isCombo ){
			selectFamily = variant.family;
		}

		this.pngCount = this.pngCount + 1;

		let url = this.sprite_url;
		let isCustomSprite = false;

		_.each( Object.keys(this.uniquePNG), (name) => {
			if( variant.name == name ){ 
				url = PUBLIC_URL + '/images/'+this.uniquePNG[name]+'.png';
				isCustomSprite = true;
			}
		})
		// Render Variant
		return(
			<FontVariant
				selectFont        = { () => this.selectFont( variant.name, selectFamily, variant.weight, title ) }
				selectedFont      = { this.state.activeVariant }
				activeIsStaffPick = { this.state.activeIsStaffPick }
				font              = { variant }
				family            = { parentFont }
				index             = { index }
				title             = { title } 
				key               = { variant.name+index }
				location          = { this.pngCount }
				url               = { url }
				bgSize            = { this.backgroundSize }
				isCustomSprite    = { isCustomSprite }
				isSingle          = { false }

				renderCustomFontIframes = { renderCustomFontIframes }
				onIframeRenderComplete={this.handleIframeRenderComplete}
				customIframesLoadingState={this.state.customIframesLoadingState}
			/>
		)
	})

	// this.state.collectionForRender
	render() {

		this.pngCount = -1;

		return (
			<div 
				className="font-picker"
				ref={ el => this.container = el }
			>
						<div className="title">
							<div className="categories">
								<Button label="Staff Picks" 
									onMouseDown={ ()=> this.scrollToTitle('staffpicks') }
								/>
								<Button label="Sans"
									onMouseDown={ ()=> this.scrollToTitle('sansserifs') }
								/>
								<Button label="Serif"
									onMouseDown={ ()=> this.scrollToTitle('serifs') }
								/>
								<Button label="Mono"
									onMouseDown={ ()=> this.scrollToTitle('monospaced') }
								/>
								<Button label="Custom"
									onMouseDown={(e)=> { this.scrollToTitle('custom') }}
								/>
								<Button 
									onMouseDown={ ()=> this.scrollToRandom() }
									className="randomize-icon" 
									label={ <RandomIcon />} 
								/>
							</div>
						</div>
					{ this.state.collectionForRender && !this.state.isSetup ? (
						<>
						<div 
							// onClick={ (e) => this.handleClick(e) } 
							className="font-collection clusterize-scroll"
						>
							<ul>
								<div className="spacer"></div>
								{ this.FontList( this.state.collectionForRender, this.state.renderCustomFontIframes ) }
								<div section="end" className="category-title"></div>
							</ul>
						</div>

						<HotKey shortcut="enter" boundTo="all" callback={() => {
							// Prevents enter in alert from closing window.
								if( this.props.globalEventsPaused ){ return }

								this.props.removeUIWindow(uiWindow => {
									return uiWindow.id === 'font-picker';
								});
						}} />
						{/* || !this.state.renderCustomFontIframes */}
						{ this.state.isLoading || !this.props.fontsLoaded ? ( 
							<div className="loading-cover"></div>
						) : (null)}

					</>
				) : ( null )}

				{/* || !this.state.renderCustomFontIframes */}
				{ this.state.isLoading || !this.props.fontsLoaded ? ( 
					// loader-padding
					<div className="loader-padding-center revealed">
						<LoadingAnimation className="light" type="linear"  height="76px" width="76px" />
					</div>
				) : (null)}
				
			</div> 
		);

	}

	// An array of family names is made when we map over custom fonts for render
	// As we render the iframe for the family title, remove it from the array
	// When the list is empty or down to its last item, remove the loader.
	handleIframeRenderComplete = ( font, name )=>{
		const familyName = font.family_name;

		if( familyName && this.customFontsToRender.indexOf(familyName) !== -1 ){
			this.customFontsToRender = _.without(this.customFontsToRender, familyName);
		}
		
		if( this.customFontsToRender.length === 1 || this.customFontsToRender.length === 0 
				&& this.state.customIframesLoadingState === true
		){
			this.setState({
				customIframesLoadingState: false
			});
		}
	}

	toggleMenuAnimation = ( event, collection, font, section, indexOfClicked ) => {

		let _this = this

		if( this.isAnimating == true ){
			return
		}

		_this.isAnimating = true;

		let el = this.refMap[ font.family ]?.current;

		if( section == 'staffpicks'){
			let staffPickName = font.family+'_staffpick'
			el = this.refMap[ staffPickName ]?.current;
		}

		if( !collection[section][indexOfClicked].isOpen ){
			//It takes too apply the transition opacity, so do it right away.
			el.children[0]?.children[0]?.classList.add('open-opacity')
		}

		// content = el.querySelector(':scope > div')
		// summary = el.querySelector(':scope > .family-head')

		let familyContainer = el, // font family outer container.
		content = el.children[1], //variant container
		summary = el.children[0], //family head
		contentPNG = familyContainer.children[0].children[0], // targets the PNG
		homeSectionLength = null, // length of section font we're opening is in.
		openElCount = 0, 
		closeElCount = 0, 
		indexDepth = 15, // how many following fonts do we animate from this font section 
		overflow = 0, // how many folloing fonts in the next SECTION do we animate
		sectionIndex = null, // current index of section
		nextSection = null, // current index of next section, for animating section titles.
		sectionsArray = ['staffpicks', 'sansserifs', 'serifs', 'monospaced', 'custom', 'end'], //sections in "visual" order
		elHeight = familyContainer.outerHeight;

		homeSectionLength = collection[section].length - 1;
		// Get next section
		sectionIndex = sectionsArray.indexOf(section);
		nextSection = sectionsArray[sectionIndex+1];
		// if we have to calculate overflow into the next section...
		if( indexOfClicked + indexDepth > homeSectionLength && familyContainer !== 'custom' ){
			overflow = indexOfClicked + indexDepth - homeSectionLength; // determine fonts in next section
			indexDepth = indexDepth - overflow; //adjust index lookup depth of current section
		}

		this.nextAll = [];
		this.sectionHeaders = [];

		let oneAfterInitialIndex = indexOfClicked+1;

		for (let i = 0; i < indexDepth; i++) {
			let nextFamily = collection[section][oneAfterInitialIndex+i];
			let lookupName =  nextFamily.family;
			if( section == 'staffpicks' ){
				lookupName = nextFamily.family + "_staffpick";
			}

			this.nextAll.push( this.refMap[ lookupName ]?.current );
		}

		if( indexDepth < 15 && overflow > 0 && section !== 'custom' ){

			for (let i = 0; i < overflow; i++) {
				let nextFamily = collection[nextSection]?.[i]
				if ( nextFamily ) {
					this.nextAll.push( this.refMap[ nextFamily.family ]?.current );
				}
			}

		} 

		// Always animate all following sections...
		let followingSections = sectionsArray.slice(sectionIndex+1);
		_.each(followingSections, (sectionTitle) => {
			let sectionToAnimate = this.container.querySelector('[section="'+sectionTitle+'"]');
			if( sectionToAnimate ){
				this.sectionHeaders.push( sectionToAnimate )
			}
		})

		openElCount = this.nextAll.length;
		closeElCount = this.nextAll.length;

		if( !collection[section][indexOfClicked].isOpen ) {

			let styles = getComputedStyle(content),
				verticalPadding = parseInt(styles.paddingTop) + parseInt(styles.paddingBottom);

			familyContainer.setAttribute('open', '');
			familyContainer.open = true;

			let lineHeight = summary.getBoundingClientRect().height
			let tempHeight = content.childElementCount * lineHeight

			this.container.querySelector('[section="end"]').style.height = tempHeight+'px';

			content.addEventListener('transitionend', openTransitionComplete);
			content.addEventListener('transitioncancel', openTransitionComplete);

			// double requestAnimationFrame = smoother animation
			requestAnimationFrame(function(){
				content.dispatchEvent(fontAnimationStartEvent);
				requestAnimationFrame(function(){

					familyContainer.classList.add('visible');
					content.style.position = 'absolute';

					contentPNG.classList.remove('open-opacity')

					let transformHeight = content.scrollHeight + verticalPadding;
					let inverseHeight = -Math.abs( transformHeight )

					content.style.transform    = 'translateY('+inverseHeight+'px)';
					content.style.transition   = 'transform 180ms cubic-bezier(.85,0,.19,1)';
					content.style.transform    = 'translateY(0px)';

					for(let i = 0; i < _this.sectionHeaders.length; i++ ){
						let tempEl = _this.sectionHeaders[i]
						tempEl.style.transition = 'transform 180ms cubic-bezier(.85,0,.19,1)';
						tempEl.style.transform  = 'translateY('+transformHeight+'px)';
					}

					for(let i = 0; i < openElCount; i++ ){
						if( _this.nextAll[i] ){
							let tempEl = _this.nextAll[i]
							tempEl.style.transition = 'transform 180ms cubic-bezier(.85,0,.19,1)';
							tempEl.style.transform  = 'translateY('+transformHeight+'px)';
						}
					}

				});
			});

		} else {

			if(event) {
				event.preventDefault();
			}

			familyContainer.classList.add('closing');

			content.addEventListener('transitionend', closeTransitionComplete);
			content.addEventListener('transitioncancel', closeTransitionComplete);

			let transformHeight = content.scrollHeight;
			let inverseHeight = -Math.abs( transformHeight );

			let lineHeight = summary.getBoundingClientRect().height
			let tempHeight = content.childElementCount * lineHeight

			this.container.querySelector('[section="end"]').style.height = tempHeight+'px';

			this.contentHeader = summary;
		
			requestAnimationFrame(function(){
				requestAnimationFrame(function(){

					content.style.transform = 'translateY(0px)';
					content.style.transition = 'transform 180ms cubic-bezier(.85,0,.19,1)';
					content.style.transform = 'translateY(0px)';

					_this.contentHeader.style.backgroundColor = 'var(ui-list-block-active)';
					_this.contentHeader.style.transition = 'background-color 180ms cubic-bezier(.85,0,.19,1)';
					_this.contentHeader.style.backgroundColor = 'var(--ui-background-default)';

					contentPNG.style.opacity = '0.13';
					contentPNG.style.transition = 'opacity 180ms cubic-bezier(.85,0,.19,1)';
					contentPNG.style.opacity = '1'

					for(let i = 0; i < _this.sectionHeaders.length; i++ ){
						let tempEl = _this.sectionHeaders[i]
						if( tempEl.dataset.section == 'end' ){
							tempEl.style.height = transformHeight+'px';
						} 
						tempEl.style.transform = 'translateY(0px)';
						tempEl.style.transition = 'transform 180ms cubic-bezier(.85,0,.19,1)';
						tempEl.style.transform = 'translateY('+inverseHeight+'px)';
					}

					for(let i = 0; i < closeElCount; i++ ){
						if( _this.nextAll[i] ){
							let tempEl = _this.nextAll[i]
							if( tempEl !== undefined ){
								tempEl.style.transform = 'translateY(0px)';
								tempEl.style.transition = 'transform 180ms cubic-bezier(.85,0,.19,1)';
								tempEl.style.transform = 'translateY('+inverseHeight+'px)';
							}
						}
					}

			
				});
			});

		}

		function openTransitionComplete(e){

			content.removeEventListener('transitionend', openTransitionComplete);
			content.removeEventListener('transitioncancel', openTransitionComplete);

			content.style.cssText = ''

			for(let i = 0; i < _this.sectionHeaders.length; i++ ){
				let tempEl = _this.sectionHeaders[i];
				tempEl.style.cssText = '';
			}

			for(let i = 0; i < openElCount; i++ ){
				if( _this.nextAll[i] ){
					let tempEl = _this.nextAll[i];
					tempEl.style.cssText = '';
				}
			}

			// set state and cleanup
			collection[section][indexOfClicked].isOpen = !font.isOpen

			_this.setState({
				collectionForRender      : collection,
			}, () => {
				_this.isAnimating = false;
			})

		}

		function closeTransitionComplete(e){

			content.removeEventListener('transitionend', closeTransitionComplete);
			content.removeEventListener('transitioncancel', closeTransitionComplete);

			familyContainer.classList.remove('visible');
			// contentPNG = $('.pngpreview', familyContainer)

			contentPNG.style.removeProperty('transition');
			contentPNG.style.removeProperty('opacity');
			
			content.style.cssText = '';

			familyContainer.classList.remove('closing');
			familyContainer.removeAttribute('open')

			_this.contentHeader.style.cssText = ''

			for(let i = 0; i < _this.sectionHeaders.length; i++ ){
				let tempEl = _this.sectionHeaders[i]
				tempEl.style.cssText = ''
			}

			for(let i = 0; i < closeElCount; i++ ){
				if( _this.nextAll[i] ){
					let tempEl = _this.nextAll[i]
					if( tempEl !== undefined ){
						tempEl.style.cssText = ''
					}
				}
			}

			// set state and cleanup
			collection[section][indexOfClicked].isOpen = !font.isOpen

			_this.setState({
				collectionForRender : collection,
			}, () => {
				_this.isAnimating = false;
			})
			// _this.calcContainerHeights() // in use with scroll groups that we dont have

		}

	}

}

function mapReduxStateToProps(state, ownProps) {

	return {
		fontCollection: state.fontCollection,
		fontsLoaded: state.frontendState.fontsLoaded,
		hasFontCollection: state.fontCollection.hasFontCollection,
		globalEventsPaused: state.adminState.pauseGlobalEventExecution,
		colorScheme: state.adminState.darkMode ? 'dark' : 'light'
	};

}

function mapDispatchToProps(dispatch) {
	
	return bindActionCreators({
	    addFontsToFontCollection:actions.addFontsToFontCollection,		
		addUIWindow: actions.addUIWindow,		
		removeUIWindow: actions.removeUIWindow,
		updateFrontendState: actions.updateFrontendState
	}, dispatch);

}

export default connect(
	mapReduxStateToProps, 
	mapDispatchToProps
)(FontPickerComponent);
