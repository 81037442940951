import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from "@cargo/ui-kit";
import { actions } from '../../actions';
import UploadIcon from "../../svg-icons/upload-icon.svg";
import { uploadMedia, deleteMedia, convertFileToImage } from '../../lib/media';
import { getSupportedFileTypes } from './helpers';

const UploadButton = ({
	onDrop,
    onUpload,
    pageId,
	addUIWindow,
	onFileUpload,
	allowedExtensions,
	suppressFileWindow,
	onHash
}) => {

    const inputRef = useRef(null);

    const uploadImage = (files) => {

    	
    	if( allowedExtensions && allowedExtensions.length > 0 ){
			files = files.filter(file=> {
				const ext = file.name.toLowerCase().split('.').pop();
				return allowedExtensions.indexOf( ext ) > -1;
			});
    	}

		files.forEach(async (file, i) => {

            const withinMediaSize = file.size /1048576 < 25;

			if (
				getSupportedFileTypes('image').includes(file.type) ||
				( getSupportedFileTypes('video').includes(file.type) && withinMediaSize )
			) {
				file.onHash = hash => {
					const mediaItem = document.createElement('media-item');					
					// convert the file to an actual Image so we can read dimensions
					// grab the dimensions immediately on conversion
					mediaItem.setAttribute('hash', hash);			

				}
				uploadMedia({
					target: getCRDTItem({ reducer: 'pages.byId', item: pageId }),
					field: 'media'
				}, [file])[0]
				.then(uploadedImage => {
					const mediaItem = document.createElement('media-item');					
					// convert the file to an actual Image so we can read dimensions
					// grab the dimensions immediately on conversion
					mediaItem.setAttribute('hash', uploadedImage.model.hash);
					if( onDrop){
						onDrop(mediaItem);
					}		
					onUpload(mediaItem);
				})
				.catch(err => {

				});
			} else {

				if( !suppressFileWindow ){

					addUIWindow({
						group: 'right-menu-bar',
						component: import('../right-menu-bar/library-window'),
						id: `library-window`,
						props: {
							type: 'popover',
							uiWindowType: 'popover',
							borderRadius: 'radius-all',
							// clickoutLayer: true,
							windowName: 'library',
							className: 'files',
							closeOnSingleClickout: true,
							invokeTarget: null,
							invokeWindow: 'media-window',
							closeButton: false,
							positionType: 'from-button',
							avoidTransform: true,
							waitForHeightBeforeRender: true,
							minimumRenderHeight: 50,
							buttonOffset: {
								top: 10,
								right: -5
							},
							acceptDrops: true,
							buttonPos: document.querySelector('button.files-button')?.getBoundingClientRect() ?? null,
							mediaType: 'files'
						}
					},{
						removeGroup: false
					});	
				}

				setTimeout(()=>{
					file.onHash = hash => {
						if( onHash ){
							onHash(hash, file);
						}
					}
					uploadMedia({
						target: getCRDTItem({ reducer: 'media' }),
						field: 'data'
					}, [file])[0]
						.then(result => {	
							
							if( onFileUpload ){
								onFileUpload(result);
							}
					})	                	
				}, 250)

			}
		})
	}

    return (
        <>
            <input 
                type='file' 
                multiple 
                onChange={e => uploadImage(Array.from(inputRef?.current?.files))} 
                hidden 
                id="file" 
                ref={inputRef}
            />
            <Button 
                className="upload-button" 
                label={<><UploadIcon />&nbsp; Upload</>}
                onMouseDown={e => inputRef.current?.click()}
            />
        </>
    )
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        addUIWindow: actions.addUIWindow,
        updateUIWindow: actions.updateUIWindow,
        removeUIWindow: actions.removeUIWindow,
    }, dispatch);

}

function mapReduxStateToProps(state, ownProps) {
	return {
		pageId: ownProps.pageId ? ownProps.pageId : state.frontendState.PIDBeingEdited,
	};
}
    
export default connect(
    mapReduxStateToProps,
    mapDispatchToProps
)(UploadButton);

//export {ImagePicker};