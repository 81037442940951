import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from "@cargo/ui-kit";
import { actions } from '../../actions';

const FilesButton = ({
    addUIWindow,
    removeUIWindow,
    filter,
}) => {

    return (
        <Button 
            className="files-button" 
            label={`Files`}
            onMouseDown={e => {
                addUIWindow({
                    group: 'right-menu-bar',
                    component: import('../right-menu-bar/library-window'),
                    id: `library-window`,
                    props: {
                        type: 'popover',
                        uiWindowType: 'popover',
                        borderRadius: 'radius-all',
                        // clickoutLayer: true,
                        windowName: 'library',
                        className: 'files',
                        closeOnSingleClickout: true,
                        invokeTarget: e.currentTarget,
                        invokeWindow: 'custom-font-wizard-window',
                        closeButton: false,
                        positionType: 'center',
                        buttonOffset: {
                            top: 10,
                            right: -5
                        },
                        acceptDrops: true,
                        avoidTransform: true,
                        waitForHeightBeforeRender: true,
                        minimumRenderHeight: 50,
                        clickoutLayer: true,
                        buttonPos: e.currentTarget.getBoundingClientRect(),
                        mediaType: 'files',
                        filter
                    }
                },{
                    removeGroup: false
                });
            }}
        />
    )
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        addUIWindow: actions.addUIWindow,
        updateUIWindow: actions.updateUIWindow,
        removeUIWindow: actions.removeUIWindow,
    }, dispatch);

}

function mapReduxStateToProps(state, ownProps) {
	return {
		pageId: state.frontendState.PIDBeingEdited,
	};
}
    
export default connect(
    mapReduxStateToProps,
    mapDispatchToProps
)(FilesButton);