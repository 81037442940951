import { FRONTEND_DATA } from "../../../globals";
import { 
	rangeCanBeModifiedByTextCommands,
	getAllParents,
	wrapRangeWith,
	getNodesInRangeFilteredBySelector
} from "../helpers";

import _ from 'lodash';

export default {

	requiresTextInRange: true,
	requiresUncollapsedRange: true,
	requiresActiveRange: true,

	remove: function() {

		const state = this.getState();
		
		if(state.isApplied) {
			FRONTEND_DATA.contentWindow.CargoEditor.mutationManager.execute(()=>{	

				Object.keys(state.matches).forEach(match => {

					state.matches[match].forEach(span => {

						span.style.removeProperty('--font-scale');
						span.style.removeProperty('font-size');

						if(span.style.length === 0) {
							span.removeAttribute('style');
						}
						// If span has no attributes, unwrap it
						if (span.hasAttributes() === false && span.tagName === 'SPAN') {
							const firstChild = span.firstChild;
							const lastChild = span.lastChild;
							FRONTEND_DATA.contentWindow.CargoEditor.helpers.unwrap(span, true);
							const range = FRONTEND_DATA.contentWindow.CargoEditor.rangy.createRange();
							range.setStartBefore(firstChild);
							range.setEndAfter(lastChild);
							FRONTEND_DATA.contentWindow.CargoEditor.helpers.setActiveRange(range);
						}

					});
				});

			});
		}
	},

	execute: function(newSize){

		wrapRangeWith('span', {
			before: () => {

				// first remove all old text-styles from our newly wrapped range
				getNodesInRangeFilteredBySelector('SPAN, H1, H2, A').forEach(node => {
					const fontScale = node.style.getPropertyValue('--font-scale');
					const fontSize = node.style.fontSize;
					if(fontSize !== '') {
						node.style.removeProperty('font-size');
					}
					if(fontScale !== '') {
						node.style.removeProperty('--font-scale');
					}
					if(node.style.length === 0) {
						node.removeAttribute('style');
					}

				});

			},
			after: (nodes) => {

				nodes.forEach(node => {
					// set font style
					if (newSize.indexOf('%') === -1) {
						node.style.fontSize = newSize;
					} else {
						const newScale = parseFloat(newSize) / 100.0;
						node.style.setProperty('--font-scale', newScale);
					}
				});
				
			}
		});

	},

	getState: (range) => {

		let matches = {};

		range = range || FRONTEND_DATA.contentWindow.CargoEditor.getActiveRange();

		// loop over all spans contained by this range
		getNodesInRangeFilteredBySelector('SPAN, H1, H2, A', {
			parentsOnly: true
		}).forEach(node => {
			const computedValue = node.style.getPropertyValue('--font-scale');
			const fontScale = computedValue === '' ? '' : `${parseFloat(computedValue) * 100}%`;
			const fontSize = node.style.fontSize;
			if (fontSize !== '') {
				(matches[fontSize] = matches[fontSize] || []).push(node);
			}
			if(fontScale !== '' && fontSize === '') {
				(matches[fontScale] = matches[fontScale] || []).push(node);
			}
		});

		return {
			isAllowed: rangeCanBeModifiedByTextCommands(range),
			isApplied: Object.keys(matches).length > 0,
			matches
		}

	},
	priority: 1
 
}