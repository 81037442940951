import { FRONTEND_DATA } from "../../../globals";
import { helpers } from "@cargo/common";

import _ from 'lodash';

export default {

	requiresTextInRange: false,
	requiresUncollapsedRange: true,
	requiresActiveRange: true,
	requiresImageInRange: true,
	ignoreButtonInUseState: true,


	// gallery tag name can happen when this command is triggered via context menu
	// otherwise, grab the last-created one
	execute: function(galleryTagName){

		let range = FRONTEND_DATA.contentWindow.CargoEditor.getActiveRange();
		const state = this.getState(range);
		if(!state.isAllowed || state.isApplied) {
			return;
		}

	},

	getState: (range)=> {

		if(!range){
			range = FRONTEND_DATA.contentWindow.CargoEditor.getActiveRange();
		}


		return {
			isApplied: false,
			isAllowed: range !== null && range !== undefined
		};
	},	
	priority: 1

 
}