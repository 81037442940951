import { FRONTEND_DATA } from "../../../globals";

import { 
	getNodesInRangeFilteredBySelector 
} from "../helpers";

export default {

	requiresTextInRange: false,
	requiresUncollapsedRange: true,
	requiresActiveRange: true,

	getState: function(range) {
		if( !range){
			return {
				isAllowed: false,
				isApplied: false,
			}
		}

		let selectionInThumbnailIndex = range.commonAncestorContainer && range.commonAncestorContainer.parentElement.closest('[thumbnail-index]') || range.commonAncestorContainer.hasAttribute?.('thumbnail-index');
		
		let hasInterfaceElementInRange = range.getNodes(Node.ELEMENT_NODE, function(node){
			return node.nodeName === 'SHOP-PRODUCT' || node.nodeName === 'AUDIO-PLAYER'
		}).length > 0;

		const selectedMediaItems = getNodesInRangeFilteredBySelector('MEDIA-ITEM', {includeParents: false, includePartiallyContainedNodes: false})

		const nodes = [
			...getNodesInRangeFilteredBySelector('A', {includeParents: true, includePartiallyContainedNodes: true}),
			...getNodesInRangeFilteredBySelector('MEDIA-ITEM[href]', {includeParents: false, includePartiallyContainedNodes: false})
		].filter((link)=>{
			return !selectedMediaItems.some(mediaItem=>mediaItem.contains(link) && !mediaItem.hasAttribute('href'))
		})

		let foundMatch = false;

		let index = 0;
		while(!foundMatch && nodes[index]){
			let node = nodes[index]
			if(node.nodeType === Node.ELEMENT_NODE && node.nodeName === 'A' || node.nodeName==='MEDIA-ITEM') {
				foundMatch = true;
			}
			index++;			
		}

		return {
			isApplied: nodes.length > 0,
			isAllowed: !selectionInThumbnailIndex 
							&& !hasInterfaceElementInRange 
							&& (foundMatch || !range.collapsed),
		};
	},	
	priority: 2

}