import React, { Component } from 'react';

export const LabelRadioGroup = (props) => {

	const {children, label, icon, innerPadding, labelIsCheckbox, type, className, ...otherProps} = props;

	return (

		<div 
			className={`label-radio-group ${className ? className : ''}`}
			{...otherProps}
		>
			{ label ? ( <>{ labelIsCheckbox ? ( <>{label}</> ) : ( <label className="radio-group-label">{label}</label> )}</> ) : ( null ) }
		
			<div className={`radio-button-group${type === 'text' ? ' text' : ''}`}>
				{children}
			</div>
		</div>
	);

};