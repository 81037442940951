import { FRONTEND_DATA } from "../../../globals";
import { 
	rangeCanBeAligned
} from "../helpers";

export default {

	requiresTextInSelection: false,
	requiresUncollapsedRange: true,
	requiresActiveRange: true,

	execute: function(){
		
		var range = FRONTEND_DATA.contentWindow.CargoEditor.getActiveRange();

		if(range && range.collapsed === false) {
			FRONTEND_DATA.contentWindow.CargoEditor.execCommand('justifyrangeright');
		}

	},

	getState: function(range) {

		return {
			isApplied: FRONTEND_DATA.contentWindow.CargoEditor.queryCommandState('justifyrangeright'),
			isAllowed: rangeCanBeAligned(range),
		};
	},
	
	priority: 0

}