import React, { Component } from 'react';

import { Formik, Field, Form } from 'formik';

import { getColorString, getRandomColor } from "./helpers"
import { helpers } from "@cargo/common"
import { ResetButton, Button } from "@cargo/ui-kit";

import _ from 'lodash';

import { bindActionCreators } from 'redux';
import { actions } from "../../actions";
import { connect } from 'react-redux';

import { CustomPicker } from "../../lib/react-color";
import { EditableInput } from "../../lib/react-color/components/common";

import { FRONTEND_DATA } from "../../globals";

import { commands } from "../../lib/inline-editor";
// import ColorPickerTabs from "./color-picker-tabs";

import RandomIcon from "@cargo/common/icons/randomize.svg";

import tinycolor from "tinycolor2";

const ColorField = CustomPicker(class Interface extends Component {

    constructor(props) {
        super(props);

        this.state = {
            hslColor: null,
            hsvColor: null,
            colorFormat: null,
            colorString: null,
        };
    }

    handleAlphaInputChange = (value) => {
        return false
        // Handles change in the text input for alpha %
        this.setState((state, props) => {
            if ( !state.hslColor ){
                const newColorObj = tinycolor(value);
                let newColor = newColorObj.toHsl();
                newColor.a = value;
                return {hslColor: newColor}
            } else {

                const hsl = {...state.hslColor};

                let key = Object.keys(value)[0];
                value  = value[key];

                if( isNaN( value ) ){
                    return false;
                }

                let val = Number( value );

                if (val < 0) {
                  val = 0
                } else if (val > 100) {
                  val = 100
                }

                val /= 100
                hsl.a = val;

                return {hslColor: hsl} 
            }

        })     

    }

    handleInputChange = (value, event) => {
        return
        let key = Object.keys(value)[0];
        value   = value[key];

        const newColorObj = tinycolor(value);

        if ( newColorObj.isValid() ){

            let format = newColorObj.getFormat()
         
            if( format !== this.state.colorFormat && 
                format !== 'string'               && 
                format !== 'name'                 &&
                this.keydown === true             &&
                ( event?.type == 'blur' || event?.type == 'keydown')
            ){
                this.setState({
                    hslColor: newColorObj.toHsl(),
                    colorFormat: format
                })

            } else {
                this.setState({
                    hslColor: newColorObj.toHsl()
                })
            }
        

        }
    }

    updateFormik = ( newColorObj, name, type ) => {

        let colorString = null;

        if( this.state.colorFormat ){
            // use helper function to determine formatting based off format string
            colorString = getColorString( newColorObj, this.state.colorFormat );
        } else {
            // Otherwise decide between RGBA or HEX dependant on opacity
            colorString = newColorObj._a === 1 ? newColorObj.toHexString() : newColorObj.toRgbString();
        }

        const {field, form} = this.props;

        if ( form
             && colorString
             && _.get(this.props.form.values, name) !== colorString 
        ){
            form.setFieldValue(name, colorString, false)
        }

    }

    updateTabProps = () => {
        this.props.updateUIWindow('color-palette', { form: this.props.form })
    }

    randomizeColor = () => {

        let newColorObj = getRandomColor( this.state.hslColor );

        this.hideSelectionColor();

        if ( newColorObj.isValid() && !this.props.formattingPicker ){
            if( this.props.tabs ){
                this.updateFormik(newColorObj, this.props.tabs[0].name)
            } else {
                this.updateFormik(newColorObj, this.props.field.name)
            }
        }

        if( this.props.formattingPicker && newColorObj.isValid() ){
            let colorString = newColorObj._a === 1 ? newColorObj.toHexString() : newColorObj.toRgbString();
            commands['textcolor'].execute(colorString);
        }
        
    }

    deriveColorString = ( name ) => {

        let colorString = _.get(this.props.form.values, name);

        if( colorString === null ){
            let primaryTabName  = this.props.tabs[0].name;
            let primaryTabColor = _.get(this.props.form.values, primaryTabName);

            if( !_.isEqual(primaryTabName, name) ){
                colorString = primaryTabColor;
            }
        }

        return colorString
    }

    openModal = (e) => {

        if( e.button == 2){
            return
        }

        if( e?.target.closest('.color-picker') || e?.target.closest('.input-field') || e?.target.closest('button') ) return
        
        if(e){
            e.persist();
        }

        // check if we're dragging a color - if we aren't, proceed to open it a short while later
        this.dragging = false;
        if( e?.target.closest('.preview-color') ){
            setTimeout(()=>{
                if( !this.dragging ){
                    this.openModal({
                        clientX: e.clientX,
                        clientY: e.clientY,
                        target: e.target.parentElement,
                        currentTarget: e.target.parentElement,
                        button: e.button,
                        persist: ()=>{}
                    });
                }
            }, 10);
            return;
        }        

        let buttonPos = e?.target?.getBoundingClientRect();

        if( e?.target.closest('.color-field') ){
            buttonPos = e?.target.closest('.color-field').getBoundingClientRect();
        }
        
        let existingPicker = _.find(this.props.uiWindows.byId, (uiWindow) => { return uiWindow.id === 'formatting/textcolor' });
        // If we have an existing tabbed color picker
        if( existingPicker ){
            // remove the existing color picker
            this.props.removeUIWindow( existingPicker.id );
        }

        const invokeWindow = this.props.invokeWindow ? this.props.invokeWindow : e.currentTarget.closest('.uiWindow')?.getAttribute('window-id');
        const resetColor = this.props.modalResetColor ? this.props.modalResetColor : null;

        if (this.props.formattingPicker) {

            this.props.addUIWindow({
                // group: 'formatting',
                component: import('../top-menu-bar/formatting-ui-windows/textcolor'),
                id: 'textcolor',
                props: {
                    type                : 'popover',
                    invokeWindow        : invokeWindow,
                    buttonPos           : e ? buttonPos : null,
                    windowName          : 'textcolor',
                    autoHeight          : false,
                    positionType        : 'from-button',
                    borderRadius        : 'radius-all',
                    clickoutLayer       : this.props.clickoutLayer,    
                    closeOnSingleClickout: true,
                    removeRule : (name) => {
                        const {field, form} = this.props;
                        form.setFieldValue(name, resetColor, false);
                        let removeType = name === 'backColor' ? 'background' : 'color';
                        this.props.removeSingleProperty( removeType );
                    },
                },
            });

            return;
        }

        if( this.props.tabs ){

            this.props.addUIWindow({
              component: import('./color-picker-tabs'),
              id: 'color-palette',
              props: {
                invokeWindow  : invokeWindow,
                buttonPos     : e ? buttonPos : null,
                windowName        : 'color-palette',
                originalEvent     : e,
                updateTabProps    : this.updateTabProps,
                deriveColorString : this.deriveColorString,
                ignoreTabPriority : this.props.ignoreTabPriority,
                sessionStorage     : this.props.sessionStorage ? this.props.sessionStorage : null,
                waitForHeightBeforeRender: true,
                tabs: this.props.tabs,
                form: this.props.form,
                updateFieldOnChange: (newVal, name) => {
  
                    let colorObj = tinycolor( newVal );

                    let changeTabName     = name;
                    let primaryTabName    = this.props.tabs[0].name;
                    let primaryTabColor   = _.get(this.props.form.values, primaryTabName);

                    let secondaryTabName  = this.props.tabs[1].name;
                    let secondaryTabColor = _.get(this.props.form.values, secondaryTabName);

                    if( 
                        this.props.synced !== false 
                        && _.isEqual(changeTabName, primaryTabName)
                        && ( ( primaryTabColor === secondaryTabColor ) || ( !secondaryTabColor ) )
                    ){
                        this.updateFormik(newVal, this.props.tabs[1].name);
                        this.updateFormik(newVal, this.props.tabs[0].name);

                        return
                    } 

                    this.updateFormik(newVal, name);

                },
                removeRule : (name) => {

                    const {field, form} = this.props;

                    let changeTabName     = name;
                    let primaryTabName    = this.props.tabs[0].name;
                    let primaryTabColor   = _.get(this.props.form.values, primaryTabName);

                    let secondaryTabName  = this.props.tabs[1].name;
                    let secondaryTabColor = _.get(this.props.form.values, secondaryTabName);

                    if( 
                        this.props.synced !== false 
                        && _.isEqual(changeTabName, primaryTabName)
                        && ( ( primaryTabColor === secondaryTabColor ) || ( !secondaryTabColor ) )
                    ){
                        form.setFieldValue(secondaryTabName, resetColor, false);
                        form.setFieldValue(primaryTabName, resetColor, false);
                        return
                    }

                    form.setFieldValue(name, resetColor, false)
                },
                originWindowPos  : e ? e.target.closest('.uiWindow').getBoundingClientRect() : null ,
                closeOnSingleClickout: true,
                invokeTarget: e ? e.currentTarget : null,
                positionType: 'from-button',
                buttonOffset: { top: ( buttonPos.height / 2 ) * -1, right: buttonPos.width / 2 },
                synced: this.props.synced === false ? false : true
              }
            })


        } else {

            this.props.addUIWindow({
              component: import('./color-picker-modal'),
              id: 'color-palette',
              props: {
                invokeWindow: invokeWindow,
                buttonPos: e ? buttonPos : null,
                windowName: 'color-palette',
                // pass swatch value to color picker
                initialValue: this.props.field.value,
                originalEvent: e,
                colorString: this.state.colorString,
                colorFormat: this.state.colorFormat,
                alpha: this.props.alpha,
                className: this.props.alpha === false ? 'alpha-disabled' : '',
                form: this.props.form,
                name: this.props.field.name,
                waitForHeightBeforeRender: true,
                updateFieldOnChange: (newVal, name) => {
                    let colorObj = tinycolor( newVal );
                    this.updateFormik(newVal, name);
                },
                removeRule : (name) => {
                    const {field, form} = this.props;
                    form.setFieldValue(name, resetColor, false)
                },
                originWindowPos  : e ? e.target.closest('.uiWindow').getBoundingClientRect() : null,
                closeOnSingleClickout: true,
                invokeTarget: e ? e.currentTarget : null,
                positionType: 'from-button',
                buttonOffset: { top: ( buttonPos.height / 2 ) * -1, right: buttonPos.width / 2 }

              }
            })

        }

    }

    onDownState = (e) => {
        if( e?.target.closest('.input-field') || e?.target.closest('button.randomize') ) return;
        this.setState({isMouseDown: e.target.hasAttribute('contentEditable') === false})
    }

    onDownStateRelease = (e) => {
        if (this.state.isMouseDown) {
            this.setState({isMouseDown: false}) 
        }
    }
    onColorSwatchDragOver = (e)=>{
        e.preventDefault();
    }
    onColorSwatchDrop = (e)=>{
        e.preventDefault();
        const dropData = e.dataTransfer.getData('text/color');

        if( dropData ){

            if( this.props.tabs ){
                this.props.form.setFieldValue(this.props.tabs[0].name, dropData, false)
            } else {
                this.props.form.setFieldValue(this.props.field.name, dropData, false)                
            }


        }
    }
    onColorSwatchDragStart = (e)=>{
        this.dragging = true;

        var canvas = document.createElement('canvas');
        canvas.width = 80;
        canvas.height = 80;
        canvas.style.width = '40px'
        canvas.style.height = '40px'
        canvas.style.position = 'fixed';
        canvas.style.top = 0;
        canvas.style.left = 0;
        canvas.style.zIndex = -1;

        const ctx = canvas.getContext('2d');

        // start by creating pattern
        const patternCanvas = document.createElement('canvas');
        patternCanvas.width = 20;
        patternCanvas.height = 20;
        const pCtx = patternCanvas.getContext('2d');
        pCtx.fillStyle = '#fff';
        pCtx.fillRect(0,0,20, 20);
        pCtx.fillStyle = '#ccc';
        pCtx.fillRect(0,0,10, 10);
        pCtx.fillRect(10,10,10, 10);

        const pattern = ctx.createPattern(patternCanvas, 'repeat');

        // clear canvas with transparency
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // make 'transparent grid circle'
        ctx.fillStyle = pattern;
        ctx.ellipse(40, 40, 30, 30, 0, 0, Math.PI*2);
        ctx.fill();

        // make 'color fill'
        ctx.fillStyle = this.props.field.value;
        ctx.ellipse(40, 40, 30, 30, 0, 0, Math.PI*2)
        ctx.fill();

        // make 'drop shadow stroke'
        ctx.lineWidth = 1;
        ctx.strokeStyle = 'rgba(0, 0, 0, 0.15)';
        ctx.ellipse(40, 40, 29, 29, 0, 0, Math.PI*2)
        ctx.stroke();

        document.body.appendChild(canvas);

        const rect = e.currentTarget.getBoundingClientRect();
        
        // Safari and Firefox have the correct behavior here.
        // Chrome makes us double up the size (presumably because the element's dimensions are halved)
        // if( helpers.isSafari() || helpers.isFirefox() || window.devicePixelRatio ===1 ){
            e.dataTransfer.setDragImage(canvas, (e.clientX + -rect.left + 5)  , (e.clientY + -rect.top + 5) )
        // } else {
            // e.dataTransfer.setDragImage(canvas, (e.clientX + -rect.left + 5) * 2  , (e.clientY + -rect.top + 5) * 2 )
        // }

        e.dataTransfer.setData("text/color", this.props.field.value);

        setTimeout(()=>{
            canvas.remove();
        }, 120)
    }

    isTransparent = (value) => {
        if (value) {
            let hslVal = tinycolor(value);
            return value === 'transparent' || value === null || hslVal.getAlpha() === 0;
        }
        
        return true;
    }

    render(){
        const newColorObj  = tinycolor( this.props.field.value );
        const hsl = {...this.state.hslColor};
        const hsv = {...this.state.hsvColor};

        // prevent hue creeping in hsv > hsl conversion
        hsv.h = hsl.h;

        // alpha slider uses rgba
        const rgb = newColorObj.toRgb();
        const rgbaString = newColorObj.toRgbString();
        const hex = newColorObj.toHexString();

        let primaryInputValue = hsl.a < 1 ? rgbaString : hex;

        if( this.props.colorFormat ){
            primaryInputValue = getColorString( newColorObj, this.props.colorFormat )
        }

        let primaryTabColor   = this.props.tabs ? _.get(this.props.form.values, this.props.tabs[0].name) : null;
        let secondaryTabColor = this.props.tabs ? _.get(this.props.form.values, this.props.tabs[1].name) : null;

        if( this.props.formattingPicker ){
            primaryTabColor   =  commands['textcolor'].getCurrentColor() ?? 'rgba(0,0,0,0)';
            secondaryTabColor =  commands['backcolor'].getCurrentColor() ?? 'rgba(0,0,0,0)';
        }

        return (
            <div 
                className    = { `color-field${this.state.isMouseDown ? ' down-state' : ''}${this.props?.type ? ' '+this.props.type : ''}${this.props?.showRandom ? ' randomize' : ''}${this.props?.className ? ' '+this.props.className : ''}`}
                onMouseUp    = { e => this.onDownStateRelease(e) }
                onMouseLeave = { e => this.onDownStateRelease(e) }
                // onClick      = { e => this.openModal(e) }
                onMouseDown = { e => this.openModal(e) }
            >
                <ResetButton isOverriding={this.props.isOverriding} overrideReset={this.props.overrideReset} />

                { this.props?.type !== 'swatch' ? (
                    <label>{ this.props.label }</label>
                ) : ( null )}

                {this.props?.showRandom ? ( 
                    <Button
                        onMouseDown   = { ()=> this.randomizeColor() } 
                        className="random-color-button"
                    >
                        <RandomIcon />
                    </Button>

                    ) : (
                    null
                )}

                <div className="color-inputs" >
                    {/* { this.props?.type !== 'swatch' && this.props?.type !== 'label-swatch' ? ( */}
                    {/*     <> */}
                    {/*         <div className="hex"> */}
                    {/*             <EditableInput */}
                    {/*                 {...this.props} */}
                    {/*                 value={ hex } */}
                    {/*                 label={this.props.label ? this.props.label : this.props.field.name} */}
                    {/*                 type={'hex field'} */}
                    {/*                 onChange={ this.handleInputChange } */}
                    {/*                 updateOnAction={ false }  // Allows updating when valid color is entered                   */}
                    {/*             /> */}
                    {/*         </div> */}
                    {/*         { this.props.alpha !== false ? (  */}
                    {/*             <div className="opacity"> */}
                    {/*                 <EditableInput */}
                    {/*                     {...this.props} */}
                    {/*                     value={ Math.round(rgb.a * 100) } */}
                    {/*                     onChange={ this.handleAlphaInputChange } */}
                    {/*                     label={this.props.label ? this.props.label : this.props.field.name} */}
                    {/*                     type={'alpha'} */}
                    {/*                     updateOnAction={ false }  // Allows updating when valid color is entered                */}
                    {/*                 /> */}
                    {/*             </div> */}
                    {/*         ) : ( null )} */}
                    {/*     </> */}
                    {/* ) : ( null )} */}
                    <div className="preview square">
                        <div className="preview-background">
                            {this.props.tabs || this.props.formattingPicker ? 
                                <div 
                                    className="preview-color split"
                                >
                                    <div
                                        draggable
                                        onDrop={this.onColorSwatchDrop}
                                        onDragStart={this.onColorSwatchDragStart}
                                        onDragOver={this.onColorSwatchDragOver}
                                        className="split" style={{
                                        backgroundColor: primaryTabColor
                                    }}></div>
                                    {(!this.isTransparent(secondaryTabColor) || this.props.showSecondaryAlways === true) && 
                                        <div
                                            draggable
                                            onDrop={this.onColorSwatchDrop}
                                            onDragStart={this.onColorSwatchDragStart}
                                            onDragOver={this.onColorSwatchDragOver}
                                            className="split" style={{
                                            backgroundColor: secondaryTabColor
                                        }}></div>
                                    }
                                </div>
                            : 
                                <div 
                                    draggable
                                    onDrop={this.onColorSwatchDrop}
                                    onDragStart={this.onColorSwatchDragStart}
                                    onDragOver={this.onColorSwatchDragOver}
                                    className="preview-color" 
                                    style={{
                                        backgroundColor: this.state.colorString ? this.state.colorString : this.props?.field?.value
                                    }}>
                                </div>
                            //      <svg className="outline" viewBox="0 0 40 40" style={{'enableBackground':'new 0 0 40 40'}}>
                            //      <circle cx="20" cy="20" r="15"/>
                            //  </svg>
                            }
                            
                        </div>
                    </div>
                </div>
            </div>

        )
    }

    hideSelectionColor = () => {

        if( !this.props.hideSelectionColorOnRandom ){
            return
        }

        const frontendDoc = FRONTEND_DATA.contentWindow.document;

        if(!frontendDoc.body.querySelector('#temp-sp-styles')){
            const selectionRule = '::selection { background:transparent !important;} body { --selection-color:transparent!important; }';
            const styleNode = document.createElement('style');
            styleNode.innerHTML = selectionRule;
            frontendDoc.body.appendChild(styleNode);
            styleNode.id = 'temp-sp-styles';
        }

        this.showSelectionColor();

    }

    showSelectionColor = _.debounce(()=> { 

        if( !this.props.hideSelectionColorOnRandom ){
            return
        }

        const frontendDoc = FRONTEND_DATA.contentWindow.document;

        if(frontendDoc.body.querySelector('#temp-sp-styles')){
            frontendDoc.body.querySelector('#temp-sp-styles').remove();
        }
    }, 2000, false)


    componentDidUpdate(prevProps, prevState) {

        if ( !prevState.hslColor ){
            this.props.onChange(this.state.hslColor);
            return  
        }    

        if( 
            this.props.tabs 
            && !_.isEqual(this.props.tabs[0].name, this.props.field.name)
        ){
            // Indicates prop tab mixup. The preview field should never change as a result of the secondary tab changing.
            return
        }

        if( this.props?.field?.value !== prevProps?.field?.value ){

            let colorObj = this.props?.field?.value ? tinycolor( this.props.field.value ) : tinycolor( 'rgba(0,0,0,0)' );

            // if( !this.props?.field?.value ){ return }
            
            let toHSL = colorObj.toHsl()
            let toHSV = colorObj.toHsv()

            // Prevstate condition here may cause a bug. Investigate if odd behavior arises.
            // if( this.state.hslColor.a !== 0 && prevState.hslColor.a !== 0  ){
            //Normalize and compare the inherited value >> HERE <<
            if( prevProps?.field?.value !== null 
                && this.props?.field?.value === null 
                && this.props.overridingPopertyValue
                && this.props.overridingPopertyValue === prevProps?.field?.value ){
                    return 
            }
            
            this.setState({ 
                colorString : colorObj.toRgbString(),
                hslColor    : toHSL,
                hsvColor    : toHSV,
            }, ()=> {
                // Send an update to the open color picker...
                this.props.updateUIWindow('color-palette', { form: this.props.form })
            })


            // }
        }

    };

    componentDidMount(){

        let value = this.props.field.value && this.props.field.value !== 'initial' ? this.props.field.value : 'rgba(0,0,0,0)';

        const newColorObj = tinycolor(value);

        this.setState({
            colorString : newColorObj.toRgbString(),
            hslColor    : newColorObj.toHsl(),
            hsvColor    : newColorObj.toHsv(),
        })

    };

    componentWillUnmount(){

        // Show selection color right away without debounce.
        if( !this.props.hideSelectionColorOnRandom ){
           return
        }

        const frontendDoc = FRONTEND_DATA.contentWindow.document;

        if(frontendDoc.body.querySelector('#temp-sp-styles')){
           frontendDoc.body.querySelector('#temp-sp-styles').remove();
        }

        };

});

function mapReduxStateToProps(state, ownProps) {

    return {
        uiWindows: state.uiWindows
    };

}

function mapDispatchToProps(dispatch) {
    
    return bindActionCreators({
        addUIWindow: actions.addUIWindow,
        updateUIWindow: actions.updateUIWindow,
        removeUIWindow: actions.removeUIWindow
    }, dispatch);

}

export default connect(
    mapReduxStateToProps,
    mapDispatchToProps
)(ColorField);