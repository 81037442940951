import { FRONTEND_DATA } from "../../../globals";
import _ from 'lodash';

export default {

	requiresTextInRange: false,
	requiresUncollapsedRange: false,
	requiresActiveRange: true,

	onEditorLoad: function() {

		FRONTEND_DATA.contentWindow.CargoEditor.events.on('editor-click', function(editor, e){

			let parent = e.target;

			while(parent !== null && parent.nodeType !== Node.DOCUMENT_NODE) {

				if( parent.nodeType === Node.ELEMENT_NODE && parent.nodeName === 'CARGO-MAP') {

					var range = FRONTEND_DATA.contentWindow.CargoEditor.rangy.createRange(),
						bbBox = parent.getBoundingClientRect();

					if(e !== undefined && e.clientX < (bbBox.left + (bbBox.width / 10))){
						// If we click on the left side of the picker, place selection to the left
						range.setStartBefore(parent);
						range.setEndBefore(parent);

					} else if( e !== undefined && e.clientX > bbBox.right - (bbBox.width / 10) ) {
						// If we click to the right side of the picker, place selection to the right
						range.setStartAfter(parent);
						range.setEndAfter(parent);

						
					} else {
						// Otherwise, select the player itself
						range.setStartBefore(parent);
						range.setEndAfter(parent);
						// Set new active range
						FRONTEND_DATA.contentWindow.CargoEditor.helpers.setActiveRange(range);
						// run new active range through get state to show proper button state
						this.getState(range);
						return
					}

					FRONTEND_DATA.contentWindow.CargoEditor.helpers.setActiveRange(range);

					return
				} 

				parent = parent.parentNode;
			}

		}, this);

	},

	execute: function(){

		var range = FRONTEND_DATA.contentWindow.CargoEditor.getActiveRange();

		if(range == undefined){
			return
		}

		// console.log("audioNodes", audioNodes )
		if( this.getState(range).isApplied === false ){
			FRONTEND_DATA.contentWindow.CargoEditor.mutationManager.execute(()=>{

				let map = FRONTEND_DATA.contentWindow.document.createElement('cargo-map');

				map.setAttribute('api-key', '');

				range.insertNode(map);

				range.setStartBefore(map);
				range.setEndAfter(map);

				// Set new active range
				FRONTEND_DATA.contentWindow.CargoEditor.helpers.setActiveRange(range);

				const rect = map.getBoundingClientRect();
				const svgPosition = {
					x: rect.x + -10,
					y: rect.y,
					left: rect.right + -10,
					right: rect.right + -10,
					top: rect.top + 0,
					bottom: rect.top + 27,
					width: rect.width,
					height: 10
				}

				window.UIWindowOpener.openUIWindow({
					windowName: 'map-ineditor',
					windowAlias: 'map',
					positionRect: svgPosition,
					closeButton: false,
					closeOnAllClickout: true,
					supportsMobile: false,
					props: {
						mapNode: map
					}
				});		

				this.getState(range);
			});

		}

	
	},

	getState: function(range) {

		const audioNodes = FRONTEND_DATA.contentWindow.CargoEditor.helpers.getAllEffectivelyContainedNodes(
			range, 
			node => node.nodeName === "CARGO-MAP"
		);

		const activeWindowId = FRONTEND_DATA.globals.ADMIN_FRAME.adminWindow.UIWindowOpener.props.activeWindow ? FRONTEND_DATA.globals.ADMIN_FRAME.adminWindow.UIWindowOpener.props.activeWindow.id : null;

		// FRONTEND_DATA.contentWindow.CargoEditor.events.on('editor-click', this.onEditorInteraction, this);

		return {
			isApplied: audioNodes.length > 0 || activeWindowId === 'formatting/map-ineditor' ? true : false,
			isAllowed: true,
		};
	},
	priority: 1
 
}