import { FRONTEND_DATA } from "../../../globals";
import {
	getEffectsTargetsContainedByRange,
	shouldBeWrappedInSpans,
	wrapRangeWith
} from "../helpers";

import { effectsBorderDefaults } from "../../../defaults/border-defaults";
import { effectsDropshadowDefaults } from "../../../defaults/dropshadow-defaults";


import _, { replace } from 'lodash';

const isMediaItem = (node) => {
	return node.tagName === "MEDIA-ITEM";
}

const hasBorderStyles = (node) => {
	if (!node) {
		return false;
	}
	let styles = null;
	if (isMediaItem(node)) {
		styles = node.hasAttribute('uses') && node.getAttribute('uses').includes('inline-part-styles') && node.hasAttribute('media-style') ? node.getAttribute('media-style') : null;
	} else {
		styles = node.hasAttribute('style') ? node.getAttribute('style') : null;
	}
	if (styles === null) {
		return false;
	}
	if (styles.includes('border') || styles.includes('padding')) {
		return true;
	}
	return false;
}

const getBorderObject = (node) => {

	const inlineStyle = isMediaItem(node) ? `${node.getAttribute('media-style')}` : `${node.getAttribute('style')}`;
	//const computedStyle = isMediaItem(node) ? getComputedStyle(node.shadowRoot.querySelector('[part*="media"]')) : getComputedStyle(node);

	const properties = [
		'border-width',
		'border-top-width',
		'border-right-width',
		'border-bottom-width',
		'border-left-width',
		'border-color',
		'border-radius',
		'border-top-right-radius',
		'border-top-left-radius',
		'border-bottom-right-radius',
		'border-bottom-left-radius',
		'border-style',
		'background',
		'background-color',
		'padding',
		'padding-top',
		'padding-right',
		'padding-bottom',
		'padding-left'
	];

	let hasInlineBorderStyles = false;
	properties.forEach((property) => {
		if (inlineStyle.includes(property)) {
			hasInlineBorderStyles = true;
		}
	});
	if (hasInlineBorderStyles === false) {
		return null;
	}


	return properties.reduce((acc, curr) => {
		let key = curr;
		if (key.includes('padding') || key === 'background') {
			key = `border-${key}`;
		}
		if (key === 'background-color') {
			key = 'border-background';
		}
		acc[key] = ( acc[key] ?? getStyle(inlineStyle, curr)?.trim() ) ?? null;
		return acc;
	}, {})

}

const replaceRule = (style, property, rule) => {
	if (!style) {
		return `${property}: ${rule};`
	}
	if (!style.includes(`${property}:`)) {
		return `${style} ${property}: ${rule};`;
	}
	const pattern = getRule(style, property);
	let replaced = style.replace(pattern, `${property}: ${rule};`);
	replaced = replaced.replace(';;', ';');
	replaced = replaced.trim();
	return replaced;
}

const getRule = (style, property) => {
	if (!style.includes(`${property}:`)) {
		return null;
	}
	const start = style.indexOf(`${property}: `);
	const end = style.indexOf('; ', start) !== -1 ? style.indexOf('; ', start) : style.length;
	let string = style.substring(start, end);
	return string;
}

const getEdgeValueFromShorthand = (property, shorthand) => {
	let string = null;
	const parts = shorthand.split(' ');
	switch (parts.length) {
		case 1: {
			string = parts[0];
			break;
		}
		case 2: {
			if (property.includes('top') || property.includes('bottom')) {
				string = parts[0];
			}
			if (property.includes('left') || property.includes('right')) {
				string = parts[1];
			}
			break;
		}
		case 3: {
			if (property.includes('top')) {
				string = parts[0];
			}
			if (property.includes('right')) {
				string = parts[1];
			}
			if (property.includes('bottom')) {
				string = parts[2];
			}
			if (property.includes('left')) {
				string = parts[1];
			}
			break;
		}
		case 4: {
			if (property.includes('top')) {
				string = parts[0];
			}
			if (property.includes('right')) {
				string = parts[1];
			}
			if (property.includes('bottom')) {
				string = parts[2];
			}
			if (property.includes('left')) {
				string = parts[3];
			}
			break;
		}
	}
	return string;
}

const getCornerValueFromShorthand = (property, shorthand) => {
	let string = null;
	const parts = shorthand.split(' ');
	switch (parts.length) {
		case 1: {
			string = parts[0];
		}
		case 2: {
			if (property.includes('top-left') || property.includes('bottom-right')) {
				string = parts[0];
			}
			if (property.includes('bottom-left') || property.includes('top-right')) {
				string = parts[1];
			}
			break;
		}
		case 3: {
			if (property.includes('top-left')) {
				string = parts[0];
			}
			if (property.includes('topright')) {
				string = parts[1];
			}
			if (property.includes('bottom-right')) {
				string = parts[2];
			}
			if (property.includes('bottom-left')) {
				string = parts[1];
			}
			break;
		}
		case 4: {
			if (property.includes('top-left')) {
				string = parts[0];
			}
			if (property.includes('top-right')) {
				string = parts[1];
			}
			if (property.includes('bottom-right')) {
				string = parts[2];
			}
			if (property.includes('bottom-left')) {
				string = parts[3];
			}
			break;
		}
	}
	return string;
}

const getStyle = (style, property) => {
	let string = getRule(style, property)?.replace(`${property}:`, '').replace(';', '').trim();
	if (string) {
		if (property === 'border-width' && string.includes(' ')) {
			string = string.split(' ')[0];
		}
		if (property === 'padding' && string.includes(' ')) {
			string = string.split(' ')[0];
		}
		if (property === 'border-radius' && string.includes(' ')) {
			string = string.split(' ')[0];
		}
	} else {
		const borderShorthand = getRule(style, 'border')?.replace(`border:`, '').replace(';', '').trim();
		if (property !== 'border-width' && property.includes('border-') && property.includes('-width')) {
			const borderWidthShorthand = getRule(style, 'border-width')?.replace(`border-width:`, '').replace(';', '').trim();
			if (borderWidthShorthand) {
				string = getEdgeValueFromShorthand(property, borderWidthShorthand);
			} else {
				if (borderShorthand) {
					string = borderShorthand.split(' ')[0];
				}
			}
		}
		if (property === 'border-style' && borderShorthand) {
			string = borderShorthand.split(' ')[1];
		}
		if (property === 'border-color' && borderShorthand) {
			string = borderShorthand.split(' ')[2];
		}
		if (property !== 'border-radius' && property.includes('border-') && property.includes('-radius')) {
			const borderRadiusShorthand = getRule(style, 'border-radius')?.replace(`border-radius:`, '').replace(';', '').trim();
			if (borderRadiusShorthand) {
				string = getCornerValueFromShorthand(property, borderRadiusShorthand);
			}
		}
		if (property.includes('padding-')) {
			const paddingShorthand = getRule(style, 'padding')?.replace(`padding:`, '').replace(';', '').trim();
			if (paddingShorthand) {
				string = getEdgeValueFromShorthand(property, paddingShorthand);
			}
		}		
	}
	return string;
}

const clearRule = (style, property) => {
	if (!style.includes(`${property}:`)) {
		return style;
	}

	return style.replace(getRule(style, property), '');
}

const getNewStyle = (prevStyle, changes) => {
	let newStyle = prevStyle;
	//newStyle = clearRule(newStyle, 'border');
	Object.keys(changes).forEach((key) => {
		let property = key;
		if (key.includes('border-padding') || key.includes('border-background')) {
			property = property.replaceAll('border-', '');
		}
		if (changes[key] === null) {
			newStyle = clearRule(newStyle, key);
		} else {
			newStyle = replaceRule(newStyle, property, changes[key]);
		}
		if (property === 'border-width') {
			newStyle = clearRule(newStyle, 'border-top-width');
			newStyle = clearRule(newStyle, 'border-right-width');
			newStyle = clearRule(newStyle, 'border-bottom-width');
			newStyle = clearRule(newStyle, 'border-left-width');
		}
		if (property === 'border-radius') {
			newStyle = clearRule(newStyle, 'border-top-left-radius');
			newStyle = clearRule(newStyle, 'border-top-right-radius');
			newStyle = clearRule(newStyle, 'border-bottom-left-radius');
			newStyle = clearRule(newStyle, 'border-bottom-right-radius');
		}
		if (property === 'padding') {
			newStyle = clearRule(newStyle, 'padding-top');
			newStyle = clearRule(newStyle, 'padding-right');
			newStyle = clearRule(newStyle, 'padding-bottom');
			newStyle = clearRule(newStyle, 'padding-left');
		}
	})
	newStyle = newStyle.replaceAll(';;', ';');
	newStyle = newStyle.replaceAll(' ;', '');
	newStyle = newStyle.trim();
	if (newStyle[0] === ';') {
		newStyle = newStyle.substring(1).trim();
	}
	newStyle = newStyle.replace(';;', ';');
	return newStyle;
}

const getClearedStyle = (prevStyle) => {
	let newStyle = prevStyle;
	const rules = [
		'border',
		'border-width',
		'border-top-width',
		'border-right-width',
		'border-bottom-width',
		'border-left-width',
		'padding',
		'padding-top',
		'padding-left',
		'padding-bottom',
		'padding-right',
		'border-radius',
		'border-top-left-radius',
		'border-bottom-left-radius',
		'border-bottom-right-radius',
		'border-top-right-radius',
		'border-color',
		'background',
		'background-color',
		'border-style',
		'display'
	]
	rules.forEach((rule) => {
		newStyle = clearRule(newStyle, rule);
	})
	newStyle = newStyle.replaceAll(';;', ';');
	newStyle = newStyle.replaceAll(' ;', '');
	if (newStyle[0] === ';') {
		newStyle = newStyle.substring(1).trim();
	}
	newStyle = newStyle.trim();
	if (newStyle.length < 4) {
		newStyle = '';
	}
	return newStyle;
}

export default {

	requiresTextInRange: false,
	requiresUncollapsedRange: true,
	requiresActiveRange: true,

	removeFromNode(node, targetAttr) {
		if (node) {
			FRONTEND_DATA.contentWindow.CargoEditor.mutationManager.execute(() => {
				if ( node.hasAttribute('style') ) {
					const clearedStyle = getClearedStyle(node.getAttribute('style'));
					if (clearedStyle.length) {
						node.setAttribute('style', clearedStyle);
					} else {
						node.removeAttribute('style');
					}
				}
				if (node.hasAttribute('media-style')) {
					const clearedStyle = getClearedStyle(node.getAttribute('media-style'));
					if (clearedStyle.length) {
						node.setAttribute('media-style', clearedStyle);
					} else {
						node.removeAttribute('media-style');
					}
				}
				if (node.hasAttribute('uses') && node.getAttribute('uses').includes('inline-part-styles') && node.hasAttribute('media-style') === false) {
					const newUses = node.getAttribute('uses').replace('inline-part-styles', '').trim();
					if (newUses.length) {
						node.setAttribute('uses', newUses);
					} else {
						node.removeAttribute('uses');
					}
				}
			});
		}
	},

	remove: function (targetAttr, mediaItemsArr) {

		const state = mediaItemsArr ? this.getState(null, mediaItemsArr) : this.getState();
		let matchesNode = state.matches ? state.matches.node : null;

		if (mediaItemsArr) {
			mediaItemsArr.forEach((mediaItemsArrNode) => {
				this.removeFromNode(mediaItemsArrNode, targetAttr);
			})
		} else {
			this.removeFromNode(matchesNode, targetAttr);
			if (matchesNode && matchesNode.tagName === "SPAN" && matchesNode.hasAttributes() === false) {
				const firstChild = matchesNode.firstChild;
				const lastChild = matchesNode.lastChild;
				FRONTEND_DATA.contentWindow.CargoEditor.helpers.unwrap(matchesNode, true);
				const range = FRONTEND_DATA.contentWindow.CargoEditor.rangy.createRange();
				range.setStartBefore(firstChild);
				range.setEndAfter(lastChild);
				FRONTEND_DATA.contentWindow.CargoEditor.helpers.setActiveRange(range);
			}
		}
	},

	execute: function (targetAttr, changes, mediaItemsArr) {

		const state = mediaItemsArr ? this.getState(null, mediaItemsArr) : this.getState();
		const CargoEditor = FRONTEND_DATA.contentWindow.CargoEditor;
		const range = CargoEditor.getActiveRange();


		

		// If range contains multiple nodes or only a text node, then wrap in <span uses/>
		// Otherwise, add the uses attribute directly to the node
		if (shouldBeWrappedInSpans(state.targets, mediaItemsArr)) {
			wrapRangeWith('span', {
				after: (spans) => {
					spans.forEach(span => {
						if (changes && Object.keys(changes).length > 0) {
							FRONTEND_DATA.contentWindow.CargoEditor.mutationManager.execute(() => {
								let prevStyle = span.hasAttribute('style') ? span.getAttribute('style') : '';
								prevStyle = replaceRule(prevStyle, 'display', 'inline-block');
								span.setAttribute('style', getNewStyle(prevStyle, changes));
								// Check if last node is a <br> and move it outside of the span
								if (span.lastChild && span.lastChild.tagName === 'BR') {
									span.parentNode.insertBefore(span.lastChild, span.nextSibling);
								}
							});
						}
						// const range = FRONTEND_DATA.contentWindow.CargoEditor.rangy.createRange();
						// range.setStartBefore(span);
						// range.setEndAfter(span);
						// FRONTEND_DATA.contentWindow.CargoEditor.helpers.setActiveRange(range);
					});
				}
			});
		} else {
			state.targets.forEach((target) => {
				FRONTEND_DATA.contentWindow.CargoEditor.mutationManager.execute(() => {
					if (isMediaItem(target)) {
						const uses = target.hasAttribute('uses') ? target.getAttribute('uses') : null;
						if (!uses) {
							target.setAttribute('uses', `inline-part-styles`);
						} else {
							if (!uses.includes('inline-part-styles')) {
								target.setAttribute('uses', `${uses} inline-part-styles`);
							}
						}
						let prevStyle = target.getAttribute('media-style');
						const newStyle = getNewStyle(prevStyle, changes);
						if (newStyle === 'display: inline-block;') {
							target.removeAttribute('media-style');
						} else {
							target.setAttribute('media-style', newStyle);
						}
					} else {
						let prevStyle = target.getAttribute('style');
						prevStyle = replaceRule(prevStyle, 'display', 'inline-block');
						const newStyle = getNewStyle(prevStyle, changes);
						if (newStyle === 'display: inline-block;') {
							target.removeAttribute('style');
						} else {
							target.setAttribute('style', newStyle);
						}
					}
				});
				if (!mediaItemsArr) {
					// const range = FRONTEND_DATA.contentWindow.CargoEditor.rangy.createRange();
					// range.setStartBefore(target);
					// range.setEndAfter(target);
					// FRONTEND_DATA.contentWindow.CargoEditor.helpers.setActiveRange(range);
				}
			})
		}
	},

	getState: (range, mediaItemsArr) => {

		range = range || FRONTEND_DATA.contentWindow.CargoEditor.getActiveRange();

		let targets;
		if (!mediaItemsArr) {
			targets = getEffectsTargetsContainedByRange();
		} else {
			targets = mediaItemsArr;
		}

		if (targets.length > 1 && targets.every((target) => target.tagName === 'MEDIA-ITEM')) {
			mediaItemsArr = targets;
		}

		let matches = targets[0] && targets[0].nodeType === Node.ELEMENT_NODE ? {
			node: targets[0],
			border: getBorderObject(targets[0])
		} : null;

		if (!mediaItemsArr && targets.length > 1) {
			matches = null;
		}

		const columnUnitParents = targets.reduce((acc, curr) => {
			if (curr.parentNode && curr.parentNode.tagName === 'COLUMN-UNIT' && !acc.includes(curr.parentNode)) {
				acc.push(curr.parentNode);
			}
			return acc;
		}, [])

		let isAllowed = false;

		if (range) {
			isAllowed = !range.collapsed && !targets.some((node) => node.tagName === 'COLUMN-SET') && !targets.some((node) => node.tagName === 'COLUMN-UNIT') && columnUnitParents.length < 2
		}

		return {
			isAllowed,
			isApplied: matches !== null && hasBorderStyles(matches.node),
			matches,
			targets,
		}

	},
	priority: 1

}