import React, { useEffect, useState, useMemo, useRef, useCallback } from "react";
import { LoadingAnimation } from "@cargo/ui-kit";

export const SaveButton = props => {

	const {onSave, formik, show,...otherProps} = props;

	const [loading, setLoading] = useState(false);
	const [initial, setInitial] = useState(false);
	const prevState             = usePrevious({show});


	useEffect(() => {
		// code to run on component mount
		setInitial(show);

		if( prevState?.show === true ){
			// code to run on component mount
			setLoading(true);
		} else {
			setLoading(false);
		}

	}, [show])


	return (
		<button
			{...otherProps}
			className={`save-button${ initial ? ' initial' : ''}`}
			onClick={() => { onSave( formik ) }}
			type="button"
		>
			<span>Save</span>
			{loading ? ( <LoadingAnimation /> ) : ( null )}
		</button>
	);
};

// This gives us access to previous props within our main component.
function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}