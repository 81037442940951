import React, { useState } from 'react';
import _ from 'lodash';

import { Formik, Field } from 'formik';

import { processCSSValue } from "./helpers"
import { GenericRange } from "./";

// Requires white list of possible unit values --> { 'px', '%', 'rem'}
// Requires defaultUnit --> 'rem'
// Optional: Allow Non Unit Values like calc( ) --> Bool
// Requires unit string --> '12rem' or '12'
// Will validate for accepted unit else will add default unit to number.
function ParseCSSUnit (unitString='', defaultUnit, validUnitSet, allowNonUnitValues){
		unitString = unitString?.toString() || '';
		
		const possibleUnits = validUnitSet
		const nonUnitValues = allowNonUnitValues ? ['--', 'calc('] : []

		let cssNum = parseFloat(unitString) || 0;
		let cssUnit = unitString?.replace((cssNum+''), '').trim() || '';
		// if the value is unitless, append the unit to the value when it's returned
		if (
			!possibleUnits.includes(cssUnit) &&
			nonUnitValues.filter(value => unitString.indexOf(value) > -1 ).length == 0 
		){

			cssUnit = defaultUnit;
			unitString = cssNum+defaultUnit
		}

		return unitString;
}

class CSSRange extends React.Component {

	constructor(props){
		super(props);

		this.possibleUnits = ['%', 'cm', 'mm', 'Q', 'in', 'pc', 'pt', 'px', 'em', 'ex', 'ch', 'rem', 'lh', 'vw', 'vh', 'vmin', 'vmax', 'fr', ''];
		this.dragging = false;
		this.dragPos = 0;

		let separatedValue = processCSSValue(props.field.value) || [0, ''];

		this.state = {
			hasFocus: false,
			displayTempValue: false,
			tempValue: '',
			assumedUnit: separatedValue[1] || props.defaultUnit || 'px'
		}

		this.rangeRef = React.createRef();
		this.inputRef = React.createRef();

	}

	render(){

		const {label, name, field, defaultUnit='px', unitSet, ...props} = this.props;
		let formikValue = field.value;


		// Returns string...
		let parsedCSSUnit = ParseCSSUnit( formikValue, defaultUnit, Object.keys( unitSet ), false );

		// Helper function accepts string '10px', returns array [10, 'px']
		let processedRule = processCSSValue( parsedCSSUnit );
		
		let activeUnit = this.state.assumedUnit;

		let min = 0;
		let max = 100;
		let step = 1;
		let displayValue = '';

		if( processedRule[0] =='auto' || processedRule[1] =='auto'){

			if ( this.state.displayTempValue ) {
				displayValue = this.state.tempValue;
			} else {
				displayValue ='auto'
			}

		} else {

			// Set slider conditions -- be sure we have a valid unit.
			min  = unitSet?.[activeUnit]?.min ?? min;
			max  = unitSet?.[activeUnit]?.max ?? max;
			step = unitSet?.[activeUnit]?.step ?? step;

			if ( this.state.displayTempValue ) {
				displayValue = this.state.tempValue;
			} else if (defaultUnit == activeUnit){
				displayValue = processedRule[0]
			} else {
				displayValue = field.value;
			}
		}

		return <div
			className={`css-range${this.props.disabled ? ' disabled': ''}`}
			onMouseDown={this.onRangePointerDown}
		>
			<GenericRange
				{...this.props}

				ref={this.rangeRef}
				onChange={this.onRangeChange}
				value={processedRule[0]}
				unit={''}
				min={min}
				max={max}
				step={step}
				disabled={activeUnit=='auto'}

			/>
			<div className="text-input" onMouseDown={this.onMouseDown} >
				<input 
					spellCheck={false}
					ref={this.inputRef}
					value={displayValue} 
					onChange={this.onChange}
					onKeyDown={this.handleKeyDown}
					onBlur={this.onBlur}
					onFocus={this.onFocus}
				/>
			</div>
		</div>

	}

	clampValue = (separatedValue)=>{

		if(separatedValue[0]=='auto'){
			return 'auto'
		}

		const {unitSet, min=0, max=100, ...props} = this.props;

		let activeUnit = separatedValue[1];

		// Set slider conditions -- be sure we have a valid unit.
		let activeMin  = unitSet?.[activeUnit]?.min ?? min;
		let activeMax  = unitSet?.[activeUnit]?.max ?? max;

		return Math.min(activeMax, Math.max(activeMin, separatedValue[0]));
	}


	// events

	onRangePointerDown=(e)=>{

		// if we click down in a place that's squarely in the range, we defocus the text input
		if ( !e.target.closest('.text-input') && this.inputRef.current){
			this.inputRef.current.blur();
		}
	}

	onMouseDown = (e)=>{

		// this stuff is maybe too cute, commenting it out for now
		return;

		if ( this.state.hasFocus){
			return
		}
		if(this.props.onMouseDown){
			this.props.onMouseDown(e);
		}
		window.addEventListener('pointermove', this.onPointerMove)
		window.addEventListener('pointerup', this.onPointerUp)
		window.addEventListener('pointercancel', this.onPointerUp)

		this.dragPos = e.clientX;
		this.preventDrag = false;

		clearTimeout(this.preventDragTimeout);
		this.preventDragTimeout = setTimeout(function(){
			this.preventDrag = true;
		}.bind(this), 250)
	}

	onPointerMove =(e)=>{

		if ( this.preventDrag ){
			return;
		}

		// prevent clumsy-finger drag
		if ( Math.abs(e.clientX - e.dragPos) < 4){
			return
		}

		if ( !this.dragging ){
			this.rangeRef.current.handlePointerDown(e);			
		}

		this.dragging = true;

		if ( this.inputRef.current ){
			this.inputRef.current.blur();			
		}
		if (this.rangeRef.current){
			if ( this.dragging ){
				this.rangeRef.current.setValueInRange(e);				
			}
		}
		this.dragging = true;
	}

	onPointerUp = (e)=>{
		
		if (e && this.rangeRef.current && this.dragging ){
			this.rangeRef.current.handlePointerUp(e);
		}

		if(this.props.onPointerUp){
			this.props.onPointerUp(e);
		}
		window.removeEventListener('pointermove', this.onPointerMove)
		window.removeEventListener('pointerup', this.onPointerUp)
		window.removeEventListener('pointercancel', this.onPointerUp)		
	}

	onRangeChange = (value)=>{

		const {form, field} = this.props;
		if ( !form || !field){
			return;
		}

		form.setFieldValue(
			field.name, 
			''+value+this.state.assumedUnit,
			false
		)		
	}

	onChange = (e)=>{

		const {form, field} = this.props;

		let value = e.currentTarget.value;
		let separatedValue = processCSSValue(value) || [null, null];

		let numberIsValid = !isNaN(separatedValue[0]);
		let unitIsValid = false;

		if ( separatedValue[1] ){
			if ( this.props.unitSet){
				unitIsValid = this.props.unitSet.hasOwnProperty(separatedValue[1])
			}else {
				unitIsValid = this.possibleUnits.indexOf(separatedValue[1]) > -1
			}
		}

		// we can update form even with an invalid unit, just as long as we had one previously
		let unit = unitIsValid ? separatedValue[1] : this.state.assumedUnit;

		if ( numberIsValid && form ) {

			form.setFieldValue(
				field.name, 
				unit == 'auto' ? 'auto' : this.clampValue([separatedValue[0], unit])+unit+'', 
				false
			)
			this.setState({
				tempValue: value,
				displayTempValue: true,
				assumedUnit: unit
			})
		} else {

			this.setState({
				tempValue: value,
				assumedUnit: unit,
				displayTempValue: true
			})			
		}

	}

	handleKeyDown = (e)=>{

		if ( e.key == 'Enter'){

			this.resetTextInput(e);

			if (this.inputRef.current){
				this.inputRef.current.blur();
			}
		}

		// behavior to increment value
		const {form, field} = this.props;
		if ( !form || !field ){
			return;
		}


		let separatedValue = processCSSValue(field.value) || [null, null];
		let newValue = separatedValue[0];
		if ( isNaN(newValue) || separatedValue[1]=='auto' ){
			return
		}

		if ( e.key != 'ArrowUp' && e.key !='ArrowDown'){
			return
		}

		// i'll think of a way to consolidate this into something clever later
		if (e.key == 'ArrowUp'){

			if ( e.shiftKey ){

				if ( e.altKey ){
					newValue = newValue + 100
				} else {
					newValue= newValue + 10
				}

			} else {
				newValue = newValue + 1;
			}

		} else if (e.key == 'ArrowDown'){

			if ( e.shiftKey ){

				if ( e.altKey ){
					newValue = newValue + -100
				} else {
					newValue= newValue + -10
				}

			} else {
				newValue = newValue + -1;
			}
		}

		newValue = this.clampValue([newValue, this.state.assumedUnit])

		this.setState({
			tempValue: newValue+separatedValue[1]+''
		})

		form.setFieldValue(
			field.name, 
			newValue+separatedValue[1]+'', 
			false
		)		
	}

	componentDidUpdate(prevProps){

		if(prevProps.field.value != this.props.field.value){

			let separatedValue = processCSSValue(this.props.field.value) || [0, ''];

			if(separatedValue[1] != this.state.assumedUnit){
				this.setState({
					assumedUnit: separatedValue[1] || this.props.defaultUnit || 'px'
				});				
			}

		}

	}

	onFocus = (e)=>{
		this.setState({
			hasFocus: true
		})
	}

	onBlur = (e)=>{
		this.setState({
			hasFocus: false
		})
		this.resetTextInput();
	}

	resetTextInput = (e) =>{

		this.setState({
			tempValue: null,
			displayTempValue: false
		})
	
	}
		
}

export {CSSRange};
