import { FRONTEND_DATA } from "../../../globals";

import { 
	retrieveSequentualNodesEffectivelyContainedByRange,
	rangeCanBeModifiedByTextCommands,
	removeParentsWithGivenNodeName, 
} from "../helpers";

import _ from 'lodash';

export default {

	requiresTextInRange: true,
	requiresUncollapsedRange: true,
	requiresActiveRange: true,

	execute: function(){

		const state = this.getState();

		if(state){
		
			const CargoEditor = FRONTEND_DATA.contentWindow.CargoEditor;
			const {isApplied} = state;

			if(isApplied === false){

				// if we have a regular UL, just add a class to it
				if(FRONTEND_DATA.contentWindow.CargoEditor.queryCommandState('insertunorderedlist')) {

					CargoEditor.mutationManager.execute(function(){
						retrieveSequentualNodesEffectivelyContainedByRange('UL').forEach(list => list.classList.add('lineated'))
					});

				} else {

					CargoEditor.events.once('mutation-before-success', function(editor){
						_.each(editor.generateUnreportedMutationSummaries(), function(summary){
							_.each(summary.added, function(node){
								node.setSaveable(true);
								if(node.nodeName === "UL"){
									// add our class
									node.className = 'lineated'
								}
							});
						});
					});
					
					CargoEditor.execCommand('insertunorderedlist');

				}

			} else {

				CargoEditor.execCommand('insertunorderedlist');

				const range = CargoEditor.getActiveRange();
				CargoEditor.mutationManager.execute(function(){
					
					const start = range.startContainer,
						end = range.endContainer
					const nodes = CargoEditor.helpers.getAllEffectivelyContainedNodes(range, function(node){
						return node.nodeName === "DIV";
					});

					_.each(nodes, function(node){
						CargoEditor.helpers.removePreservingDescendants(node, range);
					});

					removeParentsWithGivenNodeName(range.commonAncestorContainer, node => node.nodeName === "DIV" && node.classList.contains('lineated'), range);
					removeParentsWithGivenNodeName(start, node => node.nodeName === "DIV" && node.classList.contains('lineated'), range);
					removeParentsWithGivenNodeName(end, node => node.nodeName === "DIV" && node.classList.contains('lineated'), range);

					CargoEditor.helpers.setActiveRange(range);

				});

			}

		}

	},

	getState: (range) => {
		const nodes = retrieveSequentualNodesEffectivelyContainedByRange('UL');
		let foundMatch = false;
		let index = 0;
		while(!foundMatch && nodes[index]){
			let node = nodes[index]
			if(node.nodeType === Node.ELEMENT_NODE && node.nodeName === 'UL') {
				if(node.classList.contains("lineated")) {
					foundMatch = true;
				}
			}
			index++;
		}
		return {
			isAllowed: rangeCanBeModifiedByTextCommands(range),
	 		isApplied: foundMatch
	 	}
	},	
	priority: 1
 
}