import { Field } from 'formik';
import { RangeExtension } from './range-extension';
import { Scrubber } from './scrubber';
import { TextButton } from './text-button';
import { useState, useEffect } from 'react';
import { DetailsMenu } from './details-menu';
import { blurSliderDefaults } from '../../defaults/scrubber-defaults';
import ColorPickerField from './color-picker-field'
import { MoreActions } from '@cargo/ui-kit';
import { CheckBox } from "../ui-kit";
import { processCSSValue } from './helpers';
import { has } from 'lodash';

export const BackdropFilter = ({allowedProperties = ['blur'], ...props}) => {

    const [ backdropFilterValues, setBackdropFilterValues ] = useState(null);

    const findClosingBracket = (str, pos) => {
		const rExp = /\(|\)/g;
		rExp.lastIndex = pos + 1;
		var deep = 1;
		while ((pos = rExp.exec(str))) {
		  if (!(deep += str[pos.index] === "(" ? 1 : -1 )) { return pos.index }
		}
	}

	const getBackdropFilterValues = () => {
        const backdropFilterValues = {};
		if (props.field.value) {
			// Parse the backdrop-filter string to get the properties and their values
            const backdropFilterParts = props.field.value.split(' ');
            backdropFilterParts.forEach((part) => {
                let [key, value] = part.split('(');
                if (!value) {
                    return;
                }
                // Remove the last character from the value, which is the closing bracket
                value = value.substring(0, value.length - 1);
                backdropFilterValues[key] = value;
            });
		}
        return backdropFilterValues;
	}

    useEffect(() => {
        setBackdropFilterValues(getBackdropFilterValues());
    }, [props.field.value])


    const handleChange = (name, value) => {
        const prevObj = getBackdropFilterValues();
        const newObj = {
            ...prevObj,
            [name]: value
        }

        const cssValue = processCSSValue(value);
        if (cssValue[0] === 0) {
            removeProperty(name);
            return;
        }
        const newBackdropFilter = Object.keys(newObj).map((key) => {
            return `${key}(${newObj[key]})`;
        }).join(' ');
        props.form.setFieldValue(
            props.field.name, 
            newBackdropFilter,
            false
        );
    }

    const removeProperty = (property) => {
        if (!props?.field?.value?.includes(property)) {
            return;
        }
        const start = props.field.value.indexOf(`${property}(`) + property.length + 1;
        const end = findClosingBracket(props.field.value, start);
        const pattern = props.field.value.substring(start - 12, end + 1);
        let newBackdropFilter = props.field.value.replace(pattern, '');
        // If new backdrop filter is empty, set to null
        if (newBackdropFilter === '') {
            newBackdropFilter = null;
        }
        props.form.setFieldValue(
            props.field.name,
            newBackdropFilter,
            false
        );
    }

    const hasPropertyStyles = (property) => {
        let hasPropertyStyles = false;
        if (props.field.value && props.field.value.includes(property)) {
            hasPropertyStyles = true;
        }
        return hasPropertyStyles;
    };

    return (
        <>
            {allowedProperties.includes('blur') && (
                <Scrubber
                    className={props.className}
                    label={props.prefix ? `${props.prefix} Blur` : 'Blur'}
                    field={{
                        name: "blur",
                        value: backdropFilterValues ? backdropFilterValues['blur'] : null,
                    }}
                    disabled={props.disabled}
                    form={{
                        setFieldValue: (name, value) => handleChange(name, value),
                        setFieldTouched: () => {}
                    }}
                    {...blurSliderDefaults} 
                />
            )}
            {allowedProperties.includes('grayscale') && (
                <Scrubber
                    label={props.prefix ? `${props.prefix} Grayscale` : 'Grayscale'}
                    field={{
                        name: "grayscale",
                        value: backdropFilterValues ? backdropFilterValues['grayscale'] : null,
                    }}
                    addDefaultUnitToUnitlessNumber={true}
                    defaultUnit="%"
                    disabled={props.disabled}
                    suffix="%"
                    min={0}
                    max={100}
                    step={1}
                    form={{
                        setFieldValue: (name, value) => handleChange(name, value),
                        setFieldTouched: () => {}
                    }}
                />
            )}
            {/* <Scrubber
                label={props.prefix ? `${props.prefix} Brightness` : 'Brightness'}
                field={{
                    name: "brightness",
                    value: backdropFilterValues ? backdropFilterValues['brightness'] : null,
                }}
                addDefaultUnitToUnitlessNumber={true}
                disabled={props.disabled}
                defaultUnit="%"
                suffix="%"
                min={0}
                max={200}
                step={1}
                form={{
                    setFieldValue: (name, value) => handleChange(name, value),
                    setFieldTouched: () => {}
                }}
            /> */}
            {allowedProperties.includes('invert') && (
            <CheckBox
                label={props.prefix ? `${props.prefix} Invert` : 'Invert'}
                field={{
                    name: "invert",
                    value: backdropFilterValues && backdropFilterValues['invert'] === '100' ? true : false,
                }}
                form={{
                    setFieldValue: (name, value) => {
                        if (value === true) {
                            handleChange(name, 100)
                        } else {
                            handleChange(name, 0)
                        }
                    },
                    setFieldTouched: () => {}
                }}
                disabled={props.disabled}
            />
            )}
        </>
    )
}