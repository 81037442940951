import React, { Component, useEffect, useState } from 'react';
import uniqueId from 'lodash/uniqueId';

import { HotKeyProxy } from "../ui-kit";

import { ProcessingAnimation } from '@cargo/ui-kit';

// This component is duplicated becuase it uses hotkeys and alert contexts which are local to each project

export const RemoteAlert = (props) => {

	const { alert } = props;

	const [ showAlert, setShowAlert ] = useState(false);
	const [ message, setMessage ]     = useState(null);
	const [ htmlMessage, setHtmlMessage ]     = useState(null);
	const [ type, setType ]           = useState(null);

	useEffect(() => {

		function showMessage(e){
			setMessage( e.detail.message );
			setHtmlMessage( e.detail.htmlMessage );
		}

		document.addEventListener('open-remote-alert', showMessage)
	  return () => {
	  	// cleanup
	  	document.removeEventListener('open-remote-alert', showMessage);
	  };
	}, []);


	useEffect(() => {
		// If we've set a message string 
		if( message !== null ){
			// Trigger modal to be visible by rendering the "open modal" function.
			setShowAlert( true );
			return
		}
		// Else reset the modal by returning null. This lets it be called and recalled as needed.
		setShowAlert( false )
	}, [message])


	return showAlert ? (
		<>
			{
				setTimeout(() => {
					alert.openModal({
						header: message,
						htmlHeader: htmlMessage,
						type: 'notice',
						HotKeyProxy: HotKeyProxy,
						onConfirm: () => {
							setMessage(null)
						},
						onDeny: () => {
							setMessage(null)
						},
						onClose: () => {
							setMessage(null)
						},
					})
				}) && null // return null so we don't print the setTimeout ID to the DOM
			}
		</>
	) : ( null )
	
};