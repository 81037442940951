import React, { Component } from 'react';
import { FRONTEND_DATA } from "../../globals";
import { Formik, Field, Form } from 'formik';

import { getRandomColor, getColorString, setColorPickerPosition } from "./helpers"

import _ from 'lodash';

import { Button } from '@cargo/ui-kit';
import ColorPickerModal from "./color-picker-modal";

import tinycolor from "tinycolor2";

export class ColorPicker extends React.Component {

    //Color Picker Plugin: https://casesandberg.github.io/react-color/
    constructor(props) {
        super(props);

        let value = this.props.field.value && this.props.field.value !== 'initial' ? this.props.field.value : 'rgba(0,0,0,0)';
        this.isDefaultedColor = this.props.field.value ? false : true;
        let colorString = null;

        colorString = tinycolor( value ).toRgbString();

        this.state = {
            colorString: colorString,
            color: {},
            modalActive: this.props?.modalActive ? true : false,
            positionClass: null,
            isMouseDown: false,
            colorFormat: null,
        };

        this.buttonRef = React.createRef();
        this.menuRef   = React.createRef();

        this.firstChange = true;

    }

    openModal = (e) => {
        if( e?.target.closest('.color-picker') || e?.target.closest('.input-field') ) return
        if( this.props.openTabModal ){
            this.props.openTabModal(e)
        }
   }

    closeModal = (e) => {
    
        if( e?.target.closest('.picker-toggle') ){ return }

        this.setState({ modalActive: false });

        if( this.props.closeUIWindow ) {
            // Close modal if in UI window
            this.props.closeUIWindow();
        }

        if( this.props.closeTabModal ){
            // Close Modal if in Tab container
            this.props.closeTabModal();
        }
    }

    setPickerFormat = ( format ) => {
        this.setState({ colorFormat: format })
    }

    handleChange = (newColor, format) => {
        if( this.firstChange && this.props.displayPickerField ){
            // Prevent setup of visible color picker row from updating CRDT on component mount.
            this.firstChange = false;
            return
        }

        const newColorObj = tinycolor( newColor.rgb );
        let colorString = null;

        if( this.state.colorFormat ){
            // use helper function to determine formatting based off format string
            colorString = getColorString( newColorObj, this.state.colorFormat );
        } else {
            // Otherwise decide between RGBA or HEX dependant on opacity
            colorString = newColorObj._a === 1 ? newColorObj.toHexString() : newColorObj.toRgbString();
        }

        const {field, form} = this.props;

        if ( form && colorString ) {
            // Set field value in Formik
            if( this.state.colorString !== colorString ){
                form.setFieldValue(field.name, colorString, false)
            }
            
        }

        // let state take over
        this.setState({
            color: newColor,
            colorString: colorString
        })

    };

    render() {

        return (
            <>
                <ColorPickerModal 

                    colorString   = { this.state.colorString } 
                    onChange      = { this.handleChange } 

                    openModal     = { this.openModal } 
                    closeModal    = { this.closeModal } 

                    modalActive   = { this.state.modalActive } 

                    colorFormat     = { this.state.colorFormat }
                    setPickerFormat = { this.setPickerFormat }

                    isCustomSwatch = { this.props.isCustomSwatch }

                    menuRef       = { this.menuRef }

                    {...this.props} 
                />
            </>
        );
    }

    componentDidUpdate(prevProps, prevState ){

        // update color string so that color picker is correct when it inits
        if (this.props.field.value != prevProps.field.value){
            const colorObj = tinycolor(this.props.field.value);
            this.setState({colorString: colorObj.toRgbString()})           
        }

    }


    componentDidMount(){

        const colorObj = tinycolor(this.props.field.value);
        if ( colorObj.isValid() ){
            this.setState({
                colorString: colorObj.toRgbString(),
                modalActive: this.props.modalActive ? true : false
            })            
        }

    }

}


