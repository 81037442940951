import React, { Component } from 'react';
import { Formik, Field } from 'formik';
import { connect } from 'react-redux';
import { actions } from "../../actions";
import { createSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import FormikChangeListener from "./formik-change-listener";
import { TextButton, Scrubber } from "./";
import { Button, MoreActions, ResetButton } from '@cargo/ui-kit';
import { CheckBox, RangeExtension, DetailsMenu, Filter, MoreButton } from "../ui-kit";
import VariableFontFamilyIcon from "../../svg-icons/typography-variable-font-family.svg";
import ColorField from "./color-picker-field";
import { globalBorderDefaults } from '../../defaults/border-defaults';
import blendModes from '../../defaults/blend-modes';

import { processCSSValue, addNonMobileInheritanceToPropWatcherConfig, handleBasicMultiScrubber, handleMultiScrubber, isPropWatcherSetLocally, getCustomFonts, shouldPropigateCustomFontFetch, capitalize, getFontSpriteSrc, enforceSuperFamilyClipping } from "./helpers";
import { deriveVariantFromCSS, createFVSPropertyMap, removeDiacritics, deriveFontModelFromCSS, getMatchingFVSVariant, getSuperFamilyVariant, generateFontChangeObj, deriveFontDisplayName } from "@cargo/common/font-helpers.js";

import { withPropWatchers } from "../../lib/shared-css";
import { TextStyleDefaults } from "../../defaults/text-style-defaults";
import { fontSizeDefaults, lineHeightDefaults, insetSliderDefaults } from "../../defaults/scrubber-defaults";
import TypographyAaIcon from "../../svg-icons/typography-Aa.svg";
import { MenuContext } from "@cargo/common/context-menu";
import TypographyMenuContextUI from "../right-menu-bar/typography-menu-context-ui";
import CodeViewFooterIcon from "../../svg-icons/code-view-footer.svg";
import { getFontSrc, parseFont, getAlternatesStrFromFeature, StylisticSetButton } from '../right-menu-bar/type-settings-glyphs'

import _ from 'lodash';

let fontSpriteHeight    = null; // cache of font img height
let spriteLoadingStart  = false;
let spriteLoadComplete  = false;

const preloadImage = new Image();
preloadImage.setAttribute('decoding', 'sync');

const loadAndCacheSprite = () => {
	return new Promise(resolve => {
		spriteLoadingStart = true;

		preloadImage.src = getFontSpriteSrc();

		preloadImage.decode().then(result=>{
			fontSpriteHeight = preloadImage.naturalHeight / 2
			spriteLoadComplete = true;
			resolve();
		}).catch(e => {
			console.error(e);
		});

	});
}

let isLoadingFontCollection = false;

class TypographyMenuComponent extends Component {

	constructor(props) { 
		super(props);
		this.containerRef = React.createRef();
		this.state = {
			features: {},
			parsed: null,
		}
	}

	borderStyles = [
		{
			value: 'solid',
			label: 'Solid',
		},
		{
			value: 'dotted',
			label: 'Dotted',
		},
		{
			value: 'dashed',
			label: 'Dashed',
		},
		{
			value: 'double',
			label: 'Double',
		},
		{
			value: 'groove',
			label: 'Groove',
		},
		{
			value: 'ridge',
			label: 'Ridge',
		},
		{
			value: 'inset',
			label: 'Inset',
		},
		{
			value: 'outset',
			label: 'Outset',
		},
	]

	async refreshParsedFont () {
		this.setState({
			features: {},
			parsed: null,
		});
		let fontSrc = null;
		let parsed = null;
		try {
			fontSrc = await getFontSrc({
				fontFamily: this.props.propWatcherValues[this.props.selector]['font-family'],
				fontWeight: this.props.propWatcherValues[this.props.selector]['font-weight'],
				fontStyle: this.props.propWatcherValues[this.props.selector]['font-style'],
			
			});
		} catch (e) {
			console.error('Error fetching getting font src', e);
		}

		if (!fontSrc) {
			this.setState({
				features: {},
				parsed: null,
			});
			return;
		}

		try {
			parsed = await parseFont(fontSrc);
		} catch (e) {
			console.error('Error parsing font', e);
		}

		if (!parsed) {
			this.setState({
				features: {},
				parsed: null,
			});
			return;
		}
		
		const features = {};
        if (parsed?.tables?.gsub?.features) {
            for (const feature of parsed.tables.gsub.features) {
				if (feature.tag.startsWith('ss') || feature.tag.startsWith('cv')) {
					if (features[feature.tag]) {
						continue;
					}
					features[feature.tag] = {
						alternatesStr: getAlternatesStrFromFeature(feature, parsed, fontSrc),
					}
				} else {
					continue;
				}
            }
        }
		this.setState({
			features: features,
			parsed,
		})
	}

	componentDidUpdate(prevProps, prevState) {
		// If the font family changed, update the features
		if (this.props.selector !== prevProps.selector || this.props.propWatcherValues?.[this.props.selector]?.['font-family'] !== prevProps.propWatcherValues?.[this.props.selector]?.['font-family'] || this.props.propWatcherValues?.[this.props.selector]?.['font-weight'] !== prevProps.propWatcherValues?.[this.props.selector]?.['font-weight'] || this.props.propWatcherValues?.[this.props.selector]?.['font-style'] !== prevProps.propWatcherValues?.[this.props.selector]?.['font-style']) {
            const isVariable = this.props.propWatcherValues?.[this.props.selector]?.['font-variation-settings'] ? true : false;
            if (isVariable) {
                if (this.props.propWatcherValues?.[this.props.selector]?.['font-family'] !== prevProps.propWatcherValues?.[this.props.selector]?.['font-family']) {
                    this.refreshParsedFont();
                }
            } else {
                this.refreshParsedFont();
            }
        }
	}


	componentDidMount(){

		this.refreshParsedFont();

		const shouldFetchCustomFonts = shouldPropigateCustomFontFetch();
		if( ( !this.props.fontCollectionLoaded && !isLoadingFontCollection && !this.props.fetchingFontCollection ) || shouldFetchCustomFonts ) {
			// argument skips processing of collection in favor of waiting for the custom collection to be fetched to avoid 2x processing
			this.props.fetchFontCollection( true ).then((res) => {
				
				if( res.status === 200 ){

					this.props.fetchCustomFontCollection( res.data ).then(() => { 
						isLoadingFontCollection = false;
					});

				} else {
					console.warn('Error loading Cargo fonts, custom fonts request will not be made.');
					this.props.fetchFontCollection();
					isLoadingFontCollection = false;
				}
			});
			isLoadingFontCollection = true;
		}

		if( !fontSpriteHeight && !spriteLoadingStart ){
			loadAndCacheSprite().then(() => {
				this.backgroundSize = '372px '+fontSpriteHeight+'px';
			});

		} else {
			this.backgroundSize = '372px '+fontSpriteHeight+'px';
		}

		if(this.containerRef.current) {
			this.containerRef.current.addEventListener('range-indicator-double-click', this.onRangeIndicatorDoubleClick)
		}

	}

	componentWillUnmount = () => {

		// Close the type settings glyphs window if it's open
		this.props.removeUIWindow(uiWindow => { 
			return uiWindow.id === 'type-settings-glyphs'
		});

		if(this.containerRef.current) {
			this.containerRef.current.removeEventListener('range-indicator-double-click', this.onRangeIndicatorDoubleClick)
		}

	}

	onRangeIndicatorDoubleClick = event => {

		// only reset local styles on double clicking the range indicator
		if(this.props.isLocal && event.detail.name) {

			console.log('Double click to reset local styles is not implemented for this component yet.')

		}

	}

	magnetizeLetterSpacingToZero = ( value, step, percentage ) => {

		if( percentage < 0.33 && percentage > 0.26  ){
			step = 0.01;
		}

		if( percentage > 0.33 && percentage < 0.4 ){
			step = 0.01;
		}

		var inv = 1.0 / step;

		return Math.round(value * inv) / inv;

	}

	// changes the 'font-name' attribute if a superfamily font is matched
	findSuperFamiliesOnChange = ( newFvsString, fontWeight ) => {

		let familyModel = this.currentFamilyModel;
		let collection  = this.props.fontCollection.collection;

		if( !collection ){ return }
		// If we have a model, it's an FVS model, and we don't have a matching variant
		// check to see if the model is within a super family
		if( familyModel && familyModel.variable ){
			// variants, fvsInCSS, weightInCSS
			let currVariant = getMatchingFVSVariant(familyModel.variants, newFvsString, fontWeight )
			if( !currVariant ){

				const superFamiliesObj = this.props.fontCollection.variableSuperFamilies;
				let superFamilyName = null;

				let parentSuperFamily = _.find(superFamiliesObj, function(family, familyName){
					let modelName = familyModel?.family;
					if( modelName.match(familyName) ){
						superFamilyName = familyName;
						return familyName
					}
				});
				

				if( parentSuperFamily && superFamilyName ){

					let superFamilyObj = getSuperFamilyVariant( collection, superFamilyName, parentSuperFamily, newFvsString, fontWeight );

					if( superFamilyObj ){
						let index = superFamilyObj.model.variants.indexOf( superFamilyObj.variant );
						let changeObj = generateFontChangeObj( superFamilyObj.model.variants, index, this.props.selector);
					
						return changeObj;
					}
				}
			}

		}

		return null;

	}

	openFontPicker = e => {

		let buttonOffset  = e.currentTarget.getBoundingClientRect();

		const {
			family,
			variant,
			style,
			weight,
			fvs,
			familyInCSS
		} = this.getCurrentFontSettings();

		this.props.addUIWindow({
			group: 'typography-modal',
			component: import('./font-picker'),
			id: 'font-picker',
			props: {
				windowName: 'font-picker',
				type: 'typography-modal', 
				autoHeight: false,
				allowScroll: true,
				selector: this.props.selector, //bodycopy, h1, h2, small
				scope: this.props.type, // global, local, etc...
				onFontSelect: ( changes ) => {
					this.onChange( changes )
				},
				dataVariant      : variant ?? null,
				family           : family ?? null,
				variant          : variant?.style ?? null,
				rawCSSInfo       : {
					family: family,
					style:style, 
					weight:weight,
					fvs: fvs
				},
				fontSpriteHeight : fontSpriteHeight,
				closeOnSingleClickout: true,
				invokeTarget: e.currentTarget,
				invokeWindow: e.currentTarget.closest('.uiWindow').getAttribute('window-id'),
				positionType: 'from-button',
				buttonPos: _.merge({}, buttonOffset),
				getCurrentFontSettings: this.getCurrentFontSettings,
				buttonOffset: {
					top: 25,
					right: -68
				},
			} 
		});
	};
	
	hasLocalValue(prop, selector) {
		const watcher = _.get(this.props.propWatcherMap, [selector, prop]);
		return watcher?.parsedValue !== null && watcher?.parsedValue?.content !== '';
	}

	openAdvancedOptionsWindow = (e) =>{

		e.preventDefault();
		const buttonPos = e.target.closest('.uiWindow').getBoundingClientRect();

		this.props.addUIWindow({
			group: 'right-menu-bar',
			component: import('../right-menu-bar/type-settings-glyphs'),
			id: `type-settings-glyphs`,
			props: {
				type: 'popover', 
				selector: this.props.selector,
				positionType: 'from-button', 
	            buttonPos: {
	                top: buttonPos.y+20,
	                y: buttonPos.y+15,
	                x: buttonPos.x-42,
	                left: buttonPos.left-135,
	                height: 0,
	                width: 0,
	                bottom: buttonPos.y+20,
	            }, 
				windowName: 'type-settings-glyphs',
				closeButton: true,
				ignoreClickout: true,
				closeOnSingleClickout: true,
				invokeTarget: e.currentTarget,
				invokeWindow: 'page-settings-window-controller',
				waitForHeightBeforeRender: true,
				minimumRenderHeight: 100,
			}
		},{
			removeGroup: false,
		});		

	}

	render() {

		// Uses Formik's Field component to display a value as it updates. 
		const FormikDisplay = ({field}) => { 
			if(field){
				let detailsNum = null;
				if( field.value ){
					detailsNum = field.value.replace(/[^\d.-]/g, '');
					return ( <> {detailsNum} </> );
				}
				// If we don't have a field value, return an M dash.
				return ( <>—</>);
				
			};
		};

		let variant = null;
		let familyModel = null;
		let superFamilyName = null;

		const fontPreviewStyles = this.getCurrentFontSettings();

		if( this.props.fontCollection ){

			let normalizedFamilyName = removeDiacritics( fontPreviewStyles?.family );
 			
 			familyModel = deriveFontModelFromCSS(
 				this.props.fontCollection.collection,
 				normalizedFamilyName
 			);

			// Some fonts are variable, but only have a weight axis, which doesn't get written to the CSS as a font variation setting.
			// e.g. "Marist"
			if( !fontPreviewStyles?.fvs && familyModel?.variable ){
				const hasWeightFvs = familyModel.axes.some(obj => obj.css_fvs === "wght");
				if( familyModel.axes.length === 1 && hasWeightFvs ){
					fontPreviewStyles.fvs = `'wght' ${fontPreviewStyles.weight}`;
				}
			}

			// Get variant from collection using family name, style and weight
			variant = deriveVariantFromCSS( 
				this.props.fontCollection.collection, 
				fontPreviewStyles?.family, 
				fontPreviewStyles?.style, 
				fontPreviewStyles?.weight, 
				fontPreviewStyles?.fvs,
				fontPreviewStyles?.familyInCSS
			);

			this.currentFamilyModel = familyModel;
		}

		const axisMap = fontPreviewStyles.fvs ? createFVSPropertyMap( fontPreviewStyles.fvs, fontPreviewStyles.weight ) : null;

		// Strip "Variable" from font name in preview
		let previewName = deriveFontDisplayName(this.props.fontCollection, fontPreviewStyles);
		// let isDefault = _.filter(TextStyleDefaults, textStyle => textStyle.name === this.props.selectorReadable).length !== 0;
		// let writeableStyles = { ...fontPreviewStyles };
		// writeableStyles.fontFamily = writeableStyles['fontFamily']?.replace(/[;]$/,'');

		// let fontSizeUnit = 'rem';

		let axesArr = familyModel?.axes ? familyModel?.axes : [];
		// separate out the axis values from the 'font-variation-settings' string in the CSS
		let axisValues = {};

		_.each(axesArr, (axis, i) => {
			let isReverseRange = axis.min < 0 && axis.max <= 0;
			axisValues[`${"axis-" + axis.css_fvs + (isReverseRange ? '-reversed' : '')}`] = 
				isReverseRange ? Math.abs(axisMap?.[axis.css_fvs]) : 
				axisMap?.[axis.css_fvs];
		})

		let isVariable = axesArr.length > 0;
		let isVariablePreset = false;

		if( isVariable ){
			// If we have a variable font, check to see if we have all the axes we need.
			// These sliders will be rendered, but the axisMap wont contain a value
			// The scrubber will register NaN in this case, which isn't ideal.
			let missingValues = axesArr.filter(item => axisMap && !axisMap?.hasOwnProperty(item.css_fvs));
			// Add the missing values to the axisValues object. This won't write anything to the CSS
			// but it will give the scrubber somewhere to start.
			if( missingValues.length > 0 ){
				console.log("Missing font variation setting for Scrubber from CSS", missingValues);
				
				missingValues.forEach(axis => {
					let isReverseRange = axis.min < 0 && axis.max <= 0;

					// if( familyModel && familyModel.family.includes('Diatype') && axis.title === 'Width' ){
					// 	// Hack for DIATYPE variable font. It starts at width 100 by default. 
					// 	// We currently have no way to indicate the default value for a variable font axis.
					// 	// This is the only example that starts at max but isn't a reverse range so far.
					// 	axisValues[`${"axis-" + axis.css_fvs}`] = axis.max;
					// } else 
					
					if( familyModel ){
						axisValues[`${"axis-" + axis.css_fvs + (isReverseRange ? '-reversed' : '')}`] = 
						isReverseRange ? axis.max : axis.min;
					}
				});
			}
		}

		// If we can't find an exact FVS match and we're looking at a variable font, append "Variable" to the end of the previewed font name
		if( variant?.name && isVariable ){
			isVariablePreset = true;
		}

		if( previewName.length === 0 && fontPreviewStyles?.family ){
			previewName = fontPreviewStyles.family
		}

		let underlineLinks = this.props.propWatcherValues[this.props.selector + ' a']['text-decoration'] === 'underline' ? true : false ;

		let displayBlock = this.props.propWatcherValues[this.props.selector]['display'] === 'inline-block' ? false : true ;
		
		// let hasUniqueMobileStyles = this.props.isMobile && this.props.hasStylesInSelector( this.props.selector );
		// let selectorInMap = this.props.propWatcherMap[this.props.selector]
		// let fontSizeInherits = this.props.isMobile && selectorInMap && selectorInMap['font-size']?.isInheriting;
		// if (fontSizeInherits) {
		// 	this.props.propWatcherValues[this.props.selector]['font-size'] = '100%';
		// }
		// 

		//HACK FOR GRAVITY VARIABLE NOT PROPERLY DISPLAYING AT MAX WDTH FVS VALUE
		let fvsSettingFastMode = undefined; // default is undefined
		if( familyModel && familyModel?.family === 'Gravity Variable' ){
			if( axisValues["axis-wdth"] >= 149.99 ){
				axisValues["axis-wdth"] = 150;
			}
			fvsSettingFastMode = 0.01;
		}
	
		//HACK FOR MONUMENT VARIABLES LIMITING AT 700 WGHT
		let enforceWeightMax = false;
		if( familyModel && ( familyModel?.family?.includes('Monument') ) ){
			enforceWeightMax = true;
		}
		if( familyModel && ( familyModel?.family?.includes('Diatype') ) ){
			if( !familyModel.family.includes('Compressed') && !familyModel.family.includes('Condensed') ){
				enforceWeightMax = true;
			} else {
				enforceWeightMax = false;
			}
		}
		if( enforceWeightMax ){
				axesArr.forEach((axis) => {
					if( axis.title === 'Weight' ){
						axis.max = 1000;
					}
				});
		}

		const fontFeatureSettingsValues = {};

		if (this.state.features) {
            for (const tag in this.state.features) {
                if (this.props.propWatcherValues?.[this.props.selector]?.['font-feature-settings']?.includes(tag)) {
                    fontFeatureSettingsValues[tag] = true;
                } else {
                    fontFeatureSettingsValues[tag] = false;
                }
            }
        }

		return( 

			<Formik
				// this is important for websocket updating
				enableReinitialize
				initialValues={{
					...this.props.propWatcherValues,
					underlineLinks: underlineLinks,
					displayBlock: displayBlock,
					...axisValues,
					...fontFeatureSettingsValues
				}}
			>
			{ props => {
				let fvsSelector = this.props.selector ? this.props.selector+'-font-variation-settings' : 'font-variation-settings';
				let fvsMapData = this.props.selector ? props.values?.[this.props.selector]?.['font-variation-settings'] : props.values['font-variation-settings'];
				const FVSmap = createFVSPropertyMap( fvsMapData );

				const hasBorderStyles = () => {
					let hasBorderStyles = false;
					const borderProperties = [
						'padding',
						'padding-top',
						'padding-left',
						'padding-bottom',
						'padding-right',
						'border-width',
						'border-top-width',
						'border-left-width',
						'border-bottom-width',
						'border-right-width',
						'border-radius',
						'border-top-left-radius',
						'border-bottom-left-radius',
						'border-bottom-right-radius',
						'border-top-right-radius',
						'border-color'
					];
					Object.keys(props.values[this.props.selector]).forEach((key) => {
						if (borderProperties.includes(key) && (props.values[this.props.selector][key] !== null && processCSSValue(props.values[this.props.selector][key])?.[0] !== 0) ) {
							hasBorderStyles = true;
						}
					});
					return hasBorderStyles;
				}

				const hasFilterStyles = () => {
					let hasFilterStyles = false;
					Object.keys(props.values[this.props.selector]).forEach((key) => {
						if (key === 'filter' && props.values[this.props.selector][key] !== null ) {
							hasFilterStyles = true;
						}
					});
					return hasFilterStyles;
				}

				const hideBorderPaddingScrubber = () => {
					let hideBorderPaddingScrubber = false;
					const borderPaddingSides = [
						'padding-top',
						'padding-right',
						'padding-bottom',
						'padding-left'
					];
					borderPaddingSides.forEach((side) => {
						if (props.initialValues?.[this.props.selector]?.[side] !== props.initialValues?.[this.props.selector]?.['padding']) {
							hideBorderPaddingScrubber = true;
						}
					});
					return hideBorderPaddingScrubber;
				}
				
				const hideBorderWidthScrubber = () => {
					let hideBorderWidthScrubber = false;
					const borderWidthSides = [
						'border-top-width',
						'border-right-width',
						'border-bottom-width',
						'border-left-width'
					];
					borderWidthSides.forEach((side) => {
						if (props.initialValues?.[this.props.selector]?.[side] !== props.initialValues?.[this.props.selector]?.['border-width']) {
							hideBorderWidthScrubber = true;
						}
					});
					return hideBorderWidthScrubber;
				}

				const hideBorderRadiusScrubber = () => {
					let hideBorderRadiusScrubber = false;
					const borderRadiusCorners = [
						'border-top-right-radius',
						'border-bottom-right-radius',
						'border-bottom-left-radius',
						'border-top-left-radius'
					];
					borderRadiusCorners.forEach((side) => {
						if (props.initialValues?.[this.props.selector]?.[side] !== props.initialValues?.[this.props.selector]?.['border-radius']) {
							hideBorderRadiusScrubber = true;
						}
					});
					return hideBorderRadiusScrubber;
				}

				return(
				<>

					<FormikChangeListener onChange={this.onChange}/>

					<div 
						ref={this.containerRef}
						className="ui-group"
					>
						
						<div className="grid-columns-auto-square">
							{!this.props.isMobile ?
								<Button 
									className={`font-preview${ this.props.isLocal ? ' disabled' : ''}${ isVariable ? ' variable' : ''}${ isVariablePreset ? ' preset' : ''}`}
									onMouseDown={ this.openFontPicker }
									icon={ isVariable ? <VariableFontFamilyIcon/> : <TypographyAaIcon/> }
								>
									<div className="preview-field">
										{previewName}
									</div>
								</Button>
							:
								<>
									<ResetButton 
										isOverriding={ ( this.hasLocalValue('font-size', this.props.selector) || this.hasLocalValue('color', this.props.selector) || this.hasLocalValue('background', this.props.selector) ) && this.props.isMobile } 
										overrideReset={()=> { 
											props.setFieldValue([this.props.selector, 'font-size'], null) 
											props.setFieldValue([this.props.selector, 'color'], null) 
											props.setFieldValue([this.props.selector, 'background'], null) 
										}} 
									/>
									<Field 
										component={Scrubber}
										label="Font Size"
										step={{
											em: 0.05,
											rem: 0.05,
											percent: 1,
										}}
										name={[this.props.selector, 'font-size']}
										{...fontSizeDefaults}
										// { ...{...fontSizeDefaults, ...{
										// 	defaultUnit: (this.props.isMobile ? '%' : 'rem'),
										// 	addDefaultUnitToUnitlessNumber: (this.props.isMobile ? false : true)
										// } }}
									/>
								</>
							}

							{this.props.selector !== 'bodycopy' ? (

								<Field
									component={ ColorField }
									tabs = {[
										{
											name      : [this.props.selector, 'color'],
											label     : 'Text',
										}, 
										{
											name      : [this.props.selector, 'background'],
											label     : 'Background',
										}
									]}
									sessionStorage = {'typography-colorpicker'}
									name = {[this.props.selector, 'color']}
									type = 'swatch'
									synced={false}
								/>

							):(

								<Field
									component={ ColorField }
									name={[this.props.selector, 'color']}
									type = 'swatch'
									invokeWindow="page-settings-window-controller"
									sessionStorage = {'typography-colorpicker'}

								/>

							)} 

						</div>

						
						{/* these fields are wrapped in [''] tags so that Formik does not interpret the [] brackets in selectors:  https://formik.org/docs/guides/arrays */}
						{!this.props.isMobile ?
							<Field 
								component={Scrubber}
								label="Font Size"
								name={[this.props.selector, 'font-size']}
								min={{
									px: 6,
									etc: 0.5
								}}
								step={{
									em: 0.05,
									rem: 0.05,
								}}
								allowOutOfRangeTextInput={false}
								addDefaultUnitToUnitlessNumber={true}
								defaultUnit="rem"
								value="0"
							/>
						: null }

						{!this.props.isMobile && <>
							<Field 
								component={Scrubber}
								label="Line Height"
								name={[this.props.selector, 'line-height']}
								value={1}
								{...lineHeightDefaults}
							/>

							<Field 
								component={Scrubber}
								label="Letter Spacing"
								name={[this.props.selector, 'letter-spacing']}
								value={1}
								min={{
									rem: -.15,
									em: -.15,
									px: -3,
									etc: 0,
								}}
								step={{
									em: 0.001,
									rem: 0.001,
								}}
								cssTestProperty="letter-spacing"
								allowOutOfRangeTextInput={false}
								addDefaultUnitToUnitlessNumber={true}
								defaultUnit="em"
							/>

							{/* Font Variation Settings */}
							{ isVariable && axesArr[0] && this.props.variableOptionsRevealed === true ? 
								<>
									{ axesArr.map((axis, i) => {
										
										if( props.values["axis-" + axis.css_fvs ] === undefined && 
											props.values["axis-" + axis.css_fvs + '-reversed'] === undefined
										){ return null }

										let isReverseRange = axis.min < 0 && axis.max <= 0;
										// skip the first one
										if( axis.css_fvs === 'wght' ){
					
											return (
												<Field 
													component={ Scrubber }
													className={`variable-font-range`}
													key={axis.title+i}
													label={ axis.title }
													name={"axis-" + axis.css_fvs }
													min={ isReverseRange ? axis.max : axis.min }
													max={ isReverseRange ? Math.abs(axis.min) : axis.max }
													step={ axis.step }
													numberOnlyMode={true}
												/>
											) 

										} else {	

											// console.log('else', FVSmap, axis, axis.css_fvs)

											//if( !FVSmap[axis.css_fvs] && FVSmap[axis.css_fvs] !== 0 ){ return( null ) }

											if( //HACK FOR GRAVITY VARIABLE NOT PROPERLY DISPLAYING AT MAX WDTH FVS VALUE
												familyModel 
												&& familyModel.family === 'Gravity Variable' 
												&& axis.title === 'Width'
												&& axis.max > 149
											){
												axis.max = 150;
												if( axisValues["axis-" + axis.css_fvs] > 149.99 ){
													axisValues["axis-" + axis.css_fvs] = 150;
												}
											}

											if( familyModel 
												&& familyModel.family === 'Gravity Variable' 
												&& axis.title !== 'Width'){
													fvsSettingFastMode = undefined;
												}

											return (
												<Field 
													component={ Scrubber }
													className={`variable-font-range`}
													key={axis.title+i}
													label={ axis.title }
													name={"axis-" + axis.css_fvs + (isReverseRange ? '-reversed' : '')}
													value={ FVSmap[axis.css_fvs] }
													min={ isReverseRange ? axis.max : axis.min }
													max={ isReverseRange ? Math.abs(axis.min) : axis.max }
													step={ axis.step }
													numberOnlyMode={true}
													fastPixelsPerDelta={fvsSettingFastMode}
												/>
											) 

										}
										
									}) }

								</>

							: (null) }
						</>}

						{this.props.isMobile === false && this.state.features && Object.keys(this.state.features).length > 0 ? (
							<>
								<div className="stylistic-set-buttons">
									{Object.keys(this.state.features).reduce((acc, curr) => {
										const feature = this.state.features[curr];
										feature.tag = curr;
										if (feature.alternatesStr === null || feature.alternatesStr === '') {
											return acc;
										}
										if (acc.length === 0) {
											acc.push(feature);
											return acc;
										}
										if (acc.find(item => item.alternatesStr === feature.alternatesStr)) {
											return acc;
										}
										acc.push(feature);
										return acc;
									}, []).map((feature, i) => {
										if (i < 7) {
											return (
												<Field 
													key={feature.tag}
													component={StylisticSetButton}
													name={feature.tag} 
													label={feature.alternatesStr}
												/>
											)
										}
										if (i === 7) {
											return (
												<StylisticSetButton
													label={<svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="2.27979" cy="1.67261" r="1.5" fill="black"></circle><circle cx="7.27979" cy="1.67261" r="1.5" fill="black"></circle><circle cx="12.2798" cy="1.67261" r="1.5" fill="black"></circle></svg>}
													key="more"
													onClick={(e) => {
														const buttonPos = e.target.closest('.uiWindow').getBoundingClientRect();
														this.props.addUIWindow({
															group: 'right-menu-bar',
															component: import('../right-menu-bar/type-settings-glyphs'),
															id: `type-settings-glyphs`,
															props: {
																type: 'popover', 
																selector: this.props.selector,
																positionType: 'from-button', 
																buttonPos: {
																	top: buttonPos.y+20,
																	y: buttonPos.y+15,
																	x: buttonPos.x-42,
																	left: buttonPos.left-135,
																	height: 0,
																	width: 0,
																	bottom: buttonPos.y+20,
																}, 
																windowName: 'type-settings-glyphs',
																closeButton: true,
																ignoreClickout: true,
																closeOnSingleClickout: true,
																invokeTarget: e.currentTarget,
																invokeWindow: 'page-settings-window-controller',
																waitForHeightBeforeRender: true,
																minimumRenderHeight: 100,
															}
														},{
															removeGroup: false,
														});		
													}}
													className="more"
												/>
											)
										}
										return null;
									})}
								</div>
							</>
						) : null}

						{ this.props.isMobile === false 
							// && this.props.moreOptionsRevealed 
						? (<>

							<div className="uiWindow-spacer"></div>

							<DetailsMenu 
								text="More Options"
								className={`bars${hasBorderStyles() || hasFilterStyles() ? ' in-use' : ''} more-options-menu`}
								sessionStorage={`ui-menu-textstyles-more-options`}
								noMargin={true}
								// open={hasBorderStyles()}
							>
								<div className="ui-group">

									<Button
										className={this.state.parsed === null ? 'disabled' : ''}
										onMouseDown={(e) => {
											this.openAdvancedOptionsWindow(e);
										}}
										label={<>Type Settings & Glyphs</>}
									/>

									<Field
										component={ ColorField }
										name={[this.props.selector + ' a', 'color']}
									label = 'Link Color'
										showRandom={false}
										invokeWindow="page-settings-window-controller"
										modalResetColor={ props.values[this.props.selector]['color'] ? props.values[this.props.selector]['color'] : null }
									/>

									<div className="grid-columns-auto-square">
										<Field 
											component={CheckBox} 
											name={`underlineLinks`} 
											label={'Underline Links'}
										/>
										{underlineLinks ?
										<Field
											component={ ColorField }
											name={[this.props.selector + ' a', 'text-decoration-color']}
											type = 'swatch'
											showRandom={false}
											invokeWindow="page-settings-window-controller"
											modalResetColor={ props.values[this.props.selector + ' a']['color'] ? props.values[this.props.selector + ' a']['color'] : null }
										/> : null }
									</div>

									<DetailsMenu 
										text="Border"
										className={`bars${hasBorderStyles() ? ' in-use' : ''}${this.props.selector === 'bodycopy' ? ' disabled' : ''}`}
										sessionStorage={`textstyles-border`}
										// open={hasBorderStyles()}
									>
										<div className="ui-group">
											<RangeExtension
												firstField= { 
													<Field
														name={[this.props.selector, 'border-width']}											
														component={Scrubber}
														label="Width"
														hideScrubberValue={ hideBorderWidthScrubber() }
														onMouseDown={this.hideSelectionColor}
														
														{...insetSliderDefaults}
													/>
												}
											>
												<Field name={[this.props.selector, 'border-top-width']} component={Scrubber} label="Width Top" {...insetSliderDefaults} onMouseDown={this.hideSelectionColor}  />
												<Field name={[this.props.selector, 'border-right-width']} component={Scrubber} label="Width Right" {...insetSliderDefaults} onMouseDown={this.hideSelectionColor}  />
												<Field name={[this.props.selector, 'border-bottom-width']} component={Scrubber} label="Width Bottom" {...insetSliderDefaults} onMouseDown={this.hideSelectionColor}  />
												<Field name={[this.props.selector, 'border-left-width']}  component={Scrubber} label="Width Left" {...insetSliderDefaults} onMouseDown={this.hideSelectionColor}  />
											</RangeExtension>

											<Field 
												component={ColorField}
												synced={false}
												tabs = {[
													{
														name      : [this.props.selector, 'border-color'],
														label     : 'Border',
													},
													{
														name      : [this.props.selector, 'background'],
														label     : 'Background',
													},
												]}
												label="Color"
												showRandom={true}
												showSecondaryAlways={true}
												onMouseDown={this.hideSelectionColor}
													
											/>

											<RangeExtension
												firstField= { 
													<Field
														name={[this.props.selector, 'border-radius']}											
														component={Scrubber}
														label="Radius"
														hideScrubberValue={ hideBorderRadiusScrubber() }
														onMouseDown={this.hideSelectionColor} 
															
														{...insetSliderDefaults} 
													/>
												}
											>
												<Field name={[this.props.selector, 'border-top-left-radius']} component={Scrubber} label="Radius Top Left" {...insetSliderDefaults} onMouseDown={this.hideSelectionColor}  />
												<Field name={[this.props.selector, 'border-top-right-radius']} component={Scrubber} label="Radius Top Right" {...insetSliderDefaults} onMouseDown={this.hideSelectionColor}  />
												<Field name={[this.props.selector, 'border-bottom-left-radius']} component={Scrubber} label="Radius Bottom Left" {...insetSliderDefaults} onMouseDown={this.hideSelectionColor}  />
												<Field name={[this.props.selector, 'border-bottom-right-radius']} component={Scrubber} label="Radius Bottom Right" {...insetSliderDefaults} onMouseDown={this.hideSelectionColor}  />
											</RangeExtension>

											<RangeExtension
												noMargin={true}
												firstField= { 
													<Field
														name={[this.props.selector, 'padding']}									
														component={Scrubber}
														label="Padding"
														hideScrubberValue={ hideBorderPaddingScrubber() }
														onMouseDown={this.hideSelectionColor}
															
														{...insetSliderDefaults} 
													/>
												}
											>
												<Field name={[this.props.selector, 'padding-top']} component={Scrubber} label="Padding Top" {...insetSliderDefaults} onMouseDown={this.hideSelectionColor}  />
												<Field name={[this.props.selector, 'padding-right']} component={Scrubber} label="Padding Right" {...insetSliderDefaults} onMouseDown={this.hideSelectionColor}  />
												<Field name={[this.props.selector, 'padding-bottom']} component={Scrubber} label="Padding Bottom" {...insetSliderDefaults} onMouseDown={this.hideSelectionColor}  />
												<Field name={[this.props.selector, 'padding-left']} component={Scrubber} label="Padding Left" {...insetSliderDefaults} onMouseDown={this.hideSelectionColor}  />
											</RangeExtension>

											{hasBorderStyles() ? (
												<MoreActions className="left">
													<TextButton
														className="code"
														onMouseDown={(e) => {
															this.props.queuePropwatcherChanges({
																[this.props.selector]: {
																	'border-radius': null,
																	'border-top-left-radius': null,
																	'border-top-right-radius': null,
																	'border-bottom-left-radius': null,
																	'border-bottom-right-radius': null,
																	'border-width': null,
																	'border-top-width': null,
																	'border-right-width': null,
																	'border-bottom-width': null,
																	'border-left-width': null,
																	'border-color': null,
																	'background-color': null,
																	'border-style': null,
																	'padding': null,
																	'padding-top': null,
																	'padding-right': null,
																	'padding-bottom': null,
																	'padding-left': null,
																},
															})
														}}
														label={<><em>×</em> Remove</>}
													/>
												</MoreActions>
											) : null}
										</div>
									</DetailsMenu>

									<Field 
										component={Filter}
										label=""
										name={[this.props.selector, 'filter']}
										sessionStorage={`typography-menu`}
										className={`${this.props.selector === 'bodycopy' ? ' disabled' : ''}`}
									/>

									{this.props.selector !== '.caption' ? (
										<Field 
											component={CheckBox} 
											name={`displayBlock`}
											label={'Display: Block'}
											className={`${this.props.selector === 'bodycopy' ? ' disabled' : ''}`}
										/>
									):null}

								</div>
							</DetailsMenu>

						</>) : null}

						{!this.props.isMobile && <MoreActions
							className={`${this.props.isMobile ? ('') : ('footer')}`}
						>
							<Button
								label={(<CodeViewFooterIcon />)}
								className={`code-window-button`}
								onMouseDown={(e) => {
									const buttonPos = e.currentTarget.getBoundingClientRect();

									const selector = this.props.selector;
									const defaultCSSString = `\n${selector} {\n}\n\n${selector} a {\n}`;

									this.props.addUIWindow({
										group: 'code',
										component: import('../right-menu-bar/css-selector-window'),
										id: 'css-selector-window',
										props: {
											windowName: 'css-selector-window',
											type: 'code-popover',
											positionType: 'over-button',
											autoHeight: true,
											buttonPos: _.merge({}, buttonPos),
											buttonOffset: {
												// top: -10,
												right: -410
											},
											defaultCSSString: defaultCSSString,
											allowScroll: true,
											closeButton: false,
											selector: selector, //bodycopy, h1, h2, small
											scope: this.props.type, // global, local, etc...
											closeOnSingleClickout: true,
											invokeTarget: e.currentTarget,
											invokeWindow: 'page-settings-window-controller'
										}
									});
								}}
							/>

							{/* {this.props.isMobile && hasUniqueMobileStyles ? */}
							{/* 		<TextButton  */}
							{/* 			className="reset"  */}
							{/* 			label={<><em>×</em> Reset</>} */}
							{/* 			onMouseDown={()=>{ */}
							{/* 				this.props.deleteStyleFromSelector(this.props.selector); */}
							{/* 			}} */}
							{/* 		/> */}
							{/* : (null)} */}

						</MoreActions>}

						{	// if is variable but not revealed
							// isVariable && axesArr.length > 1 && !this.props.variableOptionsRevealed &&
							// isVariable && axesArr.length > 1 &&
							// if is mobile and variable, but revealed, or mobile and not variable
							this.props.isMobile && ((isVariable && axesArr.length > 1) || !isVariable) ?
							<></>
						: null}


					</div>

				</>
				)}}
			</Formik>
		)

	}

	getExistingCSSProperties = (propertyString, selector) => {
		let properties = {};
		_.each(this.props.propWatcherValues[selector], (value, property)=>{
			if (property.match(propertyString) && value !== null && value !== undefined) {
				properties[property] = value;
			}
		})
		return properties;
	}

	getCurrentFontSettings = () => {

		const propertyValues = this.props.propWatcherValues[this.props.selector];
		// let collection = this.props.fontCollection?.collection;
		let familyInCSS = propertyValues['font-family'];
		let family     = _.first(propertyValues['font-family']?.split(','))?.trim()?.replace(/^"|"$/g, '');
		let style      = propertyValues['font-style'];
		let weight     = propertyValues['font-weight'];
		let fvs        = propertyValues['font-variation-settings'];

		if( fvs ){
			const fvsMap = createFVSPropertyMap( fvs );
			if( fvsMap['wght'] ){
				weight = fvsMap['wght'];
			}
		}

		if( !family ){
			console.log("Could not find family in prop watcher values", this.props.selector, this.props.propWatcherValues);

			// wait a microtick as to not set state in the middle of a render cycle
			Promise.resolve().then(() => {
				this.props.refreshTypographyMenus();
			});

		}

		let variant = deriveVariantFromCSS( this.props.fontCollection?.collection, family, style, weight, fvs, familyInCSS );

		return {
			family,
			variant,
			style,
			weight,
			fvs,
			familyInCSS
		}

	}

	onChange = (changes, form) => {

		let key = Object.keys(changes)[0];
		let reversedValue = false;
		const propertyValues = this.props.propWatcherValues[this.props.selector];
		let skipLetterSpacingReset = false;

		if (Object.keys(this.state.features).length > 0 && Object.keys(this.state.features).includes(key)) {
			let existingFontFeatureSettingsArr = this.props.propWatcherValues[this.props.selector]?.['font-feature-settings']?.split(',') || [];
			// Remove any whitespace from the existing font-feature-settings arr values
			existingFontFeatureSettingsArr = existingFontFeatureSettingsArr.map(item => {
				let newItem = item.replace(/\s/g, '');
				return newItem;
			});
			let newFontFeatureSettingsArr = [...existingFontFeatureSettingsArr];
	
			for (const tag in changes) {
				if (changes[tag] === true) {
					// If the tag is not already in the array, add it
					if (!newFontFeatureSettingsArr.includes(`"${tag}"`)) {
						newFontFeatureSettingsArr.push(`"${tag}"`);
					}
				} else {
					// If the tag is in the array, remove it
					if (newFontFeatureSettingsArr.includes(`"${tag}"`)) {
						newFontFeatureSettingsArr = newFontFeatureSettingsArr.filter(item => item !== `"${tag}"`);
					}
				}
			}
	
			let newFontFeatureSettings = newFontFeatureSettingsArr.join(', ');
			newFontFeatureSettings.trim();

			if (newFontFeatureSettings === '') {
				newFontFeatureSettings = null;
			}

			if (changes[this.props.selector]) {
				changes[this.props.selector]['font-feature-settings'] = newFontFeatureSettings;
			} else {
				changes[this.props.selector] = {
					'font-feature-settings': newFontFeatureSettings
				}
			}
			delete changes[key];
		}

		// if the key includes 'axis-' it is a 'font-variation-settings' property attribute pair ("'SRFF' 0" for example)
		if( key.includes('axis-') ){
			let currentFontWeight = propertyValues['font-weight'];
			// bail on change if a non-number value is being passed into the field
			if( isNaN(parseFloat(changes[key])) ) return;

			let changedAxis = key.split('axis-')[1],
				selector = key.split('axis-')[0],
				isReversedValue = changedAxis.includes('-reversed');
				changedAxis = isReversedValue ? changedAxis.replace('-reversed', '') : changedAxis;
				// create an array of each axis within the `font-variation-settings` string as it exists in the CSS
			let	FVSmap = propertyValues['font-variation-settings'] ? propertyValues['font-variation-settings'].split(',') : [],
				newFVSstring = "";

			if( //HACK FOR GRAVITY VARIABLE NOT PROPERLY DISPLAYING AT MAX WDTH FVS VALUE
				propertyValues['font-family'] === "\"Gravity Variable\""
				&& changedAxis === 'wdth' //width axis
				&& ( changes[key] === 150 || changes[key] > 149.99 )
			){
				changes[key] = 149.99
			}

			let changedPropertyInMap = FVSmap.some(item => item.toLowerCase().includes( changedAxis.toLowerCase() ) );

			if( !changedPropertyInMap && changedAxis !== 'wght' ){
				FVSmap.push(`'${changedAxis}' ${parseFloat(changes[key])}`)
			}

			let clippingArr = null;
			
			const clippingObject = _.find(this.props.clippingAxes, (family, iterativeName) => {
				let modelName = this.currentFamilyModel?.family;
				return modelName.match(iterativeName); 
			});
			// Super Family Clipping for Diatype and Monument
			if( clippingObject ){
				clippingArr = enforceSuperFamilyClipping(clippingObject, changedAxis, changes[key], FVSmap, propertyValues['font-weight'] );
				if( clippingArr ){ //If we have an array of clipping values, we need to update the FVSmap with the new values
					_.each( clippingArr, (clippedValue) => {
						let property = clippedValue.trim().split("' ")[0].trim().replace(/\'/g, '');
						let intValue = parseFloat( clippedValue.trim().replace(/[^\d.]/g, '') );
						let index = FVSmap.findIndex(item => item.includes(property));
						// Find index of existing property in FVSmap and update it with the new value if it exists
						// or add it to the end of the array if it doesn't.
						if( index > -1 ){
							// If the value exists, replace it in the array with the clipped value
							FVSmap[index] = clippedValue;
						} else if( index === -1 && property !== 'wght' ){
							FVSmap.push(clippedValue);
						} else if ( property === 'wght' && changedAxis !== 'wght' && index === -1 ){
							currentFontWeight = intValue;
							// If the property is 'wght', but we're not changing weight directly, update the font-weight property
							_.set(changes, [this.props.selector, 'font-weight'], intValue )
						}
					})
				}
			}
	
			//loop through the map of axes and create a new 'font-variation-settings' string with the updated value
			_.each(FVSmap, function(FVS, index){
				let property = FVS.trim().split("' ")[0].trim().replace(/\'/g, '');
				let val = parseFloat(FVS.trim().split("' ")[1])

				if ( property === changedAxis ) {
					val = isReversedValue ? -Math.abs(changes[key]) : changes[key];
				}

				let trailingComma = index === FVSmap.length-1 ? '' : ', '
				newFVSstring+= "'"+property+"' "+val+trailingComma;
			})

			// if the changed axis is the 'WGHT' axis, manually separate it out into the 'font-weight' property
			if (changedAxis === 'wght') {
				_.set(changes, [this.props.selector, 'font-weight'], changes[key]);
				currentFontWeight = changes[key];
			}

			// See if we can grab a super family
			let superFamilyChangeObj = this.findSuperFamiliesOnChange( newFVSstring, currentFontWeight );

			if( superFamilyChangeObj ){
				// Swap families and all related data out
				changes = superFamilyChangeObj
				skipLetterSpacingReset = true;

			} else if( newFVSstring ) {

				if( newFVSstring.trim() !== ""  ){
					// create a new changes object to send the propwatchers
					_.set(changes, [this.props.selector, 'font-variation-settings'], newFVSstring);
				}
			}

			// remove the special case property for the 'axis' slider
			delete changes[key];
		}

		if (changes.hasOwnProperty('underlineLinks')) {
			_.set(changes, [this.props.selector + ' a', 'text-decoration'], changes['underlineLinks'] ? 'underline' : 'none');
			delete changes['underlineLinks']
		}

		if (changes.hasOwnProperty('displayBlock')) {
			_.set(changes, [this.props.selector, 'display'], changes['displayBlock'] ? 'block' : 'inline-block');
			delete changes['displayBlock']
		}

		// Filter any lingering axis values
		changes = Object.fromEntries(Object.entries(changes).filter(([key]) => !key.includes('axis')));

		_.each(changes, (changedProperties, selector) => {

			let changedProp = _.keys(changedProperties)[0];

			if (changedProp === 'background' && changedProperties[changedProp] === null) {
				changedProperties[changedProp] = 'rgba(0,0,0,0)';
				changes[selector] = changedProperties;
			}

			if (changedProperties && changedProp?.match('border-')) {
				let existingBorderAttrs = this.getExistingCSSProperties('border-', selector);
				let isIndividualBorderSide = changedProp?.match('width') && changedProp?.match(/-/g).length == 2;
				let isColor = changedProp?.match('color');
				let defaults = isIndividualBorderSide || changedProp?.match('radius') || isColor ? _.omit(globalBorderDefaults, ['border-width']) : globalBorderDefaults;
				let existingAndDefaultBorderAttrs = {...defaults, ...existingBorderAttrs};
				changedProperties = {...existingAndDefaultBorderAttrs, ...changedProperties}
				changes[selector] = changedProperties;
			}

			if ( _.has(changedProperties, 'border-width') ) {

				let existingMainBorderWidthAttr =  this.getExistingCSSProperties('border-width', selector);
				let existingSubBorderWidthAttrs =  this.getExistingCSSProperties('border-', selector);

				let borderWidthPropArr = ['border-top-width','border-right-width','border-bottom-width','border-left-width']
				changedProperties = handleMultiScrubber( 'border-width', borderWidthPropArr, selector, changedProp, changedProperties, existingMainBorderWidthAttr, existingSubBorderWidthAttrs )
				changes[selector] = changedProperties;


				if (parseFloat(changedProperties['border-width']) === 0) {
					changes[selector] = {
						...changedProperties,
						'margin': '0rem'
					}
				}
			}

			if (_.has(changedProperties, 'border-radius')) {

				let existingMainRadiusAttr =  this.getExistingCSSProperties('border-radius', this.props.selector);
				let existingSubRadiusAttrs =  this.getExistingCSSProperties('border-', this.props.selector);

				let RadiusPropArr = ['border-top-left-radius','border-top-right-radius','border-bottom-right-radius','border-bottom-left-radius']
				changedProperties = handleMultiScrubber( 'border-radius', RadiusPropArr, selector, changedProp, changedProperties, existingMainRadiusAttr, existingSubRadiusAttrs )
				changes[this.props.selector] = changedProperties;
			}

			if( changedProperties && ( changedProp?.match('padding') || changedProp?.match('padding-') ) ){
				changes[this.props.selector] = handleBasicMultiScrubber('padding', this.props.selector, changedProp, changedProperties, this.getExistingCSSProperties);
			}

			if( changedProperties && ( changedProp?.match('margin') || changedProp?.match('margin-') ) ){
				changes[this.props.selector] = handleBasicMultiScrubber('margin', this.props.selector, changedProp, changedProperties, this.getExistingCSSProperties )
			}

			if ( 
				changedProp === 'border-radius' ||
				changedProp === 'border-top-left-radius' ||
				changedProp === 'border-top-right-radius' ||
				changedProp === 'border-bottom-right-radius' ||
				changedProp === 'border-bottom-left-radius'
			) {
				delete changes[this.props.selector]['border-color'];
			}

			// if the border width is set to 0, remove the border style and color
			if (
				(
					changedProp === 'border-width' ||
					changedProp === 'border-top-width' ||
					changedProp === 'border-right-width' ||
					changedProp === 'border-bottom-width' ||
					changedProp === 'border-left-width'
				) &&
				processCSSValue(changes[this.props.selector][changedProp])[0] === 0 &&
				(
					this.getExistingCSSProperties('border-style', this.props.selector) !== null ||
					this.getExistingCSSProperties('border-color', this.props.selector) !== null
				)
			) {
				changes[this.props.selector]['border-style'] = null;
				changes[this.props.selector]['border-color'] = null;
			}

		});

		if (
			changes[this.props.selector] && changes[this.props.selector]['border-color'] &&
			(
				this.props.propWatcherValues?.[this.props.selector]['border-width'] === null &&
				this.props.propWatcherValues?.[this.props.selector]['border-top-width'] === null &&
				this.props.propWatcherValues?.[this.props.selector]['border-right-width'] === null &&
				this.props.propWatcherValues?.[this.props.selector]['border-bottom-width'] === null &&
				this.props.propWatcherValues?.[this.props.selector]['border-left-width'] === null
			)
		) {
			changes[this.props.selector]['border-width'] = '0.5rem';
		}

		if ( 
			changes[this.props.selector] && changes[this.props.selector]['border-width'] && 
			this.props.propWatcherValues?.[this.props.selector]['border-color'] === null
		) {
			changes[this.props.selector]['border-color'] = 'rgba(0,0,0,0.85)';
		}

		if ( 
			changes[this.props.selector] && changes[this.props.selector]['border-width'] && 
			this.props.propWatcherValues?.[this.props.selector]['border-style'] === null
		) {
			changes[this.props.selector]['border-style'] = 'solid';
		}

		if ( 
			changes[this.props.selector] && changes[this.props.selector]['border-style'] && 
			changes[this.props.selector]['border-width'] === null &&
			changes[this.props.selector]['border-top-width'] === null &&
			changes[this.props.selector]['border-right-width'] === null &&
			changes[this.props.selector]['border-bottom-width'] === null &&
			changes[this.props.selector]['border-left-width'] === null &&
			this.props.propWatcherValues?.[this.props.selector]['border-width'] === null &&
			this.props.propWatcherValues?.[this.props.selector]['border-radius'] === null
		) {
			changes[this.props.selector]['border-width'] = '0.5rem';
		}

		if (
			changes[this.props.selector] && changes[this.props.selector]['border-width'] &&
			this.props.propWatcherValues?.[this.props.selector]['border-width'] === null
		) {
			changes[this.props.selector]['display'] = 'inline-block';
		}

		if (
			changes[this.props.selector] && changes[this.props.selector]['filter'] &&
			this.props.propWatcherValues?.[this.props.selector]['filter'] === null
		) {
			changes[this.props.selector]['display'] = 'inline-block';
		}

		if (
			(changes[this.props.selector] && changes[this.props.selector]['filter']) ||
			(changes[this.props.selector] && this.props.propWatcherValues?.[this.props.selector]['filter'])
		) {
			changes[this.props.selector]['will-change'] = 'filter';
		}

		if (
			changes[this.props.selector] && changes[this.props.selector]['filter'] === null
		) {
			changes[this.props.selector]['will-change'] = null;
		}

		// The change object will use the selector here.
		// use it as a stand in for this.props.selector in just this case.
		let specialCaseKey = Object.keys(changes)[0];

		// this.props.selector
		// Object.keys(changes[specialCaseKey]).forEach((key) => {
		// 	const checkProperties = [
		// 		'border-width',
		// 		'border-top-width',
		// 		'border-right-width',
		// 		'border-bottom-width',
		// 		'border-left-width',
		// 		'border-radius',
		// 		'border-top-left-radius',
		// 		'border-top-right-radius',
		// 		'border-bottom-right-radius',
		// 		'border-bottom-left-radius',
		// 		'padding',
		// 		'padding-top',
		// 		'padding-right',
		// 		'padding-bottom',
		// 		'padding-left',
		// 		'margin',
		// 		'margin-top',
		// 		'margin-right',
		// 		'margin-bottom',
		// 		'margin-left',
		// 	];
		// 	if (checkProperties.includes(key) 
		// 		&& changes[this.props.selector][key] !== null 
		// 		&& processCSSValue(changes[this.props.selector][key])?.[0] === 0
		// 	) {
		// 		console.log('set null', key, changes[this.props.selector][key], processCSSValue(changes[this.props.selector][key]))
		// 		// changes[this.props.selector][key] = null;
		// 	}
		// })

		// When changing text color and link color is the same or not set...
		if( changes[this.props.selector] && ( changes[this.props.selector]['color'] || changes[this.props.selector]['color'] === null )
		    && ( 
		    	this.props.propWatcherValues?.[this.props.selector]['color'] === this.props.propWatcherValues?.[this.props.selector+' a']['color'] 
		    	|| !this.props.propWatcherValues?.[this.props.selector+' a']['color']
		    )
		){
			// Keep link color in sync with text color.
			changes[this.props.selector+' a'] = { 'color': changes[this.props.selector]['color'] }		    
		} 

		let familyToCheckAgainst = !this.propWatcherValues?.[this.props.selector]?.['font-family'] ? this.props.propWatcherValues[this.props.selector]?.['font-family'] : this.propWatcherValues?.[this.props.selector]?.['font-family'];
		let changingFamily       = changes[this.props.selector]?.['font-family'];

		if( !skipLetterSpacingReset 
			&& changes[this.props.selector]?.['font-family'] 
			&& changes[this.props.selector]?.['font-family'] !== undefined
			&& familyToCheckAgainst
			&& changes[this.props.selector]?.['font-family'] !== familyToCheckAgainst
		 ){


			let changingSuperFamilyParent = null
			_.find(this.props.fontCollection.variableSuperFamilies, function(family, familyName){
				if( changingFamily.match(familyName) ){
					changingSuperFamilyParent = familyName;
					return familyName
				}
			});

			let currentSuperFamilyParent = null 
			_.find(this.props.fontCollection.variableSuperFamilies, function(family, familyName){
				if( familyToCheckAgainst.match(familyName) ){
					currentSuperFamilyParent = familyName;
					return familyName
				}
			});

			if( changingSuperFamilyParent === currentSuperFamilyParent ){
				skipLetterSpacingReset = true 
			}

			if( !skipLetterSpacingReset ){
				changes[this.props.selector]['letter-spacing'] = 0
			}

		}

		// update the prop watchers with the change obj
		this.props.queuePropwatcherChanges( changes );

	}
	
};


const mapReduxStateToProps = (state, ownProps) => {

	const isMobile = ownProps.isMobileData === true;

	return {
		isMobile,
		selector: isMobile ? `.mobile ${ownProps.selector}` : ownProps.selector,
		fontCollection: state.fontCollection,
		fontCollectionLoaded: state.fontCollection?.hasFontCollection,
		fetchingFontCollection: state.fontCollection?.fetchingFontCollection,
		variableOptionsRevealed: !isMobile ? true : state.adminState.variableOptionsRevealed,
		clippingAxes: state.fontCollection?.superFamilyClippingAxes,
		moreOptionsRevealed: state.adminState.moreOptionsRevealed ?? false,
	};

}

function mapDispatchToProps(dispatch) {
	
	return bindActionCreators({
		addUIWindow: actions.addUIWindow,
		removeUIWindow: actions.removeUIWindow,
		fetchFontCollection: actions.fetchFontCollection,
		fetchCustomFontCollection: actions.fetchCustomFontCollection,
		updateAdminState: actions.updateAdminState
	}, dispatch);

}

// create a memoized propwatcher config. This ensures we only reload 
// propWatchers if anything in the config has actually changed
const memoizedWatchedProperties = createSelector(
	(ownProps) => ownProps.selector,
	(ownProps) => ownProps.isMobile,
	(
		selector,
		isMobile
	) => {
		
		return {
			[selector]: [
				'color',
				'font-size',
				'line-height',
				'letter-spacing',
				'font-variant-name',
				'font-family',
				'font-weight',
				'font-style',
				'font-variation-settings',
				'font-feature-settings',
				'border',
				'border-top-width',
				'padding',
				'background',
				'filter',
				'will-change',
				{
					property: 'mix-blend-mode',
					default: 'normal'
				},
				'display',
				{
					property: 'padding-top',
					inheritsFrom: {
						selector: selector,
						property: 'padding'
					}
				},
				{
					property: 'padding-right',
					inheritsFrom: {
						selector: selector,
						property: 'padding'
					}
				},
				{
					property: 'padding-bottom',
					inheritsFrom: {
						selector: selector,
						property: 'padding'
					}
				},
				{
					property: 'padding-left',
					inheritsFrom: {
						selector: selector,
						property: 'padding'
					}
				},
				{property: 'border-color'},
				{property: 'border-style'},
				{property: 'border-radius'},
				{
					property: 'border-top-left-radius',
					inheritsFrom: [
						{
							selector: selector,
							property: 'border-radius'
						},
					]
				},
				{
					property: 'border-top-right-radius',
					inheritsFrom: [
						{
							selector: selector,
							property: 'border-radius'
						},
					]
				},
				{
					property: 'border-bottom-right-radius',
					inheritsFrom: [
						{
							selector: selector,
							property: 'border-radius'
						},
					]
				},
				{
					property: 'border-bottom-left-radius',
					inheritsFrom: [
						{
							selector: selector,
							property: 'border-radius'
						},
					]
				},
				{property: 'border-width'},
				{
					property: 'border-top-width',
					inheritsFrom: [
						{
							selector: selector,
							property: 'border-width'
						},
					]
				},
				{
					property: 'border-right-width',
					inheritsFrom: [
						{
							selector: selector,
							property: 'border-width'
						},
					]
				},
				{
					property: 'border-bottom-width',
					inheritsFrom: [
						{
							selector: selector,
							property: 'border-width'
						},
					]
				},
				{
					property: 'border-left-width',
					inheritsFrom: [
						{
							selector: selector,
							property: 'border-width'
						},
					]
				},
			],
			[selector + ' a']: [
				'color',
				'text-decoration',
				{
					property: 'text-decoration-color',
					inheritsFrom: [
						{
							selector: selector + ' a',
							property: 'color'
						},
					]
				}
			]
		}

	}
);

export const TypographyMenu = connect(
	mapReduxStateToProps,
	mapDispatchToProps
	)(withPropWatchers(TypographyMenuComponent,  {
		parser: ownProps => ownProps.type === 'local' ? 'local' : 'global',
		watchedProperties: memoizedWatchedProperties,
		beforePropWatcherCreation: (ownProps, propWatcherConfig) => {
			if(ownProps.isMobile) {
				addNonMobileInheritanceToPropWatcherConfig(propWatcherConfig)
			}
		}
	})
);

export {preloadImage};



