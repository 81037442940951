import React, { Component, useState } from 'react';
import { FontVariant } from "./";
import CustomFontPreview from './custom-font-preview';
import CustomFontEditorButton from './custom-font-editor-button';
import _ from 'lodash';

export class FontSection extends Component {

    constructor(props){
        super(props);

        this.state = {
            containerStyles: this.props.font.containerStyles,
        }
    }

    // updateAfterTransition = (type, title, font) => {
    //     this.props.onTransitionEnd( type, title, font )   
    // }

    render(){

        const {forwardedRef, font, title, children, count, location, url, selectFont, toggleFamily, bgSize, linkedHover, onWillUnmount, renderCustomFontIframes, customIframesLoadingState, onIframeRenderComplete, colorScheme, ...props} = this.props;

        let firstFont = font.variants[0];

        const backgroundStyle = {
            backgroundPositionY: ( location * -36 ),
            backgroundImage: "url("+url+")",
            backgroundSize: bgSize
        }

        const containerStyles = this.props.font.containerStyles;

        let capitalize = (word) => {
            return word
                .toLowerCase()
                .replace(/\w/, firstLetter => firstLetter.toUpperCase());
        }
        
        return (
            <div
                className        = {`font-section disable-drag${title === 'staffpicks' ? ' staffpickfont' : ''}${font.isOpen === true ? ' visible' : ''}${this.props.lastFamily === true ? ' last' : ''}${font.provider === 'custom' && customIframesLoadingState ? ' loading-custom-fonts' : ''}`}
                data-font-group  = { font.family }
                data-selector    = { font.family }
                style            = { this.props.font.containerStyles }
                ref              = { forwardedRef }
                onPointerEnter = { this.props.onPointerEnter }
                onPointerLeave = { this.props.onPointerLeave }
                // onTransitionEnd  = { () => this.updateAfterTransition('section', title, font.family) }
            > 
                <li 
                    data-source = { font.provider } 
                    data-family = { font.family } 
                    data-type   = { title }
                    className   = {`family-head${title === 'staffpicks' ? ' staffpickfont' : ''}${font.variable === true ? ' variable' : ''} ${linkedHover ? 'linked-hover': ''}`}
                    style       = { null }
                    onMouseDown = { () => toggleFamily() }
                    data-name   = { firstFont.name }
                >
                    {font.provider !== 'custom' ? 
                        <span className="pngpreview" style={ backgroundStyle }> </span> 
                    : 
                        <>{ renderCustomFontIframes ? <CustomFontPreview font={font} name={font.family} onIframeRenderComplete={onIframeRenderComplete} customIframesLoadingState={customIframesLoadingState} colorScheme={colorScheme} /> : null }</>
                    }
                        <span className="variant-count">
                            { count }
                        </span>
                        <div className="transition-cover">
                        </div>
                </li>
                <div
                    className={`variant-container ${font.provider === 'custom' ? 'custom' : ''}`}
                > 
                    { children }
                </div>
            </div>
        );
    }
}
