import { FRONTEND_DATA } from "../../../globals";
import { TextStyleDefaults } from "../../../defaults/text-style-defaults";
import { 
	rangeCanBeModifiedByTextCommands,
	getAllParents,
	wrapRangeWith
} from "../helpers";
import { subscriptions as textStyleSubscriptions } from "../../shared-css/global-css-subscriptions";

import _ from 'lodash';


export default {

	requiresTextInRange: true,
	requiresUncollapsedRange: true,
	requiresActiveRange: true,

	execute: function(){

		const className = "small-caps";

		const CargoEditor = FRONTEND_DATA.contentWindow.CargoEditor
		let range = CargoEditor.getActiveRange();

		const state = this.getState(range);

		if(state){

			const {isApplied, isFullyApplied} = state;

			// when the we haven't applied this to the full range, apply 
			if(!isFullyApplied){

				wrapRangeWith('span', {
					after: (nodes) => {

						// add class names
						nodes.forEach(node => {
							if(!node.classList.contains(className)) {
								// apply the text-style class to this node
								node.classList.add(className)
							}
						});

					}
				});

			} else {

				CargoEditor.mutationManager.execute(function(){
					
					const start = range.startContainer
					const end = range.endContainer;

					const nodes = [
						// get spans inside range
						...range.getNodes([1]).filter(node => node.nodeName === "SPAN" || node.nodeName === 'FIGCAPTION'),
						// get spans that are a parent of this range
						...getAllParents(range.commonAncestorContainer).filter(node => node.nodeName === "SPAN" || node.nodeName === 'FIGCAPTION')
					].filter(node => {

						if(node.classList.contains(className)){
							return true;
						}
	
						// no custom styles applied to this node. Ignore it
						return false;
	
					});

					_.each(nodes, function(node){
						node.classList.remove(className);
						if ( node.classList.length === 0 ) {
							node.removeAttribute('class');
						}
						if ( node.hasAttributes() === false ) {
							CargoEditor.helpers.removePreservingDescendants(node, range);
						}
					});

					CargoEditor.helpers.setActiveRange(range);

				});
			}
		 }
	},

	getState: (range) => {

        const customTextStyleClassNames = ["small-caps"];

		let matches = {};
		let isFullyApplied = false;

		range = range || FRONTEND_DATA.contentWindow.CargoEditor.getActiveRange();

		if(range) {

			// only get state when we know what custom text styles we're looking for
			if(customTextStyleClassNames && customTextStyleClassNames.length > 0) {
				
				// get a list of all classnames used by currently available text styles
				const allTextStyleClassNames = ["small-caps"];

				const nodes = [
					// get spans inside range
					...range.getNodes([1]).filter(node => node.nodeName === "SPAN" || node.nodeName === 'FIGCAPTION'),
					// get spans that are a parent of this range
					...getAllParents(range.commonAncestorContainer).filter(node => node.nodeName === "SPAN" || node.nodeName === 'FIGCAPTION')
				].filter(node => {

					// only use spans that contain existing text style classes
					for(let i = 0; i < node.classList.length; i++) {
						if(allTextStyleClassNames.includes(node.classList[i])){
							return true;
						}
					}

					// no custom styles applied to this node. Ignore it
					return false;

				});

				let matchCounter = 0;

				// loop over all spans contained by this range
				nodes.forEach(node => {
					// loop over this span's classNames and see if any match the supplied list of custom text styles
					node.classList.forEach(className => {

						if(customTextStyleClassNames.includes(className)) {
							// add a match for this node to the results
							(matches[className] = matches[className] || []).push(node);
							matchCounter++;
						}
					});
				});

				if(matchCounter > 0 && matchCounter === nodes.length) {
					isFullyApplied = true;
				}
				
			}

		}

		return {
			isAllowed: rangeCanBeModifiedByTextCommands(range),
	 		isApplied: Object.keys(matches).length > 0,
	 		isFullyApplied,
	 		matches
	 	}

	},
	priority: 1
 
}