import { FRONTEND_DATA } from "../../../globals";
import _ from 'lodash';
import { 
	retrieveSequentualNodesEffectivelyContainedByRange,
	rangeCanBeModifiedByTextCommands
} from "../helpers";
import { helpers } from "@cargo/common";
import { commands } from '../';

export default {

	requiresTextInRange: true,
	requiresUncollapsedRange: true,
	requiresActiveRange: true,

	execute: function(){
		// noop. This command just handles the active state for list-based commands in it's submenu
	},

	getState: function(range) {

		if( range && range.commonAncestorContainer && range.commonAncestorContainer.parentElement.closest('[thumbnail-index]') || range.commonAncestorContainer.hasAttribute?.('thumbnail-index')){
			return {
				isApplied: false,
				isAllowed: false,
			}
		}	

		let isApplied = commands.orderedlist.getState()?.isApplied || commands.unorderedlist.getState()?.isApplied;

		return {
			isApplied,
			isAllowed: rangeCanBeModifiedByTextCommands(range),
		};
	},

	priority: 0,

}