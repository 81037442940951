import { FRONTEND_DATA } from "../../../globals";
import { 
	rangeCanBeModifiedByTextCommands,
	getEffectsTargetsContainedByRange,
	shouldBeWrappedInSpans,
	getAllParents,
	wrapRangeWith
} from "../helpers";

import { effectsBorderDefaults } from "../../../defaults/border-defaults";
import { effectsDropshadowDefaults } from "../../../defaults/dropshadow-defaults";


import _ from 'lodash';
import { is } from "lib0/function.js";

export default {

	requiresTextInRange: false,
	requiresUncollapsedRange: true,
	requiresActiveRange: true,

    attributes: {
        'rotation': {
            defaults: {
                'rotation': '0',
                'animate': '0',
            }
        },
        'eye-roll': {},
        'drag': {},
        'scroll-transition': {},
		'border': {
			defaults: effectsBorderDefaults
		},
		'dropshadow': {
			defaults: effectsDropshadowDefaults
		},
		'background': {
			defaults: {
				'background-color': 'rgba(0,0,0,0)'
			}
		},
		'blink': {
			defaults: {
				'blink-speed': 75,
				'blink-transition': 0
			}
		},
		'fit-text': {}
    },

	removeFromNode (node, targetAttr) {
		if ( node ) {

			FRONTEND_DATA.contentWindow.CargoEditor.mutationManager.execute(()=>{
				if ( targetAttr ) {
					const usesAttr = (node.getAttribute('uses') || "").replace(targetAttr, '').trim();
					if ( usesAttr.length ) {
						node.setAttribute('uses', usesAttr);
					} else {
						node.removeAttribute('uses');
					}
					if ( this.attributes[targetAttr].defaults ) {
						Object.keys(this.attributes[targetAttr].defaults).forEach((attr) => {
							node.removeAttribute(attr);
						})
					}
				} else {
					node.removeAttribute('uses');
					Object.keys(this.attributes).forEach((type) => {
						if (this.attributes[type].defaults) {
							Object.keys(this.attributes[type].defaults).forEach((attr) => {
								node.removeAttribute(attr);
							})
						}
					})
				}
			});

			const range = FRONTEND_DATA.contentWindow.CargoEditor.rangy.createRange();
			range.setStartBefore(node);
			range.setEndAfter(node);
			FRONTEND_DATA.contentWindow.CargoEditor.helpers.setActiveRange(range);
		}
	},

	remove: function(targetAttr, mediaItemsArr) {

		const state = mediaItemsArr ? this.getState(null, mediaItemsArr) : this.getState();
		let matchesNode = state.matches ? state.matches.node : null;

		if (mediaItemsArr) {
			mediaItemsArr.forEach((mediaItemsArrNode) => {
				this.removeFromNode(mediaItemsArrNode, targetAttr);
			})
		} else {
			this.removeFromNode(matchesNode, targetAttr);
			if (matchesNode && matchesNode.tagName === "SPAN" && matchesNode.hasAttributes() === false ) {
				const firstChild = matchesNode.firstChild;
				const lastChild = matchesNode.lastChild;
				FRONTEND_DATA.contentWindow.CargoEditor.helpers.unwrap(matchesNode, true);
				const range = FRONTEND_DATA.contentWindow.CargoEditor.rangy.createRange();
				range.setStartBefore(firstChild);
				range.setEndAfter(lastChild);
				FRONTEND_DATA.contentWindow.CargoEditor.helpers.setActiveRange(range);
			}
		}

	},

	execute: function(targetAttr, changes, mediaItemsArr){

		const state = mediaItemsArr ? this.getState(null, mediaItemsArr) : this.getState();
		const CargoEditor = FRONTEND_DATA.contentWindow.CargoEditor;
		const range = CargoEditor.getActiveRange();

		const eachTargeHasFitText = state.targets.length && state.targets.every((node) => node.nodeType === Node.ELEMENT_NODE && node.getAttribute('uses')?.includes('fit-text'));

		// If range contains multiple nodes or only a text node, then wrap in <span uses/>
		// Otherwise, add the uses attribute directly to the node
		if (shouldBeWrappedInSpans(state.targets, mediaItemsArr) && (
			targetAttr === 'fit-text' && eachTargeHasFitText === true
		) === false) {
			wrapRangeWith('span', {
				after: (nodes) => {
					nodes.forEach(node => {
						FRONTEND_DATA.contentWindow.CargoEditor.mutationManager.execute(() => {
							node.setAttribute('uses', targetAttr);
							if ( changes && Object.keys(changes).length > 0 ) {
								if ( this.attributes[targetAttr].defaults ) {
									Object.keys(this.attributes[targetAttr].defaults).forEach((attr) => {
										node.setAttribute(attr, changes[attr] ? changes[attr] : this.attributes[targetAttr].defaults[attr]);
									})
								}
							}
							// Check if last node is a <br> and move it outside of the node
							if (node.lastChild && node.lastChild.tagName === 'BR') {
								node.parentNode.insertBefore(node.lastChild, node.nextSibling);
							}
						});
						// const range = FRONTEND_DATA.contentWindow.CargoEditor.rangy.createRange();
						// range.setStartBefore(span);
						// range.setEndAfter(span);
						// FRONTEND_DATA.contentWindow.CargoEditor.helpers.setActiveRange(range);
					});
				}
			});
		} else {
			state.targets.forEach((target) => {
				FRONTEND_DATA.contentWindow.CargoEditor.mutationManager.execute(() => {
					if (target.nodeType === Node.ELEMENT_NODE && target?.hasAttribute('uses')) {
						if ( target.getAttribute("uses").includes(targetAttr) === false ) {
							const currentAttr = target.getAttribute('uses');
							if ( !currentAttr.includes(targetAttr)) {
								target?.setAttribute('uses', `${targetAttr} ${currentAttr}`);
							}
						} else {
							if (!changes || !Object.keys(changes).length) {
								console.log('remove!!', targetAttr, target.getAttribute('uses'), changes)
								const newUses = target.getAttribute('uses').replace(targetAttr, '').trim();
								if ( newUses.length ) {
									target.setAttribute('uses', newUses);
								} else {
									target.removeAttribute('uses');
								}
							}
						}
					} else {
						if ( target.nodeType === Node.ELEMENT_NODE ) {
							target.setAttribute('uses', targetAttr);
						}
					}
					if ( this.attributes[targetAttr].defaults ) {
						Object.keys(this.attributes[targetAttr].defaults).forEach((attr) => {
							if( changes.hasOwnProperty(attr) && changes[attr] !== null ){
								target.setAttribute(attr, changes[attr] )
							}
						})
					}
				});
				if (!mediaItemsArr) {
					// const range = FRONTEND_DATA.contentWindow.CargoEditor.rangy.createRange();
					// range.setStartBefore(target);
					// range.setEndAfter(target);
					// FRONTEND_DATA.contentWindow.CargoEditor.helpers.setActiveRange(range);
				}
			})
		}

	},

	getState: (range, mediaItemsArr) => {

		range = range || FRONTEND_DATA.contentWindow.CargoEditor.getActiveRange();

		let isInsideMarquee = false;
		if (range) {
			const parentElement = range.commonAncestorContainer.nodeType === Node.TEXT_NODE ? range.commonAncestorContainer.parentElement : range.commonAncestorContainer;
			const marquee = parentElement.closest('marquee-set');
			isInsideMarquee = marquee ? true : false;
		}

		let targets;
		if (!mediaItemsArr) {
			targets = getEffectsTargetsContainedByRange();
		} else {
			targets = mediaItemsArr;
		}

		if (targets.length > 1 && targets.every((target) => target.tagName === 'MEDIA-ITEM')) {
			mediaItemsArr = targets;
		}

		let node = null;
		if (targets?.length === 1 && targets[0].nodeType === Node.ELEMENT_NODE && targets[0].hasAttribute('uses')) {
			node = targets[0];
		}

		if (mediaItemsArr && mediaItemsArr.length && targets.length && targets[0].nodeType === Node.ELEMENT_NODE && targets[0].hasAttribute('uses')) {
			node = targets[0];
		}

		const matches = {
			node: node ? node : null,
			eyeroll: node && node.getAttribute('uses').includes('eye-roll') ? {} : null,
			drag: node && node.getAttribute('uses').includes('drag') ? {} : null,
			scrolltransition: node && node.getAttribute('uses').includes('scroll-transition') ? {} : null,
			rotation: node && node.getAttribute('uses').includes('rotation') ? {
				rotation: node.hasAttribute('rotation') ? node.getAttribute('rotation') : '0',
				animate: node.hasAttribute('animate') ? node.getAttribute('animate') : '0',
			} : null, 
			blink: node && node.getAttribute('uses').includes('blink') ? {
				['blink-transition']: node.hasAttribute('blink-transition') ? node.getAttribute('blink-transition') : '0',
				['blink-speed']: node.hasAttribute('blink-speed') ? node.getAttribute('blink-speed') : '0',
			} : null, 
			fittext: (node && node.nodeType === node.ELEMENT_NODE && node?.getAttribute('uses')?.includes('fit-text')) || (targets.length && targets.every((node) => node.nodeType === Node.ELEMENT_NODE && node.getAttribute('uses')?.includes('fit-text'))) ? {} : null,
		};

		const columnUnitParents = targets.reduce((acc, curr) => {
			if (curr.parentNode && curr.parentNode.tagName === 'COLUMN-UNIT' && !acc.includes(curr.parentNode)) {
				acc.push(curr.parentNode);
			}
			return acc;
		}, [])

		let isAllowed = false;

		if (range) {
			isAllowed = !range.collapsed && !targets.some((node) => node.tagName === 'COLUMN-SET') && !targets.some((node) => node.tagName === 'COLUMN-UNIT') && columnUnitParents.length < 2
		}

		if (isInsideMarquee) {
			isAllowed = false;
		}

		return {
			isAllowed,
			isApplied: node !== null,
			matches,
			targets,
		}

	},
	priority: 1
 
}