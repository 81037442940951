import { FRONTEND_DATA } from "../../../globals";
import { 
	rangeCanBeModifiedByTextCommands
} from "../helpers";

export default {

	requiresTextInRange: true,
	requiresUncollapsedRange: true,
	requiresActiveRange: true,
	canBeOverruledByExternalStyle: true,

	execute: function(){
		FRONTEND_DATA.contentWindow.CargoEditor.execCommand('italic');
	},
	getState: (range)=> {

		return {
			isApplied: FRONTEND_DATA.contentWindow.CargoEditor.queryCommandState('italic'),
			// isAllowed: rangeCanBeModifiedByTextCommands(range) && !FRONTEND_DATA.contentWindow.CargoEditor.isCommandOverruledByExternalStyle('italic')
			isAllowed: rangeCanBeModifiedByTextCommands(range)
		};
	},		
	priority: -1

}