import { FRONTEND_DATA } from "../../../globals";

import { 
	rangeCanBeModifiedByTextCommands,
	getNodesInRangeFilteredBySelector,
	wrapRangeWith
} from "../helpers";

import _ from 'lodash';

const maybeConvertRGBToHex = (value) => {
	if (!value || !value.includes('rgb')) {
		return value;
	}
	if (value.includes('rgba')) {
		return value;
	}
	const rgbValues = value.replace('rgb(', '').replace(')', '').split(',');
	const hexValues = rgbValues.map((value) => {
		const hex = parseInt(value).toString(16);
		return hex.length === 1 ? `0${hex}` : hex;
	});
	return `#${hexValues.join('')}`;
}

export default {

	requiresTextInRange: true,
	requiresUncollapsedRange: true,
	requiresActiveRange: true,

	execute: function(hexColor){

		if(hexColor === 'rgba(0,0,0,0)' || hexColor === 'rgba(0, 0, 0, 0)'){

			// remove all background spans
			wrapRangeWith('span', {
				before: () => {

					// first remove all old text-styles from our newly wrapped range
					getNodesInRangeFilteredBySelector('SPAN, H1, H2, A').forEach(node => {

						if(node.style.background !== '') {
							node.style.removeProperty('background');
						}

						if(node.style.backgroundColor !== '') {
							node.style.removeProperty('background-color');
						}

						if(node.style.display !== '') {
							node.style.removeProperty('display');
						}

						if(node.style.length === 0) {
							node.removeAttribute('style');
						}

					});

				},
				after: (spans) => {

					spans.forEach(span => {
						if(span.attributes.length === 0 && span.tagName === 'SPAN') {
							FRONTEND_DATA.contentWindow.CargoEditor.helpers.unwrap(span, true);
						}
					});
					
				}
			});

		} else {

			// generate spans
			wrapRangeWith('span', {
				before: () => {

					// first remove all old text-styles from our newly wrapped range
					getNodesInRangeFilteredBySelector('SPAN, H1, H2, A').forEach(node => {

						if(node.style.background !== '') {
							node.style.removeProperty('background');
						}

						if(node.style.backgroundColor !== '') {
							node.style.removeProperty('background-color');
						}

						if (node.tagName === 'H1' || node.tagName === 'H2') {
							if(node.style.display !== '') {
								node.style.removeProperty('display');
							}
						}

						if(node.style.length === 0) {
							node.removeAttribute('style');
						}

					});

				},
				after: (spans) => {

					spans.forEach(span => {
						// set background
						span.style.background = hexColor;
						if (span.tagName === 'H1' || span.tagName === 'H2') {
							span.style.display = 'inline-block';
						}
					});
					
				}
			});

		}
		
	},

	getCurrentColor: () => {
		
		const nodes = getNodesInRangeFilteredBySelector('SPAN, H1, H2, A', {
			parentsOnly: true
		});

		let currentColor = null;
		let index = 0;
		while(!currentColor && nodes[index]){
			let node = nodes[index]
			if(node.nodeType === Node.ELEMENT_NODE && (node.nodeName === 'SPAN' || node.nodeName === 'H1' || node.nodeName === 'H2')) {
				currentColor = maybeConvertRGBToHex(getComputedStyle(node).backgroundColor);
			}
			index++;
		}

		return currentColor;
	},


	getState: (range) => {
		const nodes = getNodesInRangeFilteredBySelector('SPAN, H1, H2, A', {
			parentsOnly: true
		});
		let foundMatch = false;
		let index = 0;
		while(!foundMatch && nodes[index]){
			let node = nodes[index]
			if(node.nodeType === Node.ELEMENT_NODE && (node.nodeName === 'SPAN' || node.nodeName === 'H1' || node.nodeName === 'H2')) {
				if(node.style.background !== '' || node.style.backgroundColor !== '') {
					foundMatch = true;
				}
			}
			index++;			
		}
		return {
			isAllowed: rangeCanBeModifiedByTextCommands(range),
	 		isApplied: foundMatch
	 	}
	},	
	priority: 1
 
}