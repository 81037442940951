import React, {Component, Fragment, useRef, useEffect} from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { actions } from "../../actions";
import { bindActionCreators } from 'redux';
import { Alert, AlertContext, Button } from "@cargo/ui-kit";
import { HotKeyProxy } from './';
import { calcWindowPercentage } from "./helpers";
import { uploadMedia } from "../../lib/media";
import { FRONTEND_DATA } from "../../globals";
import { helpers } from "@cargo/common";
import { MenuContext } from "@cargo/common/context-menu";
import globalDragEventController from "../drag-event-controller";
import { getCRDTItem, convertStateToSharedType } from "../../lib/multi-user/redux";
import selectors from '../../selectors';
import { getSupportedFileTypes } from './helpers';
import { store } from '../../index';

import _ from 'lodash';

const uiWindowComponentList = {};
const scrollPositions = {};

let uiWindowResizeObserver = null;

const initUIWindowResizeObserver = _.once(() => {

	if(!helpers.isServer) {

		uiWindowResizeObserver = new ResizeObserver(entries => {

			entries.forEach(entry => {

				const uiWindow = uiWindowComponentList[entry.target.__UIwindowId];

				if ( !uiWindow ){
					return;
				}

				uiWindow.onResize(entry.contentRect)

			});
			
		});
	}

});

const mountedUIWindows = new WeakSet();

class UIWindowClass extends Component {

	constructor(props) {
		super(props);

		this.UIWindowId = _.uniqueId();

		this.contentFrameWindowMargin = 15;
		this.adminBarSize = 40;

		this.state = {
			// window is droppable
			windowIsDroppable: false,
			// drag behavior
			drag: {
				isDragging: false,
				isDragged: false,
				wasDragged: false,
				affinity: {
					top: false,
					bottom: false,
					right: false,
					left: false
				},
				x: 0,
				y: 0
			},
			// component HxW
			el: {
				width: 0,
				height: 0
			},
			// element position
			position: {
				x: 0,
				y: 0
			},
			// button position
			buttonPos: {
				x: null,
				y: null,
				width: null,
				height: null
			},
			positionFromSession: false,
			// autoHeight: this.props.autoHeight ? true : true, // allow window to determine height based on content
			// set max height
			maxHeight: null,
			maxInnerHeight: null,
			zIndex: 320, //320
			visibility: 'hidden'
		}

		// type:: string -- popover / pane, describes window behavior
		// positionType:: string -- corresponts to CSS class for specific position, if not set, window will set it's own position based on button pos.
		// borderRadius:: string -- corresponds to CSS class for border radius corners
		// autoHeight:: boolean -- allow window to determine height based on content
		// buttonPos:: flattened coordinates of the button, may or may not be used according to arguments set above
		// tabbed:: boolean -- does the window have individual tabs?

		this.uiWindowRef = React.createRef();
		// Lets us know how the window is opened for setting borders.
		// Not a part of state b/c it does not directly affect the component render
		// & b/c setState is asynch and might not update in time for calculations.
		this.vertical = null;
		this.horizontal = null;
		this.positionType = this.props.positionType;

		this.handleViewportResize = this.handleViewportResize.bind(this);

		initUIWindowResizeObserver();

	}

	superBadScrollHack = {

		resetHackState: ()=>{
			this.scrollDrag = 0;

			/* it's possible to launch the window while the button is stickied below the top of the frame
			so we adjust the start position up to the top of the rect (see the openuiwindow method in 
			column-editor.js, etc)
			*/			
			this.startPosition = {
				x: this.state.position.x,
				y: this.props.buttonPos.y + this.props.buttonPos.height
			}
			this.startedScrolling = false;
		},

		scroll: (scrollDelta)=>{

			if(this.state.drag.wasDragged){
				return;
			}

			if(!this.startPosition){
				this.superBadScrollHack.resetHackState();
			}			

			if( this.startedScrolling === false ){
				this.superBadScrollHack.startScrolling();
			}

			this.scrollDrag = this.scrollDrag + scrollDelta;

			if(this.startedScrolling){
				this.superBadScrollHack.endScrolling();
			}
		},

		startScrolling: ()=>{

			cancelAnimationFrame(this.pointerMoveTick)			

			this.startedScrolling = true;
			this.setZIndex('scroll');
			this.pointerMoveTick = requestAnimationFrame(this.superBadScrollHack.whileScrolling);
		},

		whileScrolling: ()=>{
			const clampedPosition = this.getClampedWindowPosition({
				x: this.startPosition.x,
				y: this.startPosition.y+-this.scrollDrag,
			});

			this.setState((prevState)=>{
				return {
					...prevState,
					position: {
						x: clampedPosition.position.x,
						y: clampedPosition.position.y,
					}
				}
			}, ()=>{
				if( this.startedScrolling){
					this.pointerMoveTick = requestAnimationFrame(this.superBadScrollHack.whileScrolling);					
				}

			})
	
		},

		endScrolling: _.debounce(()=>{
			cancelAnimationFrame(this.pointerMoveTick)
			let drag = {...this.state.drag};
			let position = {...this.state.position};
			this.startedScrolling = false;

			// this.setState({
			// 	position: {
			// 		x: Math.round(drag.x),
			// 		y: Math.round(drag.y)
			// 	}
			// })				
		}, 50)		
	}

	setButtonPosState = () => {
		
		let sessionPosition = this.props.sessionPosition?.[this.props.id];

		if ( this.props.buttonPos && (!sessionPosition || this.props.ignoreSessionPosition) ){
				this.setState({
					buttonPos: {
						x: this.props.buttonPos.x,
						y: this.props.buttonPos.y,
						width: this.props.buttonPos.width,
						height: this.props.buttonPos.height
					},
				}, ()=> {

					if( !this.props.positionType && this.props.type === 'popover' && this.props.position !== 'center' ){
						this.setUIWindowPosition()
					}

					if( this.props.positionType === 'center-under-button' ){
						this.setPositionCenteredBelow();
					}

					if ( this.props.positionType === 'over-button' ){
						this.positionOverButton();
					}

					if( this.props.positionType === 'from-button' ){
						this.positionFromButton();
					}

					if( this.props.positionType === 'from-invokeeWindow' ){
						this.positionFromInvokeeWindow();
					}

				})
		}

	}

	componentDidMount() {

		// Resize observer handles ui Window width and height setting
		let uiWindow = this.uiWindowRef.current;

		mountedUIWindows.add(uiWindow);

		this.setZIndex('mount');

		uiWindow.__UIwindowId = this.UIWindowId;
		uiWindowComponentList[this.UIWindowId] = this;
		uiWindowResizeObserver.observe(uiWindow);

		let sessionPosition = this.props.sessionPosition?.[this.props.id];
		// reset dragged state in redux store. We use this to decide if we'll save the position
		// when the window closes.
		this.props.updateUIWindow(this.props.id, { dragged: false })

		this.setButtonPosState();

		if( this.props.originWindowPos 
			&& !this.props.positionType
			&& !sessionPosition
		 ){
			this.positionChildFromParent();
		}

		if( sessionPosition 
			&& !this.props.ignoreSessionPosition
		 ){
		 	// Caution: windows that cannot be dragged should NOT undergo this process
			 this.setInitialPosition(sessionPosition.x, sessionPosition.y);
			 this.setState({ positionFromSession: true })
		}

		if( this.props.maxHeight ){
			this.handleViewportResize();
			window.addEventListener('resize', this.handleViewportResize);
		}

		this.props.updateUIWindow(this.props.id, {
			reference: this.uiWindowRef
		})

		// add drag over listener here if the window accepts drops
		if(this.props.acceptDrops){
			globalDragEventController.on('drop', this.handleDrop)
			globalDragEventController.on('dragover', this.handleDragOver)
			this.setState({
				windowIsDroppable: false
			});
		}

		// if we previously had this window open and stored a last known scroll pos: restore it
		if(this.props.scrollRestoration) {

			const scrollContainer = this.uiWindowRef.current?.querySelector('.uiWindow-inner');

			if(scrollContainer) {
				const lastScrollPos = scrollPositions[this.props.id];

				if(lastScrollPos && lastScrollPos > 0) {
					requestAnimationFrame(() => {
						scrollContainer.scrollTop = lastScrollPos;
					});
				}
			}

		}

	}

	componentWillUnmount(){

		mountedUIWindows.delete(this.uiWindowRef.current);

		// clear animation from bad scroll hack
		cancelAnimationFrame(this.pointerMoveTick)

		if ( !helpers.isServer){
			uiWindowResizeObserver.unobserve(this.uiWindowRef.current)
			delete uiWindowComponentList[this.UIWindowId];
		}
		if( this.props.maxHeight ){
			window.removeEventListener('resize', this.handleViewportResize)
		}

		globalDragEventController.off('dragover', this.handleDragOver)
		globalDragEventController.off('drop', this.handleDrop)

		// when using scroll restoration, store the last known scroll position
		// when unmounting. We'll restore it when this id mounts next
		if(this.props.scrollRestoration) {

			const scrollContainer = this.uiWindowRef.current?.querySelector('.uiWindow-inner');

			if(scrollContainer) {
				scrollPositions[this.props.id] = scrollContainer.scrollTop;
			}

		}

		if (typeof this.props.closeCallback === 'function') {
			this.props.closeCallback(this.props.lastClickCoordinates)
		}
	}

	componentDidUpdate(prevProps, prevState){


		if( this.state.drag.isDragged !== prevState.drag.isDragged ){
			this.props.updateUIWindow(this.props.id, { dragged: true })
		}

		if( ( prevState.el.height === 0 && this.state.el.height !== 0 ) 
			|| ( prevState.el.width === 0 && this.state.el.width !== 0 ) ){
			this.setInitialPosition(this.state.position.x, this.state.position.y);
		}

		// if( prevState.el.width === 0 && this.state.el.width !== 0 ){
		// 	this.setInitialPosition(this.state.position.x, this.state.position.y);
		// }

		if (
			this.props.windowSize.width != prevProps.windowSize.width ||
			this.props.windowSize.height != prevProps.windowSize.height
		) {
			this.onWindowResize(prevProps.windowSize);
		}

		if( this.state.drag.isDragging && !prevState.drag.isDragging ){
			this.props.updateUIWindow(this.props.id, { dragging: true })
		}

		if( this.props.zIndex !== prevProps.zIndex ){
			this.setState({zIndex: this.props.zIndex })
		}

		if(!_.isEqual(this.props.buttonPos, prevProps.buttonPos)) {
			this.setButtonPosState();
		}

	}

	setUIWindowPosition() {
		// Primary positioning logic for MAIN UI windows like the Global settings tab.
		const 	clientWidth  = this.props.windowSize.width,
				clientHeight = this.props.windowSize.height;

		let uiWindow       = this.uiWindowRef.current,
			uiWindowWidth  = uiWindow.offsetWidth,
		    uiWindowHeight = uiWindow.offsetHeight,
		    buttonPos      = this.state.buttonPos,
		    buttonX        = buttonPos.x,
		    buttonY        = buttonPos.y,
		    offsetX        = 1,
		    offsetY        = 1,
		    x              = null,
		    y              = null,
		    rowHeight      = Math.min(buttonPos.height, buttonPos.width);

		    // 1. UIWindow height defaults to 30px before filled with content
		    // 2. ButtonPos is based off the top left corner of the button;
		    // 	  this means all top bar elements are buttonY == 0

		    // Determine how/where we will open the uiWindow
		    // Open left if uiWindow width + button width won't exit viewport boundary.
		    if( uiWindowWidth + buttonX + rowHeight >= clientWidth ){
		    	// UIWindow opens from the right and expands left across the button.

		    	// Set horizontal position
		    	x = buttonX - uiWindowWidth;
		    	x = x + buttonPos.width

		    	// Adjust if the uiWindow overlays the right bar.
		    	if( x + uiWindowWidth >= clientWidth ){
		    		x = ( x - rowHeight )
		    	}
		    	this.horizontal = 'left'
		    } else {
		    	// UIWindow opens from the left and expands right across the button.
		    	x = buttonX;
		    	this.horizontal = 'right'
		    }

		    if( uiWindowHeight > buttonY || uiWindowHeight == 0 && buttonY == 0 ){
		    	// if top right corner of button is positioned less than 30px from viewport top
		    	y = buttonY + rowHeight;
		    	this.vertical = 'below'
		    } else {
		    	// UIWindow opens starting at the top of the button
		    	y = buttonY - offsetY
		    	if( y < rowHeight ){
		    		y = ( buttonY + rowHeight );
		    	}
		    	this.vertical = 'above'
		    }

		    this.setState({
		    	position: {x: x, y: y},
		    	rowHeight: rowHeight
		    });
	}

	setPositionCenteredBelow(){
		// For centering a formatting window directly below the button clicked to open it ( Top Bar )
		let uiWindowRect = this.uiWindowRef.current.getBoundingClientRect();
		let rowHeight    = Math.min( this.props.buttonPos.height, this.props.buttonPos.width );
		let x            = this.props.buttonPos.x + this.contentFrameWindowMargin - ( uiWindowRect.width / 2 );
		let y            = rowHeight + this.contentFrameWindowMargin;
		this.setState({ position: {x: x, y: y} })
	}

	positionChildFromParent(){
		// Used for positioning a window relative to a window it is opened from. ( Font Picker )
		let x = this.props.originWindowPos.left - this.adminBarSize;
		let y = this.props.originWindowPos.top;

		if( x < this.contentFrameWindowMargin ){
			x = this.contentFrameWindowMargin
		}
		
		// if( this.props.windowName == "font-picker" ){
		// 	y = y - 25;
		// 	x = x - 50;
		// }

		this.setInitialPosition(x,y)

	}

	positionOverButton() {
		// Code view places the window over the button it's opened from
		const 	clientWidth 	= this.props.windowSize.width,
				clientHeight 	= this.props.windowSize.height;

		const   buffer = 10; //10px buffer

		// Code view window. 
		let uiWindow   = this.uiWindowRef.current,
		uiWindowWidth  = uiWindow.offsetWidth,
	    uiWindowHeight = uiWindow.offsetHeight,
		buttonX        = this.state.buttonPos.x,
		buttonY        = this.state.buttonPos.y,
		offsetY        = this.props.buttonOffset?.top ?? -100,
		offsetX        = this.props.buttonOffset?.right ?? ( this.state.buttonPos.width - 100 ),
		x              = null,
		y              = null;

	    x = buttonX + offsetX;
	    y = buttonY + offsetY;

	    if( x + uiWindowWidth > clientWidth - buffer ){
	        // Open Left
	        x = clientWidth - uiWindowWidth - 20; 
	    }

	    if( uiWindowHeight + y > clientHeight ){
	        // Open above
	        y = clientHeight - uiWindowHeight - 20
	    }
	
	    this.setInitialPosition(x,y)
	}

	positionFromInvokeeWindow() {

		// Positions the window bottom/left from it's inception point.
		const 	clientWidth 	= this.props.windowSize.width,
				clientHeight 	= this.props.windowSize.height;

		let     buffer = 10; //10px buffer

		let uiWindow   = this.uiWindowRef.current,
		uiWindowWidth  = uiWindow.offsetWidth,
	    uiWindowHeight = uiWindow.offsetHeight,
		parentWindowX  = this.state.buttonPos.x,
		parentWindowY  = this.state.buttonPos.y,
		buttonWidth    = this.props.buttonPos.width,
		offsetY        = this.props.buttonOffset?.top ?? 0,
		offsetX        = this.props.buttonOffset?.right ?? 0,
		x              = null,
		y              = null;

		x = parentWindowX + offsetX;
		y = parentWindowY + offsetY;

	    if( x + uiWindowWidth > clientWidth - buffer ){
	        // Open Left
	        x = this.props.buttonPos.right - uiWindowWidth - offsetX
	    }

	    if( uiWindowHeight + y > clientHeight ){
	        // Open above
	        y = clientHeight - uiWindowHeight - offsetY;
	    }

	    this.setInitialPosition(x,y)

	}

	positionFromButton() {

		// Positions the window bottom/left from it's inception point.
		const 	clientWidth 	= this.props.windowSize.width,
				clientHeight 	= this.props.windowSize.height;

		let     buffer = 10; //10px buffer

		let uiWindow   = this.uiWindowRef.current,
		uiWindowWidth  = uiWindow.offsetWidth,
	    uiWindowHeight = uiWindow.offsetHeight,
		buttonX        = this.state.buttonPos.x,
		buttonY        = this.state.buttonPos.y,
		buttonHeight   = this.props.buttonPos.height,
		buttonWidth    = this.props.buttonPos.width,
		offsetY        = this.props.buttonOffset?.top ?? 0,
		offsetX        = this.props.buttonOffset?.right ?? 0,
		x              = null,
		y              = null;

		x = buttonX + offsetX;
		y = buttonY + buttonHeight + offsetY;

	    if( x + uiWindowWidth > clientWidth - buffer ){
	        // Open Left
	        x = this.props.buttonPos.right - uiWindowWidth - offsetX
	    }

	    if( uiWindowHeight + y > clientHeight ){
	        // Open above
	        y = clientHeight - uiWindowHeight - offsetY;
	    }

	    if( this.props.windowName == "font-picker" ){
	    	y = y - 35;
	    }

	    this.setInitialPosition(x,y)
	}

	setInitialPosition(x, y) {

		// this.setState({ position: {x: x, y: y} })
		let clampedPosition = this.getClampedWindowPosition({x, y}, undefined, 'init');
		
		this.setState({
			// drag: {
			// 	x: 0,
			// 	y: 0,
			// 	isDragged: false,
			// 	isDragging: false,
			// 	affinity: clampedPosition.affinity
			// },
			position: {
				x: clampedPosition.position.x,
				y: clampedPosition.position.y
			},
			visibility: clampedPosition.visibility
		})


	}

	handleViewportResize() {

		let maxHeight = calcWindowPercentage(80, 'height');
		maxHeight = Math.round(maxHeight);

		let maxInnerHeight = maxHeight - 2;

		this.setState({ 
			maxHeight: maxHeight,
			maxInnerHeight: maxInnerHeight
		})
	}



	onResize = (contentRect) =>{

		let xDelta = 0;
		let yDelta = 0;

		let el = {
			width: contentRect.width,
			height: contentRect.height
		}

		let clampedPosition = {...this.state.position}
		let clampedPositionObj = {};

		// OR condition covers windows that have not been dragged, but resize
		// due to content within the window that may change size after initial render
		// and can be opened as a sub window; e.g the file library. 
		if ( this.state.drag.isDragged && !this.state.drag.isDragging || 
			this.props.waitForHeightBeforeRender && !this.state.drag.isDragging 
		){
			clampedPositionObj = this.getClampedWindowPosition(undefined, el);
			clampedPosition = clampedPositionObj?.position;
		}

		this.setState({
			el: el,
			position: clampedPosition,
			visibility: clampedPositionObj?.visibility
		})
	}

	handleDrop = (editor, e, dragData) => {

		const windowContainer = this.uiWindowRef.current;

		// only accept drop events that occurring inside of the window's container
		if(!windowContainer || !windowContainer.contains(e.target)) {
			return;
		}

		e.preventDefault();

		if(this.props.acceptDrops === true){

			this.setState({
				windowIsDroppable: false
			})

			const droppedFiles = dragData.dataTransfer.get('files');

			// Check for incoming image models - even if drop gets handled elsewhere we'll still need those models
			if (dragData.dataTransfer?.get('image-models')) {

				const state = store.getState();

				const existingHashes = selectors.getMediaByParent(state)[this.props.PIDBeingEdited].reduce((prev, curr) => {
					return [...prev, curr.hash]
				}, [])
				
				let newModels = dragData.dataTransfer.get('image-models').filter(media => !existingHashes.includes(media.hash));

				if (newModels.length > 0) {

					// insert image in CRDT if not already there.
					const { CRDTItem: pageCRDT } = getCRDTItem({
						reducer: "pages.byId",
						item: this.props.PIDBeingEdited
					});

					// If the images for the page aren't in a CRDT, create a new Y Array to store them
					if (pageCRDT.get("media") instanceof Y.Array === false) {
						const sharedMediaType = new Y.Array();
						sharedMediaType.insert(0, pageCRDT.get("media") || []);
						pageCRDT.set("media", sharedMediaType);
					}

					// Convert models from plain objects to proper shared types
					newModels = newModels.map(model => convertStateToSharedType(model, new Y.Map())).filter(model => model instanceof Y.Map);

					pageCRDT.get('media').push(newModels);

				}
			}

			if(droppedFiles?.length > 0){

				if(this.props.mediaType === 'files') {

					// drop into the global media library
					Promise.all(
						uploadMedia({
							target: getCRDTItem({ reducer: 'media' }),
							field: 'data'
						}, droppedFiles)
					).then(results => {
						console.log(results, "Images added to file library");
					});

				} else {

					// drop in page
					if(this.props.PIDBeingEdited) {

						droppedFiles.forEach(async (file) => {
							if ( getSupportedFileTypes('image').includes(file.type) || getSupportedFileTypes('video').includes(file.type) ) {
								uploadMedia({
									target: getCRDTItem({ reducer: 'pages.byId', item: this.props.PIDBeingEdited }),
									field: 'media'
								}, [file])
							} else {
								uploadMedia({
									target: getCRDTItem({ reducer: 'media' }),
									field: 'data'
								}, [file])
								
								if( this.props.suppressFileWindow){
									return
								}

								this.props.addUIWindow({
									group: 'right-menu-bar',
									component: import('../right-menu-bar/library-window'),
									id: `library-window`,
									props: {
										type: 'popover',
										uiWindowType: 'popover',
										borderRadius: 'radius-all',
										// clickoutLayer: true,
										windowName: 'library',
										className: 'files',
										closeOnSingleClickout: true,
										invokeTarget: document.querySelector('.files-button'),
										invokeWindow: 'media-window',
										closeButton: false,
										positionType: 'from-button',
										avoidTransform: true,
										waitForHeightBeforeRender: true,
										minimumRenderHeight: 50,
										buttonOffset: {
											top: 10,
											right: -5
										},
										acceptDrops: true,
										buttonPos: document.querySelector('.files-button')?.getBoundingClientRect(),
										mediaType: 'files',
									}
								},{
									removeGroup: false
								});
							}
						})
					}

				}

				
				
			}
		}

	}

	handleDragOver = (editor, e, dragData) => {

		const pointerIsInsideWindow = this.uiWindowRef.current?.contains(e.target) ?? false;
		let isNew = true;

		if (dragData.dataTransfer?.get('image-models')) {

			const state = store.getState();

			const existingHashes = selectors.getMediaByParent(state)[this.props.PIDBeingEdited].reduce((prev, curr) => {
				return [...prev, curr.hash]
			}, [])
			const newModels = dragData.dataTransfer.get('image-models').filter(media => !existingHashes.includes(media.hash));

			if (newModels.length === 0) {
				isNew = false;
			}
		}

		if (dragData.dataTransfer?.get('file-models')) {
			isNew = false;
		}


		if( 
			// update every time the pointer event is inside the window
			pointerIsInsideWindow
		){
			
			if( this.props.draggingUploadedImage ){ return }

			this.setState({
				windowIsDroppable: isNew
			});

		} 
			// when leaving the window
			else 
		{

			if( this.props.draggingUploadedImage ){ return }

			this.setState({
				windowIsDroppable: false
			});

		}
	}

	closeButton = () => {
		return(
			<div className="close close-uiWindow">
			<AlertContext.Consumer>
				{(Alert) => (
					<Button
						onMouseDown={(e)=>{ 
							// If we pass hasAlert = true, pop alert before close
							if( !this.props.hasAlert ){
								this.closeWindow()
							} else {
								Alert.openModal({
									header: this.props.alertText,
									type: 'confirm',
									HotKeyProxy: HotKeyProxy,
									onConfirm: () => {
										Alert.closeModal();
										this.closeWindow();
									},
								})
							}
						}}
						label={
							<svg width="20" height="20" viewBox="0 0 20 20" fill="none">
								<circle opacity="var(--ui-close-circle-opacity)" cx="10" cy="10" r="10" fill="var(--ui-color-flat-reverse)"/>
								<path fillRule="evenodd" clipRule="evenodd" d="M10.0002 11.0607L14.3099 15.3703L15.3705 14.3096L11.0609 10L15.3705 5.69036L14.3099 4.6297L10.0002 8.93934L5.69054 4.62964L4.62988 5.6903L8.93958 10L4.62988 14.3097L5.69054 15.3704L10.0002 11.0607Z" fill="var(--ui-color-flat)"/>
							</svg>
						}
					/>
				)}
			</AlertContext.Consumer>
			</div>
		)
	}

	render() {

		const updateChildrenWithProps = this.props.windowName === "images" 
			? React.Children.map(this.props.children, (child => {
				return React.cloneElement(child, {
					windowIsDroppable: this.state.windowIsDroppable
				});
			})) : null;

		let outerStyles = {}
		let contentStyles = {}
		let innerStyles = {}

		outerStyles.maxHeight   = this.state.maxHeight ? this.state.maxHeight+'px' : '';
		contentStyles.maxHeight = this.state.maxHeight ? this.state.maxHeight+'px' : '';
		innerStyles.maxHeight   = this.state.maxHeight ? this.state.maxInnerHeight+'px' : '';

		if ( !this.positionType ||
			 this.positionType === 'center-under-button' ||
			 this.positionType === 'from-button' ||
			 this.positionType === 'over-button' 
		){
			outerStyles.top =	this.state.position.y ? this.state.position.y : '';
			outerStyles.left =	this.state.position.x ? this.state.position.x : ''; 
		}

		if (this.positionType === 'from-invokeeWindow') {
			outerStyles.top =	this.state.position.y ? this.state.position.y : '';
			outerStyles.left =	this.state.position.x ? this.state.position.x : ''; 
			outerStyles.position = 'fixed';
		}

		if ( this.state.drag.isDragged || ( this.state.positionFromSession && !this.ignoreSessionPosition ) ){

			outerStyles.transform = `translate(${this.state.drag.x}px, ${this.state.drag.y}px)`;
			outerStyles.top =	this.state.position.y+'px' 
			outerStyles.left =	this.state.position.x+'px'
			outerStyles.bottom = 'auto';
		}

		if( this.positionType === 'self-center' ){
			outerStyles.top = null;
			outerStyles.left = null;
			outerStyles.position = null;
		}

		// Strip transform style after dragging is complete.  
		if( 
			( this.state.drag.x == 0 || !this.state.drag.x ) &&
			( this.state.drag.y == 0 || !this.state.drag.y ) 
		){
			outerStyles.transform = null;
		}

		if( !this.state.drag.isDragged &&
			!this.state.drag.wasDragged && 
			this.positionType &&
			this.positionType.toString() === 'center' &&
			( !this.props.sessionPosition?.[this.props.id] || this.ignoreSessionPosition )
		){
			// Only apply to un-dragged forced centered windows
			// Dragged windows cannot have any conflicting transform properties.
			if (this.props.avoidTransform) {
				outerStyles.left = (window.innerWidth/2) - (this.state.el.width / 2);
				outerStyles.top = (window.innerHeight/2) - (this.state.el.height / 2);
			} else {
				outerStyles.transform = 'translate(-50%, -50%)';
			}
		}

		outerStyles.zIndex = this.state.zIndex;

		if( this.props.waitForHeightBeforeRender ){
			outerStyles.visibility = this.state.visibility;
		}

		return (
			<>
			<div
				className   	= {`uiWindow${this.props.className ? (" "+this.props.className) : ""}${this.props.extraClass ? (" "+this.props.extraClass) : ""}${this.state.className ? (" "+this.state.className) : ""}${this.state.drag.isDragging ? ' dragging' : ''}${this.props.clickoutLayer || this.props.preventClickout ? ' hoisted' : ''}${this.props.supportsMobile && this.props.viewportMobile ? " mobile" : ""}${this.props.legacyWindow ? " foundation-css" : ""}`}
				window-name 	= {`${this.props.windowName ? this.props.windowName : ""}`}
				window-id		= {`${this.props.id ? this.props.id : ''}`}
				style       	= { outerStyles }
				type        	= { this.props.type }
				tabbed      	= { _.get(this.props, 'tabbed') ? _.get(this.props, 'tabbed').toString() : null }
				droppable		= { this.state.windowIsDroppable ? 'true' : '' }
				position    	= { this.positionType ? this.positionType.toString() : undefined }
				ref         	= { this.uiWindowRef }
				onMouseDown	= { this.onMouseDown }
				full-height		= { this.props.fullHeight === true ? 'true' : null }
				full-width		= { this.props.fullWidth  === true ? 'true' : null }
				invokee-state	= { this.props.invokeeState }
			>
			<MenuContext.Consumer>
			{(Menu) => 
				<AlertContext.Consumer>
					{(Alert) => 
						<div 
							className="uiWindow-content"
							style={ contentStyles }
							onContextMenu={ (e)=>{
								if (typeof this.props.onWindowRightClick === 'function') {
									this.props.onWindowRightClick(e);
								}
							}}
						>
							<div 
								className = "uiWindow-inner"
								style     = { innerStyles }
							>
								{ this.props.closeButton === true ? ( this.closeButton() ) : ( null )}
								{ this.props.windowName === "images" && updateChildrenWithProps ? ( updateChildrenWithProps ) : ( 
		                            <>
		                                {React.cloneElement(this.props.children, { 
		                                	superBadScrollHack: this.superBadScrollHack,
		                                	uiWindowProps: this.props,
		                                	menuContext: Menu,
		                                	alertContext: Alert
		                                }) }
		                            </>
								)}
							</div>

						</div>
				}
				</AlertContext.Consumer>
			}
			</MenuContext.Consumer>
			</div>

			{ this.props.clickoutLayer || this.props.preventClickout ?

				<div 
					onMouseDown={
						e=> {
							e.persist();
							this.clickout(e);
						}
					}
					className={`clickout-layer${
						this.props.type === "focus-modal" || this.props.clickoutLayerDim ? ' dim' 
						: this.props.clickoutLayerDimDark ? ' dim dark' : ''}`
					}
					adjoined-window={this.props.clickoutLayerID}
				></div>

			: null }

			</>
		)
	}

	clickout = (e) => {
		// the clickout layer is handled in the window-ui-layer by default
		if (this.props.preventClickout) {
			e.preventDefault();
			// e.stopImmediatePropagation();
			return;	
		} 

		if (this.props.clickoutLayer) {
			e.preventDefault();
			e.stopPropagation();
			this.closeWindow();
		}
	}

	setZIndex = ( type ) => {

		let otherWindows = Array.from(
			document.querySelectorAll('.uiWindow:not([window-name="'+this.props.windowName+'"])')
		).filter(uiWindow => {
			// when mounting a few windows at the same time the DOM will already have nodes
			// for windows that haven't been received their componentDidMount call yet. This will
			// mess with zIndex setting and cause windows to appear behind others when restoring them 
			// after preview is closed
			mountedUIWindows.has(uiWindow)
		});
		let baseZIndex = 320;
		let maxZIndex = 355; // we accomodate a maximum of 35 window layers.
		let currentZIndex = baseZIndex;

		if( this.uiWindowRef?.current ){
			// Get the Z index of THIS window.
			currentZIndex = parseInt( getComputedStyle( this.uiWindowRef?.current )?.getPropertyValue('z-index') );
		}

		// Start by setting awareness at either this window or assume it's the bottom
		let highestZIndex = currentZIndex >= baseZIndex ? currentZIndex : baseZIndex;
		// Currently any other window is assumed to be below.
		let otherWindowHighest = 0;
		// Is the highest window in the stack going to close when we click?
		let higherWindowIsSingleClickout = false;
		// Start getting the Z index of all other windows.
		_.each(otherWindows, (uiWindow)=> {
			let zIndex = getComputedStyle(uiWindow).getPropertyValue('z-index');
			//if the z index of the window being looped over is higher than our current highest
			if( zIndex > highestZIndex && zIndex > currentZIndex ){
				highestZIndex = parseInt( zIndex ); // convert it to a number and mark it as the highest
			}
			// if the zIndex of the window we're looping over is greater than 0 OR some other window we've looped over
			if( zIndex > otherWindowHighest ){
				let windowId = uiWindow.getAttribute('window-id');
				let windowToLayer = _.find(this.props.uiWindows.byId, (uiWindow) => { return uiWindow.id === windowId });
				// Mark that it is a clickout window sitting above the current window.
				if( windowToLayer?.props?.closeOnSingleClickout ){
					higherWindowIsSingleClickout = true;
				}
				// Parse int and register it as the highest window in the stack
				otherWindowHighest = parseInt( zIndex );
			}
		})

		// If the highest window is single clickout
		// and THIS window's Z index isn't the highest 
		// and we're getting this on mount 
		// NOTE:
		// Stopping here allows single clickout windows to closen when clicking 
		// on a window lower in the stack, without re-ordering anything. 
		if( higherWindowIsSingleClickout && currentZIndex !== highestZIndex && type !== 'mount' ){ return }

		// If THIS window's Z index is less than the highest Z index or THIS windows Z index is less than the highest possible Z index...
		if( currentZIndex <= highestZIndex || currentZIndex <= maxZIndex ){
			// If the highest Z index window is greater than the max Z index
			if( highestZIndex >= maxZIndex ){
				// Set it to the bottom of the stack.
				highestZIndex = baseZIndex
				// Set all other windows in order up from the base.
				_.each(otherWindows, (uiWindow)=> {
					let windowId = uiWindow.getAttribute('window-id');
					this.props.updateUIWindow(windowId, {zIndex: highestZIndex})
					highestZIndex = highestZIndex++
				})
				// Set THIS window higher than all the other windows ( to put it back at the top )
				// This keeps our windows within the z-index range between 320 - 355 so they never layer over
				// things like notices and alerts.
				let nextIndex = highestZIndex+1
				this.props.updateUIWindow(this.props.id, {zIndex: nextIndex });
				return
			}
			// If we're clicking or opening THIS window and it's not the highest in the stack, set it to be the highest in the stack.
			if( currentZIndex <= highestZIndex && currentZIndex <= otherWindowHighest ){
				let nextIndex = highestZIndex+1
				this.props.updateUIWindow(this.props.id, {zIndex: nextIndex })
			}
		}

	}

	// all drag events
	onMouseDown = (e) =>{

		if ( e.button != 0 || this.props.disableDragging ){
			return;
		}
		
		// do not allow drag for these window types
		if (
			this.props.type === 'fixed-pane' 
			||
			this.props.type === 'focus-modal'
		) return;

		// drag blacklist
		if ( e.target.tagName == 'IMG' ||
			e.target.closest('button') ||
			e.target.closest('input') ||
			e.target.closest('a') ||
			e.target.closest('.disable-drag') ||
			e.target.closest('window-tab[chosen="false"]') ||
			e.target.closest('.radio-button') ||
			e.target.closest('.clickout-layer') ||
			e.target.closest('code-editor') ||
			e.target.closest('.ui-element') ||
			e.target.closest('.file[draggable="true"]') ||
			// temporary page list handle
			e.target.closest('.drag-item')
		) {
			return;
		}

		// drag whitelist
		if ( e.target.closest('.ui-group') &&
			!e.target.classList.contains('more-actions') &&
			!e.target.classList.contains('uiWindow-spacer') &&
			!e.target.classList.contains('help') &&
			!e.target.classList.contains('section-header')
		) {
			return;
		}

		// if the position is set by CSS, then we need to get its rect before initing the drag
		if ( this.positionType ){
			const rect = this.uiWindowRef.current.getBoundingClientRect();
			this.setState({
				position: {
					x: rect.left,
					y: rect.top
				}
			})
		}

		this.startPointerX = this.currentPointerX = e.clientX;
		this.startPointerY = this.currentPointerY = e.clientY;

		this.pointerMoveTick = requestAnimationFrame(this.updateDragPosition);

		window.addEventListener('pointermove', this.onPointerMove);
		window.addEventListener('pointerup', this.onPointerUp);
		window.addEventListener('pointercancel', this.onPointerUp)

	}

	onPointerMove = (e) => {
		e.preventDefault();
		this.currentPointerX = e.clientX;
		this.currentPointerY = e.clientY;
	}

	updateDragPosition = (e)=> {

		let xDelta = this.currentPointerX - this.startPointerX;
		let yDelta = this.currentPointerY - this.startPointerY;
		let deltaIsUnchanged = xDelta === 0 && yDelta === 0;
		let el = this.state.el;

		// apply pointer events when starting drag, but only if the mouse has actually moved (deltaIsUnchanged)
		// note: this is the easiest way to stop click propagation and iframe from swallowing events for now
		if ( !this.state.drag.isDragging && !deltaIsUnchanged ){
			document.body.style.pointerEvents = 'none';
			document.getElementById('device-viewport').style.pointerEvents = 'none'
		}

		if( !deltaIsUnchanged ){

			if( !this.state.drag.isDragging ){
				this.setZIndex('drag');
			}

			const position = {...this.state.position}
			const clampedPosition = this.getClampedWindowPosition({
				x: position.x+xDelta,
				y: position.y+yDelta,
			})

			this.setState({
				drag: {
					x: clampedPosition.position.x - position.x,
					y: clampedPosition.position.y - position.y,
					isDragged: true,
					isDragging: true,
					wasDragged: true,
					affinity: clampedPosition.affinity
				},
				visibility: clampedPosition.visibility
			}, ()=>{
				this.pointerMoveTick = requestAnimationFrame(this.updateDragPosition)			
			})
		} else {

				this.pointerMoveTick = requestAnimationFrame(this.updateDragPosition)
		}


	}

	onPointerUp = (e) => {

		if( !this.state.drag.isDragging ){
			this.setZIndex('pointerup');
		}

		cancelAnimationFrame(this.pointerMoveTick);

		this.startPointerX = this.currentPointerX = 0;
		this.startPointerY = this.currentPointerY = 0;

		// drag = transform, position = top/left
		// after release, combine the two so the element has no transforms

		let drag = {...this.state.drag};
		let position = {...this.state.position};

		this.setState({
			drag: {
				x: 0,
				y: 0,
				isDragged: true,
				isDragging: false,
				wasDragged: true,
				affinity: drag.affinity
			},
			position: {
				x: Math.round(drag.x+position.x),
				y: Math.round(drag.y+position.y)
			}
		})

		// let the window layer know dragging is happening
		// note: the timeout is to be sure it does not trigger a close from the window ui layer
		setTimeout(_.bind(function(){
			this.props.updateUIWindow(this.props.id, {dragging: false})
		},this),200)

		// reset pointer events
		document.body.style.pointerEvents = '';
		document.getElementById('device-viewport').style.pointerEvents = ''

		window.removeEventListener('pointermove', this.onPointerMove);
		window.removeEventListener('pointerup', this.onPointerUp)
		window.removeEventListener('pointercancel', this.onPointerUp)		
	}

	getClampedWindowPosition = (attemptedPosition = this.state.position, el = this.state.el, type ) =>{
		const prevAffinity = {...this.state.drag.affinity}

		// empty space enforced around the window
		const margin = this.contentFrameWindowMargin;
		// Additional margin only applied on open. Further modification required to 
		// fully limit draggable area. 
		const additionalMarginTop    = this.props.additionalMarginTop    && type == 'init' ? this.props.additionalMarginTop    : 0;
		const additionalMarginRight  = this.props.additionalMarginRight  && type == 'init' ? this.props.additionalMarginRight  : 0;
		const additionalMarginBottom = this.props.additionalMarginBottom && type == 'init' ? this.props.additionalMarginBottom : 0;
		const additionalMarginLeft   = this.props.additionalMarginLeft   && type == 'init' ? this.props.additionalMarginLeft   : 0;

		// the amount of the window that must not overlap the borders
		// macOS behavior would be something like {top: el.height, left: 2, right: 2, bottom: 2}
		const minUnderlap = {
			top   : el.height + margin + additionalMarginTop,
			bottom: el.height + margin + additionalMarginBottom,
			left  : el.width + margin + additionalMarginLeft,
			right : el.width + margin + additionalMarginRight,
		}	

		//buttonPos.width/height is not reliable for all ui windows. going to hardcode limits instead... for now
		const rowSize = {
			height: this.adminBarSize,
			width: this.adminBarSize
		}

		const maximumDragHeight = el.height + (margin * 2) + rowSize.height + 1;
		// 1 extra pixel to account for borders

		// when resizing, we either maintain position in window or clamp to the nearest edge(s)
		// these zones tell us when to use that behavior
		// if two opposing zones are active (top and bottom), keep with previous one
		const zones = {
			top: 50,
			left: 100,
			right: 100,
			bottom: 50
		}

		const rightEdge = this.props.windowSize.width+-rowSize.width+-minUnderlap.right;
		const leftEdge = minUnderlap.left;

		const topEdge = minUnderlap.top+rowSize.height;
		const bottomEdge = this.props.windowSize.height+-minUnderlap.bottom;

		let position = {...attemptedPosition};

		let visibility = !this.uiWindowRef.current?.offsetHeight ? 'hidden' : '';

		if( this.props.waitForHeightBeforeRender 
			&& this.props.minimumRenderHeight 
			&& this.uiWindowRef.current?.offsetHeight <= this.props.minimumRenderHeight 
		){
			visibility = 'hidden';
		}
		
		let affinity = {
			top:  		Math.max( 0, (topEdge-minUnderlap.top) - (attemptedPosition.y + -zones.top) ),
			bottom: 	Math.max( 0, (attemptedPosition.y+el.height + zones.bottom)  -  (bottomEdge+minUnderlap.bottom) ),
			right: 		Math.max( 0, (attemptedPosition.x+el.width + zones.right) - (rightEdge+minUnderlap.right) ),
			left: 		Math.max( 0, (leftEdge-minUnderlap.left) - (attemptedPosition.x + -zones.left) )
		}

		// resolve affinity conflicts by comparing
		if ( affinity.top > 0 && affinity.bottom > 0 ){
			if ( affinity.top >= affinity.bottom ){
				affinity.bottom = 0;
			} else {
				affinity.top = 0;
			}
		}

		if ( affinity.right > 0 && affinity.left > 0 ){
			if ( affinity.right >= affinity.left ){
				affinity.left = 0;
			} else {
				affinity.right = 0;
			}
		}

		// clamp movement to edge
		if ( attemptedPosition.y >= bottomEdge ){
			position.y = bottomEdge
		}

		if (attemptedPosition.y + el.height <= topEdge){
			position.y = topEdge + -el.height
		}

		if ( attemptedPosition.x >= rightEdge ){
			position.x = rightEdge
		}

		if (attemptedPosition.x + el.width <= leftEdge){
			position.x = leftEdge + -el.width;
		}
		// If the window is max height allowed within our draggable boundaries, don't change the y position
		if( maximumDragHeight >= this.props.windowSize.height ){
			position.y = bottomEdge ;
		}

		return {position, affinity, visibility}

	}

	onWindowResize = (prevWindowSize)=>{

		// items that havent been dragged already know their place
		if ( !this.state.drag.isDragged && !this.state.positionFromSession ){
			return;
		}

		const diffWidth = this.props.windowSize.width - prevWindowSize.width;
		const diffHeight = this.props.windowSize.height - prevWindowSize.height;

		let {x, y} = this.state.position;
		let el = {...this.state.el};

		if ( this.state.drag.affinity.top) {

		} else if ( this.state.drag.affinity.bottom) {

			y = y+diffHeight

		} else {

			let prevPosYPercentage = (y + el.height*.5)/prevWindowSize.height
			y = prevPosYPercentage * this.props.windowSize.height + -el.height*.5
		}

		if ( this.state.drag.affinity.left) {

		} else if ( this.state.drag.affinity.right) {

			x = x+diffWidth

		} else {

			let prevPosYPercentage = (x + el.width*.5)/prevWindowSize.width
			x = prevPosYPercentage * this.props.windowSize.width + -el.width*.5
		}

		let clampedPosition = this.getClampedWindowPosition({x, y}, undefined).position;

		this.setState({
			position: clampedPosition
		})

	}

	closeWindow = () => {

		this.props.removeUIWindow(uiWindow => {
			return uiWindow.props.windowName === this.props.windowName
		});
	}

}

function mapReduxStateToProps(state, ownProps) {

	return {
		uiWindows: state.uiWindows,
		sessionPosition: state.adminState?.session?.uiWindowPosition,
		lastClickCoordinates: state.adminState?.lastClickCoordinates,
		viewportMobile: state.adminState?.viewport === 'mobile',
		PIDBeingEdited: state.frontendState.PIDBeingEdited
	};

}

function mapDispatchToProps(dispatch) {
	
	return bindActionCreators({
		removeUIWindow: actions.removeUIWindow,
		updateUIWindow: actions.updateUIWindow,
		addUIWindow: actions.addUIWindow,
	}, dispatch);

}


export const UIWindow = connect(
	mapReduxStateToProps, 
	mapDispatchToProps
)(UIWindowClass);
// export {UIWindow};
