import { UploadProgressIndicator } from './upload-progress-indicator';
import { useState, useEffect, useRef } from 'react';
import { helpers } from "@cargo/common";
import LinkIcon from "../../svg-icons/formatting-link.svg";

const Thumbnail = ({isDraggable, onDragStart, onDragEnd, onLoad, isSelected, onSelect, media, isSquare, style, children, width = 137, preventLoad}) => {

    const urlLabelRef = useRef(null);
    const [ isDragging, setIsDragging ] = useState(false);
    const [ aspectRatio, setAspectRatio ] = useState('1 / 1');
    const [ hideFunctions, setHideFunctions ] = useState(false);

    if( !media ){
        return null;
    }

    useEffect(() => {


        if(isSquare) {
            return setAspectRatio(`1 / 1`);
        }

        const width = media.width;
        const height = media.height;

        switch (true) {
            // portrait
            case width/height <= 0.6667: {
                setAspectRatio('2 / 3');
                break;
            }
            // landscape
            case width/height >= 1.7778: {
                setAspectRatio('16 / 9');
                break;
            }
            default: {
                setAspectRatio(`${width} / ${height}`);
                break;
            }
        }

    }, [media, isSquare])

    if (
        // if media model is loading...
        media.loading ||
        // or if it's video and the poster is in place but loading
        (media.poster && media.poster.loading) 
    ){
        return (
            <div className={`thumbnail ${ isSelected ? "selected" : ""}`} style={style}>
                <UploadProgressIndicator hash={media.hash} />
            </div>
        )
    }

    // This is pretty hack-y, but Safari doesn't offer an easy way to
    // merge in the image as a background image
    // this is to prevent Apple's LiveText feature from causing dragging issues for thumbnails while trying to create selectable text    
    const isSafari = navigator.userAgent.indexOf("Safari") > -1 && navigator.userAgent.indexOf("Chrome") < 0;

    let url = '';

    let requestedWidth = width * 2;

    if (media.width && media.height && media.width/media.height >= 1.7778) {
        const renderedHeight = width * .5625;
        requestedWidth = renderedHeight * (media.width/media.height);
    }

    if( media.is_image ){
        url =`https://freight.cargo.site/w/${Math.floor(requestedWidth)}/i/${media.hash}/${media.name}`;  
    } else if( media.is_video && media.poster){
        url = `https://freight.cargo.site/w/${Math.floor(requestedWidth)}/i/${media.poster.hash}/${media.poster.name}`
    } else if ( media.url_thumb){
        url = media.url_thumb;
    }

    style = {...style, ...{
        'backgroundImage': `url("${url}")`,
        'backgroundRepeat': 'no-repeat',
        'backgroundPosition': 'center',
        'backgroundSize': 'cover',
        'objectFit': 'cover',
    }}

    return (
        <div 
            key={media.hash}
            onDragStart={e => {
                setIsDragging(true)
                onDragStart(e)
            }}
            onDragEnd={e => {
                setIsDragging(false)
                onDragEnd(e)
            }}
            className={`thumbnail${ isSelected ? " selected" : ""}`}
        >
            <div 
                className="frame" 
                onMouseUp={() => setHideFunctions(false)} 
                onMouseLeave={() => setHideFunctions(false)}
            >

                <div
                    data-hash={media.hash}
                    data-image-src={url}
                    draggable={isDraggable}
                    onMouseDown={e => {
                        setHideFunctions(true);
                        onSelect(e);
                    }}
                    className={`thumb-image`}
                    style={style}
                >
                </div>
                {media.is_url ? <div className="url-label"> <LinkIcon/></div> : null }    
                <div className="functions" style={{opacity: hideFunctions ? 0 : 1}}>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Thumbnail;

export {Thumbnail};
