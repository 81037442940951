import { FRONTEND_DATA } from "../../../globals";
import _ from 'lodash';

export default {

	requiresTextInRange: false,
	requiresUncollapsedRange: false,
	requiresActiveRange: false,

	execute: function(newSize){

		const state = this.getState();
		let flyingObject = null;

		const svgPosition = {
			x: 0,
			y: 0,
			left: 0,
			right: 0,
			top: 0,
			bottom: 0,
			width: 0,
			height: 0
		}

	},
	

	getState: (range) => {

		const flyingObjectNodes = FRONTEND_DATA.contentWindow.document.querySelectorAll('[uses~="flying-object"]');

		return {
			isAllowed: true,
			isApplied: flyingObjectNodes.length > 0,
		}

	},
	priority: 1
 
}