import { FRONTEND_DATA } from "../../../globals";
import { 
	rangeCanBeAligned
} from "../helpers";

export default {

	requiresTextInSelection: false,
	requiresUncollapsedRange: true,
	requiresActiveRange: true,

	execute: function(){
		return false
	},
	getState: function(range) {

		let selectionInThumbnailIndex = range && range.commonAncestorContainer && range.commonAncestorContainer.parentElement.closest('[thumbnail-index]') || range.commonAncestorContainer.hasAttribute?.('thumbnail-index');


		// This command file serves as a proxy for the align left/right/center flyout menus.
		return {
			isApplied: false,
			isAllowed: !selectionInThumbnailIndex && rangeCanBeAligned(range),
		};
	},
	priority: 0,

}