import { FRONTEND_DATA } from "../../../globals";
import { 
	rangeCanBeModifiedByTextCommands,
	getAllParents,
} from "../helpers";

export default {

	requiresTextInRange: true,
	requiresUncollapsedRange: true,
	requiresActiveRange: true,
	canBeOverruledByExternalStyle: true,

	execute: function(){
		FRONTEND_DATA.contentWindow.CargoEditor.execCommand('underline');
	},
	getState: function(range) {
		return {
			isApplied: FRONTEND_DATA.contentWindow.CargoEditor.queryCommandState('underline'),
			isAllowed: rangeCanBeModifiedByTextCommands(range)
		};
	},	
	priority: 0

}