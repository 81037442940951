import { FRONTEND_DATA } from "../../../globals";
import { 
	rangeCanBeModifiedByTextCommands
} from "../helpers";

export default {

	requiresTextInRange: true,
	requiresUncollapsedRange: true,
	requiresActiveRange: true,
	canBeOverruledByExternalStyle: true,

	execute: function(){
		FRONTEND_DATA.contentWindow.CargoEditor.execCommand('bold');
	},
	getState: function(range) {

		return {
			isApplied: FRONTEND_DATA.contentWindow.CargoEditor.queryCommandState('bold'),
			// isAllowed: rangeCanBeModifiedByTextCommands(range) && !FRONTEND_DATA.contentWindow.CargoEditor.isCommandOverruledByExternalStyle('bold')
			isAllowed: rangeCanBeModifiedByTextCommands(range)
		};
	},	
	priority: 0

}