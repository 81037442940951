import React, {Component} from 'react';
import { Formik, Field, Form } from 'formik';
import { processCSSValue } from "./helpers";
import { globalUndoManager } from "../../lib/undo-redo";
import BezierEasing from 'bezier-easing';
import _ from 'lodash';

// This range can accept string or number
// it will set a string, eg '6rem' or '4.5'
class RangeInput extends Component {

	constructor(props){
		super(props);
		this.percentage = null;
	}

	render(){
		let separatedValue = processCSSValue(this.props.field.value) || [0, ''];
		const unit = this.props.showUnits ? separatedValue[1] : '';
		return <GenericRange
			{...this.props}

			onChange={this.onChange}
			value={separatedValue[0]}
			unit={unit}
		/>
	}

	onChange = (value) => {

		const {form, field} = this.props;
		if ( !form || !field ) {
			return 
		}

		let separatedValue = processCSSValue(field.value) || [0, ''];

		let completeValue = value+separatedValue[1];

		if( this.props.units && !completeValue.includes(this.props.units) ){
			completeValue = value+this.props.units;
		}

		form.setFieldValue(field.name, ''+completeValue, false);

	}
}


// only deals in unitless values
// gets and sets numbers
class NumberRangeInput extends Component {

	constructor(props){
		super(props);
	}

	render(){

		const {form, field} = this.props;

		return <GenericRange
			onChange={this.onChange}
			value={field.value}
			{...this.props}
		/>
	}

	onChange = (value) =>{

		const {form, field} = this.props;
		if ( !form || !field ) {
			return 
		}

		form.setFieldValue(field.name, value, false);	
	}
}


// this range is generic, and does not need to know a name, field or form
// it cannot be used by Formik directly
class GenericRange extends Component {

	constructor(props) {
		super(props);

		this.rangeRef = React.createRef();
		this.rect = null;
		this.rangeIndicatorClickCount = 0;

		if(props.easing) {

			if(props.easing.length !== 4) {
				throw '"easing" prop should be an array with 4 control points. See https://cubic-bezier.com/ to generate a curve.'
			}

			// bezier easing points are passed as an array of 2 coords [p1.x, p1.y, p2.x, p2.y]

			// convert a linear percentage to an eased percentage
			this.linearPercentageToEasedPercentage = BezierEasing(props.easing[0], props.easing[1], props.easing[2], props.easing[3])
			
			// flipping x & y on p1 and p2 gives us the reversed curve to reverse an eased percentage back to a linear percentage
			this.easedPercentageToLinearPercentage = BezierEasing(props.easing[1], props.easing[0], props.easing[3], props.easing[2])

		}

	}


	handlePointerDown = (event) => {

		this.dragged = false;

		if ( event.button === 2 || !this.rangeRef.current ){
			return
		}

		// pause undo/redo reporting while manipulating slider
		globalUndoManager.pause();

		this.rect = this.rangeRef.current.getBoundingClientRect();

		this.setValueInRange(event);

		if(this.props.onMouseDown){
			this.props.onMouseDown(event);
		}

		event.preventDefault();

		window.addEventListener('pointermove', this.handlePointerMove)
		window.addEventListener('pointercancel', this.handlePointerUp)
		window.addEventListener('pointerup', this.handlePointerUp)	
		window.addEventListener("click", this.onClickCapture, true)

		// hacky way of preventing content iframe from swallowing drags
		document.getElementById('device-viewport').style.pointerEvents = 'none'			


	}

	handleRangeIndicatorClick = event => {

		if(this.rangeIndicatorClickCount >= 1 ) {
			// We're on our second "click", hit double click logic.
			if(this.rangeIndicatorClickCount === 1) {
				this.handleRangeIndicatorDoubleClick(event)
			}
			// already in a click. Don't set another timer
			return;
		}

		this.rangeIndicatorClickCount++;

		setTimeout(() => {
			this.rangeIndicatorClickCount = 0;
		}, 300);

	}

	handleRangeIndicatorDoubleClick = event => {
		if ( this.rangeRef.current ){
			this.rangeRef.current.dispatchEvent(
				new CustomEvent('range-indicator-double-click', {
						bubbles: true,
						// place to store metadata
						detail: {
							type: 'range',
							name: this.props.field.name,
							value: this.props.field.value
						}
					}
				)
			)
		}
	}

	handlePointerMove = (event) => {

		event.preventDefault();

		this.dragged = true;
		this.setValueInRange(event);
	}

	handlePointerUp = (event) => {

		if ( this.dragged ){
			event.preventDefault();
			event.stopPropagation();
		}

		if(this.props.onPointerUp){
			this.props.onPointerUp(event);
		}

		// only report the final value
		globalUndoManager.resume();

		window.removeEventListener('pointermove', this.handlePointerMove)
		window.removeEventListener('pointercancel', this.handlePointerUp)
		window.removeEventListener('pointerup', this.handlePointerUp)

		document.getElementById('device-viewport').style.pointerEvents = ''

	}

	onClickCapture = (event) => {

		if (this.dragged){
			event.preventDefault();
			event.stopPropagation();
		}

		// only report the final value
		globalUndoManager.resume();
		
		window.removeEventListener("click", this.onClickCapture, true);

	}

	stepRound(value, step) {
		step || (step = 1.0);
		var inv = 1.0 / step;
		return Math.round(value * inv) / inv;
	}

	setValueInRange(event) {

		const {min, max, step=parseFloat(step)} = this.props;

		let percentage = Math.max(Math.min(1,(event.clientX-this.rect.left)/this.rect.width), 0);

		if( this.props.easing ){
			percentage = this.linearPercentageToEasedPercentage( percentage );
		}

		let steppedValue = (1-percentage)*min + (percentage)*max;

		if( this.props.customChangeFn ){
			steppedValue = this.props.customChangeFn( steppedValue, step, percentage )
		}

		if ( !isNaN(step) && !this.props.customChangeFn ){
			steppedValue = this.stepRound(steppedValue, step);
		}

		if(this.props.onChange && !isNaN( steppedValue )){
			this.props.onChange(steppedValue);
		}

	}

	processDisplayValue( clampedValue ){

		let displayValue = clampedValue;

		if( Math.abs(clampedValue) < 1 && parseFloat(clampedValue) !== 0 && !this.props.showLeadingZero ){
			if ( clampedValue > 0 ){
				// Strip leading 0 from any number less than 1
				displayValue = clampedValue.toString().replace(/^0+/, '');
			} else {
				displayValue = clampedValue.toString().replace(/^-0+/, '');
				displayValue = '-'+displayValue;
			}
		}

		return displayValue
	}

	render(){
		const {value, min, max, step, label, unit ='', suffix = '', name, ...props} = this.props;

		const borderWidth = 2;
		let clampedValue = Math.max(Math.min(value, max), min) || value;
		let displayValue = this.processDisplayValue( clampedValue );

		let indicatorPosition = (value - min)/(max-min);

		if( this.props.easing ){
			indicatorPosition = this.easedPercentageToLinearPercentage( indicatorPosition );
		}

		let rangeBarWidth =`calc(${indicatorPosition*100}% + -${indicatorPosition*borderWidth}px)`	

		return(
			<div 
				className={`range${this.props?.disabled ? ' disabled' : ''}${this.props?.className ? ' '+this.props?.className : ''}`} 
				onMouseDown={this.handlePointerDown}
			>

				<label>
					{label}
					<div className="range-counter">
							<span>
								{ this.props.showRangeValue ? (<>{displayValue}</>) : this.props.isParent ? "Mixed" : (<>{displayValue}</>) }{unit}{suffix}
							</span>
					</div>
				</label>

				<div className="range-slider" ref={this.rangeRef}>
					<div
						className="range-bar"
						style={{
							width: rangeBarWidth
						}}
					>
					</div>
					<div
						className="range-indicator"
						onMouseDown={this.handleRangeIndicatorClick}
						style={{
							left: rangeBarWidth
						}}
					></div>
				</div>
				
				<input
					type="range"
					min={min}
					max={max}
					step={step}
					defaultValue={clampedValue}
				/>
				
			</div>
		)
	};

}

GenericRange.defaultProps = {
	min: 0,
	max: 100,
	step: 1,
	value: 50,
};

export {RangeInput, GenericRange, NumberRangeInput}
