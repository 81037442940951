import React, { useRef } from 'react';
import _ from 'lodash';

import { TooltipContext } from "@cargo/common/tooltip";

export const RadioButton = (props) =>{

	const {form, field, title, tooltip, onPointerLeave, onPointerEnter, icon, disabled} = props;

	const sanitizedName = String(field.name).replace(/,/g, '-').replace(/\./g, '');

	// generate a quasi-unique ID to link label and input.
	const generated_id = "radio" + '/' + sanitizedName + '/' + props.value;

	const buttonRef = useRef(null);

	let isChecked = props.visuallyForceNull ? false : _.isEqual(props.value, field.value) ? true : false;

	return (
		<TooltipContext.Consumer>
		    {(Tooltip) => 
				<div 
					className={`radio-button${ isChecked ? ' checked' : ''}${props.className ? (' '+props.className) : ('')}`}
					title={title || null}
					ref={ buttonRef }
					onPointerUp={props?.onPointerUp}
					disabled={disabled}
					onMouseDown={(e)=> {

						form.setFieldValue(field.name, props.value, false)
						props.visuallyForceNull && form.setFieldTouched(field.name, props.value, false)

						if ( tooltip ) {
							Tooltip.closeTip?.(e)
						}

					}}
					onPointerEnter={(e)=> {

						if (onPointerEnter) {
							onPointerEnter(e)
						}

						if( Tooltip?.openTip ){
							let buttonPos = buttonRef.current.getBoundingClientRect();

							Tooltip.openTip?.({
							    innerText: tooltip,
							    shortcutText: null,
							    buttonPos: {
							    	x: buttonPos.x,
							    	y: buttonPos.y,
							    	width: buttonPos.width,
							    	height: buttonPos.height,
							    	customOffsetX: props.tooltipOffsetX,
							    	customOffsetY: props.tooltipOffsetY
							    },
							    event: e,
							})
						}

					}}
					onPointerLeave={(e) => { 

						if (onPointerLeave) {
							onPointerLeave(e)
						}

						if( Tooltip?.closeTip ){
							Tooltip.closeTip?.(e)
						} 
						 

					}}
				>
					<input
						type="radio"
						id={generated_id}
						value={props.value}
						onChange={(e) => {}}
						checked={ isChecked }
						onBlur={field.onBlur}
					/>
					<label htmlFor={generated_id} >
						{icon ? icon : props.label}
					</label>
				</div>
			}
		</TooltipContext.Consumer>
		
	);
};
