import React, {Component, Fragment} from 'react';
import _ from 'lodash';
import { helpers } from "@cargo/common";
import { FRONTEND_DATA } from "../../globals";
import { globalUndoManager } from "../../lib/undo-redo";
import { Button } from '@cargo/ui-kit'
import RandomIcon from "@cargo/common/icons/randomize.svg";


class Shuffle extends Component {
	
	constructor(props){
		super(props);

	}

	render(){
		return <Button onMouseDown={this.shuffle} className="randomize"><RandomIcon/></Button>
	}

	shuffle=(e)=>{
		e.preventDefault();

		let shuffle = {};

		for (const [key, value] of Object.entries(this.props.values)) {
			if( value.excludeFromShuffle || key === 'clipScroll' ){
				continue;
			}

			switch(value.type){
				case "color":

					let r = Math.floor(Math.random()*255);
					let g = Math.floor(Math.random()*255);
					let b = Math.floor(Math.random()*255);
					shuffle[key] = "rgb("+r+", "+g+", "+b+")";
					break;

				case "radio":
				case "select":

					shuffle[key] = value.values[Math.floor(Math.random() * value.values.length)];
					break;

				case "check-box":
					shuffle[key] = Math.random() >= .5;
					break;

				case "range-input":
				case "scrubber":
				case "css-range":

					shuffle[key] = Math.random() * (value.max - value.min) + value.min

					if( value.step){
						let inv = 1 / value.step;
						shuffle[key] = Math.round(shuffle[key] * inv) / inv;
					} else {
						shuffle[key] = Math.round(shuffle[key]);
					}
					break;
			}

		}



		if( this.props.customShuffle ){
			shuffle = this.props.customShuffle(shuffle)
		}

		if( this.props.handleShuffle){
			this.props.handleShuffle(shuffle);
		}

	}
}


export {Shuffle}

