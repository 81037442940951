import React, { Component } from 'react';
import uniqueId from 'lodash/uniqueId';

import { LoadingAnimation } from '@cargo/ui-kit';

import PublishingSpinner from "@cargo/common/icons/publishing-spinner.svg";
import PublishingCheckmark from "@cargo/common/icons/publishing-checkmark.svg";

export const PublishingAnimation = ({ publishing, reverse }) => {
	return publishing !== 'inactive' ? (
		<div className={`published-notice${publishing === 'spinner' ? ' spinner' : ''}${publishing === 'published' ? ' published': ''}${publishing === 'transition' ? ' spinner transition' : ''}${reverse ? ' reverse': ''}`}>
			{publishing === 'spinner' || publishing === 'transition' ? (
				<PublishingSpinner />
			) : ( 
				<PublishingCheckmark />
			)}
		</div>
	) : (
		null
	)
};