import { FRONTEND_DATA } from "../../../globals";

export default {


	execute: function(){
		FRONTEND_DATA.contentWindow.CargoEditor.execCommand('insertHorizontalRule');
	},
	priority: 0,
	getState: (range)=> {
		const hrNodes = FRONTEND_DATA.contentWindow.CargoEditor.helpers.getAllEffectivelyContainedNodes(
			range, 
			node => node.nodeName === "HR" 
		);
		return {
			// isApplied: hrNodes.length > 0,
			isApplied: false,
			isAllowed: true,
		};
	},

}