import { FRONTEND_DATA } from "../../../globals";
import _ from 'lodash';

export default {

	requiresTextInRange: false,
	requiresUncollapsedRange: false,
	requiresActiveRange: false,

	execute: function(newSize){
	},

	getState: (range) => {
		return {
			isAllowed: true,
			isApplied: false,
		}

	},
	priority: 1
 
}